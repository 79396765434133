import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateUser, changePassword, getLicenseInfo } from "../actions/authActions";

import Spinner from "./Spinner";
import formatTime from "../utils/formatTime";
import timeFormatterPassed from "../utils/formatTimePassed";
import longAgo from "../utils/longAgo";
import DatePicker from "react-datepicker";
import moment from "moment";
import styles from "./Settings.module.scss";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

const Settings = ({ user, updateUser, changePassword, getLicenseInfo }) => {
  let history = useHistory();
  const [startDate, setStartDate] = useState(user.quotaFinishedCountStartDate);
  const [endDate, setEndDate] = useState(user.quotaFinishedCountEndDate);

  const [namingEndpoint, setNamingEndpoint] = useState({
    enabled: user.namingEndpoint.enabled,
    includeName: user.namingEndpoint.includeName,
    url: user.namingEndpoint.url,
  });

  const [userConfig, setUserConfig] = useState({
    lolPath: user.lolPath,
    externalWebsite: user.externalWebsite,
    // showPriority: user.showPriority,
    // showUserTaken: user.showUserTaken,
    // showIps: user.showIps,
    // showMac: user.showMac,

    showDisenchant: user.showDisenchant,
    enabled: user.removeOrHideBanned.enabled,
    action: user.removeOrHideBanned.action,
    exportFormat: user.exportFormat,
    // showMode: user.showMode,
    // showMissions: user.showMissions,
    // showFlashOn: user.showFlashOn,
    // showLimitRes: user.showLimitRes,
    // architecture: user.architecture,
    // showDesiredBE: user.showDesiredBE,
    // showFinishStartDates: user.showFinishStartDates,
    // showCheckerStatus: user.showCheckerStatus,
    // showFreeNameChange: user.showFreeNameChange,
    combineMissionWaitCount: user.combineMissionWaitCount,
    // showStartToFinishTime: user.showStartToFinishTime,
    // showVmCloneNumber: user.showVmCloneNumber,
    // showLimitFps: user.showLimitFps,
    // showRp: user.showRp,
    // showBotVersion: user.showBotVersion,
    // showCountry: user.showCountry,
    // showHasActiveBoost: user.showHasActiveBoost,
    checkLeague99Issue: user.checkLeague99Issue,

    // showOrangeEssence: user.showOrangeEssence,
    // showCooldown: user.showCooldown,
    // showServerId: user.showServerId,

    // showDesiredPercentage: user.showDesiredPercentage,
    // showLastGamePlayed: user.showLastGamePlayed,
    showNameSkinSearch: user.showNameSkinSearch,
    prioType: user.prioType,
    skinLanguage: user.skinLanguage,
    showSkinRarityExport: user.showSkinRarityExport,
    minimumLevelForQuotaFinishedCount: user.minimumLevelForQuotaFinishedCount,
    changeRegionOnMaintenance: user.changeRegionOnMaintenance,
    checkMaintenances: user.checkMaintenances,

    // showForceRegionSrv: user.showForceRegionSrv,
    // showRegionSrv: user.showRegionSrv,
    // showOnlyActiveAccsSrv: user.showOnlyActiveAccsSrv,
    // showForcePrioritySrv: user.showForcePrioritySrv,
    // showPrioritySrv: user.showPrioritySrv,
    // showMaintainVmsOnSrv: user.showMaintainVmsOnSrv,
    // showNumberOfVmsSrv: user.showNumberOfVmsSrv,
    // showWindowsUpdates: user.showWindowsUpdates,
    // showCloneDrivesSrv: user.showCloneDrivesSrv,
    // showInactiveVmMinutesSrv: user.showInactiveVmMinutesSrv,
    // showVmStartDelaySrv: user.showVmStartDelaySrv,
    // showManagerStartDelaySrv: user.showManagerStartDelaySrv,
    // showMonitorHardwareSrv: user.showMonitorHardwareSrv,
    // showLogToFileSrv: user.showLogToFileSrv,
    // showAutoRestartSrv: user.showAutoRestartSrv,
    // showSpacePerVmSrv: user.showSpacePerVmSrv,
    // showRamPerVmInMbSrv: user.showRamPerVmInMbSrv,
    // showVramPerVmInKbSrv: user.showVramPerVmInKbSrv,
    // showAutoAddToDashboardSrv: user.showAutoAddToDashboardSrv,
    // showAddToStartupSrv: user.showAddToStartupSrv,
    // showCpuNameSrv: user.showCpuNameSrv,
    // showCpuGhzSrv: user.showCpuGhzSrv,
    // showGpuNameSrv: user.showGpuNameSrv,
    // showRamSrv: user.showRamSrv,
    // forceQFCountPeriod: user.forceQFCountPeriod,
    // quotaFinishedCountStartDate: startDate,
    // quotaFinishedCountEndDate: endDate,
    dashboardColumns: user.dashboardColumns,
    credentialsColumns: user.credentialsColumns,
    serverColumns: user.serverColumns,
    capsolverKey: user.capsolverKey,
    capmonsterKey: user.capmonsterKey,
    captchaaiKey: user.captchaaiKey,
    "2captchaKey": user["2captchaKey"],
    captchaOption: user.captchaOption,
    useCaptcha: user.useCaptcha,
  });

  const [customWebHooks, setCustomWebHook] = useState({
    lvl30: { enabled: user.customWebHooks.lvl30.enabled, url: user.customWebHooks.lvl30.url },
    banned: { enabled: user.customWebHooks.banned.enabled, url: user.customWebHooks.banned.url },
    lpq: { enabled: user.customWebHooks.lpq.enabled, url: user.customWebHooks.lpq.url },
    nameTaken: { enabled: user.customWebHooks.nameTaken.enabled, url: user.customWebHooks.nameTaken.url },
    noAvailableAcc: { enabled: user.customWebHooks.noAvailableAcc.enabled, url: user.customWebHooks.noAvailableAcc.url },
    activeBotsStatus: { ignoreRecentlyModifiedMinutes: user.customWebHooks.activeBotsStatus.ignoreRecentlyModifiedMinutes, ignoreRecentlyModifiedEnabled: user.customWebHooks.activeBotsStatus.ignoreRecentlyModifiedEnabled, enabled: user.customWebHooks.activeBotsStatus.enabled, url: user.customWebHooks.activeBotsStatus.url, maxActiveBots: user.customWebHooks.activeBotsStatus.maxActiveBots },
    ingamePercentageStatus: { enabled: user.customWebHooks.ingamePercentageStatus.enabled, url: user.customWebHooks.ingamePercentageStatus.url, percentageThreshold: user.customWebHooks.ingamePercentageStatus.percentageThreshold },
    afkedMatch: { enabled: user.customWebHooks.afkedMatch.enabled, url: user.customWebHooks.afkedMatch.url },

    secret: user.customWebHooks.secret,
  });
  // console.log(customWebHooks.activeBotsStatus);

  const [passwordObject, setPasswordObject] = useState({ currentPassword: "", newPassword: "", newPassword2: "" });
  const [licenseInfo, setLicenseInfo] = useState({
    licenseExpireDate: "-",
    licenseExpireIn: "-",
  });
  const [licenseInfoAvailable, setLicenseInfoAvailable] = useState(false);
  const licenseInfoSet = async () => {
    let license = await getLicenseInfo();
    setLicenseInfo(license);
    setLicenseInfoAvailable(true);
  };
  useEffect(() => {
    licenseInfoSet();
  }, []);
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [showingLicenses, setShowingLicenses] = useState(false);

  const [message, setMessage] = useState({ showing: false, type: "error", msg: "" });

  const onChangePassword = (e) => {
    setPasswordObject({ ...passwordObject, [e.target.name]: e.target.value });
  };

  const handleExportConfig = (i, action) => {
    function arraymove(arr, fromIndex, toIndex) {
      let array = arr;
      var element = array[fromIndex];
      array.splice(fromIndex, 1);
      array.splice(toIndex, 0, element);
      return array;
    }
    if (action === ">") {
      setUserConfig({ ...userConfig, exportFormat: arraymove(userConfig.exportFormat, i, i + 1) });
    } else {
      setUserConfig({ ...userConfig, exportFormat: arraymove(userConfig.exportFormat, i, i - 1) });
    }
  };
  const handleCredentialsColumns = (i, action) => {
    function arraymove(arr, fromIndex, toIndex) {
      let array = arr;
      var element = array[fromIndex];
      array.splice(fromIndex, 1);
      array.splice(toIndex, 0, element);
      return array;
    }
    if (action === ">") {
      setUserConfig({ ...userConfig, credentialsColumns: arraymove(userConfig.credentialsColumns, i, i + 1) });
    } else {
      setUserConfig({ ...userConfig, credentialsColumns: arraymove(userConfig.credentialsColumns, i, i - 1) });
    }
  };
  const handleEnableCredentialsColumns = (id) => {
    setUserConfig({ ...userConfig, credentialsColumns: userConfig.credentialsColumns.map((cl) => (cl.id == id ? { ...cl, enabled: !cl.enabled } : cl)) });
  };
  const handleDashboardColumns = (i, action) => {
    function arraymove(arr, fromIndex, toIndex) {
      let array = arr;
      var element = array[fromIndex];
      array.splice(fromIndex, 1);
      array.splice(toIndex, 0, element);
      return array;
    }
    if (action === ">") {
      setUserConfig({ ...userConfig, dashboardColumns: arraymove(userConfig.dashboardColumns, i, i + 1) });
    } else {
      setUserConfig({ ...userConfig, dashboardColumns: arraymove(userConfig.dashboardColumns, i, i - 1) });
    }
  };
  const handleEnableDashboardColumns = (id) => {
    setUserConfig({ ...userConfig, dashboardColumns: userConfig.dashboardColumns.map((cl) => (cl.id == id ? { ...cl, enabled: !cl.enabled } : cl)) });
  };
  const handleServerColumns = (i, action) => {
    function arraymove(arr, fromIndex, toIndex) {
      let array = arr;
      var element = array[fromIndex];
      array.splice(fromIndex, 1);
      array.splice(toIndex, 0, element);
      return array;
    }
    if (action === ">") {
      setUserConfig({ ...userConfig, serverColumns: arraymove(userConfig.serverColumns, i, i + 1) });
    } else {
      setUserConfig({ ...userConfig, serverColumns: arraymove(userConfig.serverColumns, i, i - 1) });
    }
  };
  const handleEnableServerColumns = (id) => {
    setUserConfig({ ...userConfig, serverColumns: userConfig.serverColumns.map((cl) => (cl.id == id ? { ...cl, enabled: !cl.enabled } : cl)) });
  };
  // console.log(licenseInfo);
  const onClickUpdatePassword = async () => {
    setMessage({ ...message, showing: false });
    setIsLoadingPassword(true);
    if (passwordObject.currentPassword === "" || passwordObject.newPassword === "" || passwordObject.newPassword2 === "") {
      setMessage({ showing: true, type: "error", msg: "Fill in all fields" });
      setIsLoadingPassword(false);
      return;
    }
    if (passwordObject.newPassword === passwordObject.newPassword2) {
      let passwordChange = await changePassword(passwordObject);
      if (!passwordChange.sucess) {
        setMessage({ showing: true, type: "error", msg: passwordChange.data });
      } else {
        setMessage({ showing: true, type: "success", msg: passwordChange.data });
      }
      setPasswordObject({ currentPassword: "", newPassword: "", newPassword2: "" });
    } else {
      setMessage({ showing: true, type: "error", msg: "Passwords do not match" });
      setIsLoadingPassword(false);

      return;
    }
    setIsLoadingPassword(false);
  };

  const onChangeCheckboxUserConfig = (e) => {
    setUserConfig({ ...userConfig, [e.target.name]: e.target.checked });
  };

  const onChange = (e) => {
    setUserConfig({ ...userConfig, [e.target.name]: e.target.value });
  };

  const onChangeCheckBox = (e) => {
    setNamingEndpoint({ ...namingEndpoint, [e.target.name]: e.target.checked });
  };

  const onChangeCheckboxIgnoreRecentlyModifiedEnabled = (e) => {
    setCustomWebHook({ ...customWebHooks, activeBotsStatus: { ...customWebHooks["activeBotsStatus"], ignoreRecentlyModifiedEnabled: e.target.checked } });
  };

  const onChangeCheckboxIgnoreRecentlyModifiedMinutes = (e) => {
    setCustomWebHook({ ...customWebHooks, activeBotsStatus: { ...customWebHooks["activeBotsStatus"], ignoreRecentlyModifiedMinutes: e.target.value } });
  };

  const onChangeUrl = (e) => {
    setNamingEndpoint({ ...namingEndpoint, url: e.target.value });
  };

  const onChangePath = (e) => {
    setUserConfig({ ...userConfig, lolPath: e.target.value });
  };
  const onClickArchitecture = (e) => {
    setUserConfig({ ...userConfig, architecture: e.target.dataset.architecture });
  };
  const onClickPrioType = (e) => {
    setUserConfig({ ...userConfig, prioType: e.target.dataset.priotype });
  };

  const onClickExternalWebsite = (e) => {
    setUserConfig({ ...userConfig, externalWebsite: e.target.dataset.website });
  };

  const onClickUpdateSettings = async () => {
    setIsLoadingSettings(true);
    const userBody = {
      ...userConfig,
      namingEndpoint,
      customWebHooks,
      removeOrHideBanned: {
        enabled: userConfig.enabled,
        action: userConfig.action,
      },
    };
    await updateUser(userBody);
    setIsLoadingSettings(false);
  };

  const onChangeCustomWebHook = (e, field) => {
    if (e.target.type === "checkbox") {
      setCustomWebHook({ ...customWebHooks, [field]: { ...customWebHooks[field], enabled: e.target.checked } });
    } else {
      setCustomWebHook({ ...customWebHooks, [field]: { ...customWebHooks[field], url: e.target.value } });
    }
  };
  const onChangeActiveBotStatus = (e, field) => {
    setCustomWebHook({ ...customWebHooks, [field]: { ...customWebHooks[field], maxActiveBots: e.target.value } });
  };
  const onChangeIngamePercentage = (e, field) => {
    setCustomWebHook({ ...customWebHooks, [field]: { ...customWebHooks[field], percentageThreshold: e.target.value } });
  };
  const onChangeSecret = (e) => {
    setCustomWebHook({ ...customWebHooks, secret: e.target.value });
  };
  const dateChangerStart = (date) => {
    setStartDate(moment(date, "Y-M-DTH:mm:s").valueOf());
    setUserConfig({ ...userConfig, quotaFinishedCountStartDate: moment(date, "Y-M-DTH:mm:s").valueOf() });
  };
  const dateChangerEnd = (date) => {
    setEndDate(moment(date, "Y-M-DTH:mm:s").valueOf());
    setUserConfig({ ...userConfig, quotaFinishedCountEndDate: moment(date, "Y-M-DTH:mm:s").valueOf() });
  };
  // console.log(userConfig.dashboardColumns);
  return (
    <div className="main">
      <>
        <div className={styles.overview}>
          <div className={styles.overviewContainer}>
            <div className={styles.overviewItem}>
              <i className="fas fa-address-book"></i>

              <div className={styles.overviewItemDesc}>
                <h2>Licenses Available</h2>
                <span>{licenseInfo.licensesCount > 0 ? licenseInfo.licensesCount : "2 (FREE)"}</span>
              </div>
            </div>
            <div className={styles.overviewItem}>
              <i className="far fa-clock"></i>
              <div className={styles.overviewItemDesc}>
                <h2>Subscription Ends</h2>
                <span>{licenseInfo.licenseExpireDate}</span>
              </div>
            </div>
            <div className={styles.overviewItem}>
              <i className="fas fa-hourglass-half"></i>

              <div className={styles.overviewItemDesc}>
                <h2>Remaining Time</h2>
                <span>{licenseInfo.licenseExpireIn}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginTop: "1em", width: "90%" }} className="button aqua-green" onClick={() => setShowingLicenses(!showingLicenses)}>
            <span>Show Licenses</span>
          </div>

          <div style={{ marginTop: "1em", marginLeft: "10px", width: "10%", backgroundColor: "rgb(203 193 82)" }} className="button" onClick={() => history.push("/buy")}>
            Buy Licenses
          </div>
        </div>
        {licenseInfoAvailable && (
          <>
            {showingLicenses && (
              <div className={styles.licensesGrid}>
                {licenseInfo.licensesEntries.map((item) => (
                  <div key={item._id} className={styles.licenseItem}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <span>
                        License Amount: <span>{item.licenses}</span>
                      </span>
                      <span>{item.licenseStart > Date.now() && <span style={{ fontWeight: "bold", float: "right", color: "red" }}>Not Started Yet</span>}</span>
                      {item.note && (
                        <div className="tooltip" style={{ border: "none" }}>
                          <span className="tooltiptext">{item.note}</span>
                          <i className="fas fa-question-circle" aria-hidden="true"></i>
                        </div>
                      )}
                    </div>
                    <p style={{ color: "white" }}>
                      License Time: <span>{timeFormatterPassed(item.expireTime - item.licenseStart)}</span>
                    </p>
                    <p style={{ color: "white" }}>
                      Starting Date: <span>{formatTime(item.licenseStart)}</span>
                    </p>
                    <p style={{ color: "white" }}>
                      Ending Date: <span>{formatTime(item.expireTime)}</span>
                    </p>

                    <p style={{ color: "white" }}>
                      License Start: <span>{longAgo(item.licenseStart)}</span>
                    </p>
                    <p style={{ color: "white" }}>
                      Time Left: <span>{timeFormatterPassed(item.licenseStart > Date.now() ? item.expireTime - item.licenseStart : item.expireTime - Date.now())}</span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        <div className={styles.settingsContainer}>
          {!isLoadingSettings ? (
            <>
              <h1 style={{ marginBottom: "0.25em", display: "flex", gap: "10px", alignItems: "center" }}>
                <i className="fa-solid fa-gears"></i>Settings
              </h1>
              <div className={styles.settingsSection}>
                {/* <div className={styles.passwordSection}>
                    <div className={styles.passwordField}>
                      <label>Account Naming Endpoint</label>
                      {namingEndpoint.enabled && <input type='text' placeholder={'https://mywebsite.com'} value={namingEndpoint.url} onChange={onChangeUrl}></input>}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input onChange={onChangeCheckBox} name='enabled' className='checkbox' type='checkbox' value={namingEndpoint.enabled} defaultChecked={namingEndpoint.enabled}></input>
                      <label>Enabled</label>
                    </div>
                    {namingEndpoint.enabled && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input onChange={onChangeCheckBox} name='includeName' className='checkbox' type='checkbox' value={namingEndpoint.includeName} defaultChecked={namingEndpoint.includeName}></input>
                        <label>Include Name</label>
                      </div>
                    )}
                  </div> */}

                <div className={styles.passwordField}>
                  <label>Game Path</label>
                  <input placeholder={"Game path folder"} value={userConfig.lolPath} onChange={onChangePath}></input>
                </div>
                <h2 style={{ marginBottom: "0.25em", display: "flex", gap: "10px", alignItems: "center" }}>
                  <i className="fa-solid fa-chart-line"></i>Dashboard
                </h2>
                <div className={styles.passwordField}>
                  <label>Architecture:</label>
                  <div className={`${styles.externalWebsite} ${userConfig.architecture == "windows" ? styles.activeWebsite : ""}`} data-architecture="windows" onClick={onClickArchitecture}>
                    Windows
                  </div>
                  <div className={`${styles.externalWebsite} ${userConfig.architecture == "esxi" ? styles.activeWebsite : ""}`} data-architecture="esxi" onClick={onClickArchitecture}>
                    ESXi
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>External Account Website:</label>
                  <div className={`${styles.externalWebsite} ${userConfig.externalWebsite == "opgg" ? styles.activeWebsite : ""}`} data-website="opgg" onClick={onClickExternalWebsite}>
                    OP.GG
                  </div>
                  <div className={`${styles.externalWebsite} ${userConfig.externalWebsite == "leaguegraphs" ? styles.activeWebsite : ""}`} data-website="leaguegraphs" onClick={onClickExternalWebsite}>
                    League Graphs
                  </div>
                </div>

                <div className={styles.passwordField}>
                  <label>Prioritize Accounts Level:</label>
                  <div className={`${styles.externalWebsite} ${userConfig.prioType == "high" ? styles.activeWebsite : ""}`} data-priotype="high" onClick={onClickPrioType}>
                    High
                  </div>
                  <div className={`${styles.externalWebsite} ${userConfig.prioType == "low" ? styles.activeWebsite : ""}`} data-priotype="low" onClick={onClickPrioType}>
                    Low
                  </div>
                  <div className={`${styles.externalWebsite} ${userConfig.prioType == "random" ? styles.activeWebsite : ""}`} data-priotype="random" onClick={onClickPrioType}>
                    Random
                  </div>
                </div>
                {/* <div className={styles.passwordField}>
                  <label>Show Account Priority: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showPriority" className="checkbox" type="checkbox" value={userConfig.showPriority} defaultChecked={userConfig.showPriority}></input>
                  </div>
                </div>

                <div className={styles.passwordField}>
                  <label>Show Flagged Username Taken accounts: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showUserTaken" className="checkbox" type="checkbox" value={userConfig.showUserTaken} defaultChecked={userConfig.showUserTaken}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Ips: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showIps" className="checkbox" type="checkbox" value={userConfig.showIps} defaultChecked={userConfig.showIps}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Server MAC: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showMac" className="checkbox" type="checkbox" value={userConfig.showMac} defaultChecked={userConfig.showMac}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Account Mode: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showMode" className="checkbox" type="checkbox" value={userConfig.showMode} defaultChecked={userConfig.showMode}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Missions: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showMissions" className="checkbox" type="checkbox" value={userConfig.showMissions} defaultChecked={userConfig.showMissions}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Disenchant Option: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showDisenchant" className="checkbox" type="checkbox" value={userConfig.showDisenchant} defaultChecked={userConfig.showDisenchant}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Flash On: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showFlashOn" className="checkbox" type="checkbox" value={userConfig.showFlashOn} defaultChecked={userConfig.showFlashOn}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Desired BE: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showDesiredBE" className="checkbox" type="checkbox" value={userConfig.showDesiredBE} defaultChecked={userConfig.showDesiredBE}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Finish and Start dates: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showFinishStartDates" className="checkbox" type="checkbox" value={userConfig.showFinishStartDates} defaultChecked={userConfig.showFinishStartDates}></input>
                  </div>
                </div>

                <div className={styles.passwordField}>
                  <label>Show Start to Finish Time (days): </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showStartToFinishTime" className="checkbox" type="checkbox" value={userConfig.showStartToFinishTime} defaultChecked={userConfig.showStartToFinishTime}></input>
                  </div>
                </div>

                <div className={styles.passwordField}>
                  <label>Show Checker Status: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showCheckerStatus" className="checkbox" type="checkbox" value={userConfig.showCheckerStatus} defaultChecked={userConfig.showCheckerStatus}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Free Name Change: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showFreeNameChange" className="checkbox" type="checkbox" value={userConfig.showFreeNameChange} defaultChecked={userConfig.showFreeNameChange}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Limit Res: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showLimitRes" className="checkbox" type="checkbox" value={userConfig.showLimitRes} defaultChecked={userConfig.showLimitRes}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Limit FPS: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showLimitFps" className="checkbox" type="checkbox" value={userConfig.showLimitFps} defaultChecked={userConfig.showLimitFps}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Bot Version: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showBotVersion" className="checkbox" type="checkbox" value={userConfig.showBotVersion} defaultChecked={userConfig.showBotVersion}></input>
                  </div>
                </div> */}
                <div className={styles.passwordField}>
                  <label>Combine Mission Wait with Quota Finished count: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="combineMissionWaitCount" className="checkbox" type="checkbox" value={userConfig.combineMissionWaitCount} defaultChecked={userConfig.combineMissionWaitCount}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Check and handle Game's Maintenances: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="checkMaintenances" className="checkbox" type="checkbox" value={userConfig.checkMaintenances} defaultChecked={userConfig.checkMaintenances}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Change Region on Game's Maintenance: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="changeRegionOnMaintenance" className="checkbox" type="checkbox" value={userConfig.changeRegionOnMaintenance} defaultChecked={userConfig.changeRegionOnMaintenance}></input>
                  </div>
                </div>
                {/* <div className={styles.passwordField}>
                  <label>Show VM Clone number: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showVmCloneNumber" className="checkbox" type="checkbox" value={userConfig.showVmCloneNumber} defaultChecked={userConfig.showVmCloneNumber}></input>
                  </div>
                </div>

                <div className={styles.passwordField}>
                  <label>Show Country: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showCountry" className="checkbox" type="checkbox" value={userConfig.showCountry} defaultChecked={userConfig.showCountry}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Orange Essence: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showOrangeEssence" className="checkbox" type="checkbox" value={userConfig.showOrangeEssence} defaultChecked={userConfig.showOrangeEssence}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Cooldown: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showCooldown" className="checkbox" type="checkbox" value={userConfig.showCooldown} defaultChecked={userConfig.showCooldown}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Server Id: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showServerId" className="checkbox" type="checkbox" value={userConfig.showServerId} defaultChecked={userConfig.showServerId}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Last Game Played: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showLastGamePlayed" className="checkbox" type="checkbox" value={userConfig.showLastGamePlayed} defaultChecked={userConfig.showLastGamePlayed}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Desired Percentage: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showDesiredPercentage" className="checkbox" type="checkbox" value={userConfig.showDesiredPercentage} defaultChecked={userConfig.showDesiredPercentage}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show RP: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showRp" className="checkbox" type="checkbox" value={userConfig.showRp} defaultChecked={userConfig.showRp}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Has Active Boost: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showHasActiveBoost" className="checkbox" type="checkbox" value={userConfig.showHasActiveBoost} defaultChecked={userConfig.showHasActiveBoost}></input>
                  </div>
                </div> */}
                <div className={styles.passwordField}>
                  <label>Show Search Skin, Summoner Name and Labels: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showNameSkinSearch" className="checkbox" type="checkbox" value={userConfig.showNameSkinSearch} defaultChecked={userConfig.showNameSkinSearch}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Check for League 99% Issue: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="checkLeague99Issue" className="checkbox" type="checkbox" value={userConfig.checkLeague99Issue} defaultChecked={userConfig.checkLeague99Issue}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Remove/Hide banned accounts:</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      If enabled "remove" will delete the account completely and "hide" will remove it from dashboard, leaving it on your credentials page.
                    </span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="enabled" className="checkbox" type="checkbox" value={userConfig.enabled} defaultChecked={userConfig.enabled}></input>
                  </div>
                  {userConfig.enabled && (
                    <div style={{ marginLeft: "1em" }} className={styles.passwordField}>
                      <label>Action:</label>
                      <select className={styles.input} style={{ display: "flex", alignItems: "center" }} name="action" onChange={onChange} defaultValue={userConfig.action}>
                        <option value="hide">Hide</option>
                        <option value="remove">Remove</option>
                      </select>
                    </div>
                  )}
                </div>

                <div className={styles.passwordField}>
                  <label>Minimum Level for Quota Finished count:</label>
                  <div className={styles.field}>
                    <input name="minimumLevelForQuotaFinishedCount" type="text" placeholder={30} value={userConfig.minimumLevelForQuotaFinishedCount} onChange={onChange}></input>
                  </div>
                </div>
                {/* <h2>Quota Finished count start to end period</h2>
                  <div className={styles.passwordField}>
                    <label>Force Period into count: </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input onChange={onChangeCheckboxUserConfig} name="forceQFCountPeriod" className="checkbox" type="checkbox" value={userConfig.forceQFCountPeriod} defaultChecked={userConfig.forceQFCountPeriod}></input>
                    </div>
                  </div>
                  <div className={styles.passwordField}>
                    <span style={{ width: "100px" }}>Start Date</span>
                    <DatePicker showYearDropdown dateFormatCalendar="MMMM" yearDrop timeInputLabel="Time:" dateFormat="MM/dd/yyyy h:mm aa" showTimeInput locale="en-GB" showWeekNumbers selectsStart startDate={startDate} endDate={endDate} selected={startDate} onChange={(date) => dateChangerStart(date)} />
                  </div>
                  <div className={styles.passwordField}>
                    <span style={{ width: "100px" }}>End Date</span>
                    <DatePicker showYearDropdown dateFormatCalendar="MMMM" yearDrop timeInputLabel="Time:" dateFormat="MM/dd/yyyy h:mm aa" showTimeInput locale="en-GB" showWeekNumbers selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} selected={endDate} onChange={(date) => dateChangerEnd(date)} />
                  </div> */}
                {/* <h2>Servers</h2>
                <div className={styles.passwordField}>
                  <label>Show Force Region: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showForceRegionSrv" className="checkbox" type="checkbox" value={userConfig.showForceRegionSrv} defaultChecked={userConfig.showForceRegionSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Region: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showRegionSrv" className="checkbox" type="checkbox" value={userConfig.showRegionSrv} defaultChecked={userConfig.showRegionSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Only Active Accs: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showOnlyActiveAccsSrv" className="checkbox" type="checkbox" value={userConfig.showOnlyActiveAccsSrv} defaultChecked={userConfig.showOnlyActiveAccsSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Force Priority: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showForcePrioritySrv" className="checkbox" type="checkbox" value={userConfig.showForcePrioritySrv} defaultChecked={userConfig.showForcePrioritySrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Priority: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showPrioritySrv" className="checkbox" type="checkbox" value={userConfig.showPrioritySrv} defaultChecked={userConfig.showPrioritySrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Maintain Vms On: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showMaintainVmsOnSrv" className="checkbox" type="checkbox" value={userConfig.showMaintainVmsOnSrv} defaultChecked={userConfig.showMaintainVmsOnSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Number of Vms: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showNumberOfVmsSrv" className="checkbox" type="checkbox" value={userConfig.showNumberOfVmsSrv} defaultChecked={userConfig.showNumberOfVmsSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Windows Updates: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showWindowsUpdates" className="checkbox" type="checkbox" value={userConfig.showWindowsUpdates} defaultChecked={userConfig.showWindowsUpdates}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Clone Drives: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showCloneDrivesSrv" className="checkbox" type="checkbox" value={userConfig.showCloneDrivesSrv} defaultChecked={userConfig.showCloneDrivesSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Inactive VM Minutes: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showInactiveVmMinutesSrv" className="checkbox" type="checkbox" value={userConfig.showInactiveVmMinutesSrv} defaultChecked={userConfig.showInactiveVmMinutesSrv}></input>
                  </div>
                </div>

                <div className={styles.passwordField}>
                  <label>Show Vm Start Delay: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showVmStartDelaySrv" className="checkbox" type="checkbox" value={userConfig.showVmStartDelaySrv} defaultChecked={userConfig.showVmStartDelaySrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Manager Start Delay: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showManagerStartDelaySrv" className="checkbox" type="checkbox" value={userConfig.showManagerStartDelaySrv} defaultChecked={userConfig.showManagerStartDelaySrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Monitor Hardware: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showMonitorHardwareSrv" className="checkbox" type="checkbox" value={userConfig.showMonitorHardwareSrv} defaultChecked={userConfig.showMonitorHardwareSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Log to File: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showLogToFileSrv" className="checkbox" type="checkbox" value={userConfig.showLogToFileSrv} defaultChecked={userConfig.showLogToFileSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Auto Restart: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showAutoRestartSrv" className="checkbox" type="checkbox" value={userConfig.showAutoRestartSrv} defaultChecked={userConfig.showAutoRestartSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Space per Vm: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showSpacePerVmSrv" className="checkbox" type="checkbox" value={userConfig.showSpacePerVmSrv} defaultChecked={userConfig.showSpacePerVmSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show RAM per Vm in MB: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showRamPerVmInMbSrv" className="checkbox" type="checkbox" value={userConfig.showRamPerVmInMbSrv} defaultChecked={userConfig.showRamPerVmInMbSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show VRAM per Vm in KB: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showVramPerVmInKbSrv" className="checkbox" type="checkbox" value={userConfig.showVramPerVmInKbSrv} defaultChecked={userConfig.showVramPerVmInKbSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Auto Add to Dashboard: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showAutoAddToDashboardSrv" className="checkbox" type="checkbox" value={userConfig.showAutoAddToDashboardSrv} defaultChecked={userConfig.showAutoAddToDashboardSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show Add to Startup: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showAddToStartupSrv" className="checkbox" type="checkbox" value={userConfig.showAddToStartupSrv} defaultChecked={userConfig.showAddToStartupSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show CPU Name: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showCpuNameSrv" className="checkbox" type="checkbox" value={userConfig.showCpuNameSrv} defaultChecked={userConfig.showCpuNameSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show CPU Ghz: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showCpuGhzSrv" className="checkbox" type="checkbox" value={userConfig.showCpuGhzSrv} defaultChecked={userConfig.showCpuGhzSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show GPU Name: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showGpuNameSrv" className="checkbox" type="checkbox" value={userConfig.showGpuNameSrv} defaultChecked={userConfig.showGpuNameSrv}></input>
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Show RAM: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showRamSrv" className="checkbox" type="checkbox" value={userConfig.showRamSrv} defaultChecked={userConfig.showRamSrv}></input>
                  </div>
                </div> */}
                <h2 style={{ marginBottom: "0.25em", display: "flex", gap: "10px", alignItems: "center" }}>
                  <i className="fas fa-robot"></i>Captchas
                </h2>
                <div className={styles.settingsSection}>
                  <div className={styles.passwordField}>
                    <label>Use Captchas: </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input onChange={onChangeCheckboxUserConfig} name="useCaptcha" className="checkbox" type="checkbox" value={userConfig.useCaptcha} defaultChecked={userConfig.useCaptcha}></input>
                    </div>
                  </div>
                  <div className={styles.passwordField}>
                    <label>Captcha Service:</label>
                    <select className={styles.input} style={{ width: "180px", marginLeft: "1em", display: "flex", alignItems: "center" }} name="captchaOption" onChange={onChange} defaultValue={userConfig.captchaOption}>
                      <option value="capsolver">Capsolver</option>
                      <option value="capmonster">Capmonster</option>
                      <option value="captchaai">CaptchaAI</option>
                      <option value="2captcha">2Captcha</option>
                    </select>
                  </div>

                  {/* <div className={styles.passwordField}>
                    <label>Balance:</label>
                    <button
                      className={`${styles.green}`}
                      style={{ marginLeft: "1em" }}
                      onClick={() => {
                        // handlePopup();
                      }}
                    >
                      Manage
                    </button>
                  </div> */}
                  {userConfig.captchaOption == "capsolver" && (
                    <div className={styles.passwordField}>
                      <label>Capsolver Key</label>
                      <input style={{ width: "300px" }} placeholder={"CAPTCHA KEY"} name="capsolverKey" value={userConfig.capsolverKey} onChange={onChange}></input>
                    </div>
                  )}
                  {userConfig.captchaOption == "capmonster" && (
                    <div className={styles.passwordField}>
                      <label>Capmonster Key</label>
                      <input style={{ width: "300px" }} placeholder={"CAPTCHA KEY"} name="capmonsterKey" value={userConfig.capmonsterKey} onChange={onChange}></input>
                    </div>
                  )}
                  {userConfig.captchaOption == "captchaai" && (
                    <div className={styles.passwordField}>
                      <label>CaptchaAI Key</label>
                      <input style={{ width: "300px" }} placeholder={"CAPTCHA KEY"} name="captchaaiKey" value={userConfig.captchaaiKey} onChange={onChange}></input>
                    </div>
                  )}
                  {userConfig.captchaOption == "2captcha" && (
                    <div className={styles.passwordField}>
                      <label>2Captcha Key</label>
                      <input style={{ width: "300px" }} placeholder={"CAPTCHA KEY"} name="2captchaKey" value={userConfig["2captchaKey"]} onChange={onChange}></input>
                    </div>
                  )}
                </div>
                <h2 style={{ marginBottom: "0.25em", display: "flex", gap: "10px", alignItems: "center" }}>
                  <i className="fa-solid fa-vest-patches"></i>Skins
                </h2>

                <div className={styles.passwordField}>
                  <div className={styles.passwordField}>
                    <label>Language:</label>
                    <select className={styles.input} style={{ width: "180px", marginLeft: "1em", display: "flex", alignItems: "center" }} name="skinLanguage" onChange={onChange} defaultValue={userConfig.skinLanguage}>
                      <option value="-">Default Ingame Language</option>
                      <option value="en">English</option>
                      <option value="es">Spanish</option>
                      <option value="pt">Portuguese</option>
                      <option value="ru">Russian</option>
                      <option value="tr">Turkish</option>
                      <option value="fr">French</option>
                      <option value="jp">Japanese</option>
                      <option value="pl">Polish</option>
                    </select>
                  </div>
                </div>
                <div className={styles.passwordField} style={{ marginTop: "0.5em" }}>
                  <label>Show Skin Rarity on Export: </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input onChange={onChangeCheckboxUserConfig} name="showSkinRarityExport" className="checkbox" type="checkbox" value={userConfig.showSkinRarityExport} defaultChecked={userConfig.showSkinRarityExport}></input>
                  </div>
                </div>

                <h2 style={{ margin: "1em 0em 0.5em 0em", display: "flex", gap: "10px", alignItems: "center" }}>
                  <i className="fa-solid fa-chart-column"></i>Customize Dashboard Page Information
                </h2>
                <div style={{ display: "flex", color: "silver" }}>
                  <label>Order and fields</label>
                  <div className={`tooltip`} style={{ border: "none", marginLeft: "5px" }}>
                    <span className="tooltiptext">Click on the name of each column name to enable or disable it and choose their order clicking on the arrows to move them around.</span>
                    <i className="fas fa-question-circle"></i>
                  </div>
                </div>
                <div className={styles.passwordField} style={{ flexWrap: "wrap" }}>
                  {userConfig.dashboardColumns.map((exp, i) => (
                    <div key={exp.name} className={styles.exportItem} style={{ padding: "10px", opacity: exp.enabled ? "100%" : "20%" }}>
                      {i > 0 && <i onClick={() => handleEnableDashboardColumns(i, "<")} className="fas fa-arrow-left"></i>}
                      <span title="Click to enable or disable" onClick={() => handleEnableDashboardColumns(exp.id)} style={{ padding: "0px 5px 0px 5px", cursor: "pointer" }}>
                        {exp.name}
                      </span>

                      {i < user.dashboardColumns.length - 1 && <i onClick={() => handleEnableDashboardColumns(i, ">")} className="fas fa-arrow-right"></i>}
                    </div>
                  ))}
                </div>

                <h2 style={{ margin: "1em 0em 0.5em 0em", display: "flex", gap: "10px", alignItems: "center" }}>
                  <i className="fa-regular fa-rectangle-list"></i>Customize Credentials Page Information
                </h2>
                <div style={{ display: "flex", color: "silver" }}>
                  <label>Order and fields</label>
                  <div className={`tooltip`} style={{ border: "none", marginLeft: "5px" }}>
                    <span className="tooltiptext">Click on the name of each column name to enable or disable it and choose their order clicking on the arrows to move them around.</span>
                    <i className="fas fa-question-circle"></i>
                  </div>
                </div>
                <div className={styles.passwordField} style={{ flexWrap: "wrap" }}>
                  {userConfig.credentialsColumns.map((exp, i) => (
                    <div key={exp.name} className={styles.exportItem} style={{ padding: "10px", opacity: exp.enabled ? "100%" : "20%" }}>
                      {i > 0 && <i onClick={() => handleCredentialsColumns(i, "<")} className="fas fa-arrow-left"></i>}
                      <span title="Click to enable or disable" onClick={() => handleEnableCredentialsColumns(exp.id)} style={{ padding: "0px 5px 0px 5px", cursor: "pointer" }}>
                        {exp.name}
                      </span>

                      {i < user.credentialsColumns.length - 1 && <i onClick={() => handleCredentialsColumns(i, ">")} className="fas fa-arrow-right"></i>}
                    </div>
                  ))}
                </div>

                <h2 style={{ marginBottom: "0.5em", display: "flex", gap: "10px", alignItems: "center" }}>
                  <i className="fa-solid fas fa-server"></i>Customize Server Page Information
                </h2>
                <div style={{ display: "flex", color: "silver" }}>
                  <label>Order and fields</label>
                  <div className={`tooltip`} style={{ border: "none", marginLeft: "5px" }}>
                    <span className="tooltiptext">Click on the name of each column name to enable or disable it and choose their order clicking on the arrows to move them around.</span>
                    <i className="fas fa-question-circle"></i>
                  </div>
                </div>
                <div className={styles.passwordField} style={{ flexWrap: "wrap" }}>
                  {userConfig.serverColumns.map((exp, i) => (
                    <div key={exp.name} className={styles.exportItem} style={{ padding: "10px", opacity: exp.enabled ? "100%" : "20%" }}>
                      {i > 0 && <i onClick={() => handleServerColumns(i, "<")} className="fas fa-arrow-left"></i>}
                      <span title="Click to enable or disable" onClick={() => handleEnableServerColumns(exp.id)} style={{ padding: "0px 5px 0px 5px", cursor: "pointer" }}>
                        {exp.name}
                      </span>

                      {i < user.serverColumns.length - 1 && <i onClick={() => handleServerColumns(i, ">")} className="fas fa-arrow-right"></i>}
                    </div>
                  ))}
                </div>

                <h2 style={{ marginBottom: "0.5em", display: "flex", gap: "10px", alignItems: "center" }}>
                  <i className="fa-solid fa-file-export"></i>Export Options
                </h2>

                <label style={{ color: "silver" }}>Exporting Order:</label>
                <div className={styles.passwordField} style={{ flexWrap: "wrap" }}>
                  {user.exportFormat.map((exp, i) => (
                    <div key={exp.name} className={styles.exportItem} style={{ padding: "10px" }}>
                      {i > 0 && <i onClick={() => handleExportConfig(i, "<")} className="fas fa-arrow-left"></i>}
                      <span style={{ padding: "0px 5px 0px 5px" }}>{exp.name}</span>

                      {i < user.exportFormat.length - 1 && <i onClick={() => handleExportConfig(i, ">")} className="fas fa-arrow-right"></i>}
                    </div>
                  ))}
                </div>
                <h2 style={{ marginBottom: "0.5em", display: "flex", gap: "10px", alignItems: "center" }}>
                  <i className="fa-solid fa-paper-plane"></i>Custom Web Hooks
                </h2>

                <div className={styles.passwordField}>
                  <label>Quota Finished Event:</label>
                  <div className={styles.field}>
                    <label>Enabled</label>
                    <input onChange={(e) => onChangeCustomWebHook(e, "lvl30")} name="enabled" className="checkbox" type="checkbox" value={customWebHooks.lvl30.enabled} defaultChecked={customWebHooks.lvl30.enabled}></input>
                    {customWebHooks.lvl30.enabled && (
                      <>
                        <label>Url:</label>
                        <input name="url" type="text" placeholder={"https://mywebsite.com/api"} value={customWebHooks.lvl30.url} onChange={(e) => onChangeCustomWebHook(e, "lvl30")}></input>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Banned Account Event:</label>
                  <div className={styles.field}>
                    <label>Enabled</label>
                    <input onChange={(e) => onChangeCustomWebHook(e, "banned")} name="enabled" className="checkbox" type="checkbox" value={customWebHooks.banned.enabled} defaultChecked={customWebHooks.banned.enabled}></input>
                    {customWebHooks.banned.enabled && (
                      <>
                        <label>Url:</label>
                        <input name="url" type="text" placeholder={"https://mywebsite.com/api"} value={customWebHooks.banned.url} onChange={(e) => onChangeCustomWebHook(e, "banned")}></input>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>LPQ Event:</label>
                  <div className={styles.field}>
                    <label>Enabled</label>
                    <input onChange={(e) => onChangeCustomWebHook(e, "lpq")} name="enabled" className="checkbox" type="checkbox" value={customWebHooks.lpq.enabled} defaultChecked={customWebHooks.lpq.enabled}></input>
                    {customWebHooks.lpq.enabled && (
                      <>
                        <label>Url:</label>
                        <input name="url" type="text" placeholder={"https://mywebsite.com/api"} value={customWebHooks.lpq.url} onChange={(e) => onChangeCustomWebHook(e, "lpq")}></input>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.passwordField}>
                  <label>Name Taken Event:</label>
                  <div className={styles.field}>
                    <label>Enabled</label>
                    <input onChange={(e) => onChangeCustomWebHook(e, "nameTaken")} name="enabled" className="checkbox" type="checkbox" value={customWebHooks.nameTaken.enabled} defaultChecked={customWebHooks.nameTaken.enabled}></input>
                    {customWebHooks.nameTaken.enabled && (
                      <>
                        <label>Url:</label>
                        <input name="nameTaken" type="text" placeholder={"https://mywebsite.com/api"} value={customWebHooks.nameTaken.url} onChange={(e) => onChangeCustomWebHook(e, "nameTaken")}></input>
                      </>
                    )}
                  </div>
                </div>

                <div className={styles.passwordField}>
                  <label>No Available Account Event:</label>
                  <div className={styles.field}>
                    <label>Enabled</label>
                    <input onChange={(e) => onChangeCustomWebHook(e, "noAvailableAcc")} name="enabled" className="checkbox" type="checkbox" value={customWebHooks.noAvailableAcc.enabled} defaultChecked={customWebHooks.noAvailableAcc.enabled}></input>
                    {customWebHooks.noAvailableAcc.enabled && (
                      <>
                        <label>Url:</label>
                        <input name="noAvailableAcc" type="text" placeholder={"https://mywebsite.com/api"} value={customWebHooks.noAvailableAcc.url} onChange={(e) => onChangeCustomWebHook(e, "noAvailableAcc")}></input>
                      </>
                    )}
                  </div>
                </div>

                <div className={styles.passwordField}>
                  <label>Active Bots Status:</label>
                  <div className={styles.field}>
                    <label>Enabled</label>
                    <input onChange={(e) => onChangeCustomWebHook(e, "activeBotsStatus")} name="enabled" className="checkbox" type="checkbox" value={customWebHooks.activeBotsStatus.enabled} defaultChecked={customWebHooks.activeBotsStatus.enabled}></input>
                    {customWebHooks.activeBotsStatus.enabled && (
                      <>
                        <label>Url:</label>
                        <input name="activeBotsStatus" type="text" placeholder={"https://mywebsite.com/api"} value={customWebHooks.activeBotsStatus.url} onChange={(e) => onChangeCustomWebHook(e, "activeBotsStatus")}></input>

                        <label>Max Bots:</label>
                        <input style={{ width: "50px" }} name="activeBotsStatus" placeholder="max number of bots" type="text" value={customWebHooks.activeBotsStatus.maxActiveBots} onChange={(e) => onChangeActiveBotStatus(e, "activeBotsStatus")}></input>
                      </>
                    )}
                  </div>
                </div>
                {customWebHooks.activeBotsStatus.enabled && (
                  <div className={styles.passwordField}>
                    <div className={styles.field} style={{ margin: "0px 0px 10px 0px" }}>
                      <label>Dont count as inactive if last update was recently:</label>
                      <input onChange={(e) => onChangeCheckboxIgnoreRecentlyModifiedEnabled(e)} name="ignoreRecentlyModifiedEnabled" className="checkbox" type="checkbox" value={customWebHooks.activeBotsStatus.ignoreRecentlyModifiedEnabled} defaultChecked={customWebHooks.activeBotsStatus.ignoreRecentlyModifiedEnabled}></input>
                      {customWebHooks.activeBotsStatus.ignoreRecentlyModifiedEnabled && (
                        <>
                          <label>Not inactive if last update was less than (minutes) ago:</label>
                          <input style={{ width: "50px" }} name="activeBotsStatus" placeholder="minutes" type="text" value={customWebHooks.activeBotsStatus.ignoreRecentlyModifiedMinutes} onChange={(e) => onChangeCheckboxIgnoreRecentlyModifiedMinutes(e)}></input>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className={styles.passwordField}>
                  <label>Ingame Percentage Status:</label>
                  <div className={styles.field}>
                    <label>Enabled</label>
                    <input onChange={(e) => onChangeCustomWebHook(e, "ingamePercentageStatus")} name="enabled" className="checkbox" type="checkbox" value={customWebHooks.ingamePercentageStatus.enabled} defaultChecked={customWebHooks.ingamePercentageStatus.enabled}></input>
                    {customWebHooks.ingamePercentageStatus.enabled && (
                      <>
                        <label>Url:</label>
                        <input name="ingamePercentageStatus" type="text" placeholder={"https://mywebsite.com/api"} value={customWebHooks.ingamePercentageStatus.url} onChange={(e) => onChangeCustomWebHook(e, "ingamePercentageStatus")}></input>

                        <label>Percentage Threshold:</label>
                        <input name="ingamePercentageStatus" placeholder="minimum percentage to trigger" type="text" value={customWebHooks.ingamePercentageStatus.percentageThreshold} onChange={(e) => onChangeIngamePercentage(e, "ingamePercentageStatus")}></input>
                      </>
                    )}
                  </div>
                </div>

                <div className={styles.passwordField}>
                  <label>AFK Match Notification:</label>
                  <div className={styles.field}>
                    <label>Enabled</label>
                    <input onChange={(e) => onChangeCustomWebHook(e, "afkedMatch")} name="enabled" className="checkbox" type="checkbox" value={customWebHooks.afkedMatch.enabled} defaultChecked={customWebHooks.afkedMatch.enabled}></input>
                    {customWebHooks.afkedMatch.enabled && (
                      <>
                        <label>Url:</label>
                        <input name="afkedMatch" type="text" placeholder={"https://mywebsite.com/api"} value={customWebHooks.afkedMatch.url} onChange={(e) => onChangeCustomWebHook(e, "afkedMatch")}></input>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.passwordField} style={{ marginTop: "10px" }}>
                  <label>Secret Key</label>
                  <input placeholder={"Secret Key"} value={customWebHooks.secret} onChange={onChangeSecret}></input>
                </div>
              </div>
              <div style={{ width: "120px", marginTop: "2em" }} className="button aqua-green" onClick={onClickUpdateSettings}>
                <span>Update Settings</span>
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </div>

        <div className={styles.settingsContainer} style={{ paddingBottom: "4em" }}>
          {!isLoadingPassword ? (
            <>
              <h1>Change Password</h1>
              <div className={styles.passwordSection} style={{ flexDirection: "column", alignItems: "initial" }}>
                <div className={styles.passwordField} style={{ justifyContent: "space-between" }}>
                  <label>Current Password</label>
                  <input type="password" onChange={onChangePassword} name="currentPassword" placeholder={"*******"}></input>
                </div>
                <div className={styles.passwordField} style={{ justifyContent: "space-between" }}>
                  <label>New Password</label>
                  <input type="password" onChange={onChangePassword} name="newPassword" placeholder={"*******"}></input>
                </div>
                <div className={styles.passwordField} style={{ justifyContent: "space-between" }}>
                  <label>Repeat New Password</label>
                  <input type="password" onChange={onChangePassword} name="newPassword2" placeholder={"*******"}></input>
                </div>
                <div style={{ marginTop: "1em", width: "140px" }} className="button aqua-green" onClick={onClickUpdatePassword}>
                  <span>Change Password</span>
                </div>
                {message.showing && (
                  <div className={message.type === "error" ? styles.error : styles.success}>
                    <span>{message.msg}</span>
                  </div>
                )}
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, { updateUser, changePassword, getLicenseInfo })(Settings);
