import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Spinner from "../Spinner";
import { deleteTask, toggleActive } from "../../actions/taskActions";
import styles from "./Tasks.module.scss";

const TaskItem = ({ task, deleteTask, toggleActive, sendPopupShowing2, isPopupShowing2 }) => {
  const onClickDelete = () => {
    deleteTask(task._id);
  };
  const onClickEditTask = () => {
    sendPopupShowing2({
      showing: !isPopupShowing2.showing,
      task,
    });
  };
  const onClickToggleActive = () => {
    toggleActive(task._id);
  };
  const filterTypesMap = {
    eq: "equal",
    neq: "not equal",
    gt: "greater than",
    gte: "greater than or equal",
    lt: "less than",
    lte: "less than or equal",
  };

  return (
    <>
      <div className={styles.taskItem} style={!task.isActive ? { opacity: "50%" } : {}}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className={styles.taskName}>{task.name}</span>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <div className={styles.editTaskButton + " button"} onClick={onClickEditTask}>
              <span>Edit</span>
            </div>
            <div style={{ backgroundColor: task.isActive ? "#4caf50" : "#e33434" }} className={styles.removeTaskButton + " button"} onClick={onClickToggleActive}>
              {task.isActive ? (
                <div>
                  <span>Active</span>
                  <i style={{ marginLeft: "5px", color: "green" }} className={`fas fa-check`} />
                </div>
              ) : (
                <div>
                  <span>Not Active</span>
                  <i style={{ marginLeft: "5px", color: "#970000" }} className="fas fa-times"></i>
                </div>
              )}
            </div>
            <div style={{ width: "25px" }} className={styles.removeTaskButton + " button"} onClick={onClickDelete}>
              <i style={{ color: "white" }} className="fas fa-times"></i>
            </div>
          </div>
        </div>

        <div className={styles.taskFiltersContainer}>
          <h3>Filters</h3>
          <div className={styles.taskFilters}>
            {task.filter.map((filter) => (
              <div key={filter._id} className={styles.taskFilterItem}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
                  <span>Filter Type: </span>
                  <span style={{ color: "#cfcfcf", fontWeight: "700" }}>{filterTypesMap[filter.filterType].toUpperCase()}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
                  <span>Field Name: </span>
                  <span style={{ color: "#cfcfcf", fontWeight: "700" }}>{filter.fieldName}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
                  <span>Field Value: </span>
                  <span style={{ color: "#cfcfcf", fontWeight: "700" }}>{filter.fieldValue}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.taskFiltersContainer}>
          <h3>Updates</h3>
          <div className={styles.taskFilters}>
            {task.update.map((update) => (
              <div key={update._id} className={styles.taskFilterItem}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
                  <span>Update Type: </span>
                  <span style={{ color: "#cfcfcf", fontWeight: "700" }}>{update.updateType.toUpperCase()}</span>
                </div>
                {update.updateType != "manual_finish" && (
                  <>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
                      <span>Field Name: </span>
                      <span style={{ color: "#cfcfcf", fontWeight: "700" }}>{update.fieldName}</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
                      <span>Field Value: </span>
                      <span style={{ color: "#cfcfcf", fontWeight: "700" }}>{update.fieldValue}</span>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { deleteTask, toggleActive })(TaskItem);
