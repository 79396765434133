import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import CustomNamesItem from './CustomNamesItem';
import Spinner from "../Spinner";
import TaskItem from "./TaskItem";
import CreateTask from "./CreateTask";
import EditTask from "./EditTask";

import { getTasks } from "../../actions/taskActions";

import styles from "./Tasks.module.scss";

const Tasks = ({ tasks, getTasks }) => {
  useEffect(() => {
    getTasks();
  }, []);
  const [isPopupShowing, setIsPopupShowing] = useState({ showing: false });
  const [isPopupShowing2, setIsPopupShowing2] = useState({ showing: false });

  const [currentTasks, setCurrentTasks] = useState(tasks);
  useEffect(() => {
    if (tasks) {
      setCurrentTasks(tasks);
    }
  }, [tasks]);

  const onClickAddTask = () => {
    setIsPopupShowing({ showing: true });
  };
  return (
    <>
      {isPopupShowing.showing && <CreateTask isPopupShowing={isPopupShowing} sendPopupShowing={(v) => setIsPopupShowing(v)} />}
      {isPopupShowing2.showing && <EditTask isPopupShowing2={isPopupShowing2} sendPopupShowing2={(v) => setIsPopupShowing2(v)} />}

      <div className="main" style={{ paddingBottom: "2.5em" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "15px" }}>
          <span className={styles.tasksTitle}>Tasks</span>
          <div style={{ width: "7em" }} className={"button aqua-green"} onClick={onClickAddTask}>
            <span>Create Task</span>
          </div>
        </div>

        {currentTasks ? (
          <>
            {currentTasks.length > 0 ? (
              <>
                {currentTasks.map((task) => (
                  <TaskItem key={task._id} task={task} isPopupShowing={isPopupShowing} sendPopupShowing={(v) => setIsPopupShowing(v)} isPopupShowing2={isPopupShowing2} sendPopupShowing2={(v) => setIsPopupShowing2(v)} />
                ))}
              </>
            ) : (
              <h2>You have no tasks created, add one!</h2>
            )}
          </>
        ) : (
          <Spinner />
        )}
        <div></div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  tasks: state.tasks.tasks,
});
export default connect(mapStateToProps, { getTasks })(Tasks);
