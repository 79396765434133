import { SET_SKIN_DATA, GET_ACCOUNTS, UPDATE_ACCOUNTS, UPDATE_ACCOUNT_DASHBOARD, REMOVE_DASHBOARD, DELETE_ACCOUNT_ERROR, GET_ACCOUNTS_CREDENTIALS, CREATE_ACCOUNT_ERROR, DELETE_ACCOUNT, CLEAR_ACCOUNTS_CREDENTIALS, GET_ACCOUNTS_CREDENTIALS_ERROR, UPDATE_ACCOUNT_STATE, UPDATE_ACCOUNT_ERROR, UNSET_LOADING_ACCOUNTS, GET_ACCOUNTS_ERROR, UPDATE_ACCOUNT_STATUS, CREATE_ACCOUNT, UPDATE_ACCOUNT } from "./types";

import axios from "../utils/axiosInstance";
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

// Gett all accounts (admin)
export const getSkinsData = () => async (dispatch) => {
  try {
    // console.log("HERE CARAJO");
    const res = await axios.get("/api/v1/build/skins");
    // console.log(res.data);
    dispatch({
      type: SET_SKIN_DATA,
      payload: res.data.data,
    });
  } catch (err) {}
};

// Gett all accounts (admin)
export const getAccounts = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/accounts/all");

    dispatch({ type: UNSET_LOADING_ACCOUNTS });
    dispatch({
      type: GET_ACCOUNTS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: GET_ACCOUNTS_ERROR, payload: err });
  }
};
export const removeAccountsFromDashboard = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/accounts/remove-dashboard`, ids, config);
    // console.log(res.data.data);
    dispatch({
      type: REMOVE_DASHBOARD,
      payload: res.data.data,
    });
    // res.data.data.forEach((acc) => {
    //   dispatch({
    //     type: REMOVE_DASHBOARD,
    //     payload: acc,
    //   });
    // });
  } catch (err) {
    // console.log("here?", err);
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};

export const addToDashboard = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/accounts/${id}/add-dashboard`);

    dispatch({
      type: UPDATE_ACCOUNT_DASHBOARD,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};

export const setOfflineTesting = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/accounts/${id}/set-offline-testing`);
    dispatch({
      type: UPDATE_ACCOUNT_STATUS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};

// Gett all accounts from user
export const getMyAccounts = (fields) => async (dispatch) => {
  try {
    // console.log(fields, "fields from AccountActions");
    const res = await axios.get("/api/v1/accounts", { headers: { fields } });
    // console.log(res.data);
    dispatch({ type: UNSET_LOADING_ACCOUNTS });
    dispatch({
      type: GET_ACCOUNTS,
      payload: res.data.data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({ type: GET_ACCOUNTS_ERROR, payload: err });
  }
};
export const clearAccountsCredentials = () => async (dispatch) => {
  dispatch({ type: CLEAR_ACCOUNTS_CREDENTIALS });
};
export const getMyAccountsCredentials = (password) => async (dispatch) => {
  const body = { password };

  try {
    const res = await axios.post("/api/v1/accounts/credentials", body, config);

    dispatch({ type: UNSET_LOADING_ACCOUNTS });
    dispatch({
      type: GET_ACCOUNTS_CREDENTIALS,
      payload: res.data.data,
    });
  } catch (err) {
    const errorMessage = err.response.data.error;
    dispatch({ type: GET_ACCOUNTS_CREDENTIALS_ERROR, payload: errorMessage });
  }
};

// Create accounts
export const updateAccount = (updatedBody) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/accounts`, updatedBody, config);

    dispatch({ type: UPDATE_ACCOUNTS, payload: res.data.data });

    // res.data.data.forEach((acc) => {
    //   dispatch({ type: UPDATE_ACCOUNT, payload: acc });
    // });

    // dispatch({ type: UPDATE_ACCOUNT, payload: newAccount });
  } catch (err) {
    dispatch({ type: CREATE_ACCOUNT_ERROR, payload: err });
  }
};

// Create accounts
export const updateAccountQuotaFinished = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/accounts/quota-finished`, ids, config);

    res.data.data.forEach((acc) => {
      dispatch({ type: UPDATE_ACCOUNT, payload: acc });
    });

    // dispatch({ type: UPDATE_ACCOUNT, payload: newAccount });
  } catch (err) {
    dispatch({ type: CREATE_ACCOUNT_ERROR, payload: err });
  }
};

// Create accounts
export const addAccounts = (accounts) => async (dispatch) => {
  try {
    const createdAccounts = await axios.post("/api/v1/accounts", accounts, config);

    // const createdAccounts = await promisesHandler(accountCreationProm)

    dispatch({ type: CREATE_ACCOUNT, payload: createdAccounts.data.data });
    // createdAccounts.data.data.forEach((acc) => {
    // });
  } catch (err) {
    dispatch({ type: CREATE_ACCOUNT_ERROR, payload: err });
  }
};

// Delete account
export const deleteAccounts =
  (ids, updateInClient = false) =>
  async (dispatch) => {
    try {
      let res = await axios.delete("/api/v1/accounts", { data: ids });
      const ids_ = res.data.data;
      if (updateInClient) {
        dispatch({ type: DELETE_ACCOUNT, payload: ids });
      }
      // ids_.forEach((id) => {
      // });
    } catch (err) {
      dispatch({ type: DELETE_ACCOUNT_ERROR, payload: "error" });
    }
  };
// Pause Account
export const pauseAccounts = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`api/v1/accounts/pause`, ids, config);

    dispatch({
      type: UPDATE_ACCOUNT_STATUS,
      payload: res.data.data,
    });
    // res.data.data.forEach((acc) => {
    //   dispatch({
    //     type: UPDATE_ACCOUNT_STATUS,
    //     payload: acc,
    //   });
    // });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};
// Revert action
export const revertActions = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/accounts/${ids[0]}/revert-action`);

    dispatch({
      type: UPDATE_ACCOUNT_STATUS,
      payload: [res.data.data],
    });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};
// Unpause Account
export const unpauseAccounts = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`api/v1/accounts/unpause`, ids, config);

    dispatch({
      type: UPDATE_ACCOUNT_STATUS,
      payload: res.data.data,
    });
    // res.data.data.forEach((acc) => {
    //   dispatch({
    //     type: UPDATE_ACCOUNT_STATUS,
    //     payload: acc,
    //   });
    // });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};
// Stop Account
export const stopAccounts = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`api/v1/accounts/stop`, ids, config);
    dispatch({
      type: UPDATE_ACCOUNT_STATUS,
      payload: res.data.data,
    });
    // res.data.data.forEach((acc) => {
    //   dispatch({
    //     type: UPDATE_ACCOUNT_STATUS,
    //     payload: acc,
    //   });
    // });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};
// Restart Account
export const restartAccounts = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`api/v1/accounts/restart`, ids, config);
    dispatch({
      type: UPDATE_ACCOUNT_STATUS,
      payload: res.data.data,
    });
    // res.data.data.forEach((acc) => {
    //   dispatch({
    //     type: UPDATE_ACCOUNT_STATUS,
    //     payload: acc,
    //   });
    // });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};
// Power Off Account
export const powerOffAccounts = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`api/v1/accounts/off`, ids, config);
    dispatch({
      type: UPDATE_ACCOUNT_STATE,
      payload: res.data.data,
    });
    // res.data.data.forEach((acc) => {
    //   dispatch({
    //     type: UPDATE_ACCOUNT_STATE,
    //     payload: acc,
    //   });
    // });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};
// Power On Account
export const powerOnAccounts = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`api/v1/accounts/on`, ids, config);
    dispatch({
      type: UPDATE_ACCOUNT_STATE,
      payload: res.data.data,
    });
    // res.data.data.forEach((acc) => {
    //   dispatch({
    //     type: UPDATE_ACCOUNT_STATE,
    //     payload: acc,
    //   });
    // });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};

// Restart VM
export const restartVms = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`api/v1/accounts/restart-vm`, ids, config);

    dispatch({
      type: UPDATE_ACCOUNT_STATUS,
      payload: res.data.data,
    });
    // res.data.data.forEach((acc) => {
    //   dispatch({
    //     type: UPDATE_ACCOUNT_STATUS,
    //     payload: acc,
    //   });
    // });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};

// Turn Off VM
export const turnOffVms = (ids) => async (dispatch) => {
  try {
    const res = await axios.put(`api/v1/accounts/turn-off-vm`, ids, config);

    dispatch({
      type: UPDATE_ACCOUNT_STATUS,
      payload: res.data.data,
    });
    // res.data.data.forEach((acc) => {
    //   dispatch({
    //     type: UPDATE_ACCOUNT_STATUS,
    //     payload: acc,
    //   });
    // });
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, payload: err });
  }
};
