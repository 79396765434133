import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMyAccounts } from "../../actions/accountActions";
import { getServers, addServer } from "../../actions/serverActions";
import Overview from "../Overview/Overview";
import Spinner from "../Spinner";
import ServerItem from "./ServerItem";
import styles from "./Servers.module.scss";

import TermsOfUse from "../TermsOfUse";
import longAgo from "../../utils/longAgo";

import EditServer from "./EditServer";
import CreateServer from "./CreateServer";
import MassEdit from "./MassEdit";

const Servers = ({ accounts, servers, getMyAccounts, getServers, user }) => {
  let validLicenses = user ? user.license.filter((license) => Date.now() <= license.expireTime && Date.now() >= license.licenseStart) : [];
  // console.log(accounts, servers, user);
  let licensesCountNumber = validLicenses.map((lic) => lic.licenses).reduce((a, b) => Number(a) + Number(b), 0);

  let licensesCount = licensesCountNumber > 0 ? licensesCountNumber : 2;

  const [filterByMacAddress, setFilterByMacAddress] = useState(JSON.parse(localStorage.getItem("filterByMacAddress")) ? JSON.parse(localStorage.getItem("filterByMacAddress")) : false);
  const [filterByIpAddress, setFilterByIpAddress] = useState(JSON.parse(localStorage.getItem("filterByIpAddress")) ? JSON.parse(localStorage.getItem("filterByIpAddress")) : false);
  const [filterByServerId, setFilterByServerId] = useState(JSON.parse(localStorage.getItem("filterByServerId")) ? JSON.parse(localStorage.getItem("filterByServerId")) : false);

  const [showCountry, setShowCountry] = useState(JSON.parse(localStorage.getItem("showCountry")) ? JSON.parse(localStorage.getItem("showCountry")) : false);
  const [displayAsTable, setDisplayAsTable] = useState(JSON.parse(localStorage.getItem("displayAsTable")) ? JSON.parse(localStorage.getItem("displayAsTable")) : false);
  // const [displayAsTable, setDisplayAsTable] = useState("list");

  const [formattedServers, setFormattedServers] = useState([]);
  const [isPopupShowing, setIsPopupShowing] = useState({ server: null, showing: false });
  const [isPopupShowingMass, setIsPopupShowingMass] = useState({ serverIds: [], showing: false });
  const [isPopupShowingCreateServer, setIsPopupShowingCreateServer] = useState({ showing: false });

  const [isLoading, setIsLoading] = useState(false);
  const [serversWithMissingVMs, setServersWithMissingVMs] = useState([]);
  const [selectionOn, setSelectionOn] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  // console.log(selectedIds);
  // console.log(formattedServers);
  useEffect(() => {
    if (!accounts) {
      if (user && (user.fields !== undefined || user.fields === "")) {
        getMyAccounts(user.fields);
      }
      // getMyAccounts();
    }
    if (!servers) {
      getServers();
    }
  }, [user]);
  useEffect(() => {
    if (servers && accounts) {
      setFormattedServers(formatServers(servers, accounts));
    }
  }, [user, servers, accounts, filterByMacAddress, filterByIpAddress, filterByServerId]);
  const [accountStats, setAccountStats] = useState({
    quotaReached: 0,
    bannedAcc: 0,
    activeBots: 0,
    ingameBots: 0,
    offlineBots: 0,
    inQueueBots: 0,
    LPQAccounts: 0,
  });
  const formatServers = (srvs, accs) => {
    let accounts = accs;
    let servers = srvs.map((srv) => {
      return { ...srv, accounts: [], missingVms: [] };
    });
    let quotaReached = 0;
    let bannedAcc = 0;
    let activeBots = 0;
    let ingameBots = 0;
    let offlineBots = 0;
    let inQueueBots = 0;
    let LPQAccounts = 0;
    for (let i = 0; i < accounts.length; i++) {
      let acc = accounts[i];
      if (user && user.combineMissionWaitCount) {
        if (user && acc.currentLevel >= user.minimumLevelForQuotaFinishedCount && acc.status === "quota-finished" && acc.status === "mission-wait") {
          quotaReached++;
        }
      } else {
        if (user && acc.currentLevel >= user.minimumLevelForQuotaFinishedCount && acc.status === "quota-finished") {
          quotaReached++;
        }
      }
      // Banned Account
      if (acc.status === "banned") {
        bannedAcc++;
      }
      // ActiveBots
      if (acc.isActive) {
        activeBots++;
      }
      // Ingame Bots
      if (acc.status === "ingame" || acc.status === "tutorial") {
        ingameBots++;
      }
      // Offline bots
      if (!acc.isActive) {
        offlineBots++;
      }
      // InQueue Bots
      if (acc.status === "in-queue" || acc.status === "low-prio-wait") {
        inQueueBots++;
      }
      // LPQ accounts
      if (acc.matchmakingStatus === "low-prio-queue") {
        LPQAccounts++;
      }
      for (let server of servers) {
        // servers.forEach((server) => {
        // console.log(filterByMacAddress, filterByIpAddress);
        if (!filterByMacAddress && !filterByIpAddress && !filterByServerId) {
          continue;
        }
        if (filterByMacAddress && server.macAddress != acc.macAddress) {
          continue;
        }
        if (filterByIpAddress && server.ipAddress != acc.ip) {
          continue;
        }
        // if (filterByServerId && server.serverId != acc.serverId) {
        if (filterByServerId && (server.serverId != acc.serverId || (!server.serverId && !acc.serverId))) {
          continue;
        }
        if (server.showOnlyActive) {
          if (acc.isActive) {
            server.accounts.push(acc);
          }
        } else {
          server.accounts.push(acc);
        }
        // if (filterByMacAddress) {
        // 	if (server.ipAddress == acc.ip && server.macAddress == acc.macAddress) {
        // 		if (server.showOnlyActive) {
        // 			if (acc.isActive) {
        // 				server.accounts.push(acc);
        // 			}
        // 		} else {
        // 			server.accounts.push(acc);
        // 		}
        // 	}
        // } else {
        // 	if (server.ipAddress == acc.ip) {
        // 		if (server.showOnlyActive) {
        // 			if (acc.isActive) {
        // 				server.accounts.push(acc);
        // 			}
        // 		} else {
        // 			server.accounts.push(acc);
        // 		}
        // 	}
        // }
      }
    }

    const ingamePercentage = activeBots === 0 ? 0 : Math.round((ingameBots * 100) / activeBots);
    const activePercentage = Math.round((activeBots * 100) / licensesCount);
    let serversCopy = [];
    let serversWithMissingVMs = [];
    servers.forEach((server) => {
      server.accounts.sort((a, b) => (a.vmNumber < b.vmNumber ? 1 : -1));
      let cloneNumbersAccs = [];
      let missingVms = [];
      let total = server.accounts.length;
      let active = 0;
      let ingame = 0;
      let inqueue = 0;
      let offline = 0;
      let lpq = 0;
      let accountIds = [];
      server.accounts.forEach((acc) => {
        accountIds.push(acc._id);
        if (acc.isActive) {
          active++;
        }
        // Ingame Bots
        if (acc.status === "ingame" || acc.status === "tutorial") {
          ingame++;
        }
        // InQueue Bots
        if (acc.status === "in-queue" || acc.status === "low-prio-wait") {
          inqueue++;
        }
        // Offline bots
        if (!acc.isActive) {
          offline++;
        }
        // LPQ
        if (acc.matchmakingStatus === "low-prio-queue") {
          lpq++;
        }
        if (!isNaN(acc.vmNumber)) {
          cloneNumbersAccs.push(Number(acc.vmNumber));
        }
      });

      let maxClones = getMax(server.accounts);
      let minClones = getMin(server.accounts);

      let cloneNumbers = [];
      for (let i = minClones; i < maxClones; i++) {
        cloneNumbers.push(Number(i));
      }
      cloneNumbers.forEach((clone) => {
        if (!cloneNumbersAccs.includes(clone)) {
          missingVms.push(clone);
        }
      });

      let possibleMissingAccounts = {};
      if (missingVms.length > 0) {
        accounts.forEach((acc) => {
          missingVms.forEach((vmNumber) => {
            // if (acc.summonerName == 'Millanelia') {
            // 	console.log(acc.ip, ipAddress, acc.macAddress, macAddress, Number(acc.vmNumber), Number(vmNumber));
            // }
            // if (acc.ip == server.ipAddress && acc.macAddress == server.macAddress && Number(acc.vmNumber) == Number(vmNumber)) {
            if (acc.serverId == server.serverId && Number(acc.vmNumber) == Number(vmNumber)) {
              if (!possibleMissingAccounts[vmNumber]) {
                possibleMissingAccounts[vmNumber] = [{ _id: acc._id, summonerName: acc.summonerName, region: acc.region, lastModified: acc.lastModified }];
              } else {
                // possibleMissingAccounts[vmNumber].push({ summonerName: acc.summonerName, region: acc.region, lastModified: acc.lastModified });

                if (acc.lastModified > possibleMissingAccounts[vmNumber][0].lastModified) {
                  possibleMissingAccounts[vmNumber] = [{ _id: acc._id, summonerName: acc.summonerName, region: acc.region, lastModified: acc.lastModified }];
                }
              }
            }
          });
        });
        serversWithMissingVMs.push({ ...server, missingVms, possibleMissingAccounts });
      }
      let server_ = { ...server, stats: { total, active, ingame, inqueue, offline, lpq, accountIds }, missingVms, minClones, maxClones, possibleMissingAccounts };

      serversCopy.push(server_);
    });
    // console.log(serversWithMissingVMs);
    setAccountStats({ addedAccounts: accounts.length, ingamePercentage, activePercentage, quotaReached, bannedAcc, activeBots, ingameBots, offlineBots, inQueueBots, LPQAccounts, ingamePercentage, activePercentage });
    setServersWithMissingVMs(serversWithMissingVMs);
    let a = { ...isPopupShowing, server: serversCopy.filter((srv) => srv._id == isPopupShowing?.server?.id || srv._id == isPopupShowing?.server?._id)[0] }; // desastre
    // console.log("isPopupShowing in formatServers: ", isPopupShowing, a, serversCopy);
    setIsPopupShowing(a);
    // servers.forEach((server) => {
    // 	accounts.forEach((acc) => {
    // 		if (filterByMacAddress) {
    // 			if (server.ipAddress == acc.ip && server.macAddress == acc.macAddress) {
    // 				if (server.showOnlyActive) {
    // 					if (acc.isActive) {
    // 						server.accounts.push(acc);
    // 					}
    // 				} else {
    // 					server.accounts.push(acc);
    // 				}
    // 			}
    // 		} else {
    // 			if (server.ipAddress == acc.ip) {
    // 				if (server.showOnlyActive) {
    // 					if (acc.isActive) {
    // 						server.accounts.push(acc);
    // 					}
    // 				} else {
    // 					server.accounts.push(acc);
    // 				}
    // 			}
    // 		}
    // 	});
    // });
    // setIsPopupShowing({ server, showing: !showing });
    // console.log(serversCopy);
    return serversCopy;
  };
  const getMax = (accs) => {
    for (let i = 0; i < accs.length; i++) {
      let acc = accs[i];
      if (!isNaN(acc.vmNumber)) {
        return acc.vmNumber;
      }
    }
  };
  const getMin = (accs) => {
    for (let i = accs.length - 1; i >= 0; i--) {
      let acc = accs[i];
      if (!isNaN(acc.vmNumber)) {
        return acc.vmNumber;
      }
    }
  };

  // console.log(serversWithMissingVMs);

  // const onClickCreateServer = async () => {
  //   if (serverName && ipAddress) {
  //     setIsLoading(true);
  //     await addServer(newServer);
  //     // setNewServer({ serverName: '', ipAddress: '', forceRegion: false, showOnlyActive: false, region: 'na' });
  //     setIsLoading(false);
  //   }
  // };
  const onClickFilterByMacAddress = (e) => {
    setFilterByMacAddress(!filterByMacAddress);
    localStorage.setItem("filterByMacAddress", JSON.stringify(!filterByMacAddress));
  };
  const onClickFilterByIpAddress = (e) => {
    setFilterByIpAddress(!filterByIpAddress);
    localStorage.setItem("filterByIpAddress", JSON.stringify(!filterByIpAddress));
  };
  const onClickFilterByServerId = (e) => {
    setFilterByServerId(!filterByServerId);
    localStorage.setItem("filterByServerId", JSON.stringify(!filterByServerId));
  };
  const onClickDisplayAsTable = (mode) => {
    setDisplayAsTable(mode);
    localStorage.setItem("displayAsTable", JSON.stringify(mode));
  };
  const onClickShowCountry = (e) => {
    setShowCountry(!showCountry);
    localStorage.setItem("showCountry", JSON.stringify(!showCountry));
  };

  const onClickSelectAll = () => {
    if (selectionOn && selectedIds.length !== formattedServers.length) {
      setSelectedIds(formattedServers.map((acc) => acc._id));
    } else {
      setSelectedIds([]);
    }
  };
  const onClickToggleSelection = async () => {
    setSelectedIds([]);
    setSelectionOn(!selectionOn);
  };
  const onClickHandleMassEditServers = async () => {
    setIsPopupShowingMass({ ...isPopupShowingMass, showing: true });
  };
  const onClickRefreshAccounts = async () => {
    setIsLoading(true);
    await getServers();
    await getMyAccounts();
    setIsLoading(false);
  };
  const showColumnsThs = (id) => {
    switch (id) {
      case "serverName":
        return <th>Server Name</th>;

      case "serverId":
        return <th>Server ID</th>;
      case "ipAddress":
        return <th>Server IP</th>;
      case "macAdress":
        return <th>Server MAC</th>;
      case "missingVms":
        return <th>Missing VMs</th>;
      case "minMaxClones":
        return <th>MinMax Clones</th>;
      case "totalAccs":
        return <th>Total Accs</th>;
      case "serverActions":
        return (
          <>
            <th>Actions</th>
            <th></th>
            <th></th>
            <th></th>
          </>
        );
      case "forceRegion":
        return <th>Force Region</th>;
      case "region":
        return <th>Region</th>;
      case "showOnlyActiveAccs":
        return <th>Show Only Active Accs</th>;
      case "forcePriority":
        return <th>Force Priority</th>;
      case "priority":
        return <th>Priority</th>;
      case "maintainVmsOn":
        return <th>Maintain VMs ON</th>;
      case "numberOfVms":
        return <th>Number of VMs</th>;
      case "windowsUpdates":
        return <th>Windows Updates</th>;
      case "cloneDrives":
        return <th>Clone Drives</th>;
      case "inactiveVmMinutes":
        return <th>Inactive VM Minutes</th>;
      case "vmStartDelay":
        return <th>VM Start Delay</th>;
      case "managerStartDelay":
        return <th>Manager Start Delay</th>;
      case "monitorHardware":
        return <th>Monitor Hardware</th>;
      case "logToFile":
        return <th>Log to File</th>;
      case "autoRestart":
        return <th>Auto Restart</th>;
      case "spacePerVm":
        return <th>Space per VM</th>;
      case "ramPerVmInMb":
        return <th>RAM per VM in MB</th>;
      case "vramPerVmInKb":
        return <th>VRAM per VM in KB</th>;
      case "autoAddToDashboard":
        return <th>Auto Add Dashboard</th>;
      case "addToStartup":
        return <th>Add to Startup</th>;
      case "cpuName":
        return <th>CPU Name</th>;
      case "cpuGhz":
        return <th>CPU GHz</th>;
      case "gpuName":
        return <th>GPU Name</th>;
      case "ram":
        return <th>RAM</th>;
    }
  };
  // console.log(user, servers, accounts);
  // const { showForceRegionSrv, showRegionSrv, showOnlyActiveAccsSrv, showForcePrioritySrv, showPrioritySrv, showMaintainVmsOnSrv, showNumberOfVmsSrv, showCloneDrivesSrv, showInactiveVmMinutesSrv, showVmStartDelaySrv, showManagerStartDelaySrv, showMonitorHardwareSrv, showLogToFileSrv, showAutoRestartSrv, showSpacePerVmSrv, showRamPerVmInMbSrv, showVramPerVmInKbSrv, showAutoAddToDashboardSrv, showAddToStartupSrv, showCpuNameSrv, showCpuGhzSrv, showGpuNameSrv, showRamSrv } = user;
  return (
    <div className="main">
      {user && servers && accounts ? (
        <>
          {user.acceptedTermsOfUse ? (
            <>
              {isPopupShowingCreateServer.showing && <CreateServer isPopupShowingCreateServer={isPopupShowingCreateServer} sendPopupShowingCreateServer={(v) => setIsPopupShowingCreateServer(v)} />}

              {isPopupShowing.showing && <EditServer isPopupShowing={isPopupShowing} user={user} sendPopupShowing={(v) => setIsPopupShowing(v)} server={isPopupShowing.server} />}
              {isPopupShowingMass.showing && <MassEdit isPopupShowingMass={isPopupShowingMass} sendIsPopupShowingMass={(v) => setIsPopupShowingMass(v)} servers={servers.filter((srv) => selectedIds.includes(srv._id))} />}

              {formattedServers && accounts ? (
                <div>
                  <Overview stats={accountStats} />
                  {/* <div className='overview'>
										<div className='overview__container'>
											<div className='overview__item'>
												<i className='fas fa-user'></i>

												<div className='overview__item__desc'>
													<h2>Added Accounts</h2>
													<span>{accounts.length}</span>
												</div>
											</div>
											<div className='overview__item'>
												<i className='fas fa-user-check'></i>

												<div className='overview__item__desc'>
													<h2>Finished Accounts</h2>
													<span>{accountStats.quotaReached}</span>
												</div>
											</div>
											<div className='overview__item'>
												<i className='fas fa-user-lock'></i>

												<div className='overview__item__desc'>
													<h2>LPQ Accounts</h2>
													<span>{accountStats.LPQAccounts}</span>
												</div>
											</div>
											<div className='overview__item'>
												<i className='fas fa-user-slash'></i>

												<div className='overview__item__desc'>
													<h2>Banned Accounts</h2>
													<span>{accountStats.bannedAcc}</span>
												</div>
											</div>
											<div className='overview__item'>
												<i className='fas fa-user-ninja'></i>

												<div className='overview__item__desc'>
													<h2>Active Bots</h2>
													<span>{accountStats.activeBots}</span>
													<span className='overview__item__percentage'>({activePercentage}%)</span>
												</div>
											</div>
											<div className='overview__item'>
												<i className='fas fa-stopwatch'></i>

												<div className='overview__item__desc'>
													<h2>In Queue Bots</h2>
													<span>{accountStats.inQueueBots}</span>
												</div>
											</div>
											<div className='overview__item'>
												<i className='fas fa-headset'></i>

												<div className='overview__item__desc'>
													<h2>Ingame Bots</h2>
													<span>{accountStats.ingameBots}</span>
													<span className='overview__item__percentage'>({ingamePercentage}%)</span>
												</div>
											</div>
											<div className='overview__item'>
												<i className='fas fa-bed'></i>

												<div className='overview__item__desc'>
													<h2>Offline Bots</h2>
													<span>{accountStats.offlineBots}</span>
												</div>
											</div>
										</div>
									</div> */}
                  <div className={styles.titleContainer} style={{ alignItems: "center" }}>
                    <span>Servers Monitoring {formattedServers.length > 0 ? `(${formattedServers.length})` : ""}</span>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                      <div style={{ width: "25px" }} className={styles.createServerButton + " button"} onClick={() => setIsPopupShowingCreateServer({ showing: true })}>
                        <i style={{ color: "white" }} className="fa-solid fa-plus"></i>
                      </div>
                      <div title={"Reload Accounts"} className={`${styles.reloadButton}`} onClick={onClickRefreshAccounts}>
                        <i className={`fas fa-sync-alt`}></i>
                      </div>
                    </div>
                  </div>
                  <div className={styles.serverWrapper}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      {/* <div className={styles.buttonGroup}>
                        <div title={"Toggle Selection"} className={`${styles.optionsButton} ${selectionOn ? "green" : "red"}`} onClick={onClickToggleSelection}>
                          <span>Selection</span>
                          <i className={`fas fa-${selectionOn ? "check" : "times"}-circle`} style={{ color: `${selectionOn ? "green" : "#970000"}` }}></i>
                        </div>
                      </div> */}
                      {displayAsTable == "list" && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ marginTop: "0px" }} className={`${styles.externalWebsite} ${selectionOn ? styles.activeWebsite : ""}`} onClick={onClickToggleSelection}>
                            Selection
                            <i className={`fas fa-${selectionOn ? "check" : "times"}-circle`} style={{ marginLeft: "5px", color: `${selectionOn ? "green" : "#970000"}` }}></i>
                          </div>
                          {selectedIds.length > 1 && (
                            <button style={{ height: "2em", marginLeft: "15px", borderRadius: ".15em" }} className={`${styles.green}`} onClick={onClickHandleMassEditServers}>
                              Manage {selectedIds.length} servers
                            </button>
                          )}
                        </div>
                      )}
                      {displayAsTable == "col" && (
                        <div style={{ marginTop: "0px" }} className={`${styles.externalWebsite} ${showCountry ? styles.activeWebsite : ""}`} onClick={onClickShowCountry}>
                          Show Country
                        </div>
                      )}
                      <div style={{ alignItems: "center", display: "flex", justifyContent: "space-around" }}>
                        <span>Group By: </span>
                        <div style={{ marginLeft: "5px", marginTop: "0px" }} className={`${styles.externalWebsite} ${filterByMacAddress ? styles.activeWebsite : ""}`} onClick={onClickFilterByMacAddress}>
                          Mac Address
                        </div>
                        <div style={{ marginLeft: "5px", marginTop: "0px" }} className={`${styles.externalWebsite} ${filterByIpAddress ? styles.activeWebsite : ""}`} onClick={onClickFilterByIpAddress}>
                          Ip Address
                        </div>
                        <div style={{ marginLeft: "5px", marginTop: "0px" }} className={`${styles.externalWebsite} ${filterByServerId ? styles.activeWebsite : ""}`} onClick={onClickFilterByServerId}>
                          Server Id
                        </div>
                      </div>
                      {/* <div style={{ marginTop: "0px" }} className={`${styles.externalWebsite} ${displayAsTable ? styles.activeWebsite : ""}`} onClick={onClickDisplayAsTable}>
                        Display as Table
                      </div> */}

                      <div style={{ display: "flex" }}>
                        <div title={"Display as list"} className={`${styles.reloadButton}`} onClick={() => onClickDisplayAsTable("list")} style={{ padding: "0px 15px", backgroundColor: displayAsTable == "list" ? "#302f4e" : "" }}>
                          <i className="fa-solid fa-list"></i>
                        </div>
                        <div title={"Display as table"} className={`${styles.reloadButton}`} onClick={() => onClickDisplayAsTable("col")} style={{ marginLeft: "0.5em", padding: "0px 15px", backgroundColor: displayAsTable == "col" ? "#302f4e" : "" }}>
                          <i className="fa-solid fa-grip-vertical"></i>
                        </div>
                        <div title={"Display as monitor screenshots"} className={`${styles.reloadButton}`} onClick={() => onClickDisplayAsTable("monitor")} style={{ marginLeft: "0.5em", padding: "0px 15px", backgroundColor: displayAsTable == "monitor" ? "#302f4e" : "" }}>
                          <i className="fa fa-desktop"></i>
                        </div>
                      </div>
                      {/* <div className={`${styles.externalWebsite} ${showAccActions ? styles.activeWebsite : ''}`} onClick={onClickShowAccActions}>
												Show Acc Actions
											</div> */}
                    </div>
                    {serversWithMissingVMs.length > 0 && !isLoading && (
                      <div style={{ marginTop: "1em", color: "#ff1e1e" }}>
                        {serversWithMissingVMs.map((srv) => (
                          <div key={Math.random()}>
                            Server <span>[ {srv.serverName}</span>
                            <span style={{ color: "#347474" }}> | </span>
                            <span>{srv.ipAddress}</span>
                            {srv.forceRegion && (
                              <>
                                <span style={{ color: "#347474" }}> | </span>

                                <span> {srv.region.toUpperCase()}</span>
                              </>
                            )}
                            {srv.forcePriority && (
                              <>
                                <span style={{ color: "#347474" }}> | </span>

                                <span> {srv.priority}</span>
                              </>
                            )}
                            <span> ] has {srv.missingVms.length} missing VMs.</span>
                            <div className="tooltip" style={{ borderBottom: "none", marginLeft: ".5em" }}>
                              <i className={`far fa-user`} style={{ color: "crimson" }}></i>

                              <span className="tooltiptext">
                                Missing VM{srv.missingVms.length > 1 ? "s" : ""}: {srv.missingVms.map((m, i) => `${i != 0 ? ", \n" : ""}${m}`)}
                                {Object.entries(srv.possibleMissingAccounts).length > 0 && (
                                  <>
                                    <br></br>
                                    <br></br>
                                    {Object.entries(srv.possibleMissingAccounts).map(([key, value], i) => (
                                      <span key={Math.random()}>
                                        {value.map((acc) => `${key}: ${acc.summonerName} - ${acc.region.toUpperCase()} - Last Update: ${acc.lastModified > 0 ? longAgo(acc.lastModified) : "Never"}`)}
                                        {i < Object.entries(srv.possibleMissingAccounts).length - 1 && <br></br>}
                                      </span>
                                    ))}
                                  </>
                                )}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {!isLoading ? (
                      <>
                        {displayAsTable == "col" && (
                          <div className={styles.serversContainer}>
                            <>{formattedServers && formattedServers.map((srv) => <ServerItem selectionOn={selectionOn} selectedIds={selectedIds} setSelectedIds={setSelectedIds} displayAsTable={displayAsTable} key={Math.random()} showCountry={showCountry} server={srv} isPopupShowing={isPopupShowing} sendPopupShowing={(v) => setIsPopupShowing(v)} />)}</>
                          </div>
                        )}

                        {displayAsTable == "list" && (
                          <table style={{ marginTop: "1em" }}>
                            <thead>
                              <tr>
                                <th colSpan="">
                                  <div>
                                    <i style={{ cursor: "pointer" }} onClick={onClickSelectAll} className={`fa${selectedIds.length === accounts.length ? "s" : "r"} fa-check-square`}></i>
                                  </div>
                                </th>
                                {user.serverColumns.map((col) => {
                                  return col.enabled ? showColumnsThs(col.id) : null;
                                })}
                              </tr>
                            </thead>

                            <tbody>
                              <>{formattedServers && formattedServers.map((srv) => <ServerItem selectionOn={selectionOn} selectedIds={selectedIds} setSelectedIds={setSelectedIds} displayAsTable={displayAsTable} key={Math.random()} showCountry={showCountry} server={srv} isPopupShowing={isPopupShowing} sendPopupShowing={(v) => setIsPopupShowing(v)} />)}</>
                            </tbody>
                          </table>
                        )}
                        {displayAsTable == "monitor" && <>{formattedServers && formattedServers.map((srv) => <ServerItem selectionOn={selectionOn} selectedIds={selectedIds} setSelectedIds={setSelectedIds} displayAsTable={displayAsTable} key={Math.random()} showCountry={showCountry} server={srv} isPopupShowing={isPopupShowing} sendPopupShowing={(v) => setIsPopupShowing(v)} />)}</>}
                      </>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              ) : (
                <Spinner />
              )}
            </>
          ) : (
            <TermsOfUse />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  props,
  accounts: state.accounts.accounts,
  user: state.auth.user,
  servers: state.servers.servers,
});
export default connect(mapStateToProps, { getMyAccounts, getServers })(Servers);
