import React, { useState } from 'react';
import { connect } from 'react-redux';
import AddAccounts from './AddAccounts';
import Credentials from './Credentials';
import Spinner from '../Spinner';
import { getMyAccountsCredentials } from '../../actions/accountActions';
import TermsOfUse from '../TermsOfUse';

const CredentialsOverview = ({ accountsCredentials, user }) => {
  const [addingOrShowing, setAddingOrShowing] = useState('adding');

  return (
    <>
      {user ? (
        <>
          {user.acceptedTermsOfUse ? (
            <div className='main'>
              <div className='credentials'>
                {addingOrShowing === 'adding' ? (
                  <div className='credentials__title-container'>
                    <span className='credentials__title'>Add Accounts</span>
                    <button className='credentials__button aqua-green anim' onClick={() => setAddingOrShowing('showing')}>
                      <span>Export Accounts</span>
                    </button>
                  </div>
                ) : (
                  <div className='credentials__title-container'>
                    <span className='credentials__title'>Credentials Overview </span>
                    <button className='credentials__button aqua-green anim' onClick={() => setAddingOrShowing('adding')}>
                      <span>Add Accounts</span>
                    </button>
                  </div>
                )}
                <div className='credentials__container'>{addingOrShowing === 'adding' ? <AddAccounts /> : <Credentials />}</div>
              </div>
            </div>
          ) : (
            <TermsOfUse />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  accountsCredentials: state.accounts.accountsCredentials,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getMyAccountsCredentials })(CredentialsOverview);

// {accounts.map(acc=> (

//   ))}
