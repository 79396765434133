import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import UserItem from './UserItem';
import EditUser from './EditUser';
import Spinner from '../Spinner';

const Users = ({ users }) => {
  console.log(users);
  const [sorts, setSorts] = useState(
    JSON.parse(localStorage.getItem('sort-users'))
      ? JSON.parse(localStorage.getItem('sort-users'))
      : {
          username: false,
          role: false,
          licenseExpire: false,
          activeBots: false,
          state: false,
        }
  );
  // test = localStorage.getItem('sort');
  // console.log(test);
  const [change, setChange] = useState(true);

  const [isPopupShowing, setIsPopupShowing] = useState({ user: null, showing: false });
  const [isLoading, setIsLoading] = useState(false);

  const onClickChangeSorting = (e) => {
    let newSorts = sorts;
    Object.keys(newSorts).forEach((key) => {
      if (key === e.target.dataset.name) {
        if (newSorts[key]) {
          newSorts[key] = false;
        } else {
          newSorts[key] = true;
        }
      } else {
        newSorts[key] = false;
      }
    });
    localStorage.setItem('sort-users', JSON.stringify(newSorts));
    setSorts(newSorts);
    setChange(!change);
  };
  console.log(isPopupShowing);
  // useEffect(() => {
  //   let sortedEnabled = false;

  //   Object.keys(sorts).forEach((key) => {
  //     if (sorts[key]) {
  //       sortedEnabled = true;
  //       switch (key) {
  //         case 'currentLevel':
  //           setSortedAccs([...accounts].sort((a, b) => (a.currentLevel < b.currentLevel ? 1 : a.currentLevel === b.currentLevel ? (a.nextLevelPercentage < b.nextLevelPercentage ? 1 : -1) : -1)));
  //           break;
  //         case 'blueEssence':
  //           setSortedAccs([...accounts].sort((a, b) => (a.blueEssence < b.blueEssence ? 1 : -1)));
  //           break;

  //         case 'nOfGames':
  //           setSortedAccs([...accounts].sort((a, b) => (a.nOfGames < b.nOfGames ? 1 : a.nOfGames === b.nOfGames ? (a.blueEssence < b.blueEssence ? 1 : -1) : -1)));

  //           break;

  //         case 'playtime':
  //           setSortedAccs([...accounts].sort((a, b) => (a.playtime < b.playtime ? 1 : a.playtime === b.playtime ? (a.blueEssence < b.blueEssence ? 1 : -1) : -1)));

  //           break;

  //         case 'skins':
  //           setSortedAccs([...accounts].sort((a, b) => (a.skins.length < b.skins.length ? 1 : a.skins.length === b.skins.length ? (a.blueEssence < b.blueEssence ? 1 : -1) : -1)));

  //           break;
  //         case 'summonerName':
  //           setSortedAccs([...accounts].sort((a, b) => a.summonerName.localeCompare(b.summonerName)));

  //           break;
  //         case 'status':
  //           setSortedAccs([...accounts].sort((a, b) => a.status.localeCompare(b.status)));

  //           break;
  //         case 'region':
  //           setSortedAccs([...accounts].sort((a, b) => a.region.localeCompare(b.region)));

  //           break;
  //         case 'isActive':
  //           setSortedAccs([...accounts].sort((a, b) => b.isActive.toString().localeCompare(a.isActive)));

  //           break;
  //       }
  //     }
  //   });
  //   if (!sortedEnabled) {
  //     setSortedAccs(accounts);
  //   }
  // }, [change, accounts]);

  // const onClickAction = async (action) => {
  //   setIsLoading(true);
  //   setSelectedIds([]);
  //   await action(selectedIds);
  //   setIsLoading(false);
  // };

  return (
    <>
      {isPopupShowing.showing && <EditUser isPopupShowing={isPopupShowing} sendPopupShowing={(v) => setIsPopupShowing(v)} />}

      <div className='accounts'>
        <div className='accounts__content'>
          {!isLoading ? (
            <table>
              <thead>
                <tr>
                  <th data-name='username' style={{ cursor: 'pointer', fontWeight: sorts['username'] ? 'bold' : '100' }} onClick={(e) => onClickChangeSorting(e)}>
                    Username
                  </th>

                  <th data-name='role' style={{ cursor: 'pointer', fontWeight: sorts['role'] ? 'bold' : '100' }} onClick={(e) => onClickChangeSorting(e)}>
                    Role
                  </th>
                  <th>State</th>
                  <th>Licenses</th>
                  <th data-name='licenseExpire' style={{ cursor: 'pointer', fontWeight: sorts['licenseExpire'] ? 'bold' : '100' }} onClick={(e) => onClickChangeSorting(e)}>
                    License Expiring
                  </th>
                  <th data-name='activeBots' style={{ cursor: 'pointer', fontWeight: sorts['activeBots'] ? 'bold' : '100' }} onClick={(e) => onClickChangeSorting(e)}>
                    Active Bots
                  </th>
                  <th>Accepted Terms</th>
                  <th>Revenue</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {users.map((us) => (
                  <UserItem key={us._id} user={us} isPopupShowing={isPopupShowing} sendPopupShowing={(v) => setIsPopupShowing(v)} />
                ))}
              </tbody>
            </table>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, null)(Users);
