import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { addServer } from "../../actions/serverActions";

import Spinner from "../Spinner";
import styles from "./Servers.module.scss";
import longAgo from "../../utils/longAgo";
import axios from "../../utils/axiosInstance";

const CreateServer = ({ isPopupShowingCreateServer, sendPopupShowingCreateServer, addServer }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [newServer, setNewServer] = useState({
    maintainVmsOn: false,
    numberOfVms: 0,

    priority: 1,
    serverId: "",

    macAddress: "",
    serverName: "",
    ipAddress: "",
    forcePriority: false,
    forceRegion: false,
    showOnlyActive: true,
    showAccActions: false,
    region: "na",
    cloneDrives: "C",
    inactiveVmMinutes: 10,
    vmStartDelay: 30,
    managerStartDelay: 3,
    monitorHardware: true,
    logToFile: false,
    autoRestart: true,
    spacePerVm: 1,
    ramPerVmInMb: 1024,
    vramPerVmInKb: 65536,
    autoAddToDashboard: false,
    addToStartup: false,
  });
  const onChange = (e) => {
    setNewServer({ ...newServer, [e.target.name]: e.target.value });
  };
  const onChangeCheckBox = (e) => {
    setNewServer({ ...newServer, [e.target.name]: e.target.checked });
  };
  const createServer = async () => {
    if (newServer.serverName && newServer.ipAddress && newServer.serverId) {
      setIsLoading(true);
      await addServer(newServer);
      setIsLoading(false);

      sendPopupShowingCreateServer({ showing: false });
    }
  };

  const { serverId, serverName, macAddress, ipAddress, forceRegion, region, showOnlyActive, forcePriority, priority, numberOfVms, maintainVmsOn, windowsUpdatesAvailable, cloneDrives, inactiveVmMinutes, vmStartDelay, managerStartDelay, monitorHardware, logToFile, autoRestart, spacePerVm, ramPerVmInMb, vramPerVmInKb, autoAddToDashboard, addToStartup, cpuName, gpuName, cpuGhz, ram } = newServer;
  // console.log(newServer);
  return (
    <div className={styles.popup}>
      <div className={styles.popupContent} style={{ marginTop: "50px", maxWidth: "550px" }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div style={{ display: "flex", flexDirection: "column", fontSize: "20px", gap: "10px", marginBottom: "1em" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h2>Create Server</h2>
              <i style={{ fontSize: "1.5em" }} className="fas fa-server"></i>
            </div>
            <div className={styles.inputField} style={{ display: "flex", justifyContent: "space-between", margin: "0px" }}>
              <label>Server Name</label>
              <input onChange={onChange} name="serverName" type="text" value={serverName} placeholder="AMD 3900X"></input>
            </div>
            <div className={styles.inputField} style={{ display: "flex", justifyContent: "space-between", margin: "0px" }}>
              <label>Server ID</label>
              <input onChange={onChange} name="serverId" type="text" value={serverId} placeholder="-"></input>
            </div>
            <div className={styles.inputField} style={{ display: "flex", justifyContent: "space-between", margin: "0px" }}>
              <label>Server IP</label>
              <input onChange={onChange} name="ipAddress" type="text" value={ipAddress} placeholder="192.168.0.1"></input>
            </div>
            {/* <div className={styles.inputField}>
												<label>MAC Address</label>
												<input onChange={onChange} name='macAddress' type='text' value={macAddress}></input>
											</div> */}
            <div className={styles.inputField}>
              <label>Show Only Active Accounts</label>
              <input onChange={onChangeCheckBox} name="showOnlyActive" className="checkbox" type="checkbox" value={showOnlyActive} defaultChecked={showOnlyActive}></input>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
              <div className={styles.inputField}>
                <label>Force Region</label>
                <input onChange={onChangeCheckBox} name="forceRegion" className="checkbox" type="checkbox" value={forceRegion} defaultChecked={forceRegion}></input>
              </div>
              {forceRegion && (
                <div className={styles.inputField} style={{ margin: "0px" }}>
                  <label>Region</label>
                  <select name="region" onChange={onChange} defaultValue={region}>
                    <option value="na">NA</option>
                    <option value="euw">EUW</option>
                    <option value="las">LAS</option>
                    <option value="lan">LAN</option>
                    <option value="tr">TR</option>
                    <option value="kr">KR</option>
                    <option value="br">BR</option>
                    <option value="ru">RU</option>
                    <option value="eune">EUNE</option>
                    <option value="jp">JP</option>
                    <option value="oce">OCE</option>
                    <option value="ph">PH</option>
                    <option value="sg">SG</option>
                    <option value="th">TH</option>
                    <option value="tw">TW</option>
                    <option value="vn">VN</option>
                  </select>
                </div>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
              <div className={styles.inputField}>
                <label>Force Priority</label>
                <input onChange={onChangeCheckBox} name="forcePriority" className="checkbox" type="checkbox" value={forcePriority} defaultChecked={forcePriority}></input>
              </div>
              {forcePriority && (
                <div className={styles.inputField} style={{ margin: "0px" }}>
                  <label>Priority</label>
                  <input onChange={onChange} name="priority" type="text" value={priority}></input>
                </div>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
              <div className={styles.inputField}>
                <label>Maintain VMs ON</label>
                <input onChange={onChangeCheckBox} name="maintainVmsOn" className="checkbox" type="checkbox" value={maintainVmsOn} defaultChecked={maintainVmsOn}></input>
              </div>
              {maintainVmsOn && (
                <div className={styles.inputField} style={{ margin: "0px" }}>
                  <label>Number Of VMs</label>
                  <input onChange={onChange} name="numberOfVms" type="text" value={numberOfVms}></input>
                </div>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>Clone Drives</label>
              <input name="cloneDrives" className="credentials__input" value={cloneDrives} onChange={onChange}></input>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>Inactive VM Minutes</label>
              <input name="inactiveVmMinutes" className="credentials__input" value={inactiveVmMinutes} onChange={onChange}></input>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>VM Start Delay</label>
              {/* <input disabled name='macAddress' className='credentials__input' value={macAddress}></input> */}
              <input name="vmStartDelay" className="credentials__input" value={vmStartDelay} onChange={onChange}></input>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>Space Per VM</label>
              <input name="spacePerVm" className="credentials__input" value={spacePerVm} onChange={onChange}></input>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>Manager Start Delay</label>
              {/* <input disabled name='macAddress' className='credentials__input' value={macAddress}></input> */}
              <input name="managerStartDelay" className="credentials__input" value={managerStartDelay} onChange={onChange}></input>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>RAM per VM in MB</label>
              <input name="ramPerVmInMb" className="credentials__input" value={ramPerVmInMb} onChange={onChange}></input>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>VRAM per VM in MB</label>

              <select className="credentials__input" name="vramPerVmInKb" onChange={onChange} defaultValue={vramPerVmInKb} style={{ width: "60px" }}>
                <option value={32768}>32</option>
                <option value={65536}>64</option>
                <option value={131072}>128</option>
              </select>
            </div>
            {/* <section>
              <label>Auto add to Dashboard</label>
              <input onChange={onChangeCheckBox} name="autoAddToDashboard" className="checkbox" type="checkbox" value={autoAddToDashboard} defaultChecked={autoAddToDashboard}></input>
            </section> */}
            <div>
              <label>Add to Startup</label>
              <input onChange={onChangeCheckBox} name="addToStartup" className="checkbox" type="checkbox" value={addToStartup} defaultChecked={addToStartup}></input>
            </div>

            <section>
              <label>Monitor Hardware</label>
              <input onChange={onChangeCheckBox} name="monitorHardware" className="checkbox" type="checkbox" value={monitorHardware} defaultChecked={monitorHardware}></input>
            </section>
            <div>
              <label>Log to File</label>
              <input onChange={onChangeCheckBox} name="logToFile" className="checkbox" type="checkbox" value={logToFile} defaultChecked={logToFile}></input>
            </div>

            <div>
              <label>Auto Restart</label>
              <input onChange={onChangeCheckBox} name="autoRestart" className="checkbox" type="checkbox" value={autoRestart} defaultChecked={autoRestart}></input>
            </div>

            <div className={styles.inputField} style={{ marginTop: "1em", marginRight: "0px", justifyContent: "space-between" }}>
              <div className="button aqua-green" onClick={createServer}>
                Create
              </div>
              <div className="button aqua-green" onClick={() => sendPopupShowingCreateServer({ showing: false })}>
                Close
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  accounts: state.accounts.accounts,
});
export default connect(mapStateToProps, { addServer })(CreateServer);
