import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateUser } from '../../actions/authActions';
import Spinner from '../Spinner';

import styles from '../Accounts/EditAccount.module.scss';

const EditUser = ({ isPopupShowing: { user, showing }, sendPopupShowing, updateUser }) => {
  const onClickCancel = () => {
    sendPopupShowing({ user, showing: !showing });
  };

  const onClickUpdate = async () => {
    setIsLoading(true);
    await updateUser();
    setIsLoading(false);
    sendPopupShowing({ user, showing: !showing });
  };
  const [isLoading, setIsLoading] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(user);

  const [newLicense, setNewLicense] = useState({
    months: 1,
    amount: user.maxAccounts,
  });

  const onChangeNewLicense = (e) => {
    setNewLicense({
      ...newLicense,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCheckBox = (event) => {
    updatedUser({
      ...updatedUser,
      [event.target.name]: event.target.checked,
    });
  };
  const onClickAddLicense = () => {
    let _1MONTH = 2592000000;
    let licenseTime_ = updatedUser.licenseExpire + _1MONTH * newLicense.months;

    setUpdatedUser({ ...updatedUser, licenseExpire: licenseTime_ });
    // Save user
  };

  const onClickDeleteLicense = () => {
    console.log('Deleting license');
  };

  // const onChangeLevel = (event) => {
  //   const re = /^[0-9\b]+$/;
  //   if ((re.test(event.target.value) || event.target.value === '') && event.target.value.length < 3) {
  //     setUpdatedAccount({
  //       ...updatedAccount,
  //       [event.target.name]: event.target.value.trim(),
  //     });
  //   }
  // };
  // const onChangeNumber = (event) => {
  //   const re = /^[0-9\b]+$/;
  //   if (re.test(event.target.value) || event.target.value === '') {
  //     setUpdatedAccount({
  //       ...updatedAccount,
  //       [event.target.name]: event.target.value.trim(),
  //     });
  //   }
  // };
  // const onChange = (event) => {
  //   setUpdatedAccount({
  //     ...updatedAccount,
  //     [event.target.name]: event.target.value.trim(),
  //   });
  // };
  const { username, maxAccounts } = user;
  return (
    <div className={styles.popup}>
      <div className={styles.popupContent}>
        <h1>{username}</h1>
        <div className='credentials__info-row'>
          <div className='credentials__info-field'>
            <label>Months</label>
            <input onChange={onChangeNewLicense} name='months' className='credentials__input' value={newLicense.months}></input>
          </div>
          <div className='credentials__info-field'>
            <label>Amount of Licenses</label>
            <input onChange={onChangeNewLicense} name='amount' className='credentials__input' value={newLicense.amount}></input>
          </div>
          <div className='credentials__info-field' style={{ flexDirection: 'row' }}>
            <div className='button aqua-green' onClick={onClickAddLicense}>
              <span>Add License</span>
            </div>
            <div className='button' style={{ backgroundColor: 'red' }} onClick={onClickDeleteLicense}>
              <span>Delete License</span>
            </div>
          </div>
        </div>
        <div className='credentials__info-row'>
          <div className='button aqua-green' onClick={onClickCancel}>
            <span>Close</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { updateUser })(EditUser);
