import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMyAccounts } from "../../actions/accountActions";
import { getServers, addServer } from "../../actions/serverActions";
import Spinner from "../Spinner";
import SpinnerScreenshot from "./SpinnerScreenshot";
import styles from "./Servers.module.scss";
import longAgo from "../../utils/longAgo";
import ServerAccountItem from "./ServerAccountItem";
import axios from "../../utils/axiosInstance";
import ViewScreenshot from "./ViewScreenshot";
import promisesHandler from "../../utils/promisesHandler";

import { pauseAccounts, stopAccounts, restartAccounts, powerOffAccounts, restartVms, turnOffVms, powerOnAccounts, unpauseAccounts } from "../../actions/accountActions";
import { updateServer } from "../../actions/serverActions";

const ServerItem = ({
  user,
  displayAsTable,
  showCountry,
  sendPopupShowing,
  isPopupShowing,
  server: {
    stats: { total, active, ingame, inqueue, offline, lpq, accountIds },
    serverId,
    possibleMissingAccounts,
    minClones,
    maxClones,
    missingVms,
    showAccActions,
    serverName,
    forcePriority,
    priority,
    ipAddress,
    macAddress,
    region,
    forceRegion,
    accounts,
    showOnlyActive,
    maintainVmsOn,
    numberOfVms,

    windowsUpdatesAvailable,
    cloneDrives,
    inactiveVmMinutes,
    vmStartDelay,
    managerStartDelay,
    monitorHardware,
    logToFile,
    autoRestart,
    spacePerVm,
    ramPerVmInMb,
    vramPerVmInKb,
    autoAddToDashboard,
    addToStartup,
    cpuName,
    gpuName,
    cpuGhz,
    ram,
    _id,
  },
  updateServer,
  pauseAccounts,
  stopAccounts,
  restartAccounts,
  powerOffAccounts,
  restartVms,
  turnOffVms,
  powerOnAccounts,
  unpauseAccounts,
  selectionOn,
  selectedIds,
  setSelectedIds,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const handlePopup = (a) => {
    // test();
    sendPopupShowing({
      showing: !isPopupShowing.showing,
      server: {
        possibleMissingAccounts,
        serverId,
        minClones,
        maxClones,
        missingVms,
        showAccActions,
        serverName,
        ipAddress,
        forcePriority,
        priority,
        macAddress,
        region,
        forceRegion,
        accounts,
        maintainVmsOn,
        numberOfVms,
        showOnlyActive,
        windowsUpdatesAvailable,
        cloneDrives,
        inactiveVmMinutes,
        vmStartDelay,
        managerStartDelay,
        monitorHardware,
        logToFile,
        autoRestart,
        spacePerVm,
        ramPerVmInMb,
        vramPerVmInKb,
        autoAddToDashboard,
        addToStartup,
        cpuName,
        gpuName,
        cpuGhz,
        ram,
        id: _id,
      },
    });
  };

  const handleAction = async (functionName, action, id) => {
    if (id.length > 0) {
      if (window.confirm(`Are you sure you want to use ${functionName} action on ${id.length} account${id.length > 1 ? "s" : ""}?`)) {
        setIsLoading(true);
        await action(id);
        setIsLoading(false);
      }
    }
  };
  const onClickShowAccActions = async (e) => {
    // setShowAccActions(!showAccActions);
    await updateServer({
      maintainVmsOn,
      numberOfVms,
      serverName,
      ipAddress,
      macAddress,
      forceRegion,
      forcePriority,
      priority,
      region,
      showOnlyActive,
      showAccActions: !showAccActions,
      id: _id,
    });

    // localStorage.setItem('showAccActions', JSON.stringify(!showAccActions));
  };

  // const [showAccActions, setShowAccActions] = useState(JSON.parse(localStorage.getItem('showAccActions')) ? JSON.parse(localStorage.getItem('showAccActions')) : false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupShowingViewScreenshot, setIsPopupShowingViewScreenshot] = useState({ account: false, showing: false });
  const [loadingScreenshot, setIsLoadingScreenshot] = useState(true);
  const [screenshotVms, setScreenshotVms] = useState(false);
  const [showingScreenshot, setShowingScreenshot] = useState(false);
  // const [accountIds, setAccountIds] = useState(accounts.map((acc) => acc._id));

  // let total = accounts.length;
  // let active = 0;
  // let ingame = 0;
  // let inqueue = 0;
  // let offline = 0;
  // let lpq = 0;
  // let accountIds = [];

  // accounts.forEach((acc) => {
  // 	accountIds.push(acc._id);
  // 	// ActiveBots
  // 	if (acc.isActive) {
  // 		active++;
  // 	}
  // 	// Ingame Bots
  // 	if (acc.status === 'ingame' || acc.status === 'tutorial') {
  // 		ingame++;
  // 	}
  // 	// InQueue Bots
  // 	if (acc.status === 'in-queue' || acc.status === 'low-prio-wait') {
  // 		inqueue++;
  // 	}
  // 	// Offline bots
  // 	if (!acc.isActive) {
  // 		offline++;
  // 	}
  // 	// LPQ
  // 	if (acc.matchmakingStatus === 'low-prio-queue') {
  // 		lpq++;
  // 	}
  // });
  // const getMax = () => {
  // 	for (let i = 0; i < accountsFormatted.length; i++) {
  // 		let acc = accountsFormatted[i];
  // 		if (!isNaN(acc.vmNumber)) {
  // 			return acc.vmNumber;
  // 		}
  // 	}
  // };
  // const getMin = () => {
  // 	for (let i = accountsFormatted.length - 1; i >= 0; i--) {
  // 		let acc = accountsFormatted[i];
  // 		if (!isNaN(acc.vmNumber)) {
  // 			return acc.vmNumber;
  // 		}
  // 	}
  // };
  // let missingVms = [];
  // let cloneNumbersAccs = [];
  // accountsFormatted.forEach((acc) => {
  // 	if (!isNaN(acc.vmNumber)) {
  // 		cloneNumbersAccs.push(Number(acc.vmNumber));
  // 	}
  // });

  // let maxClones = getMax();
  // let minClones = getMin();

  // let cloneNumbers = [];
  // for (let i = minClones; i < maxClones; i++) {
  // 	cloneNumbers.push(Number(i));
  // }
  // cloneNumbers.forEach((clone) => {
  // 	if (!cloneNumbersAccs.includes(clone)) {
  // 		missingVms.push(clone);
  // 	}
  // });
  // let accountsFormatted = [...accounts].sort((a, b) => (a.vmNumber < b.vmNumber ? 1 : -1));
  // let accountsFormatted = [...accounts];

  // let possibleMissingAccounts = {};
  // globalAccounts.forEach((acc) => {
  // 	missingVms.forEach((vmNumber) => {
  // 		// if (acc.summonerName == 'Millanelia') {
  // 		// 	console.log(acc.ip, ipAddress, acc.macAddress, macAddress, Number(acc.vmNumber), Number(vmNumber));
  // 		// }
  // 		if (acc.ip == ipAddress && acc.macAddress == macAddress && Number(acc.vmNumber) == Number(vmNumber)) {
  // 			if (!possibleMissingAccounts[vmNumber]) {
  // 				possibleMissingAccounts[vmNumber] = [{ summonerName: acc.summonerName, region: acc.region, lastModified: acc.lastModified }];
  // 			} else {
  // 				// possibleMissingAccounts[vmNumber].push({ summonerName: acc.summonerName, region: acc.region, lastModified: acc.lastModified });

  // 				if (acc.lastModified > possibleMissingAccounts[vmNumber][0].lastModified) {
  // 					possibleMissingAccounts[vmNumber] = [{ summonerName: acc.summonerName, region: acc.region, lastModified: acc.lastModified }];
  // 				}
  // 			}
  // 		}
  // 	});
  // });
  // console.log(possibleMissingAccounts);
  // console.log(Object.entries(possibleMissingAccounts));
  // Object.entries(possibleMissingAccounts).forEach(([key, value]) => {
  // 	console.log(key, value);
  // });
  const onClickViewScreenshot = (account) => {
    setIsPopupShowingViewScreenshot({ showing: true, account });
  };
  const onClickShowingMore = async () => {
    setShowingMore(!showingMore);
    await a(!showingMore);
  };
  const onMouseOver = (event) => {
    if (selectionOn) {
      // setIsSelected(!isSelected);
      if (!isSelected) {
        setSelectedIds([...selectedIds, _id]);
      } else {
        setSelectedIds([...selectedIds].filter((id) => id !== _id));
      }
    }
  };
  const [showingMore, setShowingMore] = useState(false);
  useEffect(() => {
    // console.log("Updated");
    // if (isSelected) {
    //   setSelectedIds([...selectedIds, _id]);
    // } else {
    //   setSelectedIds([...selectedIds].filter((id) => id !== _id));
    // }
    if (selectedIds.some((id) => id === _id)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [isSelected]);

  const a = async (shwMore) => {
    setIsLoadingScreenshot(true);
    let cloneNumbers = [];

    for (let i = minClones; i <= maxClones; i++) {
      let added = false;
      for (let account of accounts) {
        if (account.vmNumber == i) {
          cloneNumbers.push({ ...account });
          added = true;
        }
      }
      if (!added) {
        cloneNumbers.push({ ...(possibleMissingAccounts[i] ? possibleMissingAccounts[i][0] : {}), isMissing: true, vmNumber: i < 10 ? `0${i}` : i });
      }
    }
    if (cloneNumbers.length == 0) {
      setIsLoadingScreenshot(false);
    }
    // if (!showingScreenshot) {
    //   // console.log("Not downloading all screenshots on useEffect");
    //   setScreenshotVms(cloneNumbers);
    //   setIsLoadingScreenshot(false);
    //   return;
    // } else {
    //   // console.log("Downloading all screenshots on useEffect");
    // }
    // -------------
    let promises = [];
    (shwMore ? cloneNumbers : cloneNumbers.slice(0, 6)).forEach((account) => {
      let screenshotDataRequest = axios.get(`/api/v1/accounts/${account._id}/screenshot`);
      promises.push(screenshotDataRequest);
    });

    let promisesResponse = await promisesHandler(promises);
    let newScreenshotVms = cloneNumbers.map((vm) => {
      for (let promise of promisesResponse) {
        if (!promise.code && vm._id == promise?.data?.data?._id) {
          return { ...vm, screenshot: promise.data.data.screenshot, screenshotDate: promise.data.data.screenshotDate };
        }
      }
      return { ...vm, screenshot: "-", screenshotDate: Date.now() };
    });
    setScreenshotVms(newScreenshotVms);

    setIsLoadingScreenshot(false);
  };
  useEffect(() => {
    if (displayAsTable != "monitor") {
      return;
    }

    a(showingMore);
  }, [displayAsTable]);
  const showColumnsTds = (id) => {
    switch (id) {
      case "serverName":
        return <td style={{ minWidth: "150px" }}>{serverName}</td>;

      case "serverId":
        return <td style={{}}>{serverId ? (serverId.length > 4 ? serverId.substring(0, 7) + "..." : serverId) : "-"}</td>;
      case "ipAddress":
        return <td>{ipAddress}</td>;
      case "macAdress":
        return <td>{macAddress}</td>;
      case "missingVms":
        return (
          <td>
            {missingVms.length > 0 ? (
              <>
                {missingVms.length > 0 && showOnlyActive && (
                  <div className="tooltip" style={{ borderBottom: "none" }}>
                    <i className={`far fa-user`} style={{ color: "crimson" }}></i>

                    <span className="tooltiptext">
                      Missing VM{missingVms.length > 1 ? "s" : ""}: {missingVms.map((m, i) => `${i != 0 ? ", \n" : ""}${m}`)}
                      {Object.entries(possibleMissingAccounts).length > 0 && (
                        <>
                          <br></br>
                          <br></br>
                          {Object.entries(possibleMissingAccounts).map(([key, value], i) => (
                            <span key={Math.random()}>
                              {value.map((acc) => `${key}: ${acc.summonerName} - ${acc.region.toUpperCase()} - Last Update: ${acc.lastModified > 0 ? longAgo(acc.lastModified) : "Never"}`)}
                              {i < Object.entries(possibleMissingAccounts).length - 1 && <br></br>}
                            </span>
                          ))}
                        </>
                      )}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <i style={{ color: "green" }} className={`fas fa-check`}></i>
            )}
          </td>
        );
      case "minMaxClones":
        return (
          <td>
            {minClones}-{maxClones}
          </td>
        );
      case "totalAccs":
        return <td>{accounts.length}</td>;
      case "serverActions":
        return (
          <>
            <td>
              <div className="accounts__buttons">
                <div title={`Pause Accounts`}>{<i className={`accounts__button fas fa-pause`} onClick={() => handleAction("[PAUSE]", pauseAccounts, accountIds)}></i>}</div>
                <div title={`Unpause Account`}>{<i className={`accounts__button fas fa-play`} onClick={() => handleAction("[UNPAUSE]", unpauseAccounts, accountIds)}></i>}</div>

                <div title={`Change Account`}>
                  <i className={`accounts__button fas fa-exchange-alt`} onClick={() => handleAction("[CHANGE]", stopAccounts, accountIds)}></i>
                </div>

                <div title={`Restart Account`}>
                  <i className={`accounts__button fas fa-sync-alt`} onClick={() => handleAction("[RESTART]", restartAccounts, accountIds)}></i>
                </div>

                {/* <div title={`Revert Action`}>
          <i className={`accounts__button fas fa-undo-alt`} onClick={() => handleAction(revertActions, accountIds)}></i>
        </div> */}

                <div title={`Turn On Account`}>
                  <i className="accounts__button fas fa-play-circle" onClick={() => handleAction("[POWER_ON]", powerOnAccounts, accountIds)}></i>
                </div>
                <div>
                  <div title={`Turn Off Account`}>
                    <i className="accounts__button fas fa-power-off" onClick={() => handleAction("[POWER_OFF]", powerOffAccounts, accountIds)}></i>
                  </div>
                </div>
                <div title={`Turn Off VM`}>
                  <i className={`accounts__button fas fa-power-off`} onClick={() => handleAction("[TURN_OFF_VMS]", turnOffVms, accountIds)}></i>
                </div>
                <div title={`Restart VM`}>
                  <i className={`accounts__button fas fa-sync-alt`} onClick={() => handleAction("[RESTART_VMS]", restartVms, accountIds)}></i>
                </div>

                <button
                  className={`${styles.green}`}
                  style={{ marginLeft: "1em" }}
                  onClick={() => {
                    handlePopup();
                    // handlePopup();
                  }}
                >
                  Manage
                </button>
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </>
        );
      case "forceRegion":
        return <td>{!forceRegion ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>;
      case "region":
        return <td>{region.toUpperCase()}</td>;
      case "showOnlyActiveAccs":
        return <td>{!showOnlyActive ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>;
      case "forcePriority":
        return <td>{!forcePriority ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>;
      case "priority":
        return <td>{priority}</td>;
      case "maintainVmsOn":
        return <td>{!maintainVmsOn ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>;
      case "numberOfVms":
        return <td>{numberOfVms}</td>;
      case "windowsUpdates":
        return (
          <td>
            {windowsUpdatesAvailable > 0 ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                <i className="fa-solid fa-circle-exclamation" style={{ color: "rgb(255, 30, 30)" }}></i>
                {windowsUpdatesAvailable}
              </div>
            ) : (
              windowsUpdatesAvailable
            )}
          </td>
        );
      case "cloneDrives":
        return <td>{cloneDrives}</td>;
      case "inactiveVmMinutes":
        return <td>{inactiveVmMinutes}</td>;
      case "vmStartDelay":
        return <td>{vmStartDelay}</td>;
      case "managerStartDelay":
        return <td>{managerStartDelay}</td>;
      case "monitorHardware":
        return <td>{!monitorHardware ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>;
      case "logToFile":
        return <td>{!logToFile ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>;
      case "autoRestart":
        return <td>{!autoRestart ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>;
      case "spacePerVm":
        return <td>{spacePerVm}</td>;
      case "ramPerVmInMb":
        return <td>{ramPerVmInMb}</td>;
      case "vramPerVmInKb":
        return <td>{vramPerVmInKb}</td>;
      case "autoAddToDashboard":
        return <td>{!autoAddToDashboard ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>;
      case "addToStartup":
        return <td>{!addToStartup ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>;
      case "cpuName":
        return <td>{cpuName}</td>;
      case "cpuGhz":
        return <td>{cpuGhz}</td>;
      case "gpuName":
        return <td>{gpuName}</td>;
      case "ram":
        return <td>{ram}</td>;
    }
  };
  // console.log(screenshotVms);
  const { showForceRegionSrv, showRegionSrv, showOnlyActiveAccsSrv, showForcePrioritySrv, showPrioritySrv, showMaintainVmsOnSrv, showNumberOfVmsSrv, showWindowsUpdates, showCloneDrivesSrv, showInactiveVmMinutesSrv, showVmStartDelaySrv, showManagerStartDelaySrv, showMonitorHardwareSrv, showLogToFileSrv, showAutoRestartSrv, showSpacePerVmSrv, showRamPerVmInMbSrv, showVramPerVmInKbSrv, showAutoAddToDashboardSrv, showAddToStartupSrv, showCpuNameSrv, showCpuGhzSrv, showGpuNameSrv, showRamSrv } = user;
  return (
    <>
      {isPopupShowingViewScreenshot.showing && <ViewScreenshot isPopupShowing={isPopupShowingViewScreenshot} sendPopupShowing={(v) => setIsPopupShowingViewScreenshot(v)} screenshotVms={screenshotVms} updateScreenshotVms={setScreenshotVms} />}
      {displayAsTable == "col" && (
        <div className={styles.serverItem}>
          <div className={styles.serverTitle}>
            <div>
              <span>{serverName}</span>
              <span style={{ color: "#347474" }}> | </span>
              <span>{ipAddress}</span>
              {forceRegion && (
                <>
                  <span style={{ color: "#347474" }}> | </span>

                  <span> {region.toUpperCase()}</span>
                </>
              )}
              {forcePriority && (
                <>
                  <span style={{ color: "#347474" }}> | </span>

                  <span> {priority}</span>
                </>
              )}
            </div>
            <div className={styles.editServer}>
              <div className={styles.activeAccs} style={{ whiteSpace: "nowrap" }}>
                {missingVms.length > 0 && showOnlyActive && (
                  <div className="tooltip" style={{ borderBottom: "none", marginLeft: ".5em" }}>
                    <i className={`far fa-user`} style={{ color: "crimson" }}></i>

                    <span className="tooltiptext">
                      Missing VM{missingVms.length > 1 ? "s" : ""}: {missingVms.map((m, i) => `${i != 0 ? ", \n" : ""}${m}`)}
                      {Object.entries(possibleMissingAccounts).length > 0 && (
                        <>
                          <br></br>
                          <br></br>
                          {Object.entries(possibleMissingAccounts).map(([key, value], i) => (
                            <span key={Math.random()}>
                              {value.map((acc) => `${key}: ${acc.summonerName} - ${acc.region.toUpperCase()} - Last Update: ${acc.lastModified > 0 ? longAgo(acc.lastModified) : "Never"}`)}
                              {i < Object.entries(possibleMissingAccounts).length - 1 && <br></br>}
                            </span>
                          ))}
                        </>
                      )}
                    </span>
                  </div>
                )}

                <div className="tooltip" style={{ borderBottom: "none", marginLeft: ".5em" }}>
                  <i className={`fas fa-user-check ${!showOnlyActive && "disabled"}`}></i>
                  <span className="tooltiptext">Show Only Active Accs: {showOnlyActive ? "ENABLED" : "DISABLED"}</span>
                </div>

                <div className="tooltip" style={{ borderBottom: "none", marginLeft: ".5em" }}>
                  <i className={`fas fa-power-off ${!maintainVmsOn && "disabled"}`}></i>
                  <span className="tooltiptext">Maintain VMs ON: {maintainVmsOn ? `ENABLED (${numberOfVms})` : "DISABLED"}</span>
                </div>
              </div>
              <button className={`${styles.green}`} onClick={handlePopup}>
                Manage
              </button>
            </div>
          </div>
          {accounts.length > 0 ? (
            <>
              {!isLoading ? (
                <div className={styles.container}>
                  <div className={styles.accountsWrapper}>
                    <div className={styles.scroll}>
                      <table>
                        <thead>
                          <tr>
                            <th>Summoner Name</th>
                            <th>Region</th>
                            <th>Current Level</th>
                            <th>BE</th>
                            <th>Status</th>
                            {/* <th>State</th> */}
                            {lpq > 0 && <th>LPQ</th>}
                            <th>VM Number</th>
                            {showCountry && <th>Country</th>}
                            {showAccActions && <th colSpan={2}>Actions</th>}

                            {/* <th>Priority</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {accounts.map((acc) => (
                            <ServerAccountItem key={acc._id} acc={acc} showCountry={showCountry} showAccActions={showAccActions} externalWebsite={user.externalWebsite} />
                            // <tr key={acc._id}>
                            // 	<td>{acc.summonerName}</td>
                            // 	<td>{acc.region.toUpperCase()}</td>
                            // 	<td>
                            // 		{acc.currentLevel} ({acc.nextLevelPercentage}%)
                            // 	</td>
                            // 	<td>{acc.blueEssence}</td>
                            // 	<td>{acc.status}</td>
                            // 	{/* <td>{acc.state.toUpperCase()}</td> */}
                            // 	{lpq > 0 && <td>{acc.matchmakingStatus == 'low-prio-queue' ? <i style={{ color: 'green' }} className='fas fa-check'></i> : <i style={{ color: 'rgb(151, 0, 0)' }} className='fas fa-times'></i>}</td>}
                            // 	<td>{acc.vmNumber}</td>
                            // 	{showCountry && <td>{acc.country}</td>}
                            // 	{/* <td>{acc.priority}</td> */}
                            // </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{ width: "10px" }}></div>
                  <div className={styles.actionContainer} style={{ fontSize: "14.5px" }}>
                    <button className="aqua-green anim" onClick={() => handleAction("[PAUSE]", pauseAccounts, accountIds)}>
                      Pause
                    </button>
                    <button className="aqua-green anim" onClick={() => handleAction("[UNPAUSE]", unpauseAccounts, accountIds)}>
                      Unpause
                    </button>

                    <button className="aqua-green anim" onClick={() => handleAction("[RESTART]", restartAccounts, accountIds)}>
                      Restart
                    </button>
                    <button className="aqua-green anim" onClick={() => handleAction("[CHANGE]", stopAccounts, accountIds)}>
                      Change Accs
                    </button>
                    <button className="aqua-green anim" onClick={() => handleAction("[POWER_ON]", powerOnAccounts, accountIds)}>
                      Turn On
                    </button>
                    <button className="aqua-green anim" onClick={() => handleAction("[POWER_OFF]", powerOffAccounts, accountIds)}>
                      Turn Off
                    </button>

                    <button className="aqua-green anim" onClick={() => handleAction("[TURN_OFF_VMS]", turnOffVms, accountIds)}>
                      Turn Off VM
                      {maintainVmsOn && (
                        <div
                          className={`tooltip`}
                          style={{
                            border: "none",
                            marginLeft: ".5em",
                            height: "100%",
                          }}
                        >
                          <span className="tooltiptext">"Maintain VMS ON" feature is turned ON, disable it before Turning OFF VMS or they will be turned ON by the Manager.</span>
                          <i className="fa-solid fa-circle-exclamation"></i>
                        </div>
                      )}
                    </button>
                    <button className="aqua-green anim" onClick={() => handleAction("[RESTART_VMS]", restartVms, accountIds)}>
                      Restart VM
                    </button>
                    <button style={{ backgroundColor: "#4393b3" }} className="aqua-green anim" onClick={onClickShowAccActions}>
                      {!showAccActions ? "Show Acc Actions" : "Hide Acc Actions"}
                    </button>
                    <div style={{ fontSize: "13.5px" }}>
                      <div>Total: {total}</div>
                      {active > 0 && <div>Active: {active}</div>}
                      {ingame > 0 && <div>Ingame: {ingame}</div>}
                      {inqueue > 0 && <div>InQueue: {inqueue}</div>}
                      {offline > 0 && <div>Offline: {offline}</div>}
                      {lpq > 0 && <div>LPQ: {lpq}</div>}
                    </div>
                  </div>
                </div>
              ) : (
                <Spinner />
              )}
            </>
          ) : (
            <div className={styles.emptyAccounts}>
              <h1>No Accounts</h1>
            </div>
          )}
        </div>
      )}
      {displayAsTable == "list" && (
        <tr className={isSelected ? "selected" : ""}>
          <td onMouseEnter={onMouseOver}>{isSelected && <i style={{ pointerEvents: "none", color: "#1c4141" }} className="fas fa-check-square"></i>}</td>
          {user.serverColumns.map((col) => {
            return col.enabled ? showColumnsTds(col.id) : null;
          })}
        </tr>
      )}

      {displayAsTable == "monitor" && (
        <>
          {screenshotVms !== false ? (
            <>
              <div style={{ margin: "3em 0em 5em 0em", padding: "9px", backgroundColor: "#3f4461", border: "1px solid rgb(63, 68, 97)", borderRadius: "5px" }}>
                <div style={{ padding: "10px" }}>
                  <div style={{ fontSize: "22.5px", display: "flex", justifyContent: "space-between", color: "silver" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <i style={{ color: "white" }} className="fa-solid fa-pen-to-square"></i>
                      <span>{serverName}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <i style={{ color: "white" }} className="fa-solid fa-fingerprint"></i>
                      <span>{serverId}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <i style={{ color: "white" }} className="fa-solid fa-globe"></i>
                      <span>{ipAddress}</span>
                    </div>
                  </div>
                  <div style={{ marginTop: "1em", display: "flex", justifyContent: "space-between", fontSize: "18px", color: "silver" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <i className="fa-solid fa-microchip" style={{ color: "white" }}></i>
                      <span>
                        {cpuName} {cpuGhz != "-" ? " ~ " + Number(cpuGhz / 1000).toFixed(2) + " GHz" : ""}
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <i className="fa-solid fa-tachograph-digital" style={{ color: "white" }}></i>
                      <span>{gpuName}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <i className="fa-solid fa-memory" style={{ color: "white" }}></i>
                      <span>{ram != "-" ? ram + " GB RAM" : "-"}</span>
                    </div>
                  </div>
                </div>
                {screenshotVms.length > 0 ? (
                  <div style={{ display: "flex", justifyContent: "space-between", marginTop: ".5em", fontSize: "20px", alignItems: "center" }}>
                    <button
                      onClick={() => {
                        if (!loadingScreenshot) {
                          a(showingMore);
                        }
                        // handlePopup();
                      }}
                      style={{ fontSize: "15px", backgroundColor: "rgb(55, 58, 84)", padding: "10px" }}
                    >
                      Update Images
                      <i style={{ marginLeft: "5px" }} className="fas fa-sync-alt" aria-hidden="true"></i>
                    </button>
                    {/* <button
                  className={`${styles.green}`}
                  onClick={() => {
                    handlePopup();
                    // handlePopup();
                  }}
                  style={{ fontSize: "15px" }}
                >
                  Manage
                </button> */}

                    <div className="accounts__buttons">
                      <div title={`Pause Accounts`}>{<i style={{ color: "rgb(34 36 48)" }} className={`accounts__button fas fa-pause`} onClick={() => handleAction("[PAUSE]", pauseAccounts, accountIds)}></i>}</div>
                      <div title={`Unpause Accounts`}>{<i style={{ color: "rgb(34 36 48)" }} className={`accounts__button fas fa-play`} onClick={() => handleAction("[UNPAUSE]", unpauseAccounts, accountIds)}></i>}</div>

                      <div title={`Change Accounts`}>
                        <i style={{ color: "rgb(34 36 48)" }} className={`accounts__button fas fa-exchange-alt`} onClick={() => handleAction("[CHANGE]", stopAccounts, accountIds)}></i>
                      </div>

                      <div title={`Restart Accounts`}>
                        <i style={{ color: "rgb(34 36 48)" }} className={`accounts__button fas fa-sync-alt`} onClick={() => handleAction("[RESTART]", restartAccounts, accountIds)}></i>
                      </div>

                      {/* <div title={`Revert Action`}>
          <i className={`accounts__button fas fa-undo-alt`} onClick={() => handleAction(revertActions, accountIds)}></i>
        </div> */}

                      <div title={`Turn On Accounts`}>
                        <i style={{ color: "rgb(34 36 48)" }} className="accounts__button fas fa-play-circle" onClick={() => handleAction("[POWER_ON]", powerOnAccounts, accountIds)}></i>
                      </div>
                      <div>
                        <div title={`Turn Off Accounts`}>
                          <i style={{ color: "rgb(34 36 48)" }} className="accounts__button fas fa-power-off" onClick={() => handleAction("[POWER_OFF]", powerOffAccounts, accountIds)}></i>
                        </div>
                      </div>
                      <div title={`Turn Off VMs`}>
                        <i style={{ color: "rgb(34 36 48)" }} className={`accounts__button fas fa-power-off`} onClick={() => handleAction("[TURN_OFF_VMS]", turnOffVms, accountIds)}></i>
                      </div>
                      <div title={`Restart VMs`}>
                        <i style={{ color: "rgb(34 36 48)" }} className={`accounts__button fas fa-sync-alt`} onClick={() => handleAction("[RESTART_VMS]", restartVms, accountIds)}></i>
                      </div>
                    </div>
                    <button className={`${styles.green}`} onClick={onClickShowingMore} style={{ fontSize: "15px", backgroundColor: "rgb(55, 58, 84)", padding: "10px" }}>
                      Show {showingMore ? "Less" : "More"}
                      <i style={{ marginLeft: "5px" }} className={`fa-solid fa-chevron-${showingMore ? "up" : "down"}`}></i>
                    </button>
                  </div>
                ) : (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <h2>No accounts</h2>
                  </div>
                )}

                <div className={styles.screenshotsGrid2} style={{ marginTop: "1em" }}>
                  {(showingMore ? screenshotVms : screenshotVms.slice(0, 6)).map((account) => (
                    <div key={Math.random()} style={{ display: "flex", justifyContent: "center" }}>
                      <div style={{ minWidth: "188.5px", border: "2px solid rgb(45 48 72)", borderRadius: "10px", height: "305px", backgroundColor: "rgb(55 58 84)", padding: "0px 10px 0px 10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <p style={{ margin: "10px 0px 10px 0px", fontWeight: "bold" }}>{account.vmNumber ? account.vmNumber : "?"}</p>
                          {account.status && <span style={{ fontSize: "10px", color: "silver" }}>{account.status.toUpperCase()}</span>}
                          <div className="tooltip" style={{ borderBottom: "none", marginLeft: ".5em" }}>
                            <i className={`far fa-user`} style={{ color: account.isMissing ? "crimson" : "rgb(1 165 1)", marginRight: "5px" }}></i>
                            <span className="tooltiptext" style={{ marginLeft: "-285px" }}>
                              {account.isMissing ? (
                                <>
                                  {"INACTIVE"}
                                  <br></br>
                                  <br></br>
                                  {account.summonerName ? (
                                    <>
                                      {"Previous account on this VM:"}
                                      <br></br>
                                      {account.summonerName} - {account.region.toUpperCase()} - Last Update: {account.lastModified > 0 ? longAgo(account.lastModified) : "Never"}
                                    </>
                                  ) : (
                                    <>{"No account"}</>
                                  )}
                                </>
                              ) : (
                                <>
                                  <span style={{ fontWeight: "bold" }}>{"ACTIVE"}</span>
                                  <br></br>
                                  <br></br>
                                  {account.summonerName} - {account.region.toUpperCase()} - Last Update: {account.lastModified > 0 ? longAgo(account.lastModified) : "Never"}
                                  <br></br>
                                  {account.status}
                                </>
                              )}
                              {/* {Object.entries(possibleMissingAccounts).length > 0 && (
                              <>
                                <br></br>
                                <br></br>
                                {Object.entries(possibleMissingAccounts).map(([key, value], i) => (
                                  <span key={Math.random()}>
                                    {value.map((acc) => `${key}: ${acc.summonerName} - ${acc.region.toUpperCase()} - Last Update: ${acc.lastModified > 0 ? longAgo(acc.lastModified) : "Never"}`)}
                                    {i < Object.entries(possibleMissingAccounts).length - 1 && <br></br>}
                                  </span>
                                ))}
                              </>
                            )} */}
                            </span>
                          </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                          {loadingScreenshot ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <SpinnerScreenshot />
                            </div>
                          ) : (
                            <>
                              {account.screenshot != "-" ? (
                                <>
                                  <img title={"Click to see in large"} onClick={() => onClickViewScreenshot(account)} style={{ height: "255px", width: "188.5px", cursor: "pointer", borderRadius: "5px", alignSelf: "center" }} src={`data:image/jpeg;base64,${account.screenshot}`}></img>
                                  {/* <span style={{ fontSize: "15px", marginTop: "5px", textAlign: "center", color: "#a5a5a5" }}>{longAgo(account.screenshotDate)}</span> */}
                                </>
                              ) : (
                                <h2 style={{ textAlign: "center" }}>NO SIGNAL</h2>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spinner />
            </div>
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, {
  updateServer,
  pauseAccounts,
  stopAccounts,
  restartAccounts,
  powerOffAccounts,
  powerOnAccounts,
  unpauseAccounts,
  restartVms,
  turnOffVms,
})(ServerItem);
