export default (playtime) => {
  const playtime_ = playtime;
  const seconds = Math.round(playtime_ / 1000);
  const minutes = Math.floor(seconds / 60);
  const minutesRest = Math.floor(seconds % 60);
  const hours = Math.floor(minutes / 60);
  const hoursRest = Math.floor(minutes % 60);

  if (Number(seconds < 60)) {
    return `${seconds}s`;
  }
  if (Number(minutes < 60)) {
    return `${minutes}m ${minutesRest}s`;
  } else if (Number(minutes > 59)) {
    return `${hours}h ${hoursRest}m`;
  }
};
