import React, { useEffect, useState } from "react";
import longAgo from "../../utils/longAgo";
import { connect } from "react-redux";
import { getHistory } from "../../actions/authActions";
import styles from "./History.module.scss";

const History = ({ history, showingIps }) => {
  const getIcon = () => {
    switch (history.eventType) {
      case "CREATED_SERVER":
      case "DELETED_SERVER":
      case "UPDATED_SERVER":
      case "UPDATED_SERVERS":
        return "fas fa-server";
      case "TURNED_ON_ACCOUNT":
        return "fas fa-play-circle";
      case "REVERTED_ACCOUNT_ACTION":
        return "fas fa-undo-alt";
      case "CHANGED_ACCOUNT":
        return "fas fa-exchange-alt";
      case "UNPAUSED_ACCOUNT":
        return "fas fa-play";
      case "PAUSED_ACCOUNT":
        return "fas fa-pause";
      case "TURNED_OFF_ACCOUNT":
      case "TURNED_OFF_VM":
        return "fas fa-power-off";
      case "RESTARTED_VM":
      case "RESTARTED_ACCOUNT":
        return "fas fa-sync-alt";
      case "DELETED_ACCOUNT":
      case "BAD_CREDS":
      case "REMOVED_ACCOUNT_DASHBOARD":
        return "fas fa-user-times";
      case "ADDED_ACCOUNT":
      case "ADDED_ACCOUNT_DASHBOARD":
        return "fas fa-user-plus";
      case "LPQ":
        return "fas fa-user-lock";
      case "BANNED":
        return "fas fa-user-slash";
      case "MANUALLY_FINISH_TASK":
      case "MANUALLY_FINISHED":
      case "FINISHED_QUOTA":
        return "fas fa-user-check";
      case "LOGGED_IN":
        return "fas fa-sign-in-alt";
      case "UPDATED_ACCOUNT_TASK":
      case "UPDATED_ACCOUNT":
      case "PASSWORD_CHANGE":
        return "fas fa-user-edit";
      case "ADDED_IP_WHITELIST":
      case "REMOVED_IP_WHITELIST":
        return "fas fa-clipboard-list";
    }
  };
  const onClick = () => {
    setShowingDetails(!showingDetails);
  };
  const [showingDetails, setShowingDetails] = useState(false);

  const { eventType, date, title, itemsModified, authorIp, propertiesModified } = history;
  return (
    <div className={styles.historyItemWrapper}>
      <div className={styles.historyItem}>
        <div style={{ display: "flex", justifyContent: "space-between", width: `${showingIps ? "150px" : "auto"}` }}>
          <div className={getIcon(eventType)}></div>
          {showingIps && <div>{authorIp} </div>}
        </div>

        <div className={styles.historyTitle}>{title}</div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "150px" }}>
          {/* <div className={styles.date}>{longAgo(Date.parse(date))}</div> */}
          <div className={styles.date}>{longAgo(date)}</div>
          <i onClick={onClick} style={{ marginTop: "3px", cursor: "pointer" }} className={`fas fa-chevron-${showingDetails ? "up" : "down"}`}></i>
        </div>
      </div>
      {showingDetails && (
        <div className={styles.historyItemBody}>
          {propertiesModified && propertiesModified.length > 0 && (
            <>
              <span style={{ fontSize: "30px" }}>Updated Fields</span>
              <div className={styles.grids} style={{ color: "#d3d3d3" }}>
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  {propertiesModified.map((prop) => (
                    <div key={prop._id}>
                      <span style={{ fontSize: "20px", fontWeight: "700", color: "#d3d3d3" }}>{prop.fieldName}</span>
                      <span style={{ fontSize: "20px" }}>{`  >  `}</span>
                      <span style={{ color: "#2cc938" }}>{prop.newFieldValue}</span>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {itemsModified.some((item) => item.itemRegion || item.itemIp) > 0 && <span style={{ fontSize: "30px" }}>Items Modified</span>}
          <div className={styles.grids} style={{ color: "#d3d3d3", marginTop: itemsModified.length > 1 ? "20px" : "0px" }}>
            {itemsModified.map((item) => (
              <div key={item._id}>
                <span>{item.itemName}</span>
                {item.itemRegion && <span> - {item.itemRegion.toUpperCase()}</span>}
                {item.itemIp && <span> - {item.itemIp}</span>}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, { getHistory })(History);
