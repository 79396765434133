import axios from "../utils/axiosInstance";
import { GET_SERVERS, SERVER_FAIL, ADD_SERVER, REMOVE_SERVER, UPDATE_SERVER, UPDATE_SERVERS } from "./types";
import setAuthToken from "../utils/setAuthToken";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
// Login User
export const getServers = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/servers");
    dispatch({
      type: GET_SERVERS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: SERVER_FAIL,
    });
  }
};

// Login User
export const addServer = (server) => async (dispatch) => {
  try {
    const res = await axios.post("/api/v1/servers", server, config);
    if (res.data.data) {
      dispatch({
        type: ADD_SERVER,
        payload: res.data.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SERVER_FAIL,
    });
  }
};

// Login User
export const updateServer = (updatedServer) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/servers/${updatedServer.id}`, updatedServer, config);

    dispatch({
      type: UPDATE_SERVER,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: SERVER_FAIL,
    });
  }
};
export const updateServerBulk = (updatedBody) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/servers/`, updatedBody, config);
    dispatch({
      type: UPDATE_SERVERS,
      payload: res.data.data,
    });
    // res.data.data.forEach((server) => {
    //   dispatch({
    //     type: UPDATE_SERVER,
    //     payload: server,
    //   });
    // });
  } catch (err) {
    dispatch({
      type: SERVER_FAIL,
    });
  }
};
// Login User
export const deleteServer = (server) => async (dispatch) => {
  try {
    await axios.delete(`/api/v1/servers/${server.id}`);
    dispatch({
      type: REMOVE_SERVER,
      payload: server,
    });
  } catch (err) {
    dispatch({
      type: SERVER_FAIL,
    });
  }
};
