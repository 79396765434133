import { GET_NAMES, DELETE_NAME, ADD_NAME } from '../actions/types';

const initialState = {
	summonerNames: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_NAMES:
			return {
				...state,
				summonerNames: payload,
			};
		case ADD_NAME:
			return {
				...state,
				summonerNames: [...state.summonerNames, ...payload],
			};
		case DELETE_NAME:
			return {
				...state,
				summonerNames: state.summonerNames.filter((name) => name.summonerName !== payload),
			};

		default:
			return state;
	}
}
