import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Accounts from "../Accounts/Accounts";
import Overview from "../Overview/Overview";

import Spinner from "../Spinner";
import { getMyAccounts, getSkinsData } from "../../actions/accountActions";
import { getServers } from "../../actions/serverActions";

const DashboardUser = ({ accounts, user: { isAuthenticated, combineMissionWaitCount, fields, license }, getServers, getMyAccounts, getSkinsData, skinsData }) => {
  useEffect(() => {
    if (fields !== undefined || fields === "") {
      //   console.log("HHOLA");
      getMyAccounts(fields);
      // getServers();
      if (!skinsData) {
        getSkinsData();
      }
    }
  }, [fields]);

  return (
    <div className="main">
      {accounts ? (
        <>
          <Accounts />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  accounts: state.accounts.accounts,
  skinsData: state.accounts.skinsData,
});
export default connect(mapStateToProps, { getMyAccounts, getServers, getSkinsData })(DashboardUser);
