import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getHistory } from '../../actions/authActions';
import styles from './History.module.scss';
import Spinner from '../Spinner';
import HistoryItem from './HistoryItem';
import TermsOfUse from '../TermsOfUse';
const History = ({ getHistory, user, history }) => {
  // console.log(history)

  // console.log(page)
  const historyGather = async (page) => {
    setLoading(true);
    // let pageNumber = window.location.search.split('=')[1];
    // console.log(pageNumber)

    let h = await getHistory(page);
    if (h.exceeded) {
      changePage(previousPage ? page-1 : 1);
      setMaxPage(page)
      return;
    }
    setHistory(h.data);
    
    setLoading(false);
    setPreviousPage(page)
  };
  useEffect(() => {
    changePage(window.location.search.split('=')[1])
  }, []);
  const [previousPage, setPreviousPage] = useState(false);
  
  const [maxPage, setMaxPage] = useState(999999999);
  const changePage = (page) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', page);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    historyGather(page)
  }
  const [showingIps, setShowingIps] = useState(false);
  
  const [history_, setHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  // console.log("Max Page:", maxPage)
  return (
    <>
      {user ? (
        <>
          {user.acceptedTermsOfUse ? (
            <div className='main'>
              {history_ ? (
                <div className={styles.historyContainer}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                  {history_.length > 0 ? <h1>Account History</h1> : <h1>No history found</h1>}

                  <div style={{ marginLeft: '.5em' }} title={'Toggle Selection'} className={`${styles.optionsButton} ${showingIps ? 'green' : 'red'}`} onClick={()=>setShowingIps(!showingIps)}>
                    <span>Show Author IP</span>
                    <i className={`fas fa-${showingIps ? 'check' : 'times'}-circle`} style={{ color: `${showingIps ? 'green' : '#970000'}` }}></i>
                    </div>
                  </div>

              
                <div>
                <div style={{fontSize:'1.2rem',display:'flex', justifyContent:'space-between', margin:'1.5em'}}>
                    <div> 
                      
                      <span className={loading && styles.disabledPageChange || (Number(window.location.search.split('=')[1])-1 > 0 ? styles.enabledPageChange : styles.disabledPageChange)} onClick={()=>changePage(Number(window.location.search.split('=')[1])-1)}>Previous Page</span>
                      
                    </div>

                    <div style={{fontWeight:'bold'}}>Page {Number(window.location.search.split('=')[1])}</div>
                  
                    <div> 
                    <span className={loading ||Number(window.location.search.split('=')[1])+1 >= maxPage ? styles.disabledPageChange : styles.enabledPageChange }  onClick={()=>changePage(Number(window.location.search.split('=')[1])+1)}>Next Page</span>

                    {/* <span className={loading && styles.disabledPageChange || (Number(window.location.search.split('=')[1])*50 <= historyLength ? styles.enabledPageChange: styles.disabledPageChange)}  onClick={()=>changePage(Number(window.location.search.split('=')[1])+1)}>Next Page</span> */}
                        
                    </div>
                </div>
                  {history_.map((h) => (
                    <HistoryItem history={h} key={h._id} showingIps={showingIps}/>
                  ))}
                  <div style={{fontSize:'1.2rem',display:'flex', justifyContent:'space-between', margin:'1.5em'}}>
                    <div> 
                      
                    <span className={loading && styles.disabledPageChange || (Number(window.location.search.split('=')[1])-1 > 0 ? styles.enabledPageChange : styles.disabledPageChange)} onClick={()=>changePage(Number(window.location.search.split('=')[1])-1)}>Previous Page</span>
                      
                      
                    </div>

                    <div style={{fontWeight:'bold'}}>Page {Number(window.location.search.split('=')[1])}</div>
                  
                    <div> 
                    <span className={loading||Number(window.location.search.split('=')[1])+1 >= maxPage ? styles.disabledPageChange : styles.enabledPageChange }  onClick={()=>changePage(Number(window.location.search.split('=')[1])+1)}>Next Page</span>

                        {/* <span className={loading && styles.disabledPageChange || (Number(window.location.search.split('=')[1])*50 <= historyLength ? styles.enabledPageChange: styles.disabledPageChange)}  onClick={()=>changePage(Number(window.location.search.split('=')[1])+1)}>Next Page</span> */}
                    </div>
                </div>
                  </div>


               
                </div>
              ) : (
                <Spinner />
              )}
            </div>
          ) : (
            <TermsOfUse />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, { getHistory })(History);
