import React from "react";
import OverviewItem from "./OverviewItem";

const Overview = ({ stats }) => {
  let { quotaReached, addedAccounts, ingamePercentage, activePercentage, bannedAcc, activeBots, ingameBots, offlineBots, inQueueBots, LPQAccounts } = stats;

  const overviewItems = [
    {
      iconClassName: "fas fa-user",
      title: "Added Accounts",
      value: addedAccounts,
      percentageValue: false,
    },
    {
      iconClassName: "fas fa-user-check",
      title: "Finished Accounts",
      value: quotaReached,
      percentageValue: false,
    },
    {
      iconClassName: "fas fa-user-lock",
      title: "LPQ Accounts",
      value: LPQAccounts,
      percentageValue: false,
    },
    {
      iconClassName: "fas fa-user-slash",
      title: "Banned Accounts",
      value: bannedAcc,
      percentageValue: false,
    },
    {
      iconClassName: "fas fa-user-ninja",
      title: "Active Bots",
      value: activeBots,
      percentageValue: activePercentage,
    },
    {
      iconClassName: "fas fa-stopwatch",
      title: "In Queue Bots",
      value: inQueueBots,
      percentageValue: false,
    },
    {
      iconClassName: "fas fa-headset",
      title: "Ingame Bots",
      value: ingameBots,
      percentageValue: ingamePercentage,
    },
    {
      iconClassName: "fas fa-bed",
      title: "Offline Bots",
      value: offlineBots,
      percentageValue: false,
    },
  ];

  return (
    <div className="overview">
      <div className="overview__container">
        {overviewItems.map((item, i) => (
          <OverviewItem key={i} iconClassName={item.iconClassName} title={item.title} value={item.value} percentageValue={item.percentageValue} />
        ))}
      </div>
    </div>
  );
};

export default Overview;
