import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateServer, deleteServer, getServers } from "../../actions/serverActions";
import { getMyAccounts } from "../../actions/accountActions";

import Spinner from "../Spinner";
import styles from "./Servers.module.scss";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import md5 from "md5";
import longAgo from "../../utils/longAgo";
import promisesHandler from "../../utils/promisesHandler";
import axios from "../../utils/axiosInstance";
import ViewScreenshot from "./ViewScreenshot";

let webSocket;
function connectWebsocket() {
  webSocket = new WebSocket("wss://relay.gydrus.net:8358");
  // webSocket = new WebSocket('wss://relay.gydrusapi.cf:8357');
}
connectWebsocket();
var isServerOnline;
var isFirstStatusRequestSent;
var isFirstSystemRequestSent;
var isCommandInProgress;

const getTime = () => {
  let date = new Date();
  let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return hours + ":" + minutes + ":" + seconds;
};
const getMax = (accs) => {
  for (let i = 0; i < accs.length; i++) {
    let acc = accs[i];
    if (!isNaN(acc.vmNumber)) {
      return acc.vmNumber;
    }
  }
};
const getMin = (accs) => {
  for (let i = accs.length - 1; i >= 0; i--) {
    let acc = accs[i];
    if (!isNaN(acc.vmNumber)) {
      return acc.vmNumber;
    }
  }
};
const EditServer = ({ isPopupShowing: { showing }, accounts, user, sendPopupShowing, server, updateServer, deleteServer, getMyAccounts, getServers }) => {
  // console.log("Server in EditServer", server);
  const serverSecret = md5(user.username).toUpperCase().substr(0, 12);
  // console.log(server);

  // console.log(server);
  const [connection, setConnection] = useState("loading");
  const [serverStatus, setServerStatus] = useState("offline");
  const [activeClones, setActiveClones] = useState(0);
  const [totalClones, setTotalClones] = useState(0);
  const [requestResult, setRequestResult] = useState("");
  const [requestMade, setRequestMade] = useState("");

  const [screenshotVms, setScreenshotVms] = useState([]);
  let maxClones = getMax(server.accounts);
  let minClones = getMin(server.accounts);

  // console.log(screenshotVms);
  webSocket.onmessage = (response) => {
    // console.log("GydrusRelay: MESSAGE");
    console.log(response.data);
    let data = JSON.parse(response.data);
    if (data.server_id != serverId) {
      return;
    }
    switch (data.request) {
      case "status":
        setServerStatus(data.status);
        if (data.status !== "offline") {
          isServerOnline = true;
          setActiveClones(data.active_clones);
          setTotalClones(data.total_clones);
          if (data.status === "online") {
            isCommandInProgress = false;
          } else {
            isCommandInProgress = true;
          }
          if (!isFirstSystemRequestSent && webSocket.readyState === webSocket.OPEN) {
            isFirstSystemRequestSent = true;
            webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: "system" }));
            setConnection("open");
          }
        } else {
          isServerOnline = false;
        }
        break;
      case "system":
        systemStats(data);
        break;

      default:
        setRequestResult(data.result);
    }
  };

  webSocket.onclose = function () {
    console.log("GydrusRelay: CLOSE");
    setServerStatus("offline");
    setConnection("closed");
    setTimeout(function () {
      console.log("Reconnecting to websocket server...");
      connectWebsocket();
    }, 1000);
  };

  webSocket.onerror = function () {
    console.log("GydrusRelay: ERROR");
    setConnection("error");
  };

  webSocket.onopen = function () {
    console.log("GydrusRelay: ON_OPEN");
  };
  const systemStats = (data) => {
    let time = getTime();
    let processorUsage = { time, CPU: data.cpu_load, GPU: data.gpu_load };
    let memoryUsage = { time };
    let systemMemoryUsage = Math.round((data.system_memory_usage * 100) / data.system_memory_total);
    let gpuMemoryUsage = Math.round((data.gpu_memory_usage * 100) / data.gpu_memory_total);
    memoryUsage = { ...memoryUsage, RAM: isNaN(systemMemoryUsage) ? 0 : systemMemoryUsage };
    memoryUsage = { ...memoryUsage, VRAM: isNaN(gpuMemoryUsage) ? 0 : gpuMemoryUsage };
    let temperatureObject = { time, CPU: data.cpu_temp, GPU: data.gpu_temp };
    let networkObject = { time, UP: data.network_up, DOWN: data.network_down };
    let drivesObject = { time };
    let drives = data.drives;
    if (typeof drives !== "undefined") {
      drives.forEach((drive) => {
        let driveName = drive.drive;
        let driveUsage = Math.round((drive.drive_usage * 100) / drive.drive_size);
        let driveSize = drive.drive_size;
        let driveAvailable = driveSize - driveUsage;
        drivesObject = { ...drivesObject, [driveName]: driveUsage };
        // drivesObject = { ...drivesObject, [driveName]: driveUsage, [`${driveName}-DriveSize`]: driveSize, [`${driveName}-DriveAvailable`]: driveAvailable };
      });
    }
    setCharts({
      processor: [...charts.processor.slice(charts.processor.length - 6, charts.processor.length), processorUsage],
      memory: [...charts.memory.slice(charts.memory.length - 6, charts.memory.length), memoryUsage],
      temperature: [...charts.temperature.slice(charts.temperature.length - 6, charts.temperature.length), temperatureObject],
      network: [...charts.network.slice(charts.network.length - 6, charts.network.length), networkObject],
      drives: [...charts.drives.slice(charts.drives.length - 6, charts.drives.length), drivesObject],
    });
  };

  const [charts, setCharts] = useState({
    processor: [
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
    ],
    memory: [
      { time: "", RAM: 0, VRAM: 0 },
      { time: "", RAM: 0, VRAM: 0 },
      { time: "", RAM: 0, VRAM: 0 },
      { time: "", RAM: 0, VRAM: 0 },
      { time: "", RAM: 0, VRAM: 0 },
      { time: "", RAM: 0, VRAM: 0 },
      { time: "", RAM: 0, VRAM: 0 },
    ],
    temperature: [
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
      { time: "", CPU: 0, GPU: 0 },
    ],
    network: [
      { time: "", UP: 0, DOWN: 0 },
      { time: "", UP: 0, DOWN: 0 },
      { time: "", UP: 0, DOWN: 0 },
      { time: "", UP: 0, DOWN: 0 },
      { time: "", UP: 0, DOWN: 0 },
      { time: "", UP: 0, DOWN: 0 },
      { time: "", UP: 0, DOWN: 0 },
    ],
    drives: [
      { time: "", C: 0, D: 0, E: 0, F: 0, G: 0 },
      { time: "", C: 0, D: 0, E: 0, F: 0, G: 0 },
      { time: "", C: 0, D: 0, E: 0, F: 0, G: 0 },
      { time: "", C: 0, D: 0, E: 0, F: 0, G: 0 },
      { time: "", C: 0, D: 0, E: 0, F: 0, G: 0 },
      { time: "", C: 0, D: 0, E: 0, F: 0, G: 0 },
      { time: "", C: 0, D: 0, E: 0, F: 0, G: 0 },
    ],
  });

  const onClickStartVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let amount = Number(prompt("Start desired amount of clones:\nAny running clones will stay active!"));
        if (amount !== 0 && !isNaN(amount)) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "vm_start";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command + "_" + amount }));
          setServerStatus(command);
          setRequestMade(command + "_" + amount);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickStartSingleVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let number = prompt("Start desired clone number:\nAny running clones will stay active!");
        if (number !== 0 && number !== "" && number != null) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "vm_start";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command + "_n" + number }));
          setServerStatus(command);
          setRequestMade(command + "_n" + number);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickStopVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Stop all running clones?\nThese will be stopped immediately!")) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "vm_stop";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command }));
          setServerStatus(command);
          setRequestMade(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickStopSingleVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let number = prompt("Stop desired clone number:\nOther existing clones remain untouched!");
        if (number !== 0 && number !== "" && number != null) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "vm_stop";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command + "_n" + number }));

          setServerStatus(command);
          setRequestMade(command + "_n" + number);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickDeleteVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Delete all clones?\nAny running clones will be stopped immediately before deleting!")) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "vm_delete";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command }));
          setServerStatus(command);
          setRequestMade(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickDeleteSingleVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let number = prompt("Delete desired clone number:\nOther existing clones remain untouched!");
        if (number !== 0 && number !== "" && number != null) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "vm_delete";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command + "_n" + number }));
          setServerStatus(command);
          setRequestMade(command + "_n" + number);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickCloneVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let amount = Number(prompt("Create desired amount of new clones:\nAny existing clones will stay untouched!"));
        if (amount !== 0 && !isNaN(amount)) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "vm_clone";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command + "_" + amount }));
          setServerStatus(command);
          setRequestMade(command + "_" + amount);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickRecloneVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let amount = Number(prompt("Create desired amount of new clones and start them:\nAny existing clones will be stopped/deleted immediately!"));
        if (amount !== 0 && !isNaN(amount)) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "vm_reclone";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command + "_" + amount }));
          setServerStatus(command);
          setRequestMade(command + "_" + amount);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickRecloneSingleVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let number = prompt("Re-clone desired clone number:\nOther existing clones remain untouched!");
        if (number !== 0 && number !== "" && number != null) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "vm_reclone";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command + "_n" + number }));
          setServerStatus(command);
          setRequestMade(command + "_n" + number);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickStartMainVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Are you sure you want to start the main VM?")) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "vm_startmain";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command }));
          setServerStatus(command);
          setRequestMade(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickRefreshSettings = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Are you sure you want to refreshing the managers settings?")) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "refresh_settings";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command }));
          setServerStatus(command);
          setRequestMade(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickRestartVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        setRequestResult("");
        let command = "vm_restart";
        setServerStatus(command);
        setRequestMade(command);
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickRestartServer = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Are you sure you want to restart the server? It will be instantly restarted")) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "restart_server";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command }));
          setServerStatus(command);
          setRequestMade(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickRestartManager = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Are you sure you want to restart Manager?")) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "restart_manager";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command }));
          setServerStatus(command);
          setRequestMade(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickShouldTurnOffOnUpToDate = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Are you sure you want change the mode Should Turn OFF On Up To Date?")) {
          setRequestResult("");
          isCommandInProgress = true;
          let command = "turn_off-on-up-to-date";
          webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: command }));
          setServerStatus(command);
          setRequestMade(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  useEffect(() => {
    if (!isFirstStatusRequestSent && webSocket.readyState === webSocket.OPEN) {
      isFirstStatusRequestSent = true;
      webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: "status" }));
    }
    const statusInterval = setInterval(() => {
      if (webSocket.readyState === webSocket.OPEN) {
        webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: "status" }));
      }
    }, 10000);
    const systemInterval = setInterval(() => {
      if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
        webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: "system" }));
      }
    }, 20000);
    return () => {
      clearInterval(statusInterval);
      clearInterval(systemInterval);
    };
  }, [charts]);

  // ACA FINALIZA EL SERVER WEBSOCKET
  const onClickUpdate = async () => {
    //webSocket.close();
    isServerOnline = false;
    isFirstStatusRequestSent = false;
    isFirstSystemRequestSent = false;
    setIsLoading(true);

    await updateServer(updatedServer);
    setIsLoading(false);
    sendPopupShowing({ server, showing: !showing });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [multipleRegions, setMultipleRegions] = useState(false);
  const [isPopupShowingViewScreenshot, setIsPopupShowingViewScreenshot] = useState({ account: false, showing: false });
  const [updatedServer, setUpdatedServer] = useState({
    maintainVmsOn: server.maintainVmsOn,
    numberOfVms: server.numberOfVms,
    serverName: server.serverName,
    serverId: server.serverId,
    ipAddress: server.ipAddress,
    macAddress: server.macAddress,
    forceRegion: server.forceRegion,
    forcePriority: server.forcePriority,
    priority: server.priority,
    region: server.region,
    showOnlyActive: server.showOnlyActive,
    showAccActions: server.showAccActions,
    //
    windowsUpdatesAvailable: server.windowsUpdatesAvailable,
    cloneDrives: server.cloneDrives,
    inactiveVmMinutes: server.inactiveVmMinutes,
    vmStartDelay: server.vmStartDelay,
    managerStartDelay: server.managerStartDelay,
    monitorHardware: server.monitorHardware,
    logToFile: server.logToFile,
    autoRestart: server.autoRestart,
    spacePerVm: server.spacePerVm,
    ramPerVmInMb: server.ramPerVmInMb,
    vramPerVmInKb: server.vramPerVmInKb,
    autoAddToDashboard: server.autoAddToDashboard,
    addToStartup: server.addToStartup,
    cpuName: server.cpuName,
    cpuGhz: server.cpuGhz,
    gpuName: server.gpuName,
    ram: server.ram,

    id: server.id,
  });
  const onClickCancel = () => {
    // webSocket.close();
    isServerOnline = false;
    isFirstStatusRequestSent = false;
    isFirstSystemRequestSent = false;
    sendPopupShowing({ user, showing: !showing });
  };
  const onChange = (e) => {
    setUpdatedServer({
      ...updatedServer,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCheckbox = (e) => {
    setUpdatedServer({
      ...updatedServer,
      [e.target.name]: e.target.checked,
    });
  };

  const onClickDelete = async () => {
    //webSocket.close();
    var answer = window.confirm("Are you sure you want to delete the server?");
    if (answer) {
      isServerOnline = false;
      isFirstStatusRequestSent = false;
      isFirstSystemRequestSent = false;
      setIsLoading(true);
      sendPopupShowing({ showing: !showing });
      await deleteServer(updatedServer);
      setIsLoading(false);
    }
  };
  const onClickViewScreenshot = (account) => {
    setIsPopupShowingViewScreenshot({ showing: true, account });
  };
  const [showingScreenshot, setShowingScreenshot] = useState(false);
  const [showingSettings, setShowingSettings] = useState(false);
  const [loadingScreenshot, setIsLoadingScreenshot] = useState(true);
  const onClickShowScreenshot = async () => {
    if (showingScreenshot === true) {
      // console.log("Not downloading screenshots on onClickShowScreenshot");
      setShowingScreenshot(!showingScreenshot);
      return;
    } else {
      // console.log("Downloading screenshots on onClickShowScreenshot");
    }
    setShowingScreenshot(!showingScreenshot);

    // console.log(screenshotVms);
    setIsLoadingScreenshot(true);
    let promises = [];
    screenshotVms.forEach((account) => {
      let screenshotDataRequest = axios.get(`/api/v1/accounts/${account._id}/screenshot`);
      promises.push(screenshotDataRequest);
    });
    let promisesResponse = await promisesHandler(promises);
    let newScreenshotVms = screenshotVms.map((vm) => {
      for (let promise of promisesResponse) {
        // console.log(promise.code);
        if (!promise.code && vm._id == promise?.data?.data?._id) {
          return { ...vm, screenshot: promise.data.data.screenshot, screenshotDate: promise.data.data.screenshotDate };
        }
      }
      return { ...vm, screenshot: "-", screenshotDate: Date.now() };
    });
    // console.log(newScreenshotVms);
    setScreenshotVms(newScreenshotVms);
    // console.log("Settings loading to false, onClickShowScreenshot");
    setIsLoadingScreenshot(false);
  };
  const onClickReloadScreenshots = async () => {
    setIsLoadingScreenshot(true);
    // onClickRefreshAccounts();
    // await getServers();
    await getMyAccounts();

    // setIsLoadingScreenshot(false);
  };
  // console.log(screenshotVms);
  // useEffect(() => {
  //   const getNewScreenshotVms = async (promises) => {
  //     if (showingScreenshot) {
  //       setIsLoadingScreenshot(true);
  //       console.log("HERE?");
  //       let promises = [];
  //       screenshotVms.forEach((account) => {
  //         let screenshotDataRequest = axios.get(`/api/v1/accounts/${account._id}/screenshot`);
  //         promises.push(screenshotDataRequest);
  //       });

  //       let promisesResponse = await promisesHandler(promises);
  //       let newScreenshotVms = screenshotVms.map((vm) => {
  //         for (let promise of promisesResponse) {
  //           if (!promise.code && vm._id == promise?.data?.data?._id) {
  //             return { ...vm, screenshot: promise.data.data.screenshot, screenshotDate: promise.data.data.screenshotDate };
  //           }
  //         }
  //         return { ...vm, screenshot: "-", screenshotDate: Date.now() };
  //       });
  //       // console.log(newScreenshotVms);
  //       setScreenshotVms(newScreenshotVms);
  //       console.log("Aca 2");

  //       setIsLoadingScreenshot(false);
  //     }
  //   };
  //   getNewScreenshotVms();
  // }, [accounts]);

  useEffect(() => {
    const a = async () => {
      setIsLoadingScreenshot(true);
      let cloneNumbers = [];
      for (let i = minClones; i <= maxClones; i++) {
        let added = false;
        for (let account of server.accounts) {
          if (account.vmNumber == i) {
            cloneNumbers.push({ ...account });
            added = true;
          }
        }
        if (!added) {
          cloneNumbers.push({ ...(server.possibleMissingAccounts[i] ? server.possibleMissingAccounts[i][0] : {}), isMissing: true, vmNumber: i < 10 ? `0${i}` : i });
        }
      }
      if (!showingScreenshot) {
        // console.log("Not downloading all screenshots on useEffect");
        setScreenshotVms(cloneNumbers);
        setIsLoadingScreenshot(false);
        return;
      } else {
        // console.log("Downloading all screenshots on useEffect");
      }
      // -------------
      let promises = [];
      cloneNumbers.forEach((account) => {
        let screenshotDataRequest = axios.get(`/api/v1/accounts/${account._id}/screenshot`);
        promises.push(screenshotDataRequest);
      });

      let promisesResponse = await promisesHandler(promises);
      let newScreenshotVms = cloneNumbers.map((vm) => {
        for (let promise of promisesResponse) {
          if (!promise.code && vm._id == promise?.data?.data?._id) {
            return { ...vm, screenshot: promise.data.data.screenshot, screenshotDate: promise.data.data.screenshotDate };
          }
        }
        return { ...vm, screenshot: "-", screenshotDate: Date.now() };
      });
      // console.log(newScreenshotVms);
      setScreenshotVms(newScreenshotVms);
      // console.log("Aca 1", newScreenshotVms);
      // console.log("Setting Loading to false useEffect");
      setIsLoadingScreenshot(false);
    };
    // setScreenshotVms(cloneNumbers);

    a();
  }, [server]);
  // console.log(server);
  // console.log(charts.drives);
  const { windowsUpdatesAvailable, cloneDrives, inactiveVmMinutes, vmStartDelay, managerStartDelay, monitorHardware, logToFile, autoRestart, spacePerVm, ramPerVmInMb, vramPerVmInKb, autoAddToDashboard, addToStartup, serverId, serverName, forcePriority, priority, macAddress, ipAddress, forceRegion, region, showOnlyActive, maintainVmsOn, numberOfVms } = updatedServer;
  // console.log(cloneDrives, inactiveVmMinutes, managerStartDelay, spacePerVm);
  return (
    <div className={styles.popup}>
      <div className={styles.popupContent} style={{ maxWidth: showingScreenshot ? "90%" : "1200px", overflowX: "scroll" }}>
        {isPopupShowingViewScreenshot.showing && <ViewScreenshot isPopupShowing={isPopupShowingViewScreenshot} sendPopupShowing={(v) => setIsPopupShowingViewScreenshot(v)} screenshotVms={screenshotVms} updateScreenshotVms={setScreenshotVms} />}
        {!isLoading ? (
          <>
            {/* <h1>Edit Server</h1> */}
            <div style={{ display: "flex", justifyContent: "space-between", fontSize: "18px", color: "silver" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <i className="fa-solid fa-microchip" style={{ color: "white" }}></i>
                <span>
                  {server.cpuName} {server.cpuGhz != "-" ? " ~ " + Number(server.cpuGhz / 1000).toFixed(2) + " GHz" : ""}
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <i className="fa-solid fa-tachograph-digital" style={{ color: "white" }}></i>
                <span>{server.gpuName}</span>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <i className="fa-solid fa-memory" style={{ color: "white" }}></i>
                <span>{server.ram != "-" ? server.ram + " GB RAM" : "-"}</span>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "8px", color: "#ffd438", cursor: "pointer" }} onClick={() => setShowingSettings(!showingSettings)}>
                {!showingSettings ? (
                  <>
                    <i className="fa-solid fa-sliders"></i>
                    <span>Settings</span>
                    <i className="fa-solid fa-arrow-right"></i>
                  </>
                ) : (
                  <>
                    <i className="fa-solid fa-arrow-left"></i>
                    <span>Back</span>
                    <i className="fa-solid fa-sliders"></i>
                  </>
                )}
                {/* style={{ color: "rgb(0, 119, 255)" }} */}
              </div>
            </div>
            <hr style={{ marginBottom: "20px" }}></hr>
            {!showingSettings ? (
              <div>
                <div className="credentials__info-row" style={{ marginBottom: "25px" }}>
                  <div className="credentials__info-field">
                    <label>Server Name</label>
                    <input name="serverName" className="credentials__input" value={serverName} onChange={onChange}></input>
                  </div>
                  <div className="credentials__info-field">
                    <label>Server Id</label>
                    <input name="serverId" className="credentials__input" value={serverId} onChange={onChange}></input>
                  </div>
                  <div className="credentials__info-field">
                    <label>Server Ip</label>
                    <input name="ipAddress" className="credentials__input" value={ipAddress} onChange={onChange}></input>
                  </div>
                  <div className="credentials__info-field">
                    <label>MAC Address</label>
                    {/* <input disabled name='macAddress' className='credentials__input' value={macAddress}></input> */}
                    <input name="macAddress" className="credentials__input" value={macAddress} onChange={onChange}></input>
                  </div>
                </div>
                <div className="credentials__info-row" style={{ marginBottom: "20px" }}>
                  <div className="credentials__info-field">
                    <label>Force Region</label>
                    <input onChange={onChangeCheckbox} name="forceRegion" className="checkbox" type="checkbox" value={forceRegion} defaultChecked={forceRegion}></input>
                  </div>
                  {forceRegion && (
                    <>
                      {!multipleRegions && !region.includes(",") ? (
                        <div className="credentials__info-field">
                          <label>Region</label>

                          <select className="credentials__input" name="region" onChange={onChange} defaultValue={region}>
                            <option value="-">-</option>

                            <option value="na">NA</option>
                            <option value="euw">EUW</option>
                            <option value="las">LAS</option>
                            <option value="lan">LAN</option>
                            <option value="tr">TR</option>
                            <option value="kr">KR</option>
                            <option value="br">BR</option>
                            <option value="ru">RU</option>
                            <option value="eune">EUNE</option>
                            <option value="jp">JP</option>
                            <option value="oce">OCE</option>
                            <option value="ph">PH</option>
                            <option value="sg">SG</option>
                            <option value="th">TH</option>
                            <option value="tw">TW</option>
                            <option value="vn">VN</option>
                          </select>
                        </div>
                      ) : (
                        <div className="credentials__info-field">
                          <label>Regions</label>

                          <input name="region" className="credentials__input" value={region} onChange={onChange}></input>
                        </div>
                      )}
                      <div className="credentials__info-field">
                        <div>
                          <label>Show Multiple Regions</label>
                          <div className={`tooltip`} style={{ border: "none", marginLeft: "5px" }}>
                            <span className="tooltiptext">Choose multiple regions by separating commas (,)</span>
                            <i className="fas fa-question-circle"></i>
                          </div>
                        </div>
                        <input onChange={() => setMultipleRegions(!multipleRegions)} className="checkbox" type="checkbox" value={multipleRegions} defaultChecked={multipleRegions}></input>
                      </div>
                    </>
                  )}
                  <div className="credentials__info-field">
                    <label>Show Only Active Accs</label>
                    <input onChange={onChangeCheckbox} name="showOnlyActive" className="checkbox" type="checkbox" value={showOnlyActive} defaultChecked={showOnlyActive}></input>
                  </div>
                </div>
                <div className="credentials__info-row" style={{ marginBottom: "20px" }}>
                  <div className="credentials__info-field">
                    <label>Force Priority</label>
                    <input onChange={onChangeCheckbox} name="forcePriority" className="checkbox" type="checkbox" value={forcePriority} defaultChecked={forcePriority}></input>
                  </div>
                  {forcePriority && (
                    <div className="credentials__info-field">
                      <div style={{ marginLeft: "5px" }}>
                        <label>Priority</label>
                        <div className={`tooltip`} style={{ border: "none", marginLeft: "5px" }}>
                          <span className="tooltiptext">Choose multiple priorities separating commas (,)</span>
                          <i className="fas fa-question-circle"></i>
                        </div>
                      </div>
                      <input name="priority" className="credentials__input" value={priority} onChange={onChange}></input>
                    </div>
                  )}
                </div>

                <div className="credentials__info-row" style={{ marginBottom: "1em" }}>
                  <div className="credentials__info-field">
                    <label>Maintain VMs On</label>
                    <input onChange={onChangeCheckbox} name="maintainVmsOn" className="checkbox" type="checkbox" value={maintainVmsOn} defaultChecked={maintainVmsOn}></input>
                  </div>
                  {maintainVmsOn && (
                    <div className="credentials__info-field">
                      <label>Number Of VMs</label>
                      <input name="numberOfVms" className="credentials__input" value={numberOfVms} onChange={onChange}></input>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div className="credentials__info-row" style={{ marginBottom: "30px" }}>
                  <div className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Clone Drives</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        Disk Name(s) where to place the Clones (separate by "+")
                      </span>
                    </div>
                    <input name="cloneDrives" className="credentials__input" value={cloneDrives} onChange={onChange}></input>
                  </div>
                  <div className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Inactive VM Minutes</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        Amount of minutes until a VM is detected as bugged without activity
                      </span>
                    </div>
                    <input name="inactiveVmMinutes" className="credentials__input" value={inactiveVmMinutes} onChange={onChange}></input>
                  </div>
                  <div className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Space per VM</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        Minimum space per Clone in MB
                      </span>
                    </div>
                    <input name="spacePerVm" className="credentials__input" value={spacePerVm} onChange={onChange}></input>
                  </div>
                  <div className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Manager Start Delay</label>
                      <span style={{ fontSize: "0.8rem", left: "-150px" }} className="tooltiptext">
                        Amount of seconds to wait before initializing GydrusManager
                      </span>
                    </div>
                    {/* <input disabled name='macAddress' className='credentials__input' value={macAddress}></input> */}
                    <input name="managerStartDelay" className="credentials__input" value={managerStartDelay} onChange={onChange}></input>
                  </div>
                </div>
                <div className="credentials__info-row" style={{ marginBottom: "30px" }}>
                  <div className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>RAM per VM in MB</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        RAM assigned to each VM in MB
                      </span>
                    </div>
                    <input name="ramPerVmInMb" className="credentials__input" value={ramPerVmInMb} onChange={onChange}></input>
                  </div>
                  <div className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>VRAM per VM in MB</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        VRAM assigned to each VM in KB
                      </span>
                    </div>
                    <select className="credentials__input" name="vramPerVmInKb" onChange={onChange} defaultValue={vramPerVmInKb}>
                      <option value={32768}>32</option>
                      <option value={65536}>64</option>
                      <option value={131072}>128</option>
                    </select>
                  </div>
                  <div className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>VM Start Delay</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        Amount of seconds to wait between each VM start action
                      </span>
                    </div>
                    {/* <input disabled name='macAddress' className='credentials__input' value={macAddress}></input> */}
                    <input name="vmStartDelay" className="credentials__input" value={vmStartDelay} onChange={onChange}></input>
                  </div>
                  <section className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Auto add to Dashboard</label>
                      <span style={{ fontSize: "0.8rem", left: "-150px" }} className="tooltiptext">
                        Auto add server to dashboard with its Server ID as name
                      </span>
                    </div>
                    <input onChange={onChangeCheckbox} name="autoAddToDashboard" className="checkbox" type="checkbox" value={autoAddToDashboard} defaultChecked={autoAddToDashboard}></input>
                  </section>
                </div>

                <div className="credentials__info-row" style={{ marginBottom: "30px" }}>
                  <section className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Add to Startup</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        Add Manager to startup
                      </span>
                    </div>
                    <input onChange={onChangeCheckbox} name="addToStartup" className="checkbox" type="checkbox" value={addToStartup} defaultChecked={addToStartup}></input>
                  </section>
                  <section className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Monitor Hardware</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        True|False - Monitor Hardware feature
                      </span>
                    </div>
                    <input onChange={onChangeCheckbox} name="monitorHardware" className="checkbox" type="checkbox" value={monitorHardware} defaultChecked={monitorHardware}></input>
                  </section>
                  <section className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Log to File</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        Log GydrusManager activity to file
                      </span>
                    </div>
                    <input onChange={onChangeCheckbox} name="logToFile" className="checkbox" type="checkbox" value={logToFile} defaultChecked={logToFile}></input>
                  </section>

                  <section className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Auto Restart</label>
                      <span style={{ fontSize: "0.8rem", left: "-150px" }} className="tooltiptext">
                        True|False - Auto Restart GydrusManager every 24h
                      </span>
                    </div>
                    <input onChange={onChangeCheckbox} name="autoRestart" className="checkbox" type="checkbox" value={autoRestart} defaultChecked={autoRestart}></input>
                  </section>
                </div>
              </div>
            )}

            <div className="credentials__info-row">
              <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickUpdate}>
                <span>Update Server</span>
              </div>
              <div className={`button ${styles.red}`} style={{ width: "101px" }} onClick={onClickDelete}>
                <span>Delete Server</span>
              </div>
              <div className="button aqua-green" onClick={onClickCancel}>
                <span>Close</span>
              </div>
            </div>
            <hr style={{ marginBottom: "0px" }}></hr>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h2>
                <>
                  GydrusManager: {serverStatus.toUpperCase()}
                  {isServerOnline && (
                    <>
                      {" "}
                      <span>
                        ({activeClones}/{totalClones})
                      </span>
                    </>
                  )}
                  {server.missingVms.length > 0 && (
                    <div className="tooltip" style={{ borderBottom: "none", marginLeft: ".5em" }}>
                      <i className={`far fa-user`} style={{ color: "crimson" }}></i>
                      <span className="tooltiptext">
                        Missing VM{server.missingVms.length > 1 ? "s" : ""}: {server.missingVms.map((m, i) => `${i != 0 ? ", \n" : ""}${m}`)}
                        {Object.entries(server.possibleMissingAccounts).length > 0 && (
                          <>
                            <br></br>
                            <br></br>
                            {Object.entries(server.possibleMissingAccounts).map(([key, value], i) => (
                              <span key={Math.random()}>
                                {value.map((acc) => `${key}: ${acc.summonerName} - ${acc.region.toUpperCase()} - Last Update: ${acc.lastModified > 0 ? longAgo(acc.lastModified) : "Never"}`)}
                                {i < Object.entries(server.possibleMissingAccounts).length - 1 && <br></br>}
                              </span>
                            ))}
                          </>
                        )}
                      </span>
                    </div>
                  )}
                  <span style={{ marginLeft: ".5em" }}>
                    {/* {server.missingVms.length == 0 && <span>|{'   '}</span>} */}
                    {server.minClones}-{server.maxClones}
                  </span>
                  {requestResult && (
                    <span>
                      {" "}
                      | Command {requestMade.toUpperCase()} result: {requestResult.toUpperCase()}
                    </span>
                  )}
                </>
              </h2>
              <div>
                {showingScreenshot && <i title={"Reload accounts and screenshots"} onClick={onClickReloadScreenshots} style={{ cursor: "pointer", marginRight: "1em", fontSize: "20px" }} className={`fas fa-sync-alt`}></i>}
                <i title={"Show Screenshots"} onClick={onClickShowScreenshot} style={{ cursor: "pointer", fontSize: "20px", opacity: showingScreenshot ? "100%" : "50%" }} className="fa fa-desktop" aria-hidden="true"></i>
                {/* <i class="fa-solid fa-person"></i> */}
              </div>
            </div>
            {showingScreenshot ? (
              <>
                {screenshotVms.length > 0 ? (
                  <div className={styles.screenshotsGrid}>
                    <>
                      {screenshotVms.map((account) => (
                        <div key={Math.random()}>
                          <div style={{ border: "2px solid rgb(36 38 54)", borderRadius: "10px", height: "450px", backgroundColor: "rgb(55 58 84)", padding: "0px 10px 0px 10px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                              <p style={{ marginLeft: "10px", fontWeight: "bold" }}>{account.vmNumber ? account.vmNumber : "?"}</p>

                              <div className="tooltip" style={{ borderBottom: "none", marginLeft: ".5em" }}>
                                <i className={`far fa-user`} style={{ color: account.isMissing ? "crimson" : "rgb(1 165 1)", marginRight: "5px" }}></i>
                                <span className="tooltiptext" style={{ marginLeft: "-285px" }}>
                                  {account.isMissing ? (
                                    <>
                                      {"INACTIVE"}
                                      <br></br>
                                      <br></br>
                                      {account.summonerName ? (
                                        <>
                                          {"Previous account on this VM:"}
                                          <br></br>
                                          {account.summonerName} - {account.region.toUpperCase()} - Last Update: {account.lastModified > 0 ? longAgo(account.lastModified) : "Never"}
                                        </>
                                      ) : (
                                        <>{"No account"}</>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <span style={{ fontWeight: "bold" }}>{"ACTIVE"}</span>
                                      <br></br>
                                      <br></br>
                                      {account.summonerName} - {account.region.toUpperCase()} - Last Update: {account.lastModified > 0 ? longAgo(account.lastModified) : "Never"}
                                      <br></br>
                                      {account.status}
                                    </>
                                  )}
                                  {/* {Object.entries(server.possibleMissingAccounts).length > 0 && (
                              <>
                                <br></br>
                                <br></br>
                                {Object.entries(server.possibleMissingAccounts).map(([key, value], i) => (
                                  <span key={Math.random()}>
                                    {value.map((acc) => `${key}: ${acc.summonerName} - ${acc.region.toUpperCase()} - Last Update: ${acc.lastModified > 0 ? longAgo(acc.lastModified) : "Never"}`)}
                                    {i < Object.entries(server.possibleMissingAccounts).length - 1 && <br></br>}
                                  </span>
                                ))}
                              </>
                            )} */}
                                </span>
                              </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                              {loadingScreenshot ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <Spinner />
                                </div>
                              ) : (
                                <>
                                  {account.screenshot != "-" ? (
                                    <>
                                      <img title={"Click to see in large"} onClick={() => onClickViewScreenshot(account)} style={{ cursor: "pointer", borderRadius: "5px", alignSelf: "center", height: "350px", width: "250px" }} src={`data:image/jpeg;base64,${account.screenshot}`}></img>
                                      <span style={{ fontSize: "15px", marginTop: "5px", textAlign: "center", color: "#a5a5a5" }}>{longAgo(account.screenshotDate)}</span>
                                    </>
                                  ) : (
                                    <h2 style={{ textAlign: "center" }}>NO SIGNAL</h2>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  </div>
                ) : (
                  <>{loadingScreenshot ? <Spinner /> : <h1 style={{ textAlign: "center" }}>No accounts</h1>}</>
                )}
              </>
            ) : (
              <>
                {connection === "open" ? (
                  <>
                    <div className="credentials__info-row">
                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickStartVm}>
                        <span>Start VMs</span>
                      </div>

                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickStopVm}>
                        <span>Stop VMs</span>
                      </div>

                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickDeleteVm}>
                        <span>Delete VMs</span>
                      </div>

                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickRecloneVm}>
                        <span>Re-Clone VMs</span>
                      </div>

                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickCloneVm}>
                        <span>Clone VM</span>
                      </div>
                      <div className="button aqua-green" style={{ width: "110px", fontSize: "12.9px" }} onClick={onClickShouldTurnOffOnUpToDate}>
                        <span>Turn Off MainVM when up to date</span>
                      </div>
                      <div className="button aqua-green" style={{ width: "110px", backgroundColor: "#0077ff" }} onClick={onClickRestartServer}>
                        <span>Restart Server</span>
                      </div>
                      {/*<div className='button aqua-green' style={{ width: '110px' }} onClick={onClickRestartVm}>
                    <span>Restart VM</span>
                  </div>*/}
                    </div>
                    <div className="credentials__info-row" style={{ textAlign: "center" }}>
                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickStartSingleVm}>
                        <span>Start VM (single)</span>
                      </div>

                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickStopSingleVm}>
                        <span>Stop VM (single)</span>
                      </div>

                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickDeleteSingleVm}>
                        <span>Delete VM (single)</span>
                      </div>

                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickRecloneSingleVm}>
                        <span>Re-Clone VM (single)</span>
                      </div>
                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickStartMainVm}>
                        <span>Start Main VM</span>
                      </div>
                      <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickRefreshSettings}>
                        <span>Refresh Settings</span>
                      </div>
                      <div className="button aqua-green" style={{ width: "110px", backgroundColor: "#0077ff" }} onClick={onClickRestartManager}>
                        <span>Restart Manager</span>
                      </div>
                      {/* <div className="button aqua-green" style={{ width: "110px", opacity: "0%" }}>
                        <span>#</span>
                      </div> */}

                      {/*<div className='button aqua-green' style={{ width: '110px' }} onClick={onClickRestartVm}>
                    <span>Restart VM</span>
                  </div>*/}
                    </div>
                    <div className={styles.monitoringGrid}>
                      <div style={{ width: "99%" }}>
                        <h2 style={{ textAlign: "center" }}>Processor Usage (%)</h2>
                        <ResponsiveContainer width="100%" height={250}>
                          <AreaChart data={charts.processor} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis type="number" domain={[0, 100]} />
                            <Tooltip />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="CPU" stroke="#8884d8" fill="#8884d8" />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="GPU" stroke="#8884d8" fill="#8384b8" />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                      <div style={{ width: "99%" }}>
                        <h2 style={{ textAlign: "center" }}>Memory Usage (%)</h2>
                        <ResponsiveContainer width="100%" height={250}>
                          <AreaChart data={charts.memory} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis type="number" domain={[0, 100]} />
                            <Tooltip />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="RAM" stroke="#8884d8" fill="#8884d8" />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="VRAM" stroke="#8884d8" fill="#8384b8" />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                      <div style={{ width: "99%" }}>
                        <h2 style={{ textAlign: "center" }}>Drive Usage (%)</h2>
                        <ResponsiveContainer width="100%" height={250}>
                          <AreaChart data={charts.drives} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis type="number" domain={[0, 100]} />
                            <Tooltip />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="C" stroke="#8884d8" fill="#8884d8" />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="D" stroke="#8884d8" fill="#8384b8" />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="E" stroke="#8884d8" fill="#7884b8" />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="F" stroke="#8884d8" fill="#7384b8" />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="G" stroke="#8884d8" fill="#6884b8" />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                      <div style={{ width: "99%" }}>
                        <h2 style={{ textAlign: "center" }}>System Temperature (°C)</h2>
                        <ResponsiveContainer width="100%" height={250}>
                          <AreaChart data={charts.temperature} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis type="number" />
                            <Tooltip />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="CPU" stroke="#8884d8" fill="#8884d8" />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="GPU" stroke="#8884d8" fill="#8384b8" />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                      <div style={{ width: "99%" }}>
                        <h2 style={{ textAlign: "center" }}>Network Usage (Kbps)</h2>
                        <ResponsiveContainer width="100%" height={250}>
                          <AreaChart data={charts.network} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis type="number" />
                            <Tooltip />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="UP" stroke="#8884d8" fill="#8884d8" />
                            <Area animationEasing={"linear"} animationDuration={500} type="monotone" dataKey="DOWN" stroke="#8884d8" fill="#8384b8" />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </>
                ) : connection === "loading" ? (
                  <>
                    <Spinner />
                    <h2 style={{ textAlign: "center" }}>Connecting to server...</h2>
                  </>
                ) : (
                  <h2 style={{ textAlign: "center" }}>Error connecting to the relay-server!</h2>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Spinner />
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  accounts: state.accounts.accounts,
});
export default connect(mapStateToProps, { getMyAccounts, getServers, updateServer, deleteServer })(EditServer);
