export default (time) => {
  const currentTime = Math.round(new Date().getTime() / 1000);
  let diff = 0;
  let secondsSince = Math.round(currentTime - time / 1000);
  if (secondsSince > 0) {
    if (secondsSince < 60) {
      // seconds
      diff = secondsSince;
      return `${diff} second${diff > 1 ? 's' : ''} ago`;
    } else if (secondsSince < 3600) {
      // minutes
      diff = Math.floor(secondsSince / 60);
      return `${diff} minute${diff > 1 ? 's' : ''} ago`;
    } else if (secondsSince < 86400) {
      // hours
      diff = Math.floor(secondsSince / 3600);
      return `${diff} hour${diff > 1 ? 's' : ''} ago`;
    } else if (secondsSince < 2628000) {
      // days
      diff = Math.floor(secondsSince / 86400);
      return `${diff} day${diff > 1 ? 's' : ''} ago`;
    } else if (secondsSince < 31536000) {
      // months
      diff = Math.floor(secondsSince / 2628000);
      return `${diff} month${diff > 1 ? 's' : ''} ago`;
    } else if (secondsSince > 31536000) {
      // years
      diff = Math.floor(secondsSince / 31536000);
      return `${diff} year${diff > 1 ? 's' : ''} ago`;
    }

  } else {
    
    secondsSince = Math.abs(secondsSince)
    if (secondsSince < 60) {
      // seconds
      diff = secondsSince;
      return `in ${diff} second${diff > 1 ? 's' : ''}`;
    } else if (secondsSince < 3600) {
      // minutes
      diff = Math.floor(secondsSince / 60);
      return `in ${diff} minute${diff > 1 ? 's' : ''}`;
    } else if (secondsSince < 86400) {
      // hours
      diff = Math.floor(secondsSince / 3600);
      return `in ${diff} hour${diff > 1 ? 's' : ''}`;
    } else if (secondsSince < 2628000) {
      // days
      diff = Math.floor(secondsSince / 86400);
      return `in ${diff} day${diff > 1 ? 's' : ''}`;
    } else if (secondsSince < 31536000) {
      // months
      diff = Math.floor(secondsSince / 2628000);
      return `in ${diff} month${diff > 1 ? 's' : ''}`;
    } else if (secondsSince > 31536000) {
      // years
      diff = Math.floor(secondsSince / 31536000);
      return `in ${diff} year${diff > 1 ? 's' : ''}`;
    }
  }
};
