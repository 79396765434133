import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

import './App.scss';
import setAuthToken from './utils/setAuthToken';
import AppWrapper from './components/AppWrapper';
import Navbar from './components/Navbar';
if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const App = () => {
  if (window.location.search.includes('captcha')) {
    window.location.replace(window.location.pathname);
  }
  return (
    <Provider store={store}>
      <div className='container'>
        <Router>
          <Navbar />
          <Switch>
            <AppWrapper />
          </Switch>
        </Router>
      </div>
    </Provider>
  );
};

export default App;
