import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateUser, changePassword, getLicenseInfo } from "../../actions/authActions";

import Spinner from "../Spinner";
import formatTime from "../../utils/formatTime";
import timeFormatterPassed from "../../utils/formatTimePassed";
import longAgo from "../../utils/longAgo";
import PaymentMethods from "./PaymentMethods";
import DatePicker from "react-datepicker";
import moment from "moment";
import styles from "../Settings.module.scss";
import "react-datepicker/dist/react-datepicker.css";

const BuyLicenses = ({ getLicenseInfo, user }) => {
  const pricing = (amount, days, fixedPrice) => {
    let ratePerDay = 0;
    if (amount <= 50) {
      ratePerDay = 0.129;
    } else if (amount <= 100) {
      ratePerDay = 0.1129;
    } else if (amount <= 200) {
      ratePerDay = 0.0967;
    } else if (amount > 200) {
      ratePerDay = 0.0806;
    }
    if (fixedPrice > 0) {
      ratePerDay = fixedPrice / 31;
    }
    // console.log(amount, days, ratePerDay, amount * days * ratePerDay);
    return (amount * days * ratePerDay).toFixed(2);
  };
  const DAY = 86400000;
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now() + DAY * 31);
  const [days, setDays] = useState(31);

  const [showingPaymentMethods, setShowingPaymentMethods] = useState(false);
  const [licenseInfoAvailable, setLicenseInfoAvailable] = useState(false);
  const [amountOfLicenses, setAmountOfLicenses] = useState(1);
  const [totalPrice, setTotalPrice] = useState(pricing(amountOfLicenses, days, 0));
  const [licenseInfo, setLicenseInfo] = useState({
    licenseExpireDate: "-",
    licenseExpireIn: "-",
  });

  const licenseInfoSet = async () => {
    let license = await getLicenseInfo();
    setLicenseInfo(license);
    setLicenseInfoAvailable(true);
  };
  useEffect(() => {
    licenseInfoSet();
  }, []);
  useEffect(() => {
    if (user) {
      setTotalPrice(pricing(amountOfLicenses, days, user.fixedPricePerLicense));
    }
  }, [user]);

  const onChangeDays = (e) => {
    if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
      setDays(e.target.value);
      setTotalPrice(pricing(amountOfLicenses, e.target.value, user.fixedPricePerLicense));
      setEndDate(startDate + DAY * e.target.value);
    }
  };
  const onChangeAmount = (e) => {
    if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
      setAmountOfLicenses(e.target.value);
      setTotalPrice(pricing(e.target.value, days, user.fixedPricePerLicense));
    }
  };

  const onClickSetStartingDateLicense = (side, license) => {
    if (side === "right") {
      setStartDate(license.expireTime);
      setEndDate(license.expireTime + DAY * days);
    } else {
      setStartDate(license.licenseStart);
      setEndDate(license.licenseStart + DAY * days);
    }
  };
  const dateChangerStart = (date) => {
    let dateEpoch = moment(date, "Y-M-DTH:mm:s").valueOf();
    setStartDate(dateEpoch);
    setEndDate(dateEpoch + DAY * days);
    // if (dateEpoch > endDate) {
    //   setEndDate(dateEpoch + DAY * days);
    // }
  };
  // const dateChangerEnd = (date) => {
  //   setEndDate(moment(date, "Y-M-DTH:mm:s").valueOf());
  // };

  return (
    <div className="main">
      {showingPaymentMethods && <PaymentMethods setShowingPaymentMethods={setShowingPaymentMethods} startDate={startDate} endDate={endDate} amountOfLicenses={amountOfLicenses} price={totalPrice} days={days} />}

      {licenseInfoAvailable ? (
        <>
          <div className={styles.overview}>
            <div className={styles.overviewContainer}>
              <div className={styles.overviewItem}>
                <i className="fas fa-address-book"></i>

                <div className={styles.overviewItemDesc}>
                  <h2>Licenses Available</h2>
                  <span>{licenseInfo.licensesCount > 0 ? licenseInfo.licensesCount : "2 (FREE)"}</span>
                </div>
              </div>
              <div className={styles.overviewItem}>
                <i className="far fa-clock"></i>
                <div className={styles.overviewItemDesc}>
                  <h2>Subscription Ends</h2>
                  <span>{licenseInfo.licenseExpireDate}</span>
                </div>
              </div>
              <div className={styles.overviewItem}>
                <i className="fas fa-hourglass-half"></i>

                <div className={styles.overviewItemDesc}>
                  <h2>Remaining Time</h2>
                  <span>{licenseInfo.licenseExpireIn}</span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "1em" }}>
            <div className={styles.settingsContainer}>
              <h1>Purchase Licenses</h1>
              <h4>Choose the start time, amount of licenses and amount of time</h4>
              <div className={styles.passwordField}>
                <label>Days</label>
                <input onChange={onChangeDays} className="credentials__input" value={days}></input>
              </div>

              <div className={styles.passwordField}>
                <label>Amount</label>
                <input onChange={onChangeAmount} className="credentials__input" value={amountOfLicenses}></input>
              </div>

              <div className={styles.passwordField} style={{ marginTop: "1em" }}>
                <span style={{ width: "100px" }}>Start Date</span>
                <DatePicker showYearDropdown dateFormatCalendar="MMMM" yearDrop timeInputLabel="Time:" dateFormat="MM/dd/yyyy h:mm aa" showTimeInput locale="en-GB" showWeekNumbers selectsStart startDate={startDate} endDate={endDate} selected={startDate} onChange={(date) => dateChangerStart(date)} />
              </div>
              <div className={styles.passwordField}>
                <span style={{ width: "100px" }}>End Date</span>
                <DatePicker
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDrop
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  locale="en-GB"
                  showWeekNumbers
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  selected={endDate}
                  disableCalendar={true}

                  //   onChange={(date) => dateChangerEnd(date)}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
              <h1>Pricing</h1>

              <h3>
                Total Price: <span style={{ fontWeight: "bold", color: "#54f120" }}>${totalPrice}</span>
              </h3>
              {user && user.fixedPricePerLicense ? (
                <div>
                  <span style={{ fontWeight: "bold" }}>Fixed Price:</span>
                  <span> ${user.fixedPricePerLicense} per license</span>
                </div>
              ) : null}
              <div className="button aqua-green" style={{ width: "120px", marginTop: "1em" }} onClick={() => setShowingPaymentMethods(!showingPaymentMethods)}>
                <span>Start Payment</span>
              </div>
            </div>
          </div>
          {licenseInfo.licensesEntries && licenseInfo.licensesEntries.length > 0 && (
            <>
              <h3>You can also use the starting or ending time of existing licenses</h3>
              <h5>Click | to set the start time of your new license, left side for Starting Date and right side for Ending Date</h5>
              <div className={styles.licensesGrid}>
                {licenseInfoAvailable && (
                  <>
                    {licenseInfo.licensesEntries.map((item) => (
                      <div key={item._id} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "10px", marginRight: ".5em", border: item.licenseStart == startDate ? "2px solid #062e06" : "none" }} className="button aqua-green" title="Set Starting Date" onClick={() => onClickSetStartingDateLicense("left", item)}>
                          <span style={{ fontSize: "20px", fontWeight: "700" }}>|</span>
                        </div>
                        <div className={styles.licenseItem} style={{ width: "270px" }}>
                          <span>
                            License Amount: <span>{item.licenses}</span>
                            {item.licenseStart > Date.now() && <span style={{ fontWeight: "bold", float: "right", color: "red" }}>Not Started Yet</span>}
                          </span>
                          <p style={{ color: "white" }}>
                            License Time: <span>{timeFormatterPassed(item.expireTime - item.licenseStart)}</span>
                          </p>
                          <p style={{ color: "white" }}>
                            Starting Date: <span>{formatTime(item.licenseStart)}</span>
                          </p>
                          <p style={{ color: "white" }}>
                            Ending Date: <span>{formatTime(item.expireTime)}</span>
                          </p>

                          <p style={{ color: "white" }}>
                            License Start: <span>{longAgo(item.licenseStart)}</span>
                          </p>
                          <p style={{ color: "white" }}>
                            Time Left: <span>{timeFormatterPassed(item.licenseStart > Date.now() ? item.expireTime - item.licenseStart : item.expireTime - Date.now())}</span>
                          </p>
                        </div>
                        <div style={{ width: "10px", marginLeft: ".5em", border: item.expireTime == startDate ? "2px solid #062e06" : "none" }} className="button aqua-green" title="Set Ending Date" onClick={() => onClickSetStartingDateLicense("right", item)}>
                          <span style={{ fontSize: "20px", fontWeight: "700" }}>|</span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, { updateUser, changePassword, getLicenseInfo })(BuyLicenses);
