export default async (arrPromises) => {
  let array = [];

  await Promise.all(arrPromises.map((p) => p.catch((e) => e)))
    .then((results) => {
      results.forEach((result) => {
        array.push(result);
      });
    })
    .catch((e) => console.log(e));

  return array;
};
