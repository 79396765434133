import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { addTask } from "../../actions/taskActions";
import Spinner from "../Spinner";
import getChampionName from "../../utils/getChampionName";
import formatPlaytime from "../../utils/formatPlaytimeDashboard";
import longAgo from "../../utils/longAgo";
import styles from "./Tasks.module.scss";
const FilterItem = ({ filter, sendUpdateFilter, allowedFilterFieldNames }) => {
  const [newFilter, setFilter] = useState(filter);
  // console.log(filter);
  const onChange = (event) => {
    setFilter({ ...newFilter, [event.target.name]: event.target.value });
    // sendUpdateFilter({ ...filter, [event.target.name]: event.target.value });
    sendUpdateFilter({ ...newFilter, [event.target.name]: event.target.value });
  };
  // console.log('Filter in FilterItem', filter);
  const onClickRemoveFilter = () => {
    sendUpdateFilter({ _id: filter._id, type: "remove" });
  };
  // console.log(filter);
  return (
    <div>
      <div className={styles.taskFilterItem}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
          <span>Filter Type: </span>
          <select style={{ marginLeft: "5px" }} className="credentials__input" name="filterType" onChange={onChange} defaultValue={newFilter.filterType}>
            <option value="eq">Equals</option>
            <option value="neq">Not Equals</option>
            <option value="gt">Greater Than</option>
            <option value="gte">Greater Than or Equals</option>
            <option value="lt">Less Than</option>
            <option value="lte">Less Than or Equals</option>
          </select>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
          <span>Field Name: </span>
          <select style={{ marginLeft: "5px" }} className="credentials__input" name="fieldName" onChange={onChange} defaultValue={newFilter.fieldName}>
            <option value="botVersion">Bot Version</option>
            <option value="limitFps">Limit FPS</option>
            <option value="vmNumber">VM Number</option>
            <option value="disenchantFragChamps">DisenchantFragChamps</option>
            <option value="disenchantFullChamps">DisenchantFullChamps</option>
            <option value="disenchantLoot">Disenchant Loot</option>
            <option value="disenchantEternal">Disenchant Eternals</option>
            <option value="nextLevelPercentage">Next Level Percentage</option>
            <option value="useCustomName">Use Custom Name</option>
            <option value="showOnDashboard">Show On Dashboard</option>
            <option value="orangeEssence">Orange Essence</option>
            <option value="blueEssence">Blue Essence</option>
            <option value="disenchantGem">Disenchant Gem</option>
            <option value="disenchantGemOption">Disenchant Gem Option</option>
            <option value="mode">Mode</option>
            <option value="region">Region</option>
            <option value="cooldownAfterSession">CooldownAfterSession</option>
            <option value="maxGamesReached">Max Games Reached</option>
            <option value="macAddress">Mac Address</option>
            <option value="disenchantEmblems">Disenchant Emblems</option>
            <option value="disenchantEmblemsOption">Disenchant Emblems Option</option>

            <option value="bestSkinType">Best Skin Type</option>
            <option value="icon">Icon</option>
            <option value="ip">IP</option>
            <option value="status">Status</option>
            <option value="state">State</option>
            <option value="skipIfNameTaken">SkipIfNameTaken</option>

            <option value="isActive">Is Active</option>
            <option value="priority">Priority</option>
            <option value="desiredBE">DesiredBE</option>
            <option value="currentLevel">Current Level</option>
            <option value="desiredLevel">Desired Level</option>
            <option value="desiredPercentage">Desired Percentage</option>
            <option value="nOfGames"> N~OfGames</option>
            <option value="maxGames">Max Games</option>
            <option value="missionTracker.completed">Completed Missions</option>
            <option value="amountOfGamesToFinish.amountOfGames">Games To Finish</option>
            <option value="skipTutorial">Skip Tutorial</option>
            <option value="hasBoostActive">Has Boost</option>
            <option value="limitResources">Limit Resources</option>
            <option value="label">Label</option>
            <option value="crazyNumber">Temp Number</option>
          </select>
          {/* <input style={{ marginLeft: '5px' }} onChange={onChange} name='fieldName' className='credentials__input' value={newFilter.fieldName}></input> */}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
          <span>Field Value: </span>
          <input placeholder={allowedFilterFieldNames[filter.fieldName]} style={{ marginLeft: "5px" }} onChange={onChange} name="fieldValue" className="credentials__input" value={newFilter.fieldValue}></input>
        </div>
      </div>
      <div style={{ justifyContent: "center", display: "flex", marginTop: ".5em" }} title="Remove Filter">
        <div style={{ height: "12px", width: "10px", backgroundColor: "#b93030" }} className="button aqua-green" onClick={onClickRemoveFilter}>
          <span style={{ fontSize: "20px", fontWeight: "700" }}>-</span>
        </div>
      </div>
    </div>
  );
};

export default FilterItem;
