import axios from "../utils/axiosInstance";
import { GET_NAMES, DELETE_NAME, ADD_NAME, NAMES_FAIL } from "./types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
// Login User
export const getNames = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/names");

    dispatch({ type: GET_NAMES, payload: res.data.data });
  } catch (err) {
    dispatch({ type: NAMES_FAIL });
  }
};

// Login User
export const addNames = (name) => async (dispatch) => {
  try {
    const res = await axios.post("/api/v1/names", name, config);

    dispatch({ type: ADD_NAME, payload: res.data.data });
  } catch (err) {
    dispatch({ type: NAMES_FAIL });
  }
};

// Login User
export const deleteName = (name) => async (dispatch) => {
  try {
    await axios.delete(`/api/v1/names/${name}`);
    dispatch({ type: DELETE_NAME, payload: name });
  } catch (err) {
    dispatch({ type: NAMES_FAIL });
  }
};
