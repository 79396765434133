import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import formatPlaytime from '../../utils/formatPlaytime';

import Spinner from '../Spinner';
import styles from './Users.module.scss';
const UserItem = ({ user, sendPopupShowing, isPopupShowing }) => {
  const { username, acceptedTermsOfUse, state, role, activeBots, licenseExpire, maxAccounts } = user;

  const handlePopup = () => {
    sendPopupShowing({
      showing: !isPopupShowing.showing,
      user,
    });
  };

  const getRevenue = (licenses) => {
    let licensePrice = 4;
    if (licenses > 50 && licenses <= 100) {
      licensePrice = 3.75;
    } else if (licenses > 100 && licenses <= 150) {
      licensePrice = 3.5;
    } else if (licenses > 150 && licenses <= 200) {
      licensePrice = 3.25;
    } else {
      licensePrice = 3;
    }

    return licenses * licensePrice;
  };
  return (
    <tr>
      <td>{username}</td>
      <td>{role}</td>
      <td>{state}</td>
      <td>{maxAccounts}</td>
      <td>{formatPlaytime(licenseExpire - Date.now())}</td>
      <td>{activeBots}</td>

      <td>{acceptedTermsOfUse ? 'YES' : 'NO'}</td>
      <td>${getRevenue(maxAccounts)} USD</td>
      <td>
        <div className={styles.btn} onClick={handlePopup}>
          <span>Manage</span>
        </div>
      </td>
    </tr>
  );
};

export default UserItem;
