import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { getMyAccounts } from "../../actions/accountActions";
// import { getServers, addServer } from "../../actions/serverActions";
// import Spinner from "../Spinner";
// import styles from "./Servers.module.scss";
// import longAgo from "../../utils/longAgo";

import { pauseAccounts, stopAccounts, restartAccounts, powerOffAccounts, restartVms, revertActions, turnOffVms, powerOnAccounts, unpauseAccounts } from "../../actions/accountActions";

const ServerAccountItem = ({ acc, showAccActions, showCountry, externalWebsite, pauseAccounts, stopAccounts, restartAccounts, revertActions, powerOffAccounts, restartVms, turnOffVms, powerOnAccounts, unpauseAccounts }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleAction = async (action, id) => {
    // if (window.confirm(`Are you sure you want to use ${functionName} action on ${id.length} account${id.length > 1 ? 's' : ''}?`)) {
    setIsLoading(true);
    await action([id]);
    setIsLoading(false);
  };
  const getExternalWebsite = (website) => {
    switch (website) {
      case "leaguegraphs":
        // return `https://www.leagueofgraphs.com/summoner/${region}/${displayName !== " " ? displayName : summonerName}`;
        return `https://www.leagueofgraphs.com/summoner/${acc.region}/${acc.displayName.split("#")[0]}-${acc.displayName.split("#")[1]}`;
      case "opgg":
        // return `https://${region}.op.gg/summoner/userName=${displayName !== " " ? displayName : summonerName}`;
        return `https://op.gg/summoners/${acc.region}/${acc.displayName.split("#")[0]}-${acc.displayName.split("#")[1]}`;
    }
  };
  return (
    <tr key={acc._id}>
      <td>
        <div className="tooltip" style={{ border: "none" }}>
          <a rel="noopener noreferrer" href={getExternalWebsite(externalWebsite)} target="_blank">
            {acc.summonerName.length > 16 ? `${acc.summonerName.substring(0, 9)}...` : acc.summonerName}
          </a>
          {acc.displayName && <span className="tooltiptext">{acc.displayName !== " " ? acc.displayName : acc.summonerName}</span>}
        </div>
      </td>
      <td>{acc.region.toUpperCase()}</td>
      <td>
        {acc.currentLevel} ({acc.nextLevelPercentage}%)
      </td>
      <td>{acc.blueEssence}</td>
      <td>{acc.status}</td>
      {/* <td>{acc.state.toUpperCase()}</td> */}
      {/* {lpq > 0 && <td>{acc.matchmakingStatus == 'low-prio-queue' ? <i style={{ color: 'green' }} className='fas fa-check'></i> : <i style={{ color: 'rgb(151, 0, 0)' }} className='fas fa-times'></i>}</td>} */}
      <td>{acc.vmNumber}</td>
      {showCountry && <td>{acc.country}</td>}
      {/* <td>{acc.priority}</td> */}
      {showAccActions && (
        <>
          <td>
            <div className="accounts__buttons">
              <div title={acc.isActive ? `Pause Account` : `Can't perform action on inactive account`}>{acc.status !== "paused" && acc.status !== "pausing" && <i className={`accounts__button fas fa-pause ${!acc.isActive && "disabled"}`} onClick={() => handleAction(pauseAccounts, acc._id)}></i>}</div>
              <div title={acc.isActive ? `Unpause Account` : `Can't perform action on inactive account`}>{(acc.status === "paused" || acc.status === "pausing") && <i className={`accounts__button fas fa-play ${(acc.status !== "paused" || !acc.isActive) && "disabled"}`} onClick={() => handleAction(unpauseAccounts, acc._id)}></i>}</div>

              <div title={acc.isActive ? `Change Account` : `Can't perform action on inactive account`}>
                <i className={`accounts__button fas fa-exchange-alt ${!acc.isActive && "disabled"}`} onClick={() => handleAction(stopAccounts, acc._id)}></i>
              </div>

              <div title={acc.isActive ? `Restart Account` : `Can't perform action on inactive account`}>
                <i className={`accounts__button fas fa-sync-alt ${!acc.isActive && "disabled"}`} onClick={() => handleAction(restartAccounts, acc._id)}></i>
              </div>
              <div title={acc.isActive ? `Revert Action` : `Can't perform action on inactive account`}>
                <i className={`accounts__button fas fa-undo-alt ${!acc.isActive && "disabled"}`} onClick={() => handleAction(revertActions, acc._id)}></i>
              </div>
              <div>{acc.state === "off" ? <i className="accounts__button fas fa-play-circle" title="Turn On Account" onClick={() => handleAction(powerOnAccounts, acc._id)}></i> : <i className="accounts__button fas fa-power-off" title="Turn Off Account" onClick={() => handleAction(powerOffAccounts, acc._id)}></i>}</div>
            </div>
          </td>
          <td>
            <div className="accounts__buttons">
              <div title={acc.isActive ? "Restart VM" : `Can't perform action on inactive account`}>
                <i className={`accounts__button fas fa-sync-alt ${!acc.isActive && "disabled"}`} title="Restart VM" onClick={() => handleAction(restartVms, acc._id)}></i>
              </div>

              <div title={acc.isActive ? "Turn Off VM" : `Can't perform action on inactive account`}>
                <i className={`accounts__button fas fa-power-off ${!acc.isActive && "disabled"}`} title="Turn Off VM" onClick={() => handleAction(turnOffVms, acc._id)}></i>
              </div>
            </div>
          </td>
        </>
      )}
    </tr>
  );
};

export default connect(null, {
  pauseAccounts,
  stopAccounts,
  restartAccounts,
  revertActions,

  powerOffAccounts,
  powerOnAccounts,
  unpauseAccounts,
  restartVms,
  turnOffVms,
})(ServerAccountItem);
