import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Spinner from "../Spinner";
import getChampionName from "../../utils/getChampionName";
import styles from "../Accounts/EditAccount.module.scss";
import axios from "../../utils/axiosInstance";
import formatPlaytime from "../../utils/formatPlaytimeDashboard";
import longAgo from "../../utils/longAgo";
const ViewScreenshot = ({ isPopupShowing: { account, showing }, sendPopupShowing, screenshotVms, updateScreenshotVms }) => {
  const [isLoading, setLoading] = useState(false);
  let [screenshotData, setScreenshotData] = useState(account);
  const onClickCancel = () => {
    sendPopupShowing({ screenshot: account.screenshot, showing: !showing });
  };
  //   console.log(account);
  const onRefreshScreenshot = async () => {
    setLoading(true);
    let screenshotDataRequest = await axios.get(`/api/v1/accounts/${account._id}/screenshot`);
    let data = screenshotDataRequest.data.data;
    // console.log(data);
    // Update ScreenshotVms in ServerItem
    updateScreenshotVms(screenshotVms.map((sc) => (sc._id == data._id ? { ...sc, ...data } : sc)));
    setScreenshotData(data);
    setLoading(false);
  };
  return (
    <div className={styles.popup} style={{ paddingTop: "2em" }}>
      <div className={styles.popupContent} style={{ padding: "2em 2em 0.5em 2em" }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {!account.screenshot != "-" ? (
              <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span style={{ fontWeight: "bold", fontSize: "20px" }}>{account.vmNumber ? account.vmNumber : "?"}</span>
                  <p style={{ marginTop: "0px", fontSize: "20px" }}>
                    {account.summonerName} - {account.region.toUpperCase()}
                  </p>
                  {!account.isMissing && <p style={{ marginTop: "0px", fontSize: "20px" }}>{account.status.toUpperCase()}</p>}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img style={{ marginBottom: "0.5em", width: "100%" }} src={`data:image/jpeg;base64,${screenshotData.screenshot}`} />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span>Taken: </span>&nbsp;<span style={{ textAlign: "center", color: "silver" }}>{longAgo(screenshotData.screenshotDate)}</span>
                </div>
              </>
            ) : (
              <h2 style={{ textAlign: "center" }}>No account</h2>
            )}
          </>
        )}
        <div className="credentials__info-row" style={{ justifyContent: "space-between", alignItems: "center", fontSize: "22px" }}>
          <div title="Update Screenshot" onClick={onRefreshScreenshot} style={{ cursor: "pointer" }}>
            <i class="fas fa-sync-alt" aria-hidden="true"></i>
          </div>
          <div className="button aqua-green" onClick={onClickCancel}>
            <span>Close</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {})(ViewScreenshot);
