import React, { useEffect, useState } from "react";
import axios from "../utils/axiosInstance";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/authActions";
const Navbar = ({ auth: { isAuthenticated, user }, logout }) => {
  const [version, setVersion] = useState("-");
  const getLatestVersion = async () => {
    const res = await axios.get(`/api/v1/build`);
    const version = res.data.data.versionNumber;
    // console.log(res.data.data.regions);
    setRegions(Object.entries(res.data.data.regions));
    setVersion(version);
  };
  const [regions, setRegions] = useState([]);
  useEffect(() => {
    getLatestVersion();
  }, []);
  const onClick = () => {
    logout();
  };
  // console.log(regions);
  return (
    <div className="navbar">
      <div className="navbar__container">
        <div className="navbar__logo">
          <Link to="/dashboard">
            GydrusBot <span style={{ fontSize: "1rem" }}>{version}</span>
            {regions && regions.some((region) => !region[1].live) && (
              <div className={`tooltip`} style={{ border: "none" }}>
                <span className="tooltiptext" style={{ fontSize: "1rem", top: "40px", bottom: "inherit" }}>
                  Regions on Maintenance:
                  <br />
                  {regions.map((region) => (
                    <>
                      {!region[1].live ? (
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          {region[0].toUpperCase()}
                          <br />
                        </span>
                      ) : null}
                    </>
                  ))}
                </span>
                <i className="fa-solid fa-circle-exclamation" style={{ marginLeft: "5px", fontSize: "1.2rem", color: "rgb(255, 30, 30)" }}></i>
              </div>
            )}
          </Link>
        </div>
        {isAuthenticated ? (
          <>
            <div className="navbar__items">
              <Link to="/settings">
                <div className="navbar__item">
                  <i className="fas fa-user-cog"></i>
                </div>
              </Link>
              <Link to="/dashboard">
                <div className="navbar__item">
                  {/* <i className="fas fa-chart-line"></i> */}
                  <i className="fa-solid fa-chart-column"></i>
                </div>
              </Link>
              <Link to="/credentials">
                <div className="navbar__item">
                  <i className="far fa-list-alt"></i>
                </div>
              </Link>
              {user.role === "admin" && (
                <Link to="/users">
                  <div className="navbar__item" style={{ fontSize: "1.8rem" }}>
                    <i className="fas fa-user-plus"></i>
                  </div>
                </Link>
              )}
              <Link to="/servers">
                <div className="navbar__item">
                  <i className="fas fa-server"></i>
                  {/* <i class="fa-sharp fa-solid fa-server"></i> */}
                </div>
              </Link>
              <Link to="/history?page=1">
                <div className="navbar__item">
                  <i className="fas fa-clipboard-list"></i>
                </div>
              </Link>

              <Link to="/whitelist-ips">
                <div className="navbar__item">
                  <i className="fas fa-pen-square"></i>
                </div>
              </Link>
              <Link to="/names">
                <div className="navbar__item">
                  <i className="fas fa-keyboard"></i>
                </div>
              </Link>
              <Link to="/tasks">
                <div className="navbar__item">
                  <i className="fa fa-flask"></i>
                </div>
              </Link>
            </div>

            <div className="navbar__button-group">
              <div className="navbar__login-info">
                <p>
                  Welcome, <span style={{ fontWeight: "bold" }}>{user.username}</span>
                </p>
              </div>
              <div className="button aqua-green" onClick={onClick} style={{ width: "70px", paddingRight: "10px" }}>
                <span>Logout</span>
                <i className="fa-solid fa-right-from-bracket"></i>
              </div>
            </div>
          </>
        ) : (
          <Link to="/terms-of-use" style={{ textDecoration: "none" }}>
            <div style={{ marginTop: ".5em" }}>
              <span style={{ color: "#b4b4b4" }}>Terms of Use</span>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(Navbar);
