import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const Home = ({ auth: { isAuthenticated }, props }) => {
  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/dashboard');
    }

    // eslint-disable-next-line
  }, [isAuthenticated]);
  return <></>;
};
const mapStateToProps = (state, props) => ({
  props,
  auth: state.auth,
});
export default connect(mapStateToProps, null)(Home);
