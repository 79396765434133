import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateServerBulk } from "../../actions/serverActions";
import Spinner from "../Spinner";
import styles from "./Servers.module.scss";

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import md5 from "md5";
import longAgo from "../../utils/longAgo";

let webSocket;
function connectWebsocket() {
  webSocket = new WebSocket("wss://relay.gydrus.net:8358");
  //   webSocket = new WebSocket("wss://relay.gydrus.net:8359");
}
connectWebsocket();
var isServerOnline;
var isFirstStatusRequestSent;
var isFirstSystemRequestSent;
// var isCommandInProgress;
const getTime = () => {
  let date = new Date();
  let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return hours + ":" + minutes + ":" + seconds;
};

const MassEdit = ({ isPopupShowingMass: { showing }, servers, user, sendIsPopupShowingMass, updateServerBulk }) => {
  const timer = (ms) => new Promise((res) => setTimeout(res, ms));
  const serverSecret = md5(user.username).toUpperCase().substr(0, 12);
  const [selectedServersCopy, setSelectedServersCopy] = useState(
    servers.map((srv) => {
      return { ...srv, responseReceived: null, requestSent: false };
    })
  );
  const [isCommandInProgress, setIsCommandInProgress] = useState(false);
  const [commandSelected, setCommandSelected] = useState("-");
  const [updatedServer, setUpdatedServer] = useState({
    maintainVmsOn: false,
    numberOfVms: 0,
    priority: 1,
    forcePriority: false,
    forceRegion: false,
    showOnlyActive: true,
    showAccActions: false,
    region: "na",
    cloneDrives: "C",
    inactiveVmMinutes: 10,
    vmStartDelay: 30,
    managerStartDelay: 3,
    monitorHardware: true,
    logToFile: false,
    autoRestart: true,
    spacePerVm: 1,
    ramPerVmInMb: 1024,
    vramPerVmInKb: 65536,
    autoAddToDashboard: false,
    addToStartup: false,
  });
  const [updatedServerBulk, setUpdatedServerBulk] = useState({});
  // console.log(server);
  const [connection, setConnection] = useState("loading");
  const [changes, setChanges] = useState("");
  const [multipleRegions, setMultipleRegions] = useState(false);

  //   const [serverStatus, setServerStatus] = useState("offline");
  // const [activeClones, setActiveClones] = useState(0);
  // const [totalClones, setTotalClones] = useState(0);

  // webSocket.onmessage = (response) => {
  // 	console.log('GydrusRelay: MESSAGE');
  // 	console.log(response.data);
  // 	let data = JSON.parse(response.data);
  // 	switch (data.request) {
  // 		case 'status':
  // 			setServerStatus(data.status);
  // 			if (data.status !== 'offline') {
  // 				isServerOnline = true;
  // 				setActiveClones(data.active_clones);
  // 				setTotalClones(data.total_clones);
  // 				if (data.status === 'online') {
  // 					isCommandInProgress = false;
  // 				} else {
  // 					isCommandInProgress = true;
  // 				}
  // 				if (!isFirstSystemRequestSent && webSocket.readyState === webSocket.OPEN) {
  // 					isFirstSystemRequestSent = true;
  // 					webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: 'system' }));
  // 					setConnection('open');
  // 				}
  // 			} else {
  // 				isServerOnline = false;
  // 			}
  // 			break;
  // 		case 'system':
  // 			systemStats(data);
  // 			break;
  // 	}
  // };
  // console.log(selectedServersCopy);
  webSocket.onmessage = (response) => {
    // console.log("GydrusRelay: MESSAGE");
    // console.log(response.data);
    if (response.data === "OK") {
      setConnection("open");
      isServerOnline = true;
      return;
    }
    let data = JSON.parse(response.data);
    let serversCopy = selectedServersCopy.map((srv) => {
      if (data.server_id === srv.serverId) {
        switch (data.result || data.status) {
          case "offline":
            return { ...srv, responseReceived: "offline" };
          case "success":
            return { ...srv, responseReceived: "success" };
          case "busy":
            return { ...srv, responseReceived: "busy" };
          case "invalid_request":
            return { ...srv, responseReceived: "invalid_request" };
          case "failed":
            return { ...srv, responseReceived: "failed" };
        }
      } else {
        return srv;
      }
    });
    setSelectedServersCopy(serversCopy);
    if (!serversCopy.some((srv) => srv.responseReceived === null)) {
      // console.log("Finalized commands");
      setIsCommandInProgress(false);
    }
    // switch (data.request) {
    // 	case 'status':
    // 		setServerStatus(data.status);
    // 		if (data.status !== 'offline') {
    // 			isServerOnline = true;
    // 			setActiveClones(data.active_clones);
    // 			setTotalClones(data.total_clones);
    // 			if (data.status === 'online') {
    // 				isCommandInProgress = false;
    // 			} else {
    // 				isCommandInProgress = true;
    // 			}
    // 			if (!isFirstSystemRequestSent && webSocket.readyState === webSocket.OPEN) {
    // 				isFirstSystemRequestSent = true;
    // 				webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: 'system' }));
    // 				setConnection('open');
    // 			}
    // 		} else {
    // 			isServerOnline = false;
    // 		}
    // 		break;
    // 	case 'system':
    // 		systemStats(data);
    // 		break;
    // }
  };
  useEffect(() => {
    // Make PING_DASHBOARD
    webSocket.send("PING_DASHBOARD");
    // setConnection("open");
  }, []);

  useEffect(() => {
    let string = "";
    Object.entries(updatedServerBulk).forEach(([key, value]) => {
      let value_ =
        typeof value != "object"
          ? value
          : Object.entries(value)
              .map(([key, value]) => key + ": " + value)
              .join(" ");
      string += `${key}: ${value_} | `;
    });

    setChanges(string);
  }, [updatedServerBulk]);

  webSocket.onclose = function () {
    console.log("GydrusRelay: CLOSE");
    // setServerStatus("offline");
    // setConnection("closed");
    setTimeout(function () {
      console.log("Reconnecting to websocket server...");
      connectWebsocket();
    }, 1000);
  };

  webSocket.onerror = function () {
    console.log("GydrusRelay: ERROR");
    // setConnection("error");
  };

  webSocket.onopen = function () {
    console.log("GydrusRelay: ON_OPEN");
  };

  const onClickStartVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let amount = Number(prompt("Start desired amount of clones:\nAny running clones will stay active!"));
        if (amount !== 0 && !isNaN(amount)) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "vm_start";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command + "_" + amount }));
              setCommandSelected(command + "_" + amount);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));
              await timer(25);
            }
          })();
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickStartSingleVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let number = prompt("Start desired clone number:\nAny running clones will stay active!");
        if (number !== 0 && number !== "" && number != null) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "vm_start";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command + "_n" + number }));
              setCommandSelected(command + "_n" + number);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

              await timer(25);
            }
          })();
          //   setServerStatus(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickStopVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Stop all running clones?\nThese will be stopped immediately!")) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "vm_stop";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command }));
              setCommandSelected(command);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

              await timer(25);
            }
          })();
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickStopSingleVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let number = prompt("Stop desired clone number:\nOther existing clones remain untouched!");
        if (number !== 0 && number !== "" && number != null) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "vm_stop";
          setCommandSelected(command + "_n" + number);

          // (async function send() {
          //   for (let srv of servers) {
          //     webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command + "_n" + number }));
          //     setCommandSelected(command + "_n" + number);
          //     setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

          //     await timer(25);
          //   }
          // })();
          const batchSize = 10; // Number of servers to process before updating state
          let processed = 0; // Keep track of processed servers
          (async function send() {
            for (let i = 0; i < servers.length; i++) {
              const srv = servers[i];
              webSocket.send(
                JSON.stringify({
                  server_id: srv.serverId,
                  server_ip: srv.ipAddress,
                  server_mac: srv.macAddress,
                  server_secret: serverSecret,
                  request: command + "_n" + number,
                })
              );

              // Only update state after every batchSize servers or at the end
              if ((i + 1) % batchSize === 0 || i === servers.length - 1) {
                setSelectedServersCopy((prevState) => prevState.map((s, index) => (index <= i ? { ...s, requestSent: true } : s)));
                await timer(25); // Wait a bit before sending next batch
              }
            }
            setIsCommandInProgress(false); // Reset command progress after all servers are processed
          })();
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickDeleteVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Delete all clones?\nAny running clones will be stopped immediately before deleting!")) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "vm_delete";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command }));
              setCommandSelected(command);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

              await timer(25);
            }
          })();
          //   setServerStatus(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickDeleteSingleVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let number = prompt("Delete desired clone number:\nOther existing clones remain untouched!");
        if (number !== 0 && number !== "" && number != null) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "vm_delete";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command + "_n" + number }));
              setCommandSelected(command + "_n" + number);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

              await timer(25);
            }
          })();
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickCloneVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let amount = Number(prompt("Create desired amount of new clones:\nAny existing clones will stay untouched!"));
        if (amount !== 0 && !isNaN(amount)) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "vm_clone";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command + "_" + amount }));
              setCommandSelected(command + "_" + amount);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

              await timer(25);
            }
          })();
          //   setServerStatus(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickRecloneVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let amount = Number(prompt("Create desired amount of new clones and start them:\nAny existing clones will be stopped/deleted immediately!"));
        if (amount !== 0 && !isNaN(amount)) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "vm_reclone";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command + "_" + amount }));
              setCommandSelected(command + "_" + amount);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

              await timer(25);
            }
          })();
          //   setServerStatus(command);
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickRecloneSingleVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        let number = prompt("Re-clone desired clone number:\nOther existing clones remain untouched!");
        if (number !== 0 && number !== "" && number != null) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "vm_reclone";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command + "_n" + number }));
              setCommandSelected(command + "_n" + number);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

              await timer(25);
            }
          })();
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickStartMainVm = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Are you sure you want to start the main VM?")) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "vm_startmain";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command }));
              setCommandSelected(command);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

              await timer(25);
            }
          })();
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickRestartManager = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Are you sure you want to restart Manager?")) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "restart_manager";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command }));
              setCommandSelected(command);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

              await timer(25);
            }
          })();
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };
  const onClickRefreshSettings = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Are you sure you want to refresh the managers settings?")) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "refresh_settings";
          (async function send() {
            for (let srv of servers) {
              webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command }));
              setCommandSelected(command);
              setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

              await timer(25);
            }
          })();
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickRestartServer = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Are you sure you want to restart the server? It will be instantly restarted")) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "restart_server";
          setCommandSelected(command);

          // (async function send() {
          //   for (let srv of servers) {
          //     webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command }));
          //     setCommandSelected(command);
          //     setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

          //     await timer(25);
          //   }
          // })();
          const batchSize = 10; // Number of servers to process before updating state
          let processed = 0; // Keep track of processed servers
          (async function send() {
            for (let i = 0; i < servers.length; i++) {
              const srv = servers[i];
              webSocket.send(
                JSON.stringify({
                  server_id: srv.serverId,
                  server_ip: srv.ipAddress,
                  server_mac: srv.macAddress,
                  server_secret: serverSecret,
                  request: command,
                })
              );

              // Only update state after every batchSize servers or at the end
              if ((i + 1) % batchSize === 0 || i === servers.length - 1) {
                setSelectedServersCopy((prevState) => prevState.map((s, index) => (index <= i ? { ...s, requestSent: true } : s)));
                await timer(25); // Wait a bit before sending next batch
              }
            }
            setIsCommandInProgress(false); // Reset command progress after all servers are processed
          })();
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  const onClickShouldTurnOffOnUpToDate = () => {
    if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
      if (!isCommandInProgress) {
        if (window.confirm("Are you sure you want change the mode Should Turn OFF On Up To Date?")) {
          setSelectedServersCopy(
            servers.map((srv) => {
              return { ...srv, responseReceived: null, requestSent: false };
            })
          );
          setIsCommandInProgress(true);
          let command = "turn_off-on-up-to-date";
          setCommandSelected(command);

          // (async function send() {
          //   for (let srv of servers) {
          //     webSocket.send(JSON.stringify({ server_id: srv.serverId, server_ip: srv.ipAddress, server_mac: srv.macAddress, server_secret: serverSecret, request: command }));
          //     // setCommandSelected(command);
          //     setSelectedServersCopy((prevState) => prevState.map((s) => (s.serverId === srv.serverId ? { ...s, requestSent: true } : s)));

          //     await timer(25);
          //   }
          // })();
          const batchSize = 10; // Number of servers to process before updating state
          let processed = 0; // Keep track of processed servers
          (async function send() {
            for (let i = 0; i < servers.length; i++) {
              const srv = servers[i];
              webSocket.send(
                JSON.stringify({
                  server_id: srv.serverId,
                  server_ip: srv.ipAddress,
                  server_mac: srv.macAddress,
                  server_secret: serverSecret,
                  request: command,
                })
              );

              // Only update state after every batchSize servers or at the end
              if ((i + 1) % batchSize === 0 || i === servers.length - 1) {
                setSelectedServersCopy((prevState) => prevState.map((s, index) => (index <= i ? { ...s, requestSent: true } : s)));
                await timer(25); // Wait a bit before sending next batch
              }
            }
            setIsCommandInProgress(false); // Reset command progress after all servers are processed
          })();
        }
      } else {
        alert("Can't perform another action while current is running!");
      }
    } else {
      alert("Relay-server connection lost!");
    }
  };

  //   useEffect(() => {
  //     if (!isFirstStatusRequestSent && webSocket.readyState === webSocket.OPEN) {
  //       isFirstStatusRequestSent = true;
  //       webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: "status" }));
  //     }
  //     const statusInterval = setInterval(() => {
  //       if (webSocket.readyState === webSocket.OPEN) {
  //         webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: "status" }));
  //       }
  //     }, 10000);
  //     const systemInterval = setInterval(() => {
  //       if (isServerOnline && webSocket.readyState === webSocket.OPEN) {
  //         webSocket.send(JSON.stringify({ server_id: serverId, server_ip: ipAddress, server_mac: macAddress, server_secret: serverSecret, request: "system" }));
  //       }
  //     }, 20000);
  //     return () => {
  //       clearInterval(statusInterval);
  //       clearInterval(systemInterval);
  //     };
  //   }, [charts]);

  // ACA FINALIZA EL SERVER WEBSOCKET
  const onClickUpdate = async () => {
    //webSocket.close();
    isServerOnline = false;
    if (Object.entries(updatedServerBulk).length > 0) {
      setIsLoading(true);
      await updateServerBulk({ ids: servers.map((srv) => srv._id), updatedFields: updatedServerBulk });
      setIsLoading(false);
    }
    sendIsPopupShowingMass({ showing: false });
  };

  const [isLoading, setIsLoading] = useState(false);

  const onClickCancel = () => {
    // webSocket.close();
    isServerOnline = false;

    sendIsPopupShowingMass({ showing: !showing });
  };
  const onChange = (e) => {
    setUpdatedServer({
      ...updatedServer,
      [e.target.name]: e.target.value,
    });
    setUpdatedServerBulk({
      ...updatedServerBulk,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCheckbox = (e) => {
    setUpdatedServer({
      ...updatedServer,
      [e.target.name]: e.target.checked,
    });
    setUpdatedServerBulk({
      ...updatedServerBulk,
      [e.target.name]: e.target.checked,
    });
  };

  // console.log(charts.drives);
  const { serverId, serverName, forcePriority, priority, macAddress, ipAddress, forceRegion, region, showOnlyActive, maintainVmsOn, numberOfVms, windowsUpdatesAvailable, cloneDrives, inactiveVmMinutes, vmStartDelay, managerStartDelay, monitorHardware, logToFile, autoRestart, spacePerVm, ramPerVmInMb, vramPerVmInKb, autoAddToDashboard, addToStartup, cpuName, gpuName, cpuGhz, ram } = updatedServer;
  return (
    <div className={styles.popup}>
      <div className={styles.popupContent} style={{ paddingBottom: "2em" }}>
        {!isLoading ? (
          <>
            <h1>Editing {servers.length} servers</h1>
            {/* <div className='credentials__info-row'>
							<div className='credentials__info-field'>
								<label>Server Name</label>
								<input name='serverName' className='credentials__input' value={serverName} onChange={onChange}></input>
							</div>
							<div className='credentials__info-field'>
								<label>Server Id</label>
								<input name='serverId' className='credentials__input' value={serverId} onChange={onChange}></input>
							</div>
							<div className='credentials__info-field'>
								<label>Server Ip</label>
								<input name='ipAddress' className='credentials__input' value={ipAddress} onChange={onChange}></input>
							</div>
							<div className='credentials__info-field'>
								<label>MAC Address</label>
								<input name='macAddress' className='credentials__input' value={macAddress} onChange={onChange}></input>
							</div>
						</div> */}
            <div className="credentials__info-row">
              <div className="credentials__info-field">
                <label>Force Region</label>
                <input onChange={onChangeCheckbox} name="forceRegion" className="checkbox" type="checkbox" value={forceRegion} defaultChecked={forceRegion}></input>
              </div>
              {forceRegion && (
                <>
                  {!multipleRegions && !region.includes(",") ? (
                    <div className="credentials__info-field">
                      <label>Region</label>

                      <select className="credentials__input" name="region" onChange={onChange} defaultValue={region}>
                        <option value="-">-</option>

                        <option value="na">NA</option>
                        <option value="euw">EUW</option>
                        <option value="las">LAS</option>
                        <option value="lan">LAN</option>
                        <option value="tr">TR</option>
                        <option value="kr">KR</option>
                        <option value="br">BR</option>
                        <option value="ru">RU</option>
                        <option value="eune">EUNE</option>
                        <option value="jp">JP</option>
                        <option value="oce">OCE</option>
                        <option value="ph">PH</option>
                        <option value="sg">SG</option>
                        <option value="th">TH</option>
                        <option value="tw">TW</option>
                        <option value="vn">VN</option>
                      </select>
                    </div>
                  ) : (
                    <div className="credentials__info-field">
                      <label>Regions</label>

                      <input name="region" className="credentials__input" value={region} onChange={onChange}></input>
                    </div>
                  )}
                  <div className="credentials__info-field">
                    <div>
                      <label>Show Multiple Regions</label>
                      <div className={`tooltip`} style={{ border: "none", marginLeft: "5px" }}>
                        <span className="tooltiptext">Choose multiple regions by separating commas (,)</span>
                        <i className="fas fa-question-circle"></i>
                      </div>
                    </div>
                    <input onChange={() => setMultipleRegions(!multipleRegions)} className="checkbox" type="checkbox" value={multipleRegions} defaultChecked={multipleRegions}></input>
                  </div>
                </>
              )}
              <div className="credentials__info-field">
                <label>Show Only Active Accs</label>
                <input onChange={onChangeCheckbox} name="showOnlyActive" className="checkbox" type="checkbox" value={showOnlyActive} defaultChecked={showOnlyActive}></input>
              </div>
            </div>

            <div className="credentials__info-row">
              <div className="credentials__info-field">
                <label>Force Priority</label>
                <input onChange={onChangeCheckbox} name="forcePriority" className="checkbox" type="checkbox" value={forcePriority} defaultChecked={forcePriority}></input>
              </div>
              {forcePriority && (
                <div className="credentials__info-field">
                  <div style={{ marginLeft: "5px" }}>
                    <label>Priority</label>
                    <div className={`tooltip`} style={{ border: "none", marginLeft: "5px" }}>
                      <span className="tooltiptext">Choose multiple priorities separating commas (,)</span>
                      <i className="fas fa-question-circle"></i>
                    </div>
                  </div>
                  <input name="priority" className="credentials__input" value={priority} onChange={onChange}></input>
                </div>
              )}
            </div>

            <div className="credentials__info-row" style={{ marginBottom: "25px" }}>
              <div className="credentials__info-field">
                <label>Maintain VMs On</label>
                <input onChange={onChangeCheckbox} name="maintainVmsOn" className="checkbox" type="checkbox" value={maintainVmsOn} defaultChecked={maintainVmsOn}></input>
              </div>
              {maintainVmsOn && (
                <div className="credentials__info-field">
                  <label>Number Of VMs</label>
                  <input name="numberOfVms" className="credentials__input" value={numberOfVms} onChange={onChange}></input>
                </div>
              )}
            </div>
            <div>
              <div className="credentials__info-row" style={{ marginBottom: "30px" }}>
                <div className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Clone Drives</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      Disk Name(s) where to place the Clones (separate by "+")
                    </span>
                  </div>
                  <input name="cloneDrives" className="credentials__input" value={cloneDrives} onChange={onChange}></input>
                </div>
                <div className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Inactive VM Minutes</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      Amount of minutes until a VM is detected as bugged without activity
                    </span>
                  </div>
                  <input name="inactiveVmMinutes" className="credentials__input" value={inactiveVmMinutes} onChange={onChange}></input>
                </div>
                <div className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Space per VM</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      Minimum space per Clone in MB
                    </span>
                  </div>
                  <input name="spacePerVm" className="credentials__input" value={spacePerVm} onChange={onChange}></input>
                </div>
                <div className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Manager Start Delay</label>
                    <span style={{ fontSize: "0.8rem", left: "-150px" }} className="tooltiptext">
                      Amount of seconds to wait before initializing GydrusManager
                    </span>
                  </div>
                  {/* <input disabled name='macAddress' className='credentials__input' value={macAddress}></input> */}
                  <input name="managerStartDelay" className="credentials__input" value={managerStartDelay} onChange={onChange}></input>
                </div>
              </div>
              <div className="credentials__info-row" style={{ marginBottom: "30px" }}>
                <div className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>RAM per VM in MB</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      RAM assigned to each VM in MB
                    </span>
                  </div>
                  <input name="ramPerVmInMb" className="credentials__input" value={ramPerVmInMb} onChange={onChange}></input>
                </div>
                <div className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>VRAM per VM in MB</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      VRAM assigned to each VM in KB
                    </span>
                  </div>
                  <select className="credentials__input" name="vramPerVmInKb" onChange={onChange} defaultValue={vramPerVmInKb}>
                    <option value={32768}>32</option>
                    <option value={65536}>64</option>
                    <option value={131072}>128</option>
                  </select>
                </div>
                <div className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>VM Start Delay</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      Amount of seconds to wait between each VM start action
                    </span>
                  </div>
                  {/* <input disabled name='macAddress' className='credentials__input' value={macAddress}></input> */}
                  <input name="vmStartDelay" className="credentials__input" value={vmStartDelay} onChange={onChange}></input>
                </div>
                <section className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Auto add to Dashboard</label>
                    <span style={{ fontSize: "0.8rem", left: "-150px" }} className="tooltiptext">
                      Auto add server to dashboard with its Server ID as name
                    </span>
                  </div>
                  <input onChange={onChangeCheckbox} name="autoAddToDashboard" className="checkbox" type="checkbox" value={autoAddToDashboard} defaultChecked={autoAddToDashboard}></input>
                </section>
              </div>

              <div className="credentials__info-row" style={{ marginBottom: "30px" }}>
                <section className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Add to Startup</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      Add Manager to startup
                    </span>
                  </div>
                  <input onChange={onChangeCheckbox} name="addToStartup" className="checkbox" type="checkbox" value={addToStartup} defaultChecked={addToStartup}></input>
                </section>
                <section className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Monitor Hardware</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      True|False - Monitor Hardware feature
                    </span>
                  </div>
                  <input onChange={onChangeCheckbox} name="monitorHardware" className="checkbox" type="checkbox" value={monitorHardware} defaultChecked={monitorHardware}></input>
                </section>
                <section className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Log to File</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      Log GydrusManager activity to file
                    </span>
                  </div>
                  <input onChange={onChangeCheckbox} name="logToFile" className="checkbox" type="checkbox" value={logToFile} defaultChecked={logToFile}></input>
                </section>

                <section className="credentials__info-field">
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Auto Restart</label>
                    <span style={{ fontSize: "0.8rem", left: "-150px" }} className="tooltiptext">
                      True|False - Auto Restart GydrusManager every 24h
                    </span>
                  </div>
                  <input onChange={onChangeCheckbox} name="autoRestart" className="checkbox" type="checkbox" value={autoRestart} defaultChecked={autoRestart}></input>
                </section>
              </div>
            </div>

            <div className="credentials__info-row" style={{ marginTop: "1em", marginBottom: "1em", alignItems: "center" }}>
              <div className="button aqua-green" style={{ width: "120px" }} onClick={onClickUpdate}>
                <span>Update Servers</span>
              </div>
              <span style={{ fontSize: ".75em" }}>{!changes ? "No changes applied yet" : changes}</span>
              <div className="button aqua-green" onClick={onClickCancel}>
                <span>Close</span>
              </div>
            </div>
            <hr></hr>

            {connection === "open" ? (
              <>
                <h2>
                  Send <span style={{ textTransform: "uppercase" }}>[{commandSelected}] </span>
                  command to {servers.length} selected servers
                </h2>
                <div className="credentials__info-row">
                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickStartVm}>
                    <span>Start VMs</span>
                  </div>

                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickStopVm}>
                    <span>Stop VMs</span>
                  </div>

                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickDeleteVm}>
                    <span>Delete VMs</span>
                  </div>

                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickRecloneVm}>
                    <span>Re-Clone VMs</span>
                  </div>

                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickCloneVm}>
                    <span>Clone VM</span>
                  </div>
                  <div className="button aqua-green" style={{ width: "110px", fontSize: "12.9px" }} onClick={onClickShouldTurnOffOnUpToDate}>
                    <span>Turn Off MainVM when up to date</span>
                  </div>
                  <div className="button aqua-green" style={{ width: "110px", backgroundColor: "#0077ff" }} onClick={onClickRestartServer}>
                    <span>Restart Server</span>
                  </div>
                  {/*<div className='button aqua-green' style={{ width: '110px' }} onClick={onClickRestartVm}>
                    <span>Restart VM</span>
                  </div>*/}
                </div>
                <div className="credentials__info-row" style={{ textAlign: "center" }}>
                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickStartSingleVm}>
                    <span>Start VM (single)</span>
                  </div>

                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickStopSingleVm}>
                    <span>Stop VM (single)</span>
                  </div>

                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickDeleteSingleVm}>
                    <span>Delete VM (single)</span>
                  </div>

                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickRecloneSingleVm}>
                    <span>Re-Clone VM (single)</span>
                  </div>
                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickStartMainVm}>
                    <span>Start Main VM</span>
                  </div>
                  <div className="button aqua-green" style={{ width: "110px" }} onClick={onClickRefreshSettings}>
                    <span>Refresh Settings</span>
                  </div>
                  <div className="button aqua-green" style={{ width: "110px", backgroundColor: "#0077ff" }} onClick={onClickRestartManager}>
                    <span>Restart Manager</span>
                  </div>
                  {/* <div className="button aqua-green" style={{ width: "110px", opacity: "0%" }}>
                    <span>#</span>
                  </div> */}

                  {/*<div className='button aqua-green' style={{ width: '110px' }} onClick={onClickRestartVm}>
                    <span>Restart VM</span>
                  </div>*/}
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginTop: "2em", maxHeight: "25em", overflow: "overlay" }}>
                  {selectedServersCopy.map((srv) => (
                    <div key={Math.random()} style={{ display: "flex" }}>
                      <span>{srv.serverName}</span>

                      {srv.requestSent === true && srv.responseReceived === null && (
                        <div>
                          <i className={`fas fa-sync-alt ${styles.rotating}`} style={{ marginLeft: ".75em", color: "#d1a62d" }}></i>
                          <span style={{ marginLeft: ".5em", color: "#a5a5a5" }}>Command sent</span>
                        </div>
                      )}
                      {srv.responseReceived === "success" && (
                        <div>
                          <i className={`fas fa-check-circle`} style={{ marginLeft: ".75em", color: "green" }}></i>
                          <span style={{ marginLeft: ".5em", color: "#a5a5a5" }}>Command finished</span>
                        </div>
                      )}
                      {(srv.responseReceived === "offline" || srv.responseReceived === "invalid_request" || srv.responseReceived === "failed") && (
                        <div>
                          <i className={`fas fa-times-circle`} style={{ marginLeft: ".75em", color: "#970000" }}></i>
                          {srv.responseReceived === "offline" && <span style={{ marginLeft: ".5em", color: "#a5a5a5" }}>Manager not connected</span>}
                          {srv.responseReceived === "invalid_request" && <span style={{ marginLeft: ".5em", color: "#a5a5a5" }}>Invalid request</span>}
                          {srv.responseReceived === "failed" && <span style={{ marginLeft: ".5em", color: "#a5a5a5" }}>Command failed</span>}
                        </div>
                      )}
                      {srv.responseReceived === "busy" && (
                        <div>
                          <i className={`fas fa-minus-circle`} style={{ marginLeft: ".75em", color: "#d1a62d" }}></i>
                          <span style={{ marginLeft: ".5em", color: "#a5a5a5" }}>Manager busy</span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </>
            ) : connection === "loading" ? (
              <>
                <Spinner />
                <h2 style={{ textAlign: "center" }}>Connecting to server...</h2>
              </>
            ) : (
              <h2 style={{ textAlign: "center" }}>Error connecting to the relay-server!</h2>
            )}
          </>
        ) : (
          <>
            <Spinner />
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, { updateServerBulk })(MassEdit);
