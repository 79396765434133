import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateUser } from '../actions/authActions';
import Settings from './Settings';
import Spinner from './Spinner';
import TermsOfUse from './TermsOfUse';

const SettingsOverview = ({ user, updateUser }) => {
  return <>{user ? <>{user.acceptedTermsOfUse ? <Settings user={user} /> : <TermsOfUse />}</> : <Spinner />}</>;
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, { updateUser })(SettingsOverview);
