import { SET_SKIN_DATA, GET_ACCOUNTS, UPDATE_ACCOUNTS, UPDATE_ACCOUNT_DASHBOARD, CLEAR_ACCOUNTS_CREDENTIALS, CREATE_ACCOUNT, DELETE_ACCOUNT_ERROR, DELETE_ACCOUNT, GET_ACCOUNTS_CREDENTIALS_ERROR, GET_ACCOUNTS_CREDENTIALS, UPDATE_ACCOUNT_STATUS, UPDATE_ACCOUNT_STATE, UPDATE_ACCOUNT_ERROR, SET_LOADING_ACCOUNTS, UNSET_LOADING_ACCOUNTS, GET_ACCOUNTS_ERROR, CLEAR_ACCOUNTS, UPDATE_ACCOUNT, REMOVE_DASHBOARD } from "../actions/types";

const initialState = {
  accounts: null,
  accountsCredentials: null,
  accountsError: false,
  accountsCredentialsError: false,
  loading: true,
  skinsData: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACCOUNT CREDENTIALS
    case SET_SKIN_DATA:
      return {
        ...state,
        skinsData: action.payload,
      };
    case GET_ACCOUNTS_CREDENTIALS:
      return {
        ...state,
        accountsCredentials: action.payload,
        accountsCredentialsError: false,
        loading: false,
      };
    case GET_ACCOUNTS_CREDENTIALS_ERROR:
      return {
        ...state,
        accountsCredentialsError: action.payload,
      };
    case CLEAR_ACCOUNTS_CREDENTIALS:
      return {
        ...state,
        accountsCredentials: null,
        accountsCredentialsError: false,
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts ? state.accounts.filter((acc) => !action.payload.includes(acc._id)) : state.accounts,
        accountsCredentials: state.accountsCredentials ? state.accountsCredentials.filter((acc) => !action.payload.includes(acc._id)) : state.accounts,
        accountsCredentialsError: false,
        // accounts: state.accounts ? state.accounts.filter((acc) => acc._id !== action.payload) : state.accounts,
        // accountsCredentials: state.accountsCredentials ? state.accountsCredentials.filter((acc) => acc._id !== action.payload) : state.accounts,
        // accountsCredentialsError: false,
      };
    case REMOVE_DASHBOARD:
      let ids3 = {};
      for (let acc of action.payload) {
        ids3[acc._id] = acc;
      }
      const newAccs3 = state.accountsCredentials ? state.accountsCredentials.map((acc) => (ids3[acc._id] ? ids3[acc._id] : acc)) : state.accountsCredentials;
      return {
        ...state,
        accounts: state.accounts ? state.accounts.filter((acc) => !ids3[acc._id]) : state.accounts,

        accountsCredentials: newAccs3,
      };
    //   return {
    //     ...state,
    //     accounts: state.accounts ? state.accounts.filter((acc) => acc._id !== action.payload._id) : state.accounts,
    //     accountsCredentials: state.accountsCredentials && state.accountsCredentials.map((acc) => (acc._id === action.payload._id ? { ...acc, showOnDashboard: action.payload.showOnDashboard, password: acc.password } : acc)),
    //     accountsCredentialsError: false,
    //   };
    case DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        accountsCredentialsError: action.payload,
      };

    // ACCOUNTS
    case CREATE_ACCOUNT:
      return {
        ...state,
        accounts: [...state.accounts, ...action.payload],
        accountsCredentials: [...state.accountsCredentials, ...action.payload],
      };

    case GET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        loading: false,
      };
    case SET_LOADING_ACCOUNTS:
      return {
        ...state,
        loading: true,
      };
    case UNSET_LOADING_ACCOUNTS:
      return {
        ...state,
        loading: false,
      };
    case GET_ACCOUNTS_ERROR:
      return {
        ...state,
        error: true,
      };
    case CLEAR_ACCOUNTS:
      return {
        ...state,
        accounts: null,
        accountsCredentials: null,
      };
    // UPDATE ACCOUNTS
    case UPDATE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.map((acc) => (acc._id === action.payload._id ? action.payload : acc)),
      };

    case UPDATE_ACCOUNTS:
      let ids = {};
      for (let acc of action.payload) {
        ids[acc._id] = acc;
      }
      const newAccs = state.accounts.map((acc) => (ids[acc._id] ? ids[acc._id] : acc));
      return {
        ...state,
        accounts: newAccs,
      };
    case UPDATE_ACCOUNT_STATUS:
      let ids2 = {};
      for (let acc of action.payload) {
        ids2[acc._id] = acc;
      }
      const newAccs2 = state.accounts.map((acc) => (ids2[acc._id] ? ids2[acc._id] : acc));
      return {
        ...state,
        accounts: newAccs2,
      };

    //   return {
    //     ...state,
    //     accounts: state.accounts.map((acc) => (acc._id === action.payload._id ? { ...acc, status: action.payload.status, isActive: action.payload.isActive } : acc)),
    //   };
    case UPDATE_ACCOUNT_DASHBOARD: // used one by one, leave like this (in credentials page)
      return {
        ...state,
        accounts: state.accounts ? state.accounts.filter((acc) => acc._id !== action.payload._id) : state.accounts,
        accountsCredentials: state.accountsCredentials && state.accountsCredentials.map((acc) => (acc._id === action.payload._id ? { ...acc, showOnDashboard: action.payload.showOnDashboard } : acc)),
      };
    case UPDATE_ACCOUNT_STATE:
      let ids4 = {};
      for (let acc of action.payload) {
        ids4[acc._id] = acc;
      }
      const newAccs4 = state.accounts.map((acc) => (ids4[acc._id] ? ids4[acc._id] : acc));
      return {
        ...state,
        accounts: newAccs4,
      };
    //   return {
    //     ...state,
    //     accounts: state.accounts.map((acc) => (acc._id === action.payload._id ? { ...acc, state: action.payload.state, isActive: action.payload.isActive } : acc)),
    //   };
    case UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
