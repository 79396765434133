import { GET_SERVERS, CLEAR_SERVERS, ADD_SERVER, REMOVE_SERVER, UPDATE_SERVER, UPDATE_SERVERS, SERVER_FAIL } from "../actions/types";

const initialState = {
  servers: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SERVERS:
      return {
        ...state,
        servers: payload,
      };
    case ADD_SERVER:
      return {
        ...state,
        servers: [...state.servers, payload],
      };
    case REMOVE_SERVER:
      return {
        ...state,
        servers: state.servers.filter((srv) => srv._id !== payload.id),
      };

    case UPDATE_SERVER:
      return {
        ...state,
        servers: state.servers.map((srv) => (srv._id === payload._id ? payload : srv)),
      };
    case UPDATE_SERVERS:
      let ids = {};
      for (let srv of payload) {
        ids[srv._id] = srv;
      }
      const newSrvs = state.servers.map((srv) => (ids[srv._id] ? ids[srv._id] : srv));
      return {
        ...state,
        servers: newSrvs,
      };
    // return {
    //   ...state,
    //   servers: state.servers.map((srv) => (srv._id === payload._id ? payload : srv)),
    // };
    case CLEAR_SERVERS:
      return {
        ...state,
        servers: null,
      };
    default:
      return state;
  }
}
