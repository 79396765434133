import axios from "../utils/axiosInstance";
import { GET_PUBLIC_API_KEYS, USER_LOADED, CLEAR_SERVERS, AUTH_ERROR, GET_IPS, UPDATE_USER, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, CLEAR_ACCOUNTS, UPDATE_USER_FAIL, CLEAR_IPS, ADD_IP, REMOVE_IP } from "./types";
import setAuthToken from "../utils/setAuthToken";

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    const config = {
      headers: {
        "show-statistics": true,
      },
    };
    try {
      const res = await axios.get("/api/v1/auth/me", config);
      dispatch({
        type: USER_LOADED,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  } else {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getPublicApiKeys = () => async (dispatch) => {
  const res = await axios.get("api/v1/buy/api-keys");
  dispatch({ type: GET_PUBLIC_API_KEYS, payload: res.data.data });
};
// Get Users
export const getUsers = () => async (dispatch) => {
  const res = await axios.get("/api/v1/users");
  dispatch({ type: UPDATE_USER, payload: res.data.data });

  return res.data.data;
};

// Create User
export const changePassword = (passwordObject) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await axios.put("/api/v1/users/change-password", passwordObject, config);
  return res.data;
};

// Create User
export const createUser = (info) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post("/api/v1/users", info, config);
    console.log("Success creating user");
  } catch (err) {
    console.log("Error creating user");
  }
};
export const setPrioritizeHigherLvl = (option) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put("/api/v1/users/prioritize-higher-lvls", { option }, config);
    dispatch({ type: UPDATE_USER, payload: res.data.data });
  } catch (err) {
    dispatch({ type: UPDATE_USER_FAIL });
  }
};

export const updateUser = (updateInfo) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put("/api/v1/users", updateInfo, config);
    dispatch({ type: UPDATE_USER, payload: res.data.data });
  } catch (err) {
    dispatch({ type: UPDATE_USER_FAIL });
  }
};

export const acceptTerms = () => async (dispatch) => {
  try {
    const res = await axios.put("/api/v1/users/accept-terms");
    dispatch({ type: UPDATE_USER, payload: res.data.data });
  } catch (err) {
    dispatch({ type: UPDATE_USER_FAIL });
  }
};

export const getHistory = (page) => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/users/history?page=" + page);
    return res.data;
  } catch {
    return false;
  }
};
export const getLicenseInfo = () => async (dispatch) => {
  const res = await axios.get("/api/v1/users/license");
  return res.data.data;
};

export const getWhitelistedIps = () => async (dispatch) => {
  const res = await axios.get("/api/v1/users/whitelisted-ips");
  dispatch({
    type: GET_IPS,
    payload: res.data.data,
  });
};
export const addWhitelistedIps = (ip) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      dashboard: true,
    },
  };
  const res = await axios.post("/api/v1/users/whitelisted-ips", { ip }, config);
  dispatch({
    type: ADD_IP,
    payload: res.data.data,
  });
};

export const deleteWhitelistedIps = (id) => async (dispatch) => {
  const config = {
    headers: {
      dashboard: true,
    },
  };
  const res = await axios.delete(`/api/v1/users/whitelisted-ips/${id}`, config);

  dispatch({
    type: REMOVE_IP,
    payload: res.data.data,
  });
};
// Login User
export const login = (credentials) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "website-login": true,
    },
  };

  try {
    const res = await axios.post("/api/v1/auth/login", credentials, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    return "BAD_CREDS";
  }
};

// Login User
export const register = (credentials) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post("/api/v1/auth/register", credentials, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
    return "SUCCESS";
  } catch (err) {
    return err.response.data.error;
  }
};
export const logout = () => async (dispatch) => {
  dispatch({ type: CLEAR_ACCOUNTS });
  dispatch({ type: CLEAR_SERVERS });
  dispatch({ type: CLEAR_IPS });

  dispatch({ type: LOGOUT });
};
