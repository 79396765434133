export default (date) => {
  const date_ = new Date(date);
  let year = date_.getFullYear();
  let month = date_.getMonth() + 1;
  if (month < 10) month = `0${month}`;
  let day = date_.getDate();
  if (day < 10) day = `0${day}`;
  let hours = date_.getHours();
  if (hours < 10) hours = `0${hours}`;
  let minutes = date_.getMinutes();
  if (minutes < 10) minutes = `0${minutes}`;
  let seconds = date_.getSeconds();
  if (seconds < 10) seconds = `0${seconds}`;

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
