import { GET_IPS, ADD_IP, CLEAR_IPS, REMOVE_IP } from '../actions/types';

const initialState = {
  ips: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_IPS:
      return {
        ...state,
        ips: payload,
      };
    case ADD_IP:
      return {
        ...state,
        ips: [...state.ips, payload],
      };
    case REMOVE_IP:
      return {
        ...state,
        ips: state.ips.filter((ip) => ip._id !== payload),
      };

    case CLEAR_IPS:
      return {
        ...state,
        ips: null,
      };
    default:
      return state;
  }
}
