import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { editTask } from "../../actions/taskActions";
import Spinner from "../Spinner";
import getChampionName from "../../utils/getChampionName";
import formatPlaytime from "../../utils/formatPlaytimeDashboard";
import longAgo from "../../utils/longAgo";
import styles from "./Tasks.module.scss";
import FilterItem from "./FilterItem";
import UpdateItem from "./UpdateItem";
function isNumber(n) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}
function isString(obj) {
  return Object.prototype.toString.call(obj) === "[object String]";
}
let allowedUpdateFieldNames = {
  disenchantFragChamps: { type: "boolean", enum: ["true", "false"] },
  disenchantFullChamps: { type: "boolean", enum: ["true", "false"] },
  disenchantLoot: { type: "boolean", enum: ["true", "false"] },
  disenchantGem: { type: "boolean", enum: ["true", "false"] },
  useCustomName: { type: "boolean", enum: ["true", "false"] },
  showOnDashboard: { type: "boolean", enum: ["true", "false"] },
  disenchantGemOption: { type: "string", enum: ["be", "oe", "skin"] },
  mode: { type: "string", enum: ["normal", "disenchant"] },
  region: { type: "string", enum: ["las", "lan", "na", "euw", "eune", "jp", "oce", "kr", "br", "tr", "ru", "ph", "sg", "th", "tw", "vn"] },
  cooldownAfterSession: { type: "number", enum: [] },
  disenchantEmblems: { type: "boolean", enum: ["true", "false"] },
  disenchantEmblemsOption: { type: "string", enum: ["be", "orb"] },
  icon: { type: "number", enum: [] },

  state: { type: "string", enum: ["on", "off"] },
  skipIfNameTaken: { type: "boolean", enum: ["true", "false"] },
  priority: { type: "number", enum: [] },
  desiredBE: { type: "number", enum: [] },
  desiredLevel: { type: "number", enum: [] },
  desiredPercentage: { type: "number", enum: [] },
  maxGames: { type: "number", enum: [] },
  maxGamesReached: { type: "boolean", enum: ["true", "false"] },
  "amountOfGamesToFinish.amountOfGames": { type: "number", enum: [] },
  completeAllMissions: { type: "boolean", enum: ["true", "false"] }, // HERE CHANGE
  completeMissionUntil: { type: "number", enum: [] }, // HERE CHANGE
  skipTutorial: { type: "boolean", enum: ["true", "false"] }, // HERE CHANGE
  disenchantEternal: { type: "boolean", enum: ["true", "false"] },
  limitResources: { type: "string", enum: [] },
  label: { type: "string", enum: [] },
  crazyNumber: { type: "number", enum: [] },
};
let allowedFilterFieldNames = {
  botVersion: "string",
  limitFps: "string",
  vmNumber: "number",
  disenchantFragChamps: "boolean",
  disenchantFullChamps: "boolean",
  disenchantLoot: "boolean",
  disenchantGem: "boolean",
  nextLevelPercentage: "number",
  useCustomName: "boolean",
  showOnDashboard: "boolean",
  orangeEssence: "number",
  blueEssence: "number",
  disenchantGemOption: "string",
  mode: "string",
  region: "string",
  cooldownAfterSession: "number",
  maxGamesReached: "boolean",
  macAddress: "string",
  disenchantEmblems: "boolean",
  disenchantEmblemsOption: "string",
  bestSkinType: "string",
  icon: "number",
  ip: "string",
  status: "string",
  state: "string",
  skipIfNameTaken: "boolean",
  isActive: "boolean",
  priority: "number",
  desiredBE: "number",
  currentLevel: "number",
  desiredLevel: "number",
  desiredPercentage: "number",
  nOfGames: "number",
  maxGames: "number",
  "missionTracker.completed": "number",
  "amountOfGamesToFinish.amountOfGames": "number",
  skipTutorial: "boolean", // HERE CHANGE
  disenchantEternal: "boolean", // HERE CHANGE
  hasBoostActive: "boolean", // HERE CHANGE
  limitResources: "string", // HERE CHANGE
  label: "string", // HERE CHANGE
  crazyNumber: "number", // HERE CHANGE
};
const EditTask = ({ isPopupShowing2: { task }, sendPopupShowing2, editTask, tasks }) => {
  const [newTask, setNewTask] = useState({ name: task.name, filter: task.filter, update: task.update });
  //   console.log(newTask);
  const onClickCancel = () => {
    sendPopupShowing2({ showing: false });
  };

  const updateFilter = (updatedFilter) => {
    if (updatedFilter.type === "remove") {
      setNewTask({ ...newTask, filter: newTask.filter.filter((filter) => filter._id != updatedFilter._id) });
    } else {
      setNewTask({ ...newTask, filter: newTask.filter.map((filter) => (filter._id == updatedFilter._id ? updatedFilter : filter)) });
    }
  };
  const updateUpdate = (updatedUpdate) => {
    if (updatedUpdate.type === "remove") {
      setNewTask({ ...newTask, update: newTask.update.filter((update) => update._id != updatedUpdate._id) });
    } else {
      if (updatedUpdate.updateType == "manual_finish") {
        setNewTask({ ...newTask, update: [{ updateType: "manual_finish", _id: updatedUpdate._id }] });
      } else {
        setNewTask({ ...newTask, update: newTask.update.map((update) => (update._id == updatedUpdate._id ? updatedUpdate : update)) });
      }
    }
  };
  const onClickAddFilter = () => {
    if (newTask.filter.length < 10) {
      setNewTask({ ...newTask, filter: [...newTask.filter, { filterType: "eq", fieldName: "botVersion", fieldValue: "", _id: Math.random() }] });
    }
  };
  const onClickAddUpdate = () => {
    if (newTask.update.length < 10) {
      if (!newTask.update.some((update) => update.updateType == "manual_finish")) {
        setNewTask({ ...newTask, update: [...newTask.update, { updateType: "fields", fieldName: "disenchantFragChamps", fieldValue: "", _id: Math.random() }] });
      }
    }
  };
  const onChangeTaskName = (event) => {
    setNewTask({ ...newTask, name: event.target.value });
  };
  const onClickUpdateTask = () => {
    if (newTask.filter.length == 0) {
      setError(`Filters can't be empty`);
      return;
    }
    if (newTask.update.length == 0) {
      setError(`Updates can't be empty`);
      return;
    }
    // Check name length
    if (newTask.name.length > 50) {
      setError(`Task name cannot exceed 50 characters`);
      return;
    }
    // Check amount of tasks

    // if (tasks.length >= 10) {
    //   setError(`Cannot add more than 10 tasks, please remove some and try again.`);
    //   return;
    // }
    // Check repeated field names and empty field values for Filter
    for (let filter_ of newTask.filter) {
      // if (newTask.filter.filter((flt) => flt.fieldName == filter_.fieldName).length >= 2) {
      //   setError("2 or more repeated field names in Filters");
      //   return;
      // }
      if (filter_.fieldValue == "") {
        setError(`Empty field value in Filters, field name: "${filter_.fieldName}"`);
        return;
      }
    }
    // Check repeated field names and empty field values for Update
    for (let update_ of newTask.update) {
      if (update_.updateType === "manual_finish") {
        continue;
      }
      if (newTask.update.filter((upd) => upd.fieldName == update_.fieldName).length >= 2) {
        setError("2 or more repeated field names in Upates");
        return;
      }

      if (update_.fieldValue == "") {
        setError(`Empty field value in Updates, field name: "${update_.fieldName}"`);
        return;
      }
      // Check Enums
      if (allowedUpdateFieldNames[update_.fieldName]["enum"].length > 0 && !allowedUpdateFieldNames[update_.fieldName]["enum"].includes(update_.fieldValue)) {
        setError(`Field "${update_.fieldName}" value in Updates is invalid.`);
        return;
      }
      // Check data types
      switch (allowedUpdateFieldNames[update_.fieldName]["type"]) {
        case "number":
          if (!isNumber(update_.fieldValue)) {
            setError(`Type does not match "Number" in Updates ${update_.fieldName}'s value`);
            return;
          }
          break;
        case "string":
          if (!isString(update_.fieldValue)) {
            setError(`Type does not match "String" in Updates ${update_.fieldName}'s value`);

            return;
          }
          break;
        case "boolean":
          if (update_.fieldValue != "false" && update_.fieldValue != "true") {
            setError(`Type does not match "Boolean" in Updates ${update_.fieldName}'s value`);

            return;
          }
          break;
      }
    }
    if (!newTask.name) {
      setError("Task needs a name");
      return;
    }

    setError("");

    let newFilters = [];
    newTask.filter.forEach((prop) => {
      let nProp = { ...prop };
      delete nProp._id;
      newFilters.push(nProp);
    });

    let newUpdates = [];
    newTask.update.forEach((prop) => {
      let nProp = { ...prop };
      delete nProp._id;
      newUpdates.push(nProp);
    });

    // console.log({ ...task, filter: newFilters, update: newUpdates, name: newTask.name });
    editTask({ ...task, filter: newFilters, update: newUpdates, name: newTask.name });
    sendPopupShowing2({ showing: false });
  };

  const [error, setError] = useState("");
  //   console.log("NewTask in EditTask", newTask);
  return (
    <div className={styles.popup}>
      <div className={styles.popupContent}>
        <div className="credentials__info-field">
          <label style={{ fontSize: "1.2rem" }}>Task Name</label>
          <input autoFocus style={{ fontSize: "2rem", height: "40px" }} onChange={onChangeTaskName} name="taskName" className="credentials__input" value={newTask.name}></input>
        </div>
        <div className={styles.taskFiltersContainer}>
          <h3>Filters</h3>
          <div className={styles.taskFilters}>
            {newTask.filter.map((filter) => (
              <FilterItem key={filter._id} filter={filter} sendUpdateFilter={(v) => updateFilter(v)} allowedFilterFieldNames={allowedFilterFieldNames} />
            ))}
            <div style={{ width: "30px", marginBottom: "1.5em" }} className="button aqua-green" onClick={onClickAddFilter} title="Add Filter">
              <span style={{ fontSize: "20px", fontWeight: "700" }}>+</span>
            </div>
          </div>
        </div>
        <div className={styles.taskFiltersContainer}>
          <h3>Updates</h3>

          <div className={styles.taskFilters}>
            {newTask.update.map((update) => (
              <UpdateItem key={update._id} update={update} sendUpdateUpdate={(v) => updateUpdate(v)} allowedUpdateFieldNames={allowedUpdateFieldNames} />
            ))}
            <div style={{ width: "30px", marginBottom: "1.5em" }} className="button aqua-green" onClick={onClickAddUpdate} title="Add Update">
              <span style={{ fontSize: "20px", fontWeight: "700" }}>+</span>
            </div>
          </div>
        </div>

        <div style={{ margin: "20px 0px 20px 0px", display: "flex", justifyContent: "space-between" }}>
          <div className="button aqua-green" style={{ width: "120px" }} onClick={onClickUpdateTask}>
            <span>Update Task</span>
          </div>
          {error && <span style={{ color: "rgb(225 51 51)", fontSize: "22px", fontWeight: "700" }}>{error}</span>}
          <div className="button aqua-green" onClick={onClickCancel}>
            <span>Close</span>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  tasks: state.tasks.tasks,
});
export default connect(mapStateToProps, { editTask })(EditTask);
