import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './WhitelistIps.module.scss';
import { deleteWhitelistedIps } from '../../actions/authActions';

const Ip = ({ whitelisetdIps, deleteWhitelistedIps }) => {
  const onClick = async () => {
    await deleteWhitelistedIps(whitelisetdIps._id);
  };
  return (
    <div className={styles.historyItemWrapper}>
      <div className={styles.historyItem}>
        <div className={styles.historyTitle}>{whitelisetdIps.ipFormatted}</div>
        <i style={{ color: 'red', cursor: 'pointer' }} className='fas fa-times' onClick={onClick} title='Remove IP'></i>
      </div>
    </div>
  );
};

export default connect(null, { deleteWhitelistedIps })(Ip);
