import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getWhitelistedIps, addWhitelistedIps } from '../../actions/authActions';
import styles from './WhitelistIps.module.scss';
import Spinner from '../Spinner';
import Ip from './Ip';
import TermsOfUse from '../TermsOfUse';

const WhitelistIps = ({ ips, getWhitelistedIps, addWhitelistedIps, user }) => {
  useEffect(() => {
    getWhitelistedIps();
  }, []);

  const onChange = (e) => {
    if (/^[\d.]+$/.test(e.target.value)) {
      setIp(e.target.value);
    }
  };
  const onClickAddIp = async () => {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip)) {
      if (ip && !ips.some((ip_) => ip_.ip === ip)) {
        setIsLoading(true);
        await addWhitelistedIps(ip);
      }
      setIp('');
      setIsLoading(false);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [ip, setIp] = useState('');
  return (
    <>
      {user ? (
        <>
          {user.acceptedTermsOfUse ? (
            <div className='main'>
              <div className={styles.serverWrapper}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className={styles.inputField}>
                    <label>Server Ip</label>
                    <input onChange={onChange} type='text' value={ip}></input>
                  </div>
                  <div className={`button aqua-green ${isLoading ? 'disabled' : ''}`} onClick={onClickAddIp}>
                    Add
                  </div>
                </div>
              </div>
              {ips ? (
                <div className={styles.historyContainer}>
                  {ips.length > 0 ? <h1>Whitelisted Ips</h1> : <h1>No Whitelisted Ips</h1>}

                  <div className={styles.monitoringGrid}>
                    {ips.map((h) => (
                      <Ip whitelisetdIps={h} key={h._id} />
                    ))}
                  </div>
                </div>
              ) : (
                <Spinner />
              )}
            </div>
          ) : (
            <TermsOfUse />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  ips: state.ips.ips,
});
export default connect(mapStateToProps, { getWhitelistedIps, addWhitelistedIps })(WhitelistIps);
