import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateAccount, updateAccountQuotaFinished } from "../../actions/accountActions";
import Spinner from "../Spinner";
import getChampionName from "../../utils/getChampionName";
import styles from "./EditAccount.module.scss";
import axios from "../../utils/axiosInstance";
import formatPlaytime from "../../utils/formatPlaytimeDashboard";
import longAgo from "../../utils/longAgo";
const Skins = ({ skinLanguage, isPopupShowing: { skins, showing }, sendPopupShowing, skinsData }) => {
  const onClickCancel = () => {
    sendPopupShowing({ skins, showing: !showing });
  };
  const setSkinName = (skin) => {
    if (typeof skin === "string") {
      return skin;
    } else {

      if (skinsData[skin.id]) {
        if (skinLanguage != "-") {
          return skinsData[skin.id][skinLanguage];
        } else {
          return skin.name;
        }
      } else {
        return String(skin.id);
      }
    }
  };
  const setSkinColor = (rarity) => {
    switch (rarity) {
      case "DEFAULT":
        return "white";
      case "RARE":
        return "white";
      case "LEGENDARY":
        return "#DC3636";
      case "EPIC":
        return "#39DADF";
      case "MYTHIC":
        return "#7943ED";
      case "ULTIMATE":
        return "#ECB827";
      default:
        return "white";
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [changes, setChanges] = useState("");
  const [updatedAccount, setUpdatedAccount] = useState({});
  //   console.log(skins);
  const { summonerName, maxGames, maxGamesReached, cooldownAfterSession, lastTimePlayed, mode, completeAllMissions, limitResources, completeMissionUntil, blackWhiteListChamp, disenchantEmblems, flashOn, desiredBE, skipTutorial, desiredLevel, killExplorer, priority, disenchantLoot, randomLanes, disenchantFragChamps, region, disenchantFullChamps, missions, skipIfNameTaken, icon } = updatedAccount;
  //   console.log(updatedAccount.cooldownAfterSession, updatedAccount.cooldownAfterSessionInHours);
  // console.log(updatedAccount);
  return (
    <div className={styles.popup}>
      <div className={styles.popupContent} style={{ maxWidth: "fit-content" }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div style={{ overflow: "hidden" }}>
            <h1 style={{ textAlign: "center" }}>Skins</h1>
            <div
              style={{
                display: "flex",
                gap: "24px",
                minHeight: "433px",
                overflowX: "auto",
                paddingBottom: "20px",
                width: "100%",
              }}
            >
              {skins.map((skin) => (
                <div
                  style={{
                    alignItems: "enter",
                    // backgroundImage: `url(https://gydrus.net/api/skins/${skin.id}.jpg)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    // cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    justifyContent: "center",
                    outline: "1px solid transparent",
                    position: "relative",
                    transition: "-webkit-transform .2s ease",
                    transition: "transform .2s ease",
                    transition: "transform .2s ease,-webkit-transform .2s ease",
                  }}
                >
                  <img style={{ width: "200px" }} src={`https://gydrus.net/api/skins/${skin.id}.jpg`}></img>
                  <span style={{ fontSize: "20px", textAlign: "center", color: setSkinColor(skin.rarity) }}>{setSkinName(skin)}</span>
                </div>
              ))}
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <div style={{ margin: "10px 0px 10px 0px" }} className="button aqua-green" onClick={onClickCancel}>
                <span>Close</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  skinsData: state.accounts.skinsData,
});
export default connect(mapStateToProps, {})(Skins);
