export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const SET_SKIN_DATA = "SET_SKIN_DATA";
export const SET_LOADING_ACCOUNTS = "SET_LOADING_ACCOUNTS";
export const UNSET_LOADING_ACCOUNTS = "UNSET_LOADING_ACCOUNTS";
export const GET_ACCOUNTS_ERROR = "GET_ACCOUNTS_ERROR";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ACCOUNTS = "CLEAR_ACCOUNTS";
export const UPDATE_ACCOUNT_STATUS = "UPDATE_ACCOUNT_STATUS";
export const UPDATE_ACCOUNT_STATE = "UPDATE_ACCOUNT_STATE";
export const GET_ACCOUNTS_CREDENTIALS = "GET_ACCOUNTS_CREDENTIALS";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNTS = "UPDATE_ACCOUNTS";
export const UPDATE_ACCOUNT_ERROR = "UPDATE_ACCOUNT_ERROR";
export const CLEAR_ACCOUNTS_CREDENTIALS = "CLEAR_ACCOUNTS_CREDENTIALS";
export const GET_ACCOUNTS_CREDENTIALS_ERROR = "GET_ACCOUNTS_CREDENTIALS_ERROR";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR";
export const UPDATE_ACCOUNT_DASHBOARD = "UPDATE_ACCOUNT_DASHBOARD";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const REMOVE_DASHBOARD = "REMOVE_DASHBOARD";

export const GET_SERVERS = "GET_SERVERS";
export const ADD_SERVER = "ADD_SERVER";
export const REMOVE_SERVER = "REMOVE_SERVER";
export const UPDATE_SERVER = "UPDATE_SERVER";
export const UPDATE_SERVERS = "UPDATE_SERVERS";
export const SERVER_FAIL = "SERVER_FAIL";
export const CLEAR_SERVERS = "CLEAR_SERVERS";

export const GET_IPS = "GET_IPS";
export const ADD_IP = "ADD_IP";
export const REMOVE_IP = "REMOVE_IP";
export const CLEAR_IPS = "CLEAR_IPS";

export const GET_NAMES = "GET_NAMES";
export const DELETE_NAME = "DELETE_NAME";
export const ADD_NAME = "ADD_NAME";
export const NAMES_FAIL = "NAMES_FAIL";

export const GET_TASKS = "GET_TASKS";
export const DELETE_TASK = "DELETE_TASK";
export const ADD_TASK = "ADD_TASK";
export const TOGGLE_ACTIVE = "TOGGLE_ACTIVE";
export const EDIT_TASK = "EDIT_TASK";
export const TASKS_FAIL = "TASKS_FAIL";

export const GET_PUBLIC_API_KEYS = "GET_PUBLIC_API_KEYS";
