import React, { useState } from "react";
import { connect } from "react-redux";
import { deleteAccounts, removeAccountsFromDashboard, addToDashboard } from "../../actions/accountActions";
import Spinner from "../Accounts/Spinner";
import formatTime from "../../utils/formatTime";

import formatPlaytime from "../../utils/formatPlaytimeDashboard";
import longAgo from "../../utils/longAgo";

import setSkinName from "../../utils/setSkinName";
const CredentialsItem = ({
  sendPopupShowing2,
  isPopupShowing2,
  skinsData,
  deleteAccounts,
  user,

  account: {
    leagueClientMode,
    label,
    desiredLevel,
    desiredPercentage,
    desiredBE,
    maxGamesReached,
    cooldownAfterSession,
    nOfGames,
    gamesInSession,
    playtime,
    completeMissionUntil,
    finishAndStart,
    ip,
    macAddress,
    limitResources,
    hasBoostActive,
    vmNumber,
    limitFps,
    serverId,
    botVersion,
    country,
    checker,
    mode,
    rp,
    lastTimePlayed,
    missionTracker,
    _id,
    priority,
    email,
    dateOfBirth,
    countryCode,
    creationDate,
    displayName,
    flashOn,
    summonerName,
    status,
    disenchantLoot,
    nextLevelPercentage,
    region,
    isActive,
    password,
    currentLevel,
    blueEssence,
    orangeEssence,
    matchmakingStatus,
    skins,
    showOnDashboard,
    bestSkinType,
    gameVersion,
  },
  removeAccountsFromDashboard,
  addToDashboard,
}) => {
  const [showingPassword, setShowingPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onClickPassword = () => {
    setShowingPassword(!showingPassword);
  };
  const onClickDelete = async () => {
    setIsLoading(true);

    await deleteAccounts([_id], true);
  };
  const onClickAddDash = async () => {
    setIsLoading(true);
    await addToDashboard(_id);
    setIsLoading(false);
  };
  const onClicRemoveDash = async () => {
    setIsLoading(true);
    await removeAccountsFromDashboard([_id]);
    setIsLoading(false);
  };
  const onClicExport = async () => {
    setIsLoading(true);
    downloadTxtFile();
    setIsLoading(false);
  };
  const rarities = {
    DEFAULT: 1,
    RARE: 2,
    EPIC: 3,
    LEGENDARY: 4,
    MYTHIC: 5,
    ULTIMATE: 6,
  };
  const handlePopupSkins = () => {
    // console.log(account);
    sendPopupShowing2({
      showing: !isPopupShowing2.showing,
      skins: skins,
    });
  };
  let formatSkins = (skins) => {
    skins.sort((a, b) => rarities[b.rarity] - rarities[a.rarity]);
    if (typeof skins[0] === "string") {
      return skins.join("-");
    } else {
      return skins.map((skin) => `${setSkinName(skin)} (${skin.rarity})`).join("-");
    }
  };
  let formatSkinsIds = (skins) => {
    skins.sort((a, b) => rarities[b.rarity] - rarities[a.rarity]);

    if (typeof skins[0] === "string") {
      return skins.join("-");
    } else {
      return skins.map((skin) => `${skin.id}`).join("-");
    }
  };
  const getExternalWebsite = (website) => {
    switch (website) {
      case "leaguegraphs":
        return `https://www.leagueofgraphs.com/summoner/${region}/${displayName !== " " ? displayName : summonerName}`;
      case "opgg":
        return `https://${region}.op.gg/summoner/userName=${displayName !== " " ? displayName : summonerName}`;
    }
  };
  const [regionFormat, setRegionFormat] = useState(JSON.parse(localStorage.getItem("regionformat-settings")) ? JSON.parse(localStorage.getItem("regionformat-settings")) : "yes");
  const regionsFormatted = {
    lan: "lan",
    kr: "kr",
    tr: "tr",
    ru: "ru",
    br: "br",
    las: "las",
    euw: "euw",
    jp: "jp",
    na: "na",
    eune: "eun",
    oce: "oc",
    ph: "ph",
    sg: "sg",
    th: "th",
    tw: "tw",
    vn: "vn",
  };
  const downloadTxtFile = () => {
    const options = JSON.parse(localStorage.getItem("export-settings"))
      ? JSON.parse(localStorage.getItem("export-settings"))
      : {
          summonerName: true,
          displayName: false,
          priority: false,
          password: true,
          region: true,
          currentLevel: false,
          blueEssence: false,
          orangeEssence: false,
          matchmakingStatus: false,
          skins: false,
          skinsIds: false,

          filterByRegion: false,
          filterByQuota: false,
          flashOn: false,
          email: false,
          dateOfBirth: false,
          creationDate: false,
          countryCode: false,
          missionsCompleted: false,
          separator: ":",
        };

    // let exportString = '';
    let accountInfo = {};
    let sp = options["separator"];
    accountInfo.summonerName = options["summonerName"] ? summonerName : "";
    accountInfo.priority = options["priority"] ? priority : "";
    accountInfo.displayName = options["displayName"] ? displayName : "";
    accountInfo.password = options["password"] ? password : "";
    accountInfo.region = options["region"] ? (regionFormat === "no" ? regionsFormatted[region].toUpperCase() : region.toUpperCase()) : "";
    accountInfo.currentLevel = options["currentLevel"] ? currentLevel : "";
    accountInfo.blueEssence = options["blueEssence"] ? blueEssence : "";
    accountInfo.orangeEssence = options["orangeEssence"] ? orangeEssence : "";

    accountInfo.matchmakingStatus = options["matchmakingStatus"] ? matchmakingStatus : "";
    accountInfo.skins = options["skins"] ? formatSkins(skins) : "";
    accountInfo.skinsIds = options["skinsIds"] ? formatSkinsIds(skins) : "";
    accountInfo.flashOn = options["flashOn"] ? flashOn.toUpperCase() : "";
    accountInfo.email = options["email"] ? email : "";
    accountInfo.dateOfBirth = options["dateOfBirth"] ? dateOfBirth : "";
    accountInfo.creationDate = options["creationDate"] ? creationDate : "";
    accountInfo.countryCode = options["countryCode"] ? countryCode : "";
    accountInfo.missionsCompleted = options["missionsCompleted"] ? missionTracker["completed"] : "";
    accountInfo.lastGamePlayed = options["lastGamePlayed"] ? (lastTimePlayed > 0 ? formatTime(lastTimePlayed) : "No Last Game") : "";
    accountInfo.rp = options["rp"] ? rp : "";
    let line = `${user.exportFormat.map((conf, i) => (accountInfo[conf.id] === "" || i == 0 || (!options[user.exportFormat[0].id] && i == 1) ? "" : sp) + accountInfo[conf.id]).join("")}`;
    const element = document.createElement("a");
    // console.log(accountInfo);
    // console.log(options);
    // console.log(line);
    const file = new Blob([line], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    const date = formatTime(Date.now());
    element.download = `${region ? region.toUpperCase() : ""} ${date} - Account.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const setSkinColor = (rarity) => {
    switch (rarity) {
      case "DEFAULT":
        return "white";
      case "RARE":
        return "white";
      case "LEGENDARY":
        return "#DC3636";
      case "MYTHIC":
        return "#7943ED";
      case "EPIC":
        return "#39DADF";
      case "ULTIMATE":
        return "#ECB827";
      default:
        return "white";
    }
  };
  const setSkinName = (skin) => {
    if (typeof skin === "string") {
      return skin;
    } else {
      if (skinsData[skin.id]) {
        if (user.skinLanguage != "-") {
          return skinsData[skin.id][user.skinLanguage];
        } else {
          return skin.name;
        }
      } else {
        return String(skin.id);
      }
    }
  };

  const showColumnsTds = (id) => {
    switch (id) {
      case "mode":
        return <td style={{ fontSize: ".5rem" }}>{mode.toUpperCase()}</td>;
      case "summonerName":
        return (
          <td>
            <div className="tooltip">
              <a rel="noopener noreferrer" href={getExternalWebsite(user.externalWebsite)} target="_blank">
                {summonerName.length > 16 ? `${summonerName.substring(0, 9)}...` : summonerName}
              </a>
              {displayName && <span className="tooltiptext">{displayName !== " " ? displayName : summonerName}</span>}
            </div>
          </td>
        );
      case "region":
        return <td>{region.toUpperCase()}</td>;
      case "password":
        return (
          <td className="credentials__password">
            {showingPassword ? (
              <span onClick={onClickPassword}>{password.trim()}</span>
            ) : (
              <span>
                <i onClick={onClickPassword} className="fas fa-eye-slash"></i>
              </span>
            )}
          </td>
        );
      case "currentLevel":
        return (
          <td>
            {" "}
            {currentLevel} ({nextLevelPercentage}%)
          </td>
        );
      case "desiredLevel":
        return <td>{desiredLevel}</td>;

      case "desiredPercentage":
        return <td>{desiredPercentage}%</td>;
      case "desiredBE":
        return <td>{desiredBE}</td>;
      case "blueEssence":
        return <td>{blueEssence}</td>;
      case "rp":
        return <td>{rp}</td>;
      case "orangeEssence":
        return <td>{orangeEssence}</td>;
      case "status":
        return <td>{status}</td>;
      case "skins":
        return (
          <td>
            {skins.length > 0 ? (
              <div className="tooltip">
                <span onClick={handlePopupSkins} style={{ cursor: "pointer", color: setSkinColor(bestSkinType), fontWeight: "bold" }}>
                  {skins.length}
                </span>

                <span className="tooltiptext" key={Math.random().toString(36).substr(2, 9)}>
                  {skins.map((skin) => (
                    <p key={Math.random().toString(36).substr(2, 9)} style={{ lineHeight: ".5px", color: setSkinColor(skin.rarity) }}>{`${setSkinName(skin)}\n`}</p>
                  ))}
                </span>
              </div>
            ) : (
              "-"
            )}
          </td>
        );
      case "disenchantLoot":
        return <td>{disenchantLoot ? <img style={{ width: "25px", height: "25px" }} src={"./BE.png"}></img> : <img style={{ width: "25px", height: "25px" }} src={"./CAPSULE.png"}></img>}</td>;
      case "isActive":
        return (
          <td>
            <i style={{ color: `${isActive ? "green" : "#970000"}` }} className={`fas fa-${isActive ? "check" : "times"}-circle`}></i>{" "}
          </td>
        );
      case "visibleOnDashboard":
        return <td>{!isLoading ? <>{showOnDashboard ? <i style={{ color: "green" }} className={`accounts__button fas fa-check-circle ${!showOnDashboard || isActive ? "disabled" : ""}`} title="Click to remove from dashboard" onClick={() => onClicRemoveDash(_id)}></i> : <i style={{ color: "#970000" }} className="accounts__button fas fa-times-circle" title="Click to add to dashboard" onClick={() => onClickAddDash(_id)}></i>}</> : <Spinner />}</td>;
      case "actions":
        return (
          <td>
            {!isLoading ? (
              <>
                <i className={`credentials__delete-button fas fa-trash-alt ${isActive ? "disabled" : ""}`} onClick={onClickDelete} title="Delete account"></i>
                <i style={{ marginLeft: "1em" }} className={`credentials__delete-button fas fa-download`} onClick={onClicExport} title="Download account"></i>
              </>
            ) : (
              <Spinner />
            )}
          </td>
        );
      case "cooldownAfterSession":
        return (
          <td>
            {maxGamesReached ? (
              <div className="tooltip">
                <span>{lastTimePlayed + cooldownAfterSession > Date.now() ? `${formatPlaytime(lastTimePlayed + cooldownAfterSession - Date.now())}` : "Ready"}</span>

                {lastTimePlayed + cooldownAfterSession > Date.now() && <span className="tooltiptext">{longAgo(lastTimePlayed + cooldownAfterSession)}</span>}
              </div>
            ) : (
              <span>-</span>
            )}
          </td>
        );
      case "nOfGames":
        return <td>{nOfGames}</td>;
      case "gamesInSession":
        return <td>{gamesInSession}</td>;
      case "playtime":
        return <td style={{ width: "350px" }}> {formatPlaytime(playtime)} </td>;
      case "finishAndStart":
        return <td style={{ textAlign: "center" }}>{finishAndStart && finishAndStart.started.date != 0 ? (((finishAndStart.finished.date != 0 ? finishAndStart.finished.date : Date.now()) - finishAndStart.started.date) / 8.64e7).toFixed(1) : "-"}</td>;

      case "priority":
        return <td>{priority}</td>;
      case "limitResources":
        return <td>{limitResources}</td>;
      case "flashOn":
        return <td>{flashOn.toUpperCase()}</td>;
      case "missionTracker":
        return (
          <td>
            {missionTracker.completed}/{completeMissionUntil}
          </td>
        );

      case "ip":
        return <td>{ip}</td>;
      case "macAddress":
        return <td>{macAddress}</td>;
      case "finishAndStartDates":
        return (
          <td>
            <div className="tooltip" style={{ border: "none" }}>
              <span className="tooltiptext">
                {finishAndStart.started.date === 0 ? (
                  <p style={{ margin: "0" }}>
                    <span style={{ color: "#bdbdbd" }}>Started: </span> N/A
                  </p>
                ) : (
                  <p style={{ margin: "0" }}>
                    {" "}
                    <span style={{ color: "#bdbdbd" }}>Started: </span>
                    {formatTime(finishAndStart.started.date)}
                  </p>
                )}
                {finishAndStart.finished.date === 0 ? (
                  <p style={{ margin: "0" }}>
                    <span style={{ color: "#bdbdbd" }}>Finished: </span> N/A
                  </p>
                ) : (
                  <p style={{ margin: "0" }}>
                    {" "}
                    <span style={{ color: "#bdbdbd" }}>Finished: </span>
                    {formatTime(finishAndStart.finished.date)}
                  </p>
                )}
              </span>
              <i className="fas fa-info-circle"></i>
            </div>
          </td>
        );
      case "checker":
        return (
          <td>
            <p style={{ margin: "0", color: "black" }}>{checker.status}</p>
          </td>
        );
      case "hasBoostActive":
        return (
          <td>
            {Date.now() < hasBoostActive ? (
              <div className="tooltip">
                <span className="tooltiptext">Boost Ends: {formatTime(hasBoostActive)}</span>
                <i style={{ color: "green" }} className="fas fa-check"></i>
              </div>
            ) : (
              <i style={{ color: "rgb(151, 0, 0)" }} className="fas fa-times"></i>
            )}
          </td>
        );
      case "vmNumber":
        return <td>{vmNumber}</td>;
      case "limitFps":
        return <td>{limitFps}</td>;
      case "serverId":
        return <td>{serverId}</td>;
      case "botVersion":
        return <td>{botVersion}</td>;
      case "country":
        return <td>{country}</td>;
      case "lastTimePlayed":
        return <td style={{ fontSize: "0.8rem" }}>{formatTime(lastTimePlayed)}</td>;
      case "label":
        return <td>{label}</td>;
      case "leagueClientMode":
        return <td>{leagueClientMode}</td>;
      case "gameVersion":
        return <td>{gameVersion}</td>;
    }
  };
  return (
    <tr>
      {user.credentialsColumns.map((col) => {
        return col.enabled ? showColumnsTds(col.id) : null;
      })}
      {/* <td>
        <div className="tooltip">
          <a rel="noopener noreferrer" href={getExternalWebsite(user.externalWebsite)} target="_blank">
            {summonerName.length > 16 ? `${summonerName.substring(0, 9)}...` : summonerName}
          </a>
          {displayName && <span className="tooltiptext">{displayName !== " " ? displayName : summonerName}</span>}
        </div>
      </td>
      <td>{region.toUpperCase()}</td>
      <td className="credentials__password">
        {showingPassword ? (
          <span onClick={onClickPassword}>{password.trim()}</span>
        ) : (
          <span>
            <i onClick={onClickPassword} className="fas fa-eye-slash"></i>
          </span>
        )}
      </td>
      <td>
        {" "}
        {currentLevel} ({nextLevelPercentage}%)
      </td>
      <td>{blueEssence}</td>
      <td>{orangeEssence}</td>

     
      <td>{status}</td>
      <td>
        {skins.length > 0 ? (
          <div className="tooltip">
            <span onClick={handlePopupSkins} style={{ cursor: "pointer", color: setSkinColor(bestSkinType), fontWeight: "bold" }}>
              {skins.length}
            </span>

            <span className="tooltiptext" key={Math.random().toString(36).substr(2, 9)}>
              {skins.map((skin) => (
                <p key={Math.random().toString(36).substr(2, 9)} style={{ lineHeight: ".5px", color: setSkinColor(skin.rarity) }}>{`${setSkinName(skin)}\n`}</p>
              ))}
            </span>
          </div>
        ) : (
          "-"
        )}
      </td>
      <td>{disenchantLoot ? <img style={{ width: "25px", height: "25px" }} src={"./BE.png"}></img> : <img style={{ width: "25px", height: "25px" }} src={"./CAPSULE.png"}></img>}</td>

      <td>
        <i style={{ color: `${isActive ? "green" : "#970000"}` }} className={`fas fa-${isActive ? "check" : "times"}-circle`}></i>{" "}
      </td>
      <td>{!isLoading ? <>{showOnDashboard ? <i style={{ color: "green" }} className={`accounts__button fas fa-check-circle ${!showOnDashboard || isActive ? "disabled" : ""}`} title="Click to remove from dashboard" onClick={() => onClicRemoveDash(_id)}></i> : <i style={{ color: "#970000" }} className="accounts__button fas fa-times-circle" title="Click to add to dashboard" onClick={() => onClickAddDash(_id)}></i>}</> : <Spinner />}</td>

      <td>
        {!isLoading ? (
          <>
            <i className={`credentials__delete-button fas fa-trash-alt ${isActive ? "disabled" : ""}`} onClick={onClickDelete} title="Delete account"></i>
            <i style={{ marginLeft: "1em" }} className={`credentials__delete-button fas fa-download`} onClick={onClicExport} title="Download account"></i>
          </>
        ) : (
          <Spinner />
        )}
      </td> */}
    </tr>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  skinsData: state.accounts.skinsData,
});
export default connect(mapStateToProps, {
  deleteAccounts,
  removeAccountsFromDashboard,
  addToDashboard,
})(CredentialsItem);
