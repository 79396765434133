import React from 'react';
import { connect } from 'react-redux';
import DashboardUser from './DashboardUser';
import Spinner from '../Spinner';
import TermsOfUse from '../TermsOfUse';

const DashboardHandler = ({ user }) => {
	if (user) {
		// console.log('accepted?: ', user.acceptedTermsOfUse);
	} else {
		// console.log("No user")
	}

	return <> {user ? <>{user.acceptedTermsOfUse ? <>{<DashboardUser />}</> : <TermsOfUse />}</> : <Spinner />}</>;
};
const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, null)(DashboardHandler);
