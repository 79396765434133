import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Spinner from "../Spinner";
import styles from "./PaymentMethods.module.scss";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import StripeCheckout from "./StripeCheckout";
import "react-coinbase-commerce/dist/coinbase-commerce-button.css";
import CoinbaseCommerceButton from "react-coinbase-commerce";
import { getPublicApiKeys } from "../../actions/authActions";
import axios from "../../utils/axiosInstance";

const PaymentMethods = ({ setShowingPaymentMethods, startDate, endDate, amountOfLicenses, days, price, publicApiKeys, getPublicApiKeys, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showingMethods, setShowingMethods] = useState(true);

  useEffect(() => {
    if (!publicApiKeys) {
      setIsLoading(true);

      console.log("Setting public api keys");
      getPublicApiKeys();
      setIsLoading(false);
    }
  }, []);
  // Stripe
  const [showingStripe, setShowingStripe] = useState(true);
  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState(false);
  const getStripeKey = async (stripeKey) => {
    const stripePromise = await loadStripe(stripeKey);
    return stripePromise;
  };
  // Coinbase
  const [showingCoinbase, setShowingCoinbase] = useState(true);
  const [chargeId, setChargeId] = useState("");

  const onClickStripe = async () => {
    setIsLoading(true);

    // Load Stripe promise
    const stripePromise = getStripeKey(publicApiKeys.stripe);
    setStripePromise(stripePromise);
    // Get Client secret using create-payment-intent
    const body = { username: user.username, licenseStart: startDate, days, licenses: amountOfLicenses };
    let stripePaymentIntent = await axios.post(`/api/v1/buy/stripe-create-payment-intent`, body);
    let clientSecret = stripePaymentIntent.data.data.clientSecret;
    // setStripeClientSecret
    setStripeClientSecret(clientSecret);
    // Show Stripe element
    setShowingMethods(false);
    setShowingStripe(true);
    setShowingCoinbase(false);
    setIsLoading(false);
  };
  const onClickCoinbase = async () => {
    setIsLoading(true);
    // Get Charge Id using create-payment-intent
    const body = { username: user.username, licenseStart: startDate, days, licenses: amountOfLicenses };
    let coinbaseCharge = await axios.post(`/api/v1/buy/coinbase-create-charge`, body);
    // console.log(coinbaseCharge);
    const chargeCode = coinbaseCharge.data.data.charge.code;
    // setStripeClientSecret
    setChargeId(chargeCode);
    // Show Stripe element
    setShowingStripe(false);
    setShowingMethods(false);
    setShowingCoinbase(true);
    setIsLoading(false);
  };
  //   console.log(isLoading, publicApiKeys);
  const onModalClosed = () => {
    return;
  };
  return (
    <div className={styles.popup}>
      <div className={styles.popupContent}>
        {isLoading || !publicApiKeys ? (
          <Spinner />
        ) : (
          <div>
            <div className={styles.paymentMethodsContainer}>
              <h1 style={{ display: "flex", margin: "0px" }}>Choose a payment method</h1>

              <div style={{ display: "flex", marginTop: "10px" }}>
                <span>
                  Amount to Pay: <span style={{ fontWeight: "bold" }}>${price}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </span>

                <span>
                  Amount of Days: <span style={{ fontWeight: "bold" }}>{days}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </span>

                <span>
                  Amount of Licenses: <span style={{ fontWeight: "bold" }}>{amountOfLicenses}</span>
                </span>
              </div>
              {showingMethods && (
                <>
                  <div className={styles.paymentMethod} style={{ marginTop: "25px" }} onClick={onClickCoinbase}>
                    <img src={"coinbase.jpg"} style={{ alignSelf: "flex-start", borderRadius: "100%", width: "50px" }}></img>
                    <div>
                      <h2 style={{ fonSize: "1.125rem", margin: "0px" }}>Coinbase Crypto</h2>
                      <p style={{ fontSize: ".875rem", margin: "0px" }}>Pay using Crypto with Coinbase</p>
                    </div>
                  </div>
                  {/* <div className={styles.paymentMethod} onClick={onClickStripe}>
                    <img src={"stripe.webp"} style={{ alignSelf: "flex-start", borderRadius: "100%", width: "50px" }}></img>
                    <div>
                      <h2 style={{ fonSize: "1.125rem", margin: "0px" }}>Stripe</h2>
                      <p style={{ fontSize: ".875rem", margin: "0px" }}>Pay using VISA or MASTERCARD credit/debit cards via Stripe</p>
                    </div>
                  </div> */}
                </>
              )}

              {/* {showingStripe && !showingMethods && (
                <>
                  <h2 style={{ margin: "40px 00px 10px 0px" }}>Stripe</h2>
                  <h4 style={{ margin: "0px 0px 20px 0px" }}>Licenses will be added as soon as payment goes through Stripe's system</h4>
                  <Elements stripe={stripePromise} options={{ clientSecret: stripeClientSecret }}>
                    <StripeCheckout />
                  </Elements>
                </>
              )} */}
              {showingCoinbase && !showingMethods && (
                <>
                  <h2 style={{ margin: "40px 00px 10px 0px" }}>Coinbase</h2>
                  <h4 style={{ margin: "0px 0px 20px 0px" }}>Licenses will be added as soon as payment goes through Coinbase's system</h4>
                  <CoinbaseCommerceButton chargeId={chargeId} className="btn btn--orange" />
                </>
              )}
            </div>
            <div style={{ margin: "0px 0px 15px 0px", justifyContent: "end" }} className="credentials__info-row">
              <div className={styles.buttonGroup}>
                <div className="button aqua-green" onClick={() => setShowingPaymentMethods(false)}>
                  <span>Close</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  publicApiKeys: state.auth.publicApiKeys,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getPublicApiKeys })(PaymentMethods);
