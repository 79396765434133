import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './TermsOfUse.module.scss';
import { acceptTerms } from '../actions/authActions';
const TermsOfUse = ({ acceptTerms }) => {
  const onClick = () => {
    acceptTerms();
  };
  return (
    <div className='main' style={{paddingBottom:'2.5em'}}>
      <h1>gydrus.net Terms of Services (Last Update: June, 6th 2020)</h1>
      <h2>1. Acceptance of Terms of Use and Amendments</h2>
      <p>Each time you use or cause access to this web site, you agree to be bound by these Terms of Use, and as amended from time to time with or without notice to you. In addition, if you are using a particular service on or through this web site, you will be subject to any rules or guidelines applicable to those services and they shall be incorporated by reference into these Terms of Use.</p>
      <h2>2. Our service</h2>
      <p>Our web site and services provided to you on and through our web site on an as is basis. You agree that the owners of this web site exclusively reserve the right and may, at any time and without notice and any liability to you, modify or discontinue this web site and its services or delete the data you provide, whether temporarily or permanently. We shall have no responsibility or liability for the timeliness, deletion, failure to store, inaccuracy, or improper delivery of any data or information. If you do not agree to these Terms of Use, do not use this site in any way, shape, or form.</p>
      <h2>3. Your Responsibilities and Registration Obligations</h2>
      <p>In order to use this web site, you must register on our site, agree to provide truthful information when requested, and be at least the age of eightteen (18) or older. When registering, you explicitly agree to our Terms of Use and as may be modified by us from time to time and available here.</p>
      <h2>4. Our Products</h2>
      <p>Gydrus sells Memberships / licenses for using our softwares. Our softwares are owned by Gydrus and are the property of Gydrus. Our softwares have been created to be used on playful purposes, on private game servers with your friends. Gydrus is not responsible if you use our softwares in another purpose. The use of our softwares in other purposes, which are not the ones that our softwares have been made, is at the own responsibility of the user. All our softwares are free, but their uses involve a Membership / license.</p>
      <h2>5. Online Conduct and Botting</h2>
      <p>You agree that you will be personally responsible for the use of your Account and for all of the communication and activity on this site and on the Client software that results from use of your Account. Your online conduct and interaction with other subscribers should be guided by common sense and basic etiquette. Gydrus may terminate your Account or a particular Subscription for any conduct or activity that Gydrus believes is illegal, constitutes a risk, or which otherwise negatively affects the enjoyment of our software by other Subscribers. You acknowledge that Gydrus is not required to provide you notice before terminating your Subscriptions(s) and/or Account, but it may choose to do so. When botting in multiplayer games, you choose to volontary break your games End User Licence Agreements, we can not be taken responsible if you loose your game account or/and get any damage caused by botting. Gydrus is not liable for potential claims caused by one of their consumers on intellectual property of third parties. In such events the consumer protects Gydrus for claims from third parties.</p>
      <h2>6. Payment Policy</h2>
      <p>Fraudulent charges with an account that sends money without the consent of the account holder or/and any kind of frauds will result in your account being instantly disabled, permanently terminated and/or legal action will be taken. We do not allow the user to share or resell his account, it will result in your account being instantly disabled, permanently terminated and/or legal action will be taken.</p>
      <h2>7. Right of withdrawal</h2>
      <p>You have the right to withdraw from this contract within 14 calendar days without giving any reason. The withdrawal period will expire 14 days after the day of the conclusion of the contract. To exercise the right of withdrawal you shall inform us, Gydrus, of your decision to withdraw by an unequivocal statement using the same platform where the services were contracted specifying date of withdrawal. To meet the withdrawal deadline, it is sufficient for you to send your communication concerning your exercise of the right of withdrawal before the withdrawal period has expired. You hereby expressly acknowledge that you:</p>
      <p>Lose your right of withdrawal in respect of the purchase of Digital Content once the performance has begun and for software, which require a prior download before accessing the content, once you are accessing the content.</p>
      <p>Lose your right of withdrawal in respect of any Services if the performance has begun with your express consent and once the contract has been fully performed by us.</p>
      <h2>8. Authentication</h2>
      <p>Tampering with our Client software to bypass any security mechanism or to dismantle our Client software will result in your account being disabled, permanently terminated and/or legal action will be taken.</p>
      <h2>9. Registration and Password</h2>
      <p>You are responsible to maintain the confidentiality of your password and shall be responsible for all uses via your registration and/or login, whether authorized or unauthorized by you. You agree to immediately notify us of any unauthorized use or your registration, user account or password. If you give an unauthorized personal your account to login, it will result in your account being disabled and permanently terminated.</p>
      <h2>10. Your conduct</h2>
      <p>You agree that all information or data of any kind, whether text, software, code, music or sound, photographs or graphics, video or other materials (Content), publicly or privately provided, shall be the sole responsibility of the person providing the Content or the person whose user account is used. You agree that our web site may expose you to Content that may be objectionable or offensive. We shall not be responsible to you in any way for the Content that appears on this web site nor for any error or omission. You explicitly agree, in using this web site or any service provided, that you shall not: Provide any Content or perform any conduct that may be unlawful, illegal, threatening, harmful, abusive, harassing, stalking, tortuous, defamatory, libelous, vulgar, obscene, offensive, objectionable, pornographic, designed to or does interfere or interrupt this web site or any service provided, infected with a virus or other destructive or deleterious programming routine, give rise to civil or criminal liability, or which may violate an applicable local, national or international law; Impersonate or misrepresent your association with any person or entity, or forge or otherwise seek to conceal or misrepresent the origin of any Content provided by you; Collect or harvest any data about other users; Provide any Content that may give rise to our civil or criminal liability or which may constitute or be considered a violation of any local, national or international law, including but not limited to laws relating to copyright, trademark, patent, or trade secrets.</p>
      <h2>11. Private Policy</h2>

      <p>The name specific data given by the customer won't be spread to third parties. The customer have the right to access, to modify, and to delete data which concern him with Gydrus.</p>
      <h2>12. Third Party Services</h2>
      <p>Goods and services of third parties may be advertised and/or made available on or through this web site. Representations made regarding products and services provided by third parties are governed by the policies and representations made by these third parties. We shall not be liable for or responsible in any manner for any of your dealings or interaction with third parties.</p>
      <h2>13. Indemnification</h2>
      <p>You agree to indemnify and hold us harmless, our subsidiaries, affiliates, related parties, officers, directors, employees, agents, independent contractors, advertisers, partners, and co-branders from any claim or demand, including reasonable attorney's fees, that may be made by any third party, that is due to or arising out of your conduct or connection with this web site or service, your provision of Content, your violation of this Terms of Use or any other violation of the rights of another person or party.</p>
      <h2>14. Disclaimer of Warranties</h2>
      <p>You understand and agree that your use of this web site and any services or content provided (the service) is made available and provided to you at your own risk. It is provided to you as is and we expressly disclaim all warranties of any kind, implied or express, including but not limited to the warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no warranty, implied or express, that any part of the service will be uninterrupted, error-free, virus-free, timely, secure, accurate, and reliable, of any quality, nor that any content is safe in any manner for download. You understand and agree that neither us nor any participant in the service provides professional advice of any kind and that use of such advice or any other information is solely at your own risk and without our liability of any kind. Some jurisdictions may not allow disclaimers of implied warranties and the above disclaimer may not apply to you only as it relates to implied warranties.</p>
      <h2>15. Limitation of Liability</h2>
      <p>You expressly understand and agree that we shall not be liable for any direct, indirect, special, incidental, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible loss (even if we have been advised of the possibility of such damages), resulting from or arising out of (1) the use of or the inability to use the service, (2) the cost to obtain substitute goods and/or services resulting from any transaction entered into on through the service, (3) unauthorized access to or alteration of your data transmissions, (4) statements or conduct of any third party on the service, or (5) any other matter relating to the service. In some jurisdictions, it is not permitted to limit liability and therefore such limitations may not apply to you.</p>
      <h2>16. Reservation of Rights</h2>
      <p>We reserve all of our rights, including but not limited to any and all copyrights, trademarks, patents, trade secrets, and any other proprietary right that we may have in our web site, its content, and the goods and services that may be provided. The use of our rights and property requires our prior written consent. We are not providing you with any implied or express licenses or rights by making services available to you and you will have no rights to make any commercial uses of our web site or service without our prior written consent.</p>
      <h2>17. Applicable Law</h2>
      <p>You agree that this Terms of Use and any dispute arising out of your use of this web site or our products or services shall be governed by and construed in accordance with local laws where the headquarters of the owner of this web site is located, without regard to its conflict of law provisions. By registering or using this web site and service you consent and submit to the exclusive jurisdiction and venue of the county or city where the headquarters of the owner of this web site is located.</p>
      <h2>18. Miscellaneous Information</h2>
      <p>In the event that this Terms of Use conflicts with any law under which any provision may be held invalid by a court with jurisdiction over the parties, such provision will be interpreted to reflect the original intentions of the parties in accordance with applicable law, and the remainder of this Terms of Use will remain valid and intact; The failure of either party to assert any right under this Terms of Use shall not be considered a waiver of any that party's right and that right will remain in full force and effect; You agree that without regard to any statue or contrary law that any claim or cause arising out of this web site or its services must be filed within one (1) month after such claim or cause arose or the claim shall be forever barred; We may assign our rights and obligations under this Terms of Use and we shall be relieved of any further obligation.</p>
      <h2>19. Other Organizations</h2>
      <p>You agree to the following, if not agreed with then leave our site NOW! I am not visiting this site to steal material (including Information, Snapshots and Source-Codes) to use against the Webmaster, Site Operator or Host or any person in any conceivable manner.</p>
      <h2>20. Contact</h2>
      <p>If you have any questions or claims, or comments regarding this Terms of Services or Privacy Policy, please contact our staff on gydrus.net.</p>
      <p className={styles.disclaimer}>This Agreement constitutes the entire agreement between Gydrus and the User and supersedes any prior statements, whether written or oral.</p>
      <div style={{ width: '100px' }} className='button aqua-green' onClick={onClick}>
        <span>Accept Terms</span>
      </div>
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  props,
  auth: state.auth,
});
export default connect(mapStateToProps, { acceptTerms })(TermsOfUse);
