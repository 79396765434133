import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateAccount, updateAccountQuotaFinished } from "../../actions/accountActions";
import Spinner from "../Spinner";
import getChampionName from "../../utils/getChampionName";
import styles from "./EditAccount.module.scss";
import axios from "../../utils/axiosInstance";
import formatPlaytime from "../../utils/formatPlaytimeDashboard";
import longAgo from "../../utils/longAgo";
const EditAccount = ({ user: { showCooldown, fields }, isPopupShowing: { accounts, showing }, sendPopupShowing, updateAccount, updateAccountQuotaFinished }) => {
  const ONE_HOUR_MILISECONDS = 3600000;

  useEffect(() => {
    const func = async () => {
      let fullAccount = await axios.get(`/api/v1/accounts/${accounts[0]._id}/full`);
      let fAcc = fullAccount.data.data;
      setUpdatedAccount({
        ...fAcc,
        amountOfGamesToFinishObject: {
          amountOfGames: fAcc.amountOfGamesToFinish.amountOfGames,
        },
        cooldownAfterSessionInHours: fAcc.cooldownAfterSession / ONE_HOUR_MILISECONDS,
      });
      setIsLoading(false);
    };
    if (accounts.length === 1) {
      func();
    } else {
      setUpdatedAccount({
        region: "na",
        desiredLevel: 30,
        desiredPercentage: 0,

        maxGames: 500,
        disenchantLoot: true,
        disenchantFragChamps: true,
        disenchantFullChamps: true,
        randomLanes: false,
        skipIfNameTaken: false,
        icon: 0,
        flashOn: "d",
        priority: 0,
        completeAllMissions: false,
        killExplorer: true,
        cooldownAfterSessionInHours: 3,
        cooldownAfterSession: 10800000,
        disenchantGem: false,
        disenchantGemOption: "skin",
        disenchantEmblems: false,
        disenchantEmblemsOption: "be",
        useCustomName: false,
        disenchantEternal: false,
        skipTutorial: false,
        usePrivateParty: false,
        crazyNumber: 0,
        completeMissionUntil: 8,
        limitResources: 0.35,
        restartIngameAfter: 60,
        waitBeforePickChamp: 0,
        desiredTutos: 0,

        // spaceGrooveOption: 'TheGroove_series',
        desiredBE: 0,
        mode: "normal",
        leagueClientMode: 2,
        freeNameChange: false,
        blackWhiteListChamp: {
          enabled: false,
          option: "whitelist",
          ids: [],
        },
        label: "-",
        missions: {
          1: "brand",
          2: "caitlyn",
          3: "illaoi",
          4: "seraphine",
          5: "thresh",
          6: "ekko",
        },
        amountOfGamesToFinishObject: {
          amountOfGames: 0,
        },
      });
      setIsLoading(false);
    }
  }, []);

  const onClickCancel = () => {
    sendPopupShowing({ accounts, showing: !showing });
  };

  const onClickUpdate = async () => {
    setIsLoading(true);

    if (accounts.length > 1) {
      await updateAccount({ ids: accounts, updatedFields: updatedAccountBulk, fields });
    } else {
      if (summonerName) {
        await updateAccount({ ids: [accounts[0]._id], updatedFields: updatedAccount, fields });
      } else {
        return;
      }
    }
    setIsLoading(false);
    sendPopupShowing({ accounts, showing: !showing });
  };

  const onClickFinishAccount = async () => {
    setIsLoading(true);

    if (accounts.length > 1) {
      await updateAccountQuotaFinished({ ids: accounts });
    } else {
      if (summonerName) {
        await updateAccountQuotaFinished({ ids: [accounts[0]._id] });
      } else {
        return;
      }
    }
    setIsLoading(false);
    sendPopupShowing({ accounts, showing: !showing });
  };

  const [isLoading, setIsLoading] = useState(true);
  const [changes, setChanges] = useState("");
  const [updatedAccount, setUpdatedAccount] = useState({});

  const [updatedAccountBulk, setUpdatedAccountBulk] = useState({});

  const onChangeMissions = (event) => {
    const value = event.target.value;
    const key = event.target.name;

    setUpdatedAccount({
      ...updatedAccount,
      missions: { ...updatedAccount.missions, [Number(key)]: value },
    });
    setUpdatedAccountBulk({
      ...updatedAccountBulk,
      missions: { ...updatedAccountBulk.missions, [Number(key)]: value },
    });
  };

  const onChangeCheckBox = (event) => {
    setUpdatedAccount({
      ...updatedAccount,
      [event.target.name]: event.target.checked,
    });
    setUpdatedAccountBulk({
      ...updatedAccountBulk,
      [event.target.name]: event.target.checked,
    });
  };
  // console.log(updatedAccount, updatedAccount.amountOfGamesToFinishObject);
  useEffect(() => {
    let string = "";
    Object.entries(updatedAccountBulk).forEach(([key, value]) => {
      let value_ =
        typeof value != "object"
          ? value
          : Object.entries(value)
              .map(([key, value]) => key + ": " + value)
              .join(" ");
      string += `${key}: ${value_} | `;
    });
    setChanges(string);
  }, [updatedAccountBulk]);

  const onChangeLevel = (event) => {
    const re = /^[0-9\b]+$/;
    if ((re.test(event.target.value) || event.target.value === "") && event.target.value.length < 4) {
      setUpdatedAccount({
        ...updatedAccount,
        [event.target.name]: event.target.value.trim(),
      });
      setUpdatedAccountBulk({
        ...updatedAccountBulk,
        [event.target.name]: event.target.value.trim(),
      });
    }
  };
  const onChangeNumber = (event) => {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      setUpdatedAccount({
        ...updatedAccount,
        [event.target.name]: event.target.value.trim(),
      });
      setUpdatedAccountBulk({
        ...updatedAccountBulk,
        [event.target.name]: event.target.value.trim(),
      });
    }
  };
  const onChangeCooldownAfterSession = (event) => {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      setUpdatedAccount({
        ...updatedAccount,
        cooldownAfterSession: ONE_HOUR_MILISECONDS * event.target.value.trim(),
        cooldownAfterSessionInHours: event.target.value.trim(),
      });
      setUpdatedAccountBulk({
        ...updatedAccountBulk,
        cooldownAfterSession: ONE_HOUR_MILISECONDS * event.target.value.trim(),
        cooldownAfterSessionInHours: event.target.value.trim(),
      });
    }
  };

  const onChangeGamesToFinish = (event) => {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      setUpdatedAccount({
        ...updatedAccount,

        amountOfGamesToFinishObject: { ...updatedAccount.amountOfGamesToFinishObject, amountOfGames: event.target.value.trim() },
      });
      setUpdatedAccountBulk({
        ...updatedAccountBulk,

        amountOfGamesToFinishObject: { ...updatedAccount.amountOfGamesToFinishObject, amountOfGames: event.target.value.trim() },
      });
    }
  };
  const onChangeLimitRes = (event) => {
    const re = /^[0-9.\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      setUpdatedAccount({
        ...updatedAccount,
        [event.target.name]: event.target.value.trim(),
      });
      setUpdatedAccountBulk({
        ...updatedAccountBulk,
        [event.target.name]: event.target.value.trim(),
      });
    }
  };

  const onChange = (event) => {
    setUpdatedAccount({
      ...updatedAccount,
      [event.target.name]: event.target.value.trim(),
    });
    setUpdatedAccountBulk({
      ...updatedAccountBulk,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const onChangeBlackWhiteListCheckbox = (event) => {
    setUpdatedAccount({
      ...updatedAccount,
      blackWhiteListChamp: { ...updatedAccount.blackWhiteListChamp, enabled: event.target.checked },
    });

    setUpdatedAccountBulk({
      ...updatedAccountBulk,
      blackWhiteListChamp: { ...updatedAccountBulk.blackWhiteListChamp, enabled: event.target.checked },
    });
  };

  const onChangeBlackWhiteListOption = (event) => {
    setUpdatedAccount({
      ...updatedAccount,
      blackWhiteListChamp: { ...updatedAccount.blackWhiteListChamp, option: event.target.value },
    });
    setUpdatedAccountBulk({
      ...updatedAccountBulk,
      blackWhiteListChamp: { ...updatedAccountBulk.blackWhiteListChamp, option: event.target.value },
    });
  };
  const onClickRemoveChampId = (id_) => {
    setUpdatedAccount({
      ...updatedAccount,
      blackWhiteListChamp: { ...updatedAccount.blackWhiteListChamp, ids: updatedAccount.blackWhiteListChamp.ids.filter((id) => Number(id) != Number(id_)) },
    });
    setUpdatedAccountBulk({
      ...updatedAccountBulk,
      blackWhiteListChamp: { ...updatedAccountBulk.blackWhiteListChamp, ids: updatedAccount.blackWhiteListChamp.ids.filter((id) => Number(id) != Number(id_)) },
    });
  };
  const onChangeBlackWhiteListIds = (event) => {
    if (!updatedAccount.blackWhiteListChamp.ids.includes(Number(event.target.value))) {
      setUpdatedAccount({
        ...updatedAccount,
        blackWhiteListChamp: { ...updatedAccount.blackWhiteListChamp, ids: [...updatedAccount.blackWhiteListChamp.ids, Number(event.target.value)] },
      });
      setUpdatedAccountBulk({
        ...updatedAccountBulk,
        blackWhiteListChamp: { ...updatedAccountBulk.blackWhiteListChamp, ids: [...updatedAccount.blackWhiteListChamp.ids, Number(event.target.value)] },
      });
    }
  };
  const [showingScreenshot, setShowingScreenshot] = useState(false);
  const [screenshotData, setScreenshotData] = useState("");
  const [loadingScreenshot, setIsLoadingScreenshot] = useState(false);
  const onClickShowScreenshot = async () => {
    setShowingScreenshot(!showingScreenshot);
    if (!screenshotData) {
      setIsLoadingScreenshot(true);
      let screenshotDataRequest = await axios.get(`/api/v1/accounts/${accounts[0]._id}/screenshot`);
      let screenshotData = screenshotDataRequest.data.data.screenshot;
      setScreenshotData(screenshotData);
      setIsLoadingScreenshot(false);
    }
  };
  const onClickReloadScreenshot = async () => {
    setIsLoadingScreenshot(true);
    let screenshotDataRequest = await axios.get(`/api/v1/accounts/${accounts[0]._id}/screenshot`);
    let screenshotData = screenshotDataRequest.data.data.screenshot;
    let screenshotDate = screenshotDataRequest.data.data.screenshotDate;
    setScreenshotData(screenshotData);
    setUpdatedAccount({ ...updatedAccount, screenshotDate: screenshotDate });
    setIsLoadingScreenshot(false);
  };
  const {
    label,
    summonerName,
    maxGames,
    maxGamesReached,
    cooldownAfterSession,
    lastTimePlayed,
    mode,
    completeAllMissions,
    limitResources,
    completeMissionUntil,
    blackWhiteListChamp,
    disenchantEmblems,
    flashOn,
    desiredBE,
    skipTutorial,
    desiredLevel,
    killExplorer,
    priority,
    disenchantLoot,
    randomLanes,
    disenchantFragChamps,
    region,
    disenchantFullChamps,
    missions,
    skipIfNameTaken,
    icon,
  } = updatedAccount;
  //   console.log(updatedAccount.cooldownAfterSession, updatedAccount.cooldownAfterSessionInHours);
  // console.log(updatedAccount);
  return (
    <div className={styles.popup}>
      <div className={styles.popupContent} style={{ width: showingScreenshot ? "400px" : "inherit" }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>{accounts.length > 1 ? <h1>Editing {accounts.length} accounts</h1> : <h1>Edit account</h1>}</div>
              {showCooldown && maxGamesReached ? (
                <span>
                  {lastTimePlayed + cooldownAfterSession > Date.now() ? (
                    <span>
                      Cooldown over in: <span style={{ color: "silver" }}>{formatPlaytime(lastTimePlayed + cooldownAfterSession - Date.now())}</span>
                    </span>
                  ) : (
                    "Ready"
                  )}
                </span>
              ) : (
                ""
              )}
              {accounts.length === 1 && <i onClick={onClickShowScreenshot} style={{ cursor: "pointer", fontSize: "20px", opacity: showingScreenshot ? "100%" : "50%" }} className="fa fa-desktop" aria-hidden="true"></i>}

              {showingScreenshot ? (
                <i style={{ cursor: "pointer", fontSize: "20px" }} className={`fas fa-sync-alt`} onClick={onClickReloadScreenshot}></i>
              ) : (
                <>
                  {accounts.length === 1 && (
                    <div className="tooltip" style={{ border: "none" }}>
                      <div style={{ fontSize: "1.1rem" }}>
                        {updatedAccount.missionTracker.completed}/{updatedAccount.completeMissionUntil}
                      </div>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        <label>Missions Completed</label>
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            {showingScreenshot ? (
              <>
                {loadingScreenshot ? <Spinner /> : <div style={{ display: "flex", justifyContent: "center" }}>{screenshotData != "-" ? <img style={{ marginBottom: "0.5em", width: "1000px" }} src={`data:image/jpeg;base64,${screenshotData}`} /> : <h2>No image available</h2>}</div>}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ textAlign: "center" }}>{longAgo(updatedAccount.screenshotDate)}</span>
                </div>
              </>
            ) : (
              <>
                <div className="credentials__info-row">
                  {accounts.length > 1 ? (
                    <>
                      <p>Changes:</p>
                      <span className={styles.spanChanges}>{!changes ? "No changes applied yet" : changes}</span>
                    </>
                  ) : (
                    <>
                      <div className="credentials__info-field">
                        <label>Sumoner Name</label>
                        <input onChange={onChange} name="summonerName" className="credentials__input" value={updatedAccount.summonerName}></input>
                      </div>
                      <div className="credentials__info-field">
                        <label>Password</label>
                        <input placeholder={`Empty for same password`} onChange={onChange} name="password" className="credentials__input" value={updatedAccount.password}></input>
                      </div>
                    </>
                  )}
                  <div className="credentials__info-field">
                    <label>Region</label>

                    <select className="credentials__input" name="region" onChange={onChange} defaultValue={updatedAccount.region}>
                      <option value="na">NA</option>
                      <option value="euw">EUW</option>
                      <option value="las">LAS</option>
                      <option value="lan">LAN</option>
                      <option value="tr">TR</option>
                      <option value="kr">KR</option>
                      <option value="br">BR</option>
                      <option value="ru">RU</option>
                      <option value="eune">EUNE</option>
                      <option value="jp">JP</option>
                      <option value="oce">OCE</option>
                      <option value="ph">PH</option>
                      <option value="sg">SG</option>
                      <option value="th">TH</option>
                      <option value="tw">TW</option>
                      <option value="vn">VN</option>
                    </select>
                  </div>
                </div>
                <div className="credentials__info-row" style={{ fontSize: "0.9rem" }}>
                  <div className="credentials__info-field">
                    <label>Disenchant Loot</label>
                    <input onChange={onChangeCheckBox} name="disenchantLoot" className="checkbox" type="checkbox" value={updatedAccount.disenchantLoot} defaultChecked={updatedAccount.disenchantLoot}></input>
                  </div>
                  <div className="credentials__info-field">
                    <label>Fragment Champions</label>
                    <input onChange={onChangeCheckBox} name="disenchantFragChamps" className="checkbox" type="checkbox" value={updatedAccount.disenchantFragChamps} defaultChecked={updatedAccount.disenchantFragChamps}></input>
                  </div>
                  <div className="credentials__info-field">
                    <label>Full Champions</label>
                    <input onChange={onChangeCheckBox} name="disenchantFullChamps" className="checkbox" type="checkbox" value={updatedAccount.disenchantFullChamps} defaultChecked={updatedAccount.disenchantFullChamps}></input>
                  </div>
                  {/* <div className="credentials__info-field">
                <label>Disenchant Emblems</label>
                <input onChange={onChangeCheckBox} name="disenchantEmblems" className="checkbox" type="checkbox" value={updatedAccount.disenchantEmblems} defaultChecked={updatedAccount.disenchantEmblems}></input>
              </div> */}
                  <div className="credentials__info-field">
                    <label>Disenchant Eternal</label>
                    <input onChange={onChangeCheckBox} name="disenchantEternal" className="checkbox" type="checkbox" value={updatedAccount.disenchantEternal} defaultChecked={updatedAccount.disenchantEternal}></input>
                  </div>
                </div>
                <div className="credentials__info-row">
                  <div className="credentials__info-field">
                    <label>Mision 1</label>
                    <select className="credentials__input" name="1" onChange={onChangeMissions} defaultValue={updatedAccount.missions["1"]}>
                      <option value="lux">Lux</option>
                      <option value="masteryi">Master Yi</option>
                      <option value="brand">Brand</option>
                      <option value="darius">Darius</option>
                      <option value="missfortune">Miss Fortune</option>
                    </select>
                  </div>
                  <div className="credentials__info-field">
                    <label>Mision 2</label>
                    <select className="credentials__input" name="2" onChange={onChangeMissions} defaultValue={updatedAccount.missions["2"]}>
                      <option value="tristana">Tristana</option>
                      <option value="ezreal">Ezreal</option>
                      <option value="caitlyn">Caitlyn</option>
                    </select>
                  </div>
                  <div className="credentials__info-field">
                    <label>Mision 3</label>
                    <select className="credentials__input" name="3" onChange={onChangeMissions} defaultValue={updatedAccount.missions["3"]}>
                      <option value="illaoi">Illaoi</option>
                      <option value="riven">Riven</option>
                      <option value="garen">Garen</option>
                    </select>
                  </div>
                </div>

                <div className="credentials__info-row">
                  <div className="credentials__info-field">
                    <label>Mision 4</label>
                    <select className="credentials__input" name="4" onChange={onChangeMissions} defaultValue={updatedAccount.missions["4"]}>
                      <option value="ziggs">Ziggs</option>
                      <option value="morgana">Morgana</option>
                      <option value="seraphine">Seraphine</option>
                    </select>
                  </div>
                  <div className="credentials__info-field">
                    <label>Mision 5</label>
                    <select className="credentials__input" name="5" onChange={onChangeMissions} defaultValue={updatedAccount.missions["5"]}>
                      <option value="thresh">Thresh</option>
                      <option value="sona">Sona</option>
                      <option value="nami">Nami</option>
                    </select>
                  </div>
                  <div className="credentials__info-field">
                    <label>Mision 6</label>
                    <select className="credentials__input" name="6" onChange={onChangeMissions} defaultValue={updatedAccount.missions["6"]}>
                      <option value="ekko">Ekko</option>
                      <option value="fizz">Fizz</option>
                      <option value="talon">Talon</option>
                    </select>
                  </div>
                </div>
                <div className="credentials__info-row">
                  <div className="credentials-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Desired Level</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        0 for disabled.
                      </span>
                    </div>
                    <input onChange={onChangeLevel} name="desiredLevel" className="credentials__input" value={updatedAccount.desiredLevel}></input>
                  </div>

                  <div className="credentials-field">
                    <div>
                      <label>Desired Percentage</label>
                    </div>
                    <input onChange={onChangeLevel} name="desiredPercentage" className="credentials__input" value={updatedAccount.desiredPercentage}></input>
                  </div>
                  <div className="credentials-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Desired BE</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        0 for disabled.
                      </span>
                    </div>
                    <input onChange={onChangeNumber} name="desiredBE" className="credentials__input" value={updatedAccount.desiredBE}></input>
                  </div>
                  <div className="credentials-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Priority</label>
                      <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                        Bot will choose the higher priority when looking for accounts to play.
                      </span>
                    </div>
                    <input onChange={onChangeNumber} name="priority" className="credentials__input" value={updatedAccount.priority}></input>
                  </div>
                </div>
                <div className="credentials__info-row" style={{ fontSize: "0.95rem" }}>
                  {/* <div className='credentials__info-field'>
								<div className='tooltip' style={{ border: 'none' }}>
									<label>Skip if name taken</label>
									<span style={{ fontSize: '0.8rem' }} className='tooltiptext'>
										Bot will change accounts if summoner name is taken and flagged account as such.
									</span>
								</div>
								<input onChange={onChangeCheckBox} name='skipIfNameTaken' className='checkbox' type='checkbox' value={updatedAccount.skipIfNameTaken} defaultChecked={updatedAccount.skipIfNameTaken}></input>
							</div> */}
                  {/* <div className='credentials__info-field'>
								<label>Kill explorer</label>

								<input onChange={onChangeCheckBox} name='killExplorer' className='checkbox' type='checkbox' value={updatedAccount.killExplorer} defaultChecked={updatedAccount.killExplorer}></input>
							</div> */}
                  <div className="credentials__info-field">
                    <label>Use Custom Name</label>

                    <input onChange={onChangeCheckBox} name="useCustomName" className="checkbox" type="checkbox" value={updatedAccount.useCustomName} defaultChecked={updatedAccount.useCustomName}></input>
                  </div>
                  <div className="tooltip" style={{ border: "none" }}>
                    <label>Icon Id</label>
                    <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                      Icon id to set after account reaches desired level.
                    </span>
                    <div className="credentials-field">
                      <input onChange={onChangeNumber} name="icon" className="credentials__input" value={updatedAccount.icon}></input>
                    </div>
                  </div>
                  {/* <div className="credentials__info-field">
                <label>Disenchant Eternal</label>
                <input onChange={onChangeCheckBox} name="disenchantEternal" className="checkbox" type="checkbox" value={updatedAccount.disenchantEternal} defaultChecked={updatedAccount.disenchantEternal}></input>
              </div> */}
                  <div className="credentials__info-field">
                    <label>Disenchant Emblems</label>
                    <input onChange={onChangeCheckBox} name="disenchantEmblems" className="checkbox" type="checkbox" value={updatedAccount.disenchantEmblems} defaultChecked={updatedAccount.disenchantEmblems}></input>
                  </div>
                  {updatedAccount.disenchantEmblems && (
                    <div className="credentials__info-field">
                      <label>Emblems Option</label>
                      <select className="credentials__input" name="disenchantEmblemsOption" onChange={onChange} defaultValue={updatedAccount.disenchantEmblemsOption}>
                        <option value="be">BE</option>
                        <option value="orb">Orb</option>
                        {/* <option value="oe">Orange Essence</option> */}
                      </select>
                    </div>
                  )}
                  {/* <div className='credentials__info-field'>
                <label>Space Groove Team</label>
                <select className='credentials__input' name='spaceGrooveOption' onChange={onChange} defaultValue={updatedAccount.spaceGrooveOption}>
                  <option value='TheGroove_series'>The Groove</option>
                  <option value='HarshVibes_series'>Harsh Vibes</option>
                  
                </select>
              </div> */}
                </div>
                <div className="credentials__info-row">
                  <div className="credentials__info-field">
                    <label>Skip Tutorial</label>
                    <input onChange={onChangeCheckBox} name="skipTutorial" className="checkbox" type="checkbox" value={updatedAccount.skipTutorial} defaultChecked={updatedAccount.skipTutorial}></input>
                  </div>
                  <div className="credentials__info-field">
                    <label>Flash on</label>
                    <select className="credentials__input" name="flashOn" onChange={onChange} defaultValue={updatedAccount.flashOn}>
                      <option value="d">D</option>
                      <option value="f">F</option>
                      <option value="random">Random</option>
                    </select>
                  </div>
                  <div className="credentials__info-field">
                    <label>Disenchant Gem</label>
                    <input onChange={onChangeCheckBox} name="disenchantGem" className="checkbox" type="checkbox" value={updatedAccount.disenchantGem} defaultChecked={updatedAccount.disenchantGem}></input>
                  </div>
                  {updatedAccount.disenchantGem && (
                    <div className="credentials__info-field">
                      <label>Gem Option</label>
                      <select className="credentials__input" name="disenchantGemOption" onChange={onChange} defaultValue={updatedAccount.disenchantGemOption}>
                        <option value="be">BE</option>
                        <option value="skin">Skin</option>
                        <option value="oe">Orange Essence</option>
                      </select>
                    </div>
                  )}
                  {/* <div className='credentials__info-field'>
								<label>Disenchant Emblems</label>
								<input onChange={onChangeCheckBox} name='disenchantEmblems' className='checkbox' type='checkbox' value={updatedAccount.disenchantEmblems} defaultChecked={updatedAccount.disenchantEmblems}></input>
							</div> */}

                  {/* <div className='credentials__info-field'>
								<label>Free Name Change</label>
								<input onChange={onChangeCheckBox} name='freeNameChange' className='checkbox' type='checkbox' value={updatedAccount.freeNameChange} defaultChecked={updatedAccount.freeNameChange}></input>
							</div> */}
                </div>
                <div className="credentials__info-row">
                  <div className="credentials__info-field">
                    <label>Max Games per session</label>
                    <input onChange={onChangeNumber} name="maxGames" className="credentials__input" value={updatedAccount.maxGames}></input>
                  </div>

                  <div className="credentials__info-field">
                    <label>Cooldown (hours)</label>
                    <input onChange={onChangeCooldownAfterSession} name="cooldownAfterSession" className="credentials__input" value={updatedAccount.cooldownAfterSessionInHours}></input>
                  </div>

                  <div className="credentials__info-field">
                    <div className="tooltip" style={{ border: "none" }}>
                      <label>Games To Finish</label>
                      {updatedAccount.amountOfGamesToFinishObject.amountOfGames > 0 && accounts.length == 1 && (
                        <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                          {updatedAccount.amountOfGamesToFinishObject.amountOfGames + updatedAccount.amountOfGamesToFinish.gamesAmountWhenSet - updatedAccount.nOfGames} games left to complete.
                          {/* {updatedAccount.amountOfGamesToFinishObject.amountOfGames} + {updatedAccount.amountOfGamesToFinish.gamesAmountWhenSet} - {updatedAccount.nOfGames} , */}
                        </span>
                      )}
                    </div>

                    <input onChange={onChangeGamesToFinish} name="amountOfGamesToFinishObject" className="credentials__input" value={updatedAccount.amountOfGamesToFinishObject.amountOfGames}></input>
                  </div>
                </div>
                <div className="credentials__info-row">
                  <div className="credentials__info-field" style={{ fontSize: "0.9rem" }}>
                    <label>Restart Ingame After (sec)</label>
                    <input onChange={onChangeNumber} name="restartIngameAfter" className="credentials__input" value={updatedAccount.restartIngameAfter}></input>
                  </div>

                  <div className="credentials__info-field" style={{ fontSize: "0.9rem" }}>
                    <label>Wait Before Pick Champ (sec)</label>
                    <input onChange={onChangeNumber} name="waitBeforePickChamp" className="credentials__input" value={updatedAccount.waitBeforePickChamp}></input>
                  </div>

                  <div className="credentials__info-field">
                    <label>Desired Tutos</label>
                    <input onChange={onChangeNumber} name="desiredTutos" className="credentials__input" value={updatedAccount.desiredTutos}></input>
                  </div>
                </div>
                <div className="credentials__info-row">
                  <div className="credentials__info-field">
                    <label>Mode:</label>
                    <select className="credentials__input" name="mode" onChange={onChange} defaultValue={updatedAccount.mode}>
                      <option value="normal">Normal</option>
                      <option value="disenchant">Disenchant</option>
                    </select>
                  </div>

                  <div className="credentials__info-field">
                    <label>League Client Mode:</label>
                    <select className="credentials__input" name="leagueClientMode" onChange={onChange} defaultValue={updatedAccount.leagueClientMode}>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                    </select>
                  </div>
                  <div className="credentials__info-field">
                    <label>Label</label>
                    <input onChange={onChange} name="label" className="credentials__input" value={updatedAccount.label}></input>
                  </div>
                  {/* <div className="credentials__info-field">
                    <label>Black/White list Champs</label>
                    <input onChange={onChangeBlackWhiteListCheckbox} name="blackWhiteListChamp" className="checkbox" type="checkbox" value={updatedAccount.blackWhiteListChamp.enabled} defaultChecked={updatedAccount.blackWhiteListChamp.enabled}></input>
                  </div> */}
                  {/* {updatedAccount.blackWhiteListChamp.enabled && (
                    <>
                      <div className="credentials__info-field">
                        <label>Option</label>
                        <select className="credentials__input" name="blackWhiteListChamp" onChange={onChangeBlackWhiteListOption} defaultValue={updatedAccount.blackWhiteListChamp.option}>
                          <option value="whitelist">Whitelist</option>
                          <option value="blacklist">Blacklist</option>
                        </select>
                      </div>
                      <div className="credentials__info-field">
                        <label>Champions</label>
                        <select className="credentials__input" name="blackWhiteListChamp" onChange={onChangeBlackWhiteListIds} value={updatedAccount.blackWhiteListChamp.ids}>
                          <option value={1}>Annie</option>
                          <option value={42}>Corki</option>
                          <option value={29}>Twitch</option>
                          <option value={222}>Jinx</option>
                          <option value={110}>Varus</option>
                          <option value={40}>Janna</option>
                          <option value={126}>Jayce</option>
                          <option value={202}>Jhin</option>
                          <option value={134}>Syndra</option>
                          <option value={45}>Veigar</option>
                          <option value={115}>Ziggs</option>
                          <option value={13}>Ryze</option>
                          <option value={235}>Senna</option>
                          <option value={133}>Quinn</option>
                          <option value={122}>Darius</option>
                          <option value={11}>Master Yi</option>
                          <option value={7}>Le Blanc</option>
                          <option value={4}>Twisted Fate</option>
                          <option value={34}>Anivia</option>
                          <option value={145}>Kai'Sa</option>
                          <option value={61}>Orianna</option>
                          <option value={203}>Kindred</option>
                          <option value={127}>Lissandra</option>
                          <option value={112}>Viktor</option>
                          <option value={163}>Taliyah</option>
                          <option value={429}>Kalista</option>
                          <option value={161}>Velkoz</option>
                          <option value={43}>Karma</option>
                          <option value={412}>Thresh</option>
                          <option value={101}>Xerath</option>
                          <option value={432}>Bard</option>
                          <option value={17}>Teemo</option>
                          <option value={90}>Malzahar</option>
                          <option value={9}>Fiddlesticks</option>
                          <option value={30}>Karthus</option>
                          <option value={25}>Morgana</option>
                          <option value={267}>Nami</option>
                          <option value={8}>Vladimir</option>
                          <option value={104}>Graves</option>
                          <option value={51}>Caitlyn</option>
                          <option value={427}>Ivern</option>
                          <option value={69}>Cassiopeia</option>
                          <option value={498}>Xayah</option>
                          <option value={18}>Tristana</option>
                          <option value={81}>Ezreal</option>
                          <option value={21}>Miss Fortune</option>
                          <option value={85}>Kennen</option>
                          <option value={15}>Sivir</option>
                          <option value={67}>Vayne</option>
                          <option value={119}>Draven</option>
                          <option value={22}>Ashe</option>
                          <option value={103}>Ahri</option>
                          <option value={37}>Sona</option>
                          <option value={16}>Soraka</option>
                          <option value={35}>Shaco</option>
                          <option value={76}>Nidalee</option>
                          <option value={86}>Garen</option>
                          <option value={150}>Gnar</option>
                          <option value={63}>Brand</option>
                          <option value={92}>Riven</option>
                          <option value={523}>Aphelios</option>
                          <option value={136}>Aurelion Sol</option>
                          <option value={268}>Azir</option>
                          <option value={143}>Zyra</option>
                          <option value={142}>Zoe</option>
                          <option value={26}>Zilean</option>
                          <option value={420}>Illaoi</option>
                          <option value={99}>Lux</option>
                          <option value={117}>Lulu</option>
                          <option value={236}>Lucian</option>
                          <option value={96}>KogMaw</option>
                          <option value={518}>Neeko</option>
                        </select>
                      </div>
                    </>
                  )} */}
                </div>
                {/* {updatedAccount.blackWhiteListChamp.enabled && (
                  <div className="credentials__info-row">
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", gridGap: "20px", width: "100%" }}>
                      {updatedAccount.blackWhiteListChamp.ids.map((id) => (
                        <div key={id} style={{ fontSize: ".9rem" }}>
                          {getChampionName(id)}
                          <i className="fas fa-times" style={{ cursor: "pointer", fontSize: ".8rem", marginLeft: ".25em", color: "rgb(151, 0, 0)" }} onClick={() => onClickRemoveChampId(id)}></i>
                        </div>
                      ))}
                    </div>
                  </div>
                )} */}
                <div className="credentials__info-row">
                  <div className="credentials__info-field">
                    <label>Limit Resources</label>
                    <input onChange={onChangeLimitRes} name="limitResources" className="credentials__input" value={updatedAccount.limitResources}></input>
                  </div>
                  <div className="credentials__info-field">
                    <label>Complete All Missions</label>
                    <input onChange={onChangeCheckBox} name="completeAllMissions" className="checkbox" type="checkbox" value={updatedAccount.completeAllMissions} defaultChecked={updatedAccount.completeAllMissions}></input>
                  </div>
                  {updatedAccount.completeAllMissions && (
                    <div className="credentials__info-field">
                      <label>Missions to Complete</label>
                      <input onChange={onChangeNumber} name="completeMissionUntil" className="credentials__input" value={updatedAccount.completeMissionUntil}></input>
                    </div>
                  )}
                </div>
                <div className="credentials__info-row">
                  <div className="credentials__info-field">
                    <label>Use Private Party</label>
                    <input onChange={onChangeCheckBox} name="usePrivateParty" className="checkbox" type="checkbox" value={updatedAccount.usePrivateParty} defaultChecked={updatedAccount.usePrivateParty}></input>
                  </div>
                  <div className="credentials__info-field">
                    <label>Temp Number</label>
                    <input onChange={onChangeNumber} name="crazyNumber" className="credentials__input" value={updatedAccount.crazyNumber}></input>
                  </div>
                </div>
              </>
            )}
            <div style={{ margin: "25px 0px 15px 0px" }} className="credentials__info-row">
              <div className={styles.buttonGroup}>
                <div style={{ width: "125px", marginLeft: "0px" }} className="button aqua-green" onClick={onClickUpdate}>
                  <span>Update Account{accounts.length > 1 && "s"}</span>
                </div>
                <div style={{ width: "120px", marginLeft: "10px", backgroundColor: "coral" }} className="button" onClick={onClickFinishAccount}>
                  <span>Finish Account{accounts.length > 1 && "s"}</span>
                </div>
              </div>
              <div className="button aqua-green" onClick={onClickCancel}>
                <span>Close</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, { updateAccount, updateAccountQuotaFinished })(EditAccount);
