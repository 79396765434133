import axios from "../utils/axiosInstance";
import { GET_TASKS, DELETE_TASK, ADD_TASK, TASKS_FAIL, EDIT_TASK, TOGGLE_ACTIVE } from "./types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
// Login User
export const getTasks = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/task");

    dispatch({ type: GET_TASKS, payload: res.data.data });
  } catch (err) {
    dispatch({ type: TASKS_FAIL });
  }
};

// Login User
export const addTask = (task) => async (dispatch) => {
  try {
    const res = await axios.post("/api/v1/task", task, config);

    dispatch({ type: ADD_TASK, payload: res.data.data });
  } catch (err) {
    dispatch({ type: TASKS_FAIL });
  }
};

// Login User
export const deleteTask = (taskId) => async (dispatch) => {
  try {
    await axios.delete(`/api/v1/task/${taskId}`);
    dispatch({ type: DELETE_TASK, payload: taskId });
  } catch (err) {
    dispatch({ type: TASKS_FAIL });
  }
};

// Login User
export const toggleActive = (taskId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/task/${taskId}/toggle-active`);
    dispatch({ type: TOGGLE_ACTIVE, payload: res.data.data });
  } catch (err) {
    dispatch({ type: TASKS_FAIL });
  }
};

// Login User
export const editTask = (task) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/task/${task._id}`, task, config);
    dispatch({ type: EDIT_TASK, payload: res.data.data });
  } catch (err) {
    dispatch({ type: TASKS_FAIL });
  }
};
