import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { register } from "../actions/authActions";
import Spinner from "./Spinner";
import { useHistory } from "react-router-dom";
import Recaptcha from "react-google-recaptcha";

const Login = ({ props, auth: { isAuthenticated, loading, error }, register }) => {
  const captchaRef = useRef(null);
  let history = useHistory();
  useEffect(() => {
    if (isAuthenticated) {
      props.history.push("/dashboard");
    }

    // eslint-disable-next-line
  }, [error, isAuthenticated, props]);

  const [registerUser, setRegisterUser] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { username, password, confirmPassword } = registerUser;
  const onChange = (e) => setRegisterUser({ ...registerUser, [e.target.name]: e.target.value.trim() });

  const RegisterUser = async (e) => {
    setErrorMessage("");
    const token = captchaRef.current.getValue();
    if (token.length == 0) {
      setErrorMessage("Captcha verification failed.");
    } else if (username === "" || password === "" || confirmPassword === "") {
      setErrorMessage("Please fill in all fields");
    } else if (password != confirmPassword) {
      setErrorMessage("Passwords do not match");
    } else {
      setIsLoggingIn(true);
      const loginResponse = await register({ username, password, captchaToken: token });

      if (loginResponse != "SUCCESS") {
        setErrorMessage(loginResponse);
        setRegisterUser({ ...registerUser, password: "", confirmPassword: "" });
      }
      setIsLoggingIn(false);
      return;
    }
    captchaRef.current.reset();
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      RegisterUser();
    }
  };
  return (
    <div className="main" style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "90%" }}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="login" onKeyDown={handleKeyDown}>
            <div className="login__container">
              <div className="login__title">Create your new account</div>
              <form className="login__form">
                <div className="login__form__fields">
                  <div className="login__form__field">
                    <input type="username" name="username" value={username} onChange={onChange} placeholder="Username" autoFocus={true} />
                  </div>
                  <div className="login__form__field">
                    <input type="password" name="password" id="password" value={password} onChange={onChange} placeholder="Password" />
                  </div>
                  <div className="login__form__field">
                    <input type="password" name="confirmPassword" id="confirmPassword" value={confirmPassword} onChange={onChange} placeholder="Confirm password" />
                  </div>
                </div>
                <div className="button aqua-green" onClick={RegisterUser} style={{ width: "auto", marginTop: "1em" }}>
                  {isLoggingIn ? (
                    <div className="loadingio-spinner-eclipse-gr3e527dds">
                      <div className="ldio-vbb5fe2lwi">
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <span>Create User</span>
                  )}
                </div>

                {errorMessage && (
                  <div style={{ alignItems: "center", marginTop: "1em", padding: "15px", border: "1px solid #302f4e", borderRadius: "10px", backgroundColor: "#3b3e59" }}>
                    <i style={{ fontSize: "1.1rem", color: "#e70000" }} className="far fa-times-circle"></i>
                    <span style={{ marginLeft: "1em", color: "#dedede" }}>{errorMessage}</span>
                  </div>
                )}
                <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
                  <Recaptcha ref={captchaRef} sitekey={"6Lf0huEkAAAAAPb4PUgCGNFd-xTGacuR4COEjmdv"} />
                </div>
                <div style={{ justifyContent: "space-around" }}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <p style={{ margin: "20px 0px 0px 0px" }}>
                      Already a member?{" "}
                      <span onClick={() => history.push("/login")} style={{ cursor: "pointer", fontWeight: "bold" }}>
                        Sign In
                      </span>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <h4>
              More amazing software at{" "}
              <a target="__blank" style={{ color: "#9897bb" }} href="https://www.elitepvpers.com">
                https://www.elitepvpers.com
              </a>
              , visit them!
            </h4>
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  props,
  auth: state.auth,
});
export default connect(mapStateToProps, { register })(Login);
