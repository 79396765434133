import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Spinner from '../Spinner';
import { deleteName } from '../../actions/customNamesActions';

const CustomNamesItem = ({ name, deleteName }) => {
	const onClickDelete = () => {
		deleteName(name.summonerName);
	};
	return (
		<>
			<tr>
				<td>{name.summonerName}</td>
				<td>
					<div>
						<i onClick={onClickDelete} style={{ cursor: 'pointer', color: 'rgb(151, 0, 0)' }} className='fas fa-times-circle'></i>
					</div>
				</td>
			</tr>
		</>
	);
};

export default connect(null, { deleteName })(CustomNamesItem);
