import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddUsers from './AddUsers';
import { getUsers } from '../../actions/authActions';
import Users from './Users';
import Spinner from '../Spinner';

const UsersHandler = ({ user, props, getUsers }) => {
  const [users, setUsers] = useState(false);

  const fetchUsers = async () => {
    setUsers(await getUsers());
  };

  useEffect(() => {
    if (user && user.role === 'user') {
      props.history.push('/');
    } else {
      fetchUsers();
    }
  }, []);
  return (
    <>
      {user && users ? (
        <div className='main'>
          <>
            <AddUsers />
            <Users users={users} />
          </>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

const mapStateToProps = (state, props) => ({
  props,
  user: state.auth.user,
});

export default connect(mapStateToProps, { getUsers })(UsersHandler);
