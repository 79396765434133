import React from "react";
import styles from "./Servers.module.scss";
const Spinner = () => {
  return (
    <div className="spinner">
      <div className={styles.loadingio_spinner_spin_nx4ifpok5e}>
        <div className={styles.ldio_w5fi8i3miu8}>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
