import React from 'react';

const Spinner = () => {
  return (
    <div className='spinner'>
      <div className='loadingio-spinner-spinner-uh4mw6ckb98'>
        <div className='ldio-7vi82ejf5rx'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
