import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { getMyAccounts, pauseAccounts, stopAccounts, restartAccounts, powerOffAccounts, powerOnAccounts, unpauseAccounts, turnOffVms, restartVms, removeAccountsFromDashboard } from "../../actions/accountActions";
import { setPrioritizeHigherLvl } from "../../actions/authActions";
import Spinner from "../Spinner";
import AccountItem from "./AccountItem";
import Skins from "./Skins";
import EditAccount from "./EditAccount";
import formatPlaytime from "../../utils/formatPlaytimeDashboard";
import styles from "./Dashboard.module.scss";
import Overview from "../Overview/Overview";
// import skinsData from "../../utils/skinsData";
import setSkinName from "../../utils/setSkinName";

const Accounts = ({
  user: { dashboardColumns, minimumLevelForQuotaFinishedCount, skinLanguage, showHasActiveBoost, showMac, showNameSkinSearch, combineMissionWaitCount, license, showLastGamePlayed, showLimitFps, showRp, showDesiredPercentage, showCountry, showOrangeEssence, showCooldown, showServerId, showBotVersion, showVmCloneNumber, showCheckerStatus, showStartToFinishTime, showFreeNameChange, showDesiredBE, showMode, showFinishStartDates, prioritizeHigherLvls, showLimitRes, showFlashOn, showPriority, showMissions, showUserTaken, showIps, showDisenchant, fields },
  userRole,
  accounts,
  error,
  getMyAccounts,
  removeAccountsFromDashboard,
  setPrioritizeHigherLvl,
  pauseAccounts,
  stopAccounts,
  restartAccounts,
  powerOffAccounts,
  powerOnAccounts,
  unpauseAccounts,
  restartVms,
  turnOffVms,
  skinsData,
}) => {
  const [sorts, setSorts] = useState(
    JSON.parse(localStorage.getItem("sort-dash"))
      ? JSON.parse(localStorage.getItem("sort-dash"))
      : {
          showDisenchant: Number(0),
          currentLevel: Number(0),
          blueEssence: Number(0),
          rp: Number(0),

          orangeEssence: Number(0),
          nOfGames: Number(0),
          skins: Number(0),
          status: Number(0),
          playtime: Number(0),
          region: Number(0),
          isActive: Number(0),
          summonerName: Number(0),
          priority: Number(0),
          showIps: Number(0),
          flashOn: Number(0),
          limitResources: Number(0),
          vmNumber: Number(0),
          botVersion: Number(0),
          gameVersion: Number(0),
          desiredBE: Number(0),
          lastTimePlayed: Number(0),
          hasBoostActive: Number(0),
          cooldownAfterSession: Number(0),
        }
  );
  const formatByPlaytime2 = (p) => {
    return p;
  };
  const [change, setChange] = useState(true);
  const [sortedAccs, setSortedAccs] = useState(accounts);
  const [isPopupShowing, setIsPopupShowing] = useState({ accounts: null, showing: false });
  const [isPopupShowing2, setIsPopupShowing2] = useState({ skins: null, showing: false });

  const [selectedIds, setSelectedIds] = useState([]);
  const [label, setLabel] = useState("-");
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectionOn, setSelectionOn] = useState(false);
  const [filterByRegion, setFilterByRegion] = useState(
    JSON.parse(localStorage.getItem("filters-region"))
      ? JSON.parse(localStorage.getItem("filters-region"))
      : {
          na: true,
          las: true,
          euw: true,
          lan: true,
          br: true,
          kr: true,
          oce: true,
          jp: true,
          eune: true,
          ru: true,
          tr: true,
          ph: true,
          sg: true,
          th: true,
          tw: true,
          vn: true,

          BE: true,
          nonBE: true,
        }
  );
  const [filterByStatus, setFilterByStatus] = useState(
    JSON.parse(localStorage.getItem("filters-status"))
      ? JSON.parse(localStorage.getItem("filters-status"))
      : {
          ingame: true,
          "in-queue": true,
          tutorial: true,
          online: true,
          "quota-finished": true,
          offline: true,
        }
  );

  const [statsPerRegion, setStatsPerRegion] = useState({
    na: { active: 0, finished: 0, total: 0 },
    las: { active: 0, finished: 0, total: 0 },
    euw: { active: 0, finished: 0, total: 0 },
    lan: { active: 0, finished: 0, total: 0 },
    br: { active: 0, finished: 0, total: 0 },
    kr: { active: 0, finished: 0, total: 0 },
    oce: { active: 0, finished: 0, total: 0 },
    jp: { active: 0, finished: 0, total: 0 },
    eune: { active: 0, finished: 0, total: 0 },
    ru: { active: 0, finished: 0, total: 0 },
    tr: { active: 0, finished: 0, total: 0 },

    ph: { active: 0, finished: 0, total: 0 },
    sg: { active: 0, finished: 0, total: 0 },
    th: { active: 0, finished: 0, total: 0 },
    tw: { active: 0, finished: 0, total: 0 },
    vn: { active: 0, finished: 0, total: 0 },

    BE: 0,
    nonBE: 0,
  });
  const [skinSearch, setSkinSearch] = useState("");
  const [summonerNameSearch, setSummonerNameSearch] = useState("");
  const eraseSummonerNameSearch = () => {
    setSummonerNameSearch("");
    setChange(!change);
  };
  const eraseSkinSearch = () => {
    setSkinSearch("");
    setChange(!change);
  };
  const [overviewStats, setOverviewStats] = useState(null);
  const formatAccountsByRegion = (accounts) => {
    let filteredAccounts = [];
    let lastLabel = "";
    let labels = [];
    let statsPerRegion_ = {
      vn: { active: 0, finished: 0, total: 0 },
      tw: { active: 0, finished: 0, total: 0 },
      th: { active: 0, finished: 0, total: 0 },
      sg: { active: 0, finished: 0, total: 0 },
      ph: { active: 0, finished: 0, total: 0 },
      na: { active: 0, finished: 0, total: 0 },
      las: { active: 0, finished: 0, total: 0 },
      euw: { active: 0, finished: 0, total: 0 },
      lan: { active: 0, finished: 0, total: 0 },
      br: { active: 0, finished: 0, total: 0 },
      kr: { active: 0, finished: 0, total: 0 },
      oce: { active: 0, finished: 0, total: 0 },
      jp: { active: 0, finished: 0, total: 0 },
      eune: { active: 0, finished: 0, total: 0 },
      ru: { active: 0, finished: 0, total: 0 },
      tr: { active: 0, finished: 0, total: 0 },
      BE: 0,
      nonBE: 0,
    };
    let quotaReached = 0;
    let bannedAcc = 0;
    let activeBots = 0;
    let ingameBots = 0;
    let offlineBots = 0;
    let inQueueBots = 0;
    let LPQAccounts = 0;
    for (var i = 0; i < accounts.length; i++) {
      let acc = accounts[i];
      // Overview
      // Labels
      if (acc.label != lastLabel && acc.label != "-") {
        lastLabel = acc.label;
        if (!labels.includes(acc.label)) {
          labels.push(acc.label);
        }
      }

      if (combineMissionWaitCount) {
        if (acc.currentLevel >= minimumLevelForQuotaFinishedCount && (acc.status === "quota-finished" || acc.status === "mission-wait")) {
          quotaReached++;
        }
      } else {
        if (acc.currentLevel >= minimumLevelForQuotaFinishedCount && acc.status === "quota-finished") {
          quotaReached++;
        }
      }
      // Banned Account
      if (acc.status === "banned") {
        bannedAcc++;
      }
      // ActiveBots
      if (acc.isActive) {
        activeBots++;
      }
      // Ingame Bots
      if (acc.status === "ingame" || acc.status === "tutorial") {
        ingameBots++;
      }
      // Offline bots
      if (!acc.isActive) {
        offlineBots++;
      }
      // InQueue Bots
      if (acc.status === "in-queue" || acc.status === "low-prio-wait") {
        inQueueBots++;
      }
      // LPQ accounts
      if (acc.matchmakingStatus === "low-prio-queue") {
        LPQAccounts++;
      }
      // Accounts

      // if ((filterByStatus[acc.status] || filterByStatus[acc.status] === undefined) && filterByRegion[acc.region]) {
      //   if (filterByRegion["BE"] && acc.disenchantLoot) {
      //     if (acc.isActive) {
      //       statsPerRegion_[acc.region]["active"] += 1;
      //     }
      //     if (acc.status == "quota-finished") {
      //       statsPerRegion_[acc.region]["finished"] += 1;
      //     }
      //     filteredAccounts.push(acc);
      //     statsPerRegion_["BE"] += 1;
      //     statsPerRegion_[acc.region]["total"] += 1;
      //   } else if (filterByRegion["nonBE"] && !acc.disenchantLoot) {
      //     if (acc.isActive) {
      //       statsPerRegion_[acc.region]["active"] += 1;
      //     }
      //     if (acc.status == "quota-finished") {
      //       statsPerRegion_[acc.region]["finished"] += 1;
      //     }
      //     filteredAccounts.push(acc);
      //     statsPerRegion_["nonBE"] += 1;
      //     statsPerRegion_[acc.region]["total"] += 1;
      //   }
      // }
      // Skin Search
      if (showNameSkinSearch && skinSearch != "") {
        let foundSkin = false;
        for (let a = 0; a < acc.skins.length; a++) {
          let skin = acc.skins[a];
          // let skinName = skin.name.toLowerCase();
          //
          let skinName = "";
          if (typeof skin === "string") {
            skinName = skin;
          } else {
            if (skinsData[skin.id]) {
              if (skinLanguage != "-") {
                skinName = skinsData[skin.id][skinLanguage];
              } else {
                skinName = skin.name;
              }
            } else {
              skinName = String(skin.id);
            }
          }
          skinName = skinName.toLowerCase();
          //
          for (let skinSearch_ of skinSearch.toLowerCase().split(",")) {
            // console.log(skinName);
            let rarity = skin.rarity.toLowerCase();
            skinName = skinName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if (skinName.includes(skinSearch_.toLowerCase()) || rarity.includes(skinSearch_.toLowerCase())) {
              foundSkin = true;
              break;
            }
          }
        }
        if (!foundSkin) {
          continue;
        }
      }

      // Status check
      if (!filterByStatus[acc.status] && filterByStatus[acc.status] !== undefined) {
        continue;
      }
      // Region check
      if (!filterByRegion[acc.region]) {
        continue;
      }

      // BE Accounts
      if (!filterByRegion["BE"] && acc.disenchantLoot) {
        continue;
      }
      // Non BE Accounts
      if (!filterByRegion["nonBE"] && !acc.disenchantLoot) {
        continue;
      }
      // Name Search
      if (showNameSkinSearch && summonerNameSearch != "") {
        if (!acc.summonerName.toLowerCase().includes(summonerNameSearch.toLowerCase())) {
          continue;
        }
      }
      // Label
      if (label != "-" && label != acc.label) {
        continue;
      }
      // Stats
      if (acc.isActive) {
        statsPerRegion_[acc.region]["active"] += 1;
      }
      if (acc.status == "quota-finished") {
        statsPerRegion_[acc.region]["finished"] += 1;
      }
      if (acc.disenchantLoot) {
        statsPerRegion_["BE"] += 1;
      } else {
        statsPerRegion_["nonBE"] += 1;
      }

      statsPerRegion_[acc.region]["total"] += 1;

      filteredAccounts.push(acc);
    }

    // Set Labels
    setLabels(labels);

    let validLicenses = license.filter((license) => Date.now() <= license.expireTime && Date.now() >= license.licenseStart);
    let licensesCountNumber = validLicenses.map((lic) => lic.licenses).reduce((a, b) => Number(a) + Number(b), 0);
    let licensesCount = licensesCountNumber > 0 ? licensesCountNumber : 2;
    const ingamePercentage = activeBots === 0 ? 0 : Math.round((ingameBots * 100) / activeBots);
    const activePercentage = Math.round((activeBots * 100) / licensesCount);

    setOverviewStats({ quotaReached, addedAccounts: accounts.length, ingamePercentage, activePercentage, bannedAcc, activeBots, ingameBots, offlineBots, inQueueBots, LPQAccounts });
    setStatsPerRegion(statsPerRegion_);

    // setSelectedIds(filteredAccounts.map((acc) => acc._id));
    localStorage.setItem("filters-region", JSON.stringify(filterByRegion));
    localStorage.setItem("filters-status", JSON.stringify(filterByStatus));

    let pageCopy = page;

    let newPageArray = Array.from({ length: Math.ceil(filteredAccounts.length / accountsPerPage) }, (_, i) => i + 1);

    if (pageCopy > newPageArray.length) {
      pageCopy = 1;
      setPage(1);
    }

    let newPage = (pageCopy - 1) * accountsPerPage > accounts.length ? 1 : pageCopy;

    let a = [...filteredAccounts].slice((newPage - 1) * accountsPerPage, (newPage - 1) * accountsPerPage + accountsPerPage);
    setPageArray(newPageArray);
    return a;
  };
  const onClickChangeSorting = (e) => {
    let newSorts = sorts;
    Object.keys(newSorts).forEach((key) => {
      if (key === e.target.dataset.name) {
        // console.log(newSorts[key]);
        if (newSorts[key] == 0) {
          // console.log("Changed to 1!")
          newSorts[key] = 1;
        } else if (newSorts[key] == 1) {
          newSorts[key] = 2;
        } else {
          newSorts[key] = 0;
        }
      } else {
        newSorts[key] = 0;
      }
    });
    localStorage.setItem("sort-dash", JSON.stringify(newSorts));
    setSorts(newSorts);
    setChange(!change);
  };
  // console.log(skinsData);
  const [accountsPerPage, setAccountsAmountPerPage] = useState(JSON.parse(localStorage.getItem("accounts-per-page-dashboard")) ? JSON.parse(localStorage.getItem("accounts-per-page-dashboard")) : 100);
  const [page, setPage] = useState(JSON.parse(localStorage.getItem("page-dashboard")) ? JSON.parse(localStorage.getItem("page-dashboard")) : 1);
  const [pageArray, setPageArray] = useState(Array.from({ length: Math.ceil(accounts.length / accountsPerPage) }, (_, i) => i + 1));
  // const getSkinData = async () => {
  //   let skinData = {};
  //   // let skins = await axios.get(`skins.json`);
  //   console.log(skinsData);
  //   Object.values(skinsData).forEach((skin) => {
  //     if (!skin.isBase) {
  //       skinData[skin.id] = skin.name;
  //     }
  //   });
  //   return skinData;
  // };
  // useEffect(() => {
  //   setSkinNames(getSkinData());
  // }, []);
  // console.log(skinNames);
  useEffect(() => {
    let sortedEnabled = false;

    Object.keys(sorts).forEach((key) => {
      if (sorts[key] != 0) {
        sortedEnabled = true;
        switch (key) {
          case "desiredBE":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.desiredBE < b.desiredBE ? 1 : -1) : a.desiredBE > b.desiredBE ? 1 : -1))));
            break;
          case "limitResources":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.limitResources < b.limitResources ? 1 : -1) : a.limitResources > b.limitResources ? 1 : -1))));
            break;
          case "flashOn":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? b.flashOn.toString().localeCompare(a.flashOn) : a.flashOn.toString().localeCompare(b.flashOn)))));
            break;
          case "showDisenchant":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? b.disenchantLoot.toString().localeCompare(a.disenchantLoot) : a.disenchantLoot.toString().localeCompare(b.disenchantLoot)))));
            break;
          case "currentLevel":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.currentLevel < b.currentLevel ? 1 : a.currentLevel === b.currentLevel ? (a.nextLevelPercentage < b.nextLevelPercentage ? 1 : -1) : -1) : a.currentLevel > b.currentLevel ? 1 : a.currentLevel === b.currentLevel ? (a.nextLevelPercentage > b.nextLevelPercentage ? 1 : -1) : -1))));
            break;
          case "blueEssence":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.blueEssence < b.blueEssence ? 1 : -1) : a.blueEssence > b.blueEssence ? 1 : -1))));
            break;

          case "vmNumber":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.vmNumber < b.vmNumber ? 1 : -1) : a.vmNumber > b.vmNumber ? 1 : -1))));
            break;
          case "botVersion":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.botVersion.replaceAll(".", "") < b.botVersion.replaceAll(".", "") ? 1 : -1) : a.botVersion.replaceAll(".", "") > b.botVersion.replaceAll(".", "") ? 1 : -1))));
            break;
          case "gameVersion":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.gameVersion.replaceAll(".", "") < b.gameVersion.replaceAll(".", "") ? 1 : -1) : a.gameVersion.replaceAll(".", "") > b.gameVersion.replaceAll(".", "") ? 1 : -1))));
            break;
          case "rp":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.rp < b.rp ? 1 : -1) : a.rp > b.rp ? 1 : -1))));
            break;
          case "orangeEssence":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.orangeEssence < b.orangeEssence ? 1 : -1) : a.orangeEssence > b.orangeEssence ? 1 : -1))));
            break;
          case "nOfGames":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.nOfGames < b.nOfGames ? 1 : a.nOfGames === b.nOfGames ? (a.blueEssence < b.blueEssence ? 1 : -1) : -1) : a.nOfGames > b.nOfGames ? 1 : a.nOfGames === b.nOfGames ? (a.blueEssence > b.blueEssence ? 1 : -1) : -1))));

            break;
          case "priority":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.priority < b.priority ? 1 : a.priority === b.priority ? (a.blueEssence < b.blueEssence ? 1 : -1) : -1) : a.priority > b.priority ? 1 : a.priority === b.priority ? (a.blueEssence > b.blueEssence ? 1 : -1) : -1))));

            break;
          case "showIps":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.showIps < b.showIps ? 1 : a.showIps === b.showIps ? (a.blueEssence < b.blueEssence ? 1 : -1) : -1) : a.showIps > b.showIps ? 1 : a.showIps === b.showIps ? (a.blueEssence > b.blueEssence ? 1 : -1) : -1))));

            break;
          case "playtime":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.playtime < b.playtime ? 1 : a.playtime === b.playtime ? (a.blueEssence < b.blueEssence ? 1 : -1) : -1) : a.playtime > b.playtime ? 1 : a.playtime === b.playtime ? (a.blueEssence > b.blueEssence ? 1 : -1) : -1))));

            break;

          case "skins":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.skins.length < b.skins.length ? 1 : a.skins.length === b.skins.length ? (a.blueEssence < b.blueEssence ? 1 : -1) : -1) : a.skins.length > b.skins.length ? 1 : a.skins.length === b.skins.length ? (a.blueEssence > b.blueEssence ? 1 : -1) : -1))));

            break;
          case "summonerName":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? b.summonerName.toString().localeCompare(a.summonerName) : a.summonerName.toString().localeCompare(b.summonerName)))));

            break;
          case "status":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? b.status.toString().localeCompare(a.status) : a.status.toString().localeCompare(b.status)))));

            break;
          case "region":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? b.region.toString().localeCompare(a.region) : a.region.toString().localeCompare(b.region)))));

            break;
          case "isActive":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? b.isActive.toString().localeCompare(a.isActive) : a.isActive.toString().localeCompare(b.isActive)))));
            break;
          case "lastTimePlayed":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.lastTimePlayed < b.lastTimePlayed ? 1 : -1) : a.lastTimePlayed > b.lastTimePlayed ? 1 : -1))));
            break;

          case "hasBoostActive":
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.hasBoostActive < b.hasBoostActive ? 1 : -1) : a.hasBoostActive > b.hasBoostActive ? 1 : -1))));
            break;
          case "cooldownAfterSession":
            // lastTimePlayed + cooldownAfterSession > Date.now();
            setSortedAccs(formatAccountsByRegion([...accounts].sort((a, b) => (sorts[key] == 1 ? (a.lastTimePlayed + a.cooldownAfterSession - Date.now() > b.lastTimePlayed + b.cooldownAfterSession - Date.now() ? 1 : -1) : a.lastTimePlayed + a.cooldownAfterSession - Date.now() < b.lastTimePlayed + b.cooldownAfterSession - Date.now() ? 1 : -1))));
            break;
        }
      }
    });
    if (selectedIds.length > 1) {
      setSelectedIds([]);
    }
    if (!sortedEnabled) {
      setSortedAccs(formatAccountsByRegion([...accounts]));
    }
  }, [change, label, accounts, filterByRegion, filterByStatus, page, accountsPerPage]);

  const onClickSelectAll = () => {
    if (selectionOn && selectedIds.length !== sortedAccs.length) {
      setSelectedIds(sortedAccs.map((acc) => acc._id));
    } else {
      setSelectedIds([]);
    }
  };

  // const onClickHigherLvls = async () => {
  //   await setPrioritizeHigherLvl(!prioritizeHigherLvls);
  // };

  const onClickAction = async (action) => {
    setIsLoading(true);
    setSelectedIds([]);
    await action(selectedIds);
    setIsLoading(false);
  };
  const onClickUpdate = () => {
    setIsPopupShowing({ accounts: selectedIds, showing: true });
  };
  const onChangeLabel = (e) => {
    setLabel(e.target.value);
  };
  const onClickToggleSelection = async () => {
    setSelectedIds([]);
    setSelectionOn(!selectionOn);
  };
  const onClickRefreshAccounts = async () => {
    setIsLoading(true);
    await getMyAccounts(fields);
    setIsLoading(false);
  };
  const onClickRegionFilter = (region) => {
    setFilterByRegion({ ...filterByRegion, [region]: !filterByRegion[region] });
    // localStorage.setItem('filters-region', JSON.stringify(filterByRegion));
  };

  const onClickStatusFilter = (status) => {
    setFilterByStatus({ ...filterByStatus, [status]: !filterByStatus[status] });
    // localStorage.setItem('filters-status', JSON.stringify(filterByStatus));
  };

  const getIconString = (numberSort) => {
    if (numberSort == 1) {
      return "down";
    } else if (numberSort == 2) {
      return "up-alt";
    }
  };
  const onChangeSkinSearch = (e) => {
    setSkinSearch(e.target.value);
  };
  const onChangeSummonerNameSearch = (e) => {
    setSummonerNameSearch(e.target.value);
  };
  const onChangeAccountsPerPage = (event) => {
    setAccountsAmountPerPage(Number(event.target.value));
    localStorage.setItem("accounts-per-page-dashboard", JSON.stringify(Number(event.target.value)));
  };
  const onChangePage = (event) => {
    setPage(Number(event.target.value));
    localStorage.setItem("page-dashboard", JSON.stringify(Number(event.target.value)));
  };
  const showColumnsThs = (id) => {
    switch (id) {
      case "mode":
        return <th>Mode</th>;
      case "summonerName":
        return (
          <th data-name="summonerName" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Summoner Name
            {sorts["summonerName"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["summonerName"])}`}></i>}
          </th>
        );
      case "currentLevel":
        return (
          <th data-name="currentLevel" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Current Level
            {sorts["currentLevel"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["currentLevel"])}`}></i>}
          </th>
        );
      case "desiredLevel":
        return <th>Desired Level</th>;

      case "desiredPercentage":
        return <th>Desired Percentage</th>;
      case "desiredBE":
        return (
          <th data-name="desiredBE" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Desired BE
            {sorts["desiredBE"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["desiredBE"])}`}></i>}
          </th>
        );
      case "blueEssence":
        return (
          <th data-name="blueEssence" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            BE
            {sorts["blueEssence"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["blueEssence"])}`}></i>}
          </th>
        );
      case "rp":
        return (
          <th data-name="rp" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            RP
            {sorts["rp"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["rp"])}`}></i>}
          </th>
        );
      case "orangeEssence":
        return (
          <th data-name="orangeEssence" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Orange Essence
            {sorts["orangeEssence"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["orangeEssence"])}`}></i>}
          </th>
        );
      case "cooldownAfterSession":
        // return <th>Cooldown over in</th>;

        return (
          <th data-name="cooldownAfterSession" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Cooldown over in
            {sorts["cooldownAfterSession"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["cooldownAfterSession"])}`}></i>}
          </th>
        );
      case "nOfGames":
        return (
          <th data-name="nOfGames" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Games Played
            {sorts["nOfGames"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["nOfGames"])}`}></i>}
          </th>
        );
      case "gamesInSession":
        return <th>Session Games</th>;
      case "skins":
        return (
          <th data-name="skins" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Skins
            {sorts["skins"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["skins"])}`}></i>}
          </th>
        );
      case "playtime":
        return (
          <th data-name="playtime" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Playtime
            {sorts["playtime"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["playtime"])}`}></i>}
          </th>
        );
      case "finishAndStart":
        return <th>Start to Finish Time (days)</th>;
      case "region":
        return (
          <th data-name="region" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Region
            {sorts["region"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["region"])}`}></i>}
          </th>
        );
      case "isActive_status_state":
        return (
          <>
            <th></th>
            <th data-name="isActive" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
              Active
              {sorts["isActive"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["isActive"])}`}></i>}
            </th>
            <th data-name="status" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
              Status
              {sorts["status"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["status"])}`}></i>}
            </th>
            <th>State</th>
            <th></th>
          </>
        );
      case "accountActions":
        return <th>Account Actions</th>;
      case "vmActions":
        return (
          <>
            <th></th>
            <th>VM Actions</th>
          </>
        );
      case "priority":
        return (
          <th data-name="priority" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Priority
            {sorts["priority"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["priority"])}`}></i>}
          </th>
        );
      case "limitResources":
        return (
          <th data-name="limitResources" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Limit Res
            {sorts["limitResources"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["limitResources"])}`}></i>}
          </th>
        );
      case "flashOn":
        return (
          <th data-name="flashOn" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Flash On
            {sorts["flashOn"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["flashOn"])}`}></i>}
          </th>
        );
      case "missionTracker":
        return <th data-name="showMissions">Missions</th>;

      case "disenchantLoot":
        return (
          <th data-name="showDisenchant" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Disenchant
            {sorts["showDisenchant"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["showDisenchant"])}`}></i>}
          </th>
        );
      case "ip":
        return (
          <th data-name="showIps" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Ips
            {sorts["showIps"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["showIps"])}`}></i>}
          </th>
        );
      case "macAddress":
        return <th>Server MAC</th>;
      case "finishAndStartDates":
        return <th data-name="showFinishStartDates">Finish and Start dates</th>;
      case "checker":
        return <th data-name="showCheckerStatus">Checker Status</th>;
      case "hasBoostActive":
        return (
          <th data-name="hasBoostActive" style={{ cursor: "pointer", minWidth: "70px" }} onClick={(e) => onClickChangeSorting(e)}>
            Has Active Boost
            {sorts["hasBoostActive"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["hasBoostActive"])}`}></i>}
          </th>
        );
      case "vmNumber":
        return (
          <th data-name="vmNumber" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            VM Number
            {sorts["vmNumber"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["vmNumber"])}`}></i>}
          </th>
        );
      case "limitFps":
        return <th>Limit FPS</th>;
      case "serverId":
        return <th>Server Id</th>;
      case "botVersion":
        // return <th>Bot Version</th>;

        return (
          <th data-name="botVersion" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Bot Version
            {sorts["botVersion"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["botVersion"])}`}></i>}
          </th>
        );
      case "country":
        return <th>Country</th>;
      case "lastTimePlayed":
        return (
          <th data-name="lastTimePlayed" style={{ cursor: "pointer", minWidth: "70px" }} onClick={(e) => onClickChangeSorting(e)}>
            Last Game Played At
            {sorts["lastTimePlayed"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["lastTimePlayed"])}`}></i>}
          </th>
        );
      case "label":
        return <th>Label</th>;
      case "leagueClientMode":
        return <th>League Client Mode</th>;
      case "gameVersion":
        return (
          <th data-name="gameVersion" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
            Game Version
            {sorts["gameVersion"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["gameVersion"])}`}></i>}
          </th>
        );
    }
  };
  // console.log(dashboardColumns);
  return (
    <>
      {overviewStats ? (
        <div className="main">
          <Overview stats={overviewStats} />
          {isPopupShowing.showing && <EditAccount isPopupShowing={isPopupShowing} sendPopupShowing={(v) => setIsPopupShowing(v)} />}
          {isPopupShowing2.showing && <Skins skinLanguage={skinLanguage} isPopupShowing={isPopupShowing2} sendPopupShowing={(v) => setIsPopupShowing2(v)} />}

          <div className="accounts">
            <div className={styles.optionsPanel}>
              <div className={styles.buttonGroup}>
                <div title={"Toggle Selection"} className={`${styles.optionsButton} ${selectionOn ? "green" : "red"}`} onClick={onClickToggleSelection}>
                  <span>Selection</span>
                  <i className={`fas fa-${selectionOn ? "check" : "times"}-circle`} style={{ color: `${selectionOn ? "green" : "#970000"}` }}></i>
                </div>
              </div>
              <div>
                <label style={{ marginRight: ".5em" }}>Accs/Page</label>
                <select className="credentials__input" style={{ width: "60px" }} onChange={onChangeAccountsPerPage} defaultValue={accountsPerPage}>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                  <option value={250}>250</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                  <option value={10000}>10000</option>
                  <option value={9999999}>All</option>
                </select>
              </div>
              <div>
                <label style={{ marginRight: ".5em" }}>Page</label>
                <select className="credentials__input" style={{ width: "60px" }} onChange={onChangePage} defaultValue={page}>
                  {pageArray.map((page) => (
                    <option key={page} value={page}>
                      {page}
                    </option>
                  ))}
                </select>
              </div>

              {selectedIds.length > 1 && (
                <>
                  {!isLoading && (
                    <div className={styles.buttonGroupActions}>
                      <div className={styles.selectedAccounts}>
                        <span>Selected Accounts: {selectedIds.length}</span>
                      </div>
                      <div title={"Remove from dashboard"} className={styles.optionsButton} onClick={() => onClickAction(removeAccountsFromDashboard)}>
                        <i className={`fas fa-times-circle`}></i>

                        <span>Remove</span>
                      </div>
                      <div className={styles.optionsButton} onClick={() => onClickAction(pauseAccounts)}>
                        <i className={`fas fa-pause`}></i>
                        <span>Pause</span>
                      </div>
                      <div className={styles.optionsButton} onClick={() => onClickAction(unpauseAccounts)}>
                        <i className={`fas fa-play`}></i>
                        <span>Unpause</span>
                      </div>
                      <div className={styles.optionsButton} onClick={() => onClickAction(stopAccounts)}>
                        <i className={`fas fa-exchange-alt`}></i>

                        <span>Change Accs</span>
                      </div>
                      <div className={styles.optionsButton} onClick={() => onClickAction(restartAccounts)}>
                        <i className={`fas fa-sync-alt`}></i>

                        <span>Restart</span>
                      </div>

                      <div className={styles.optionsButton} onClick={() => onClickAction(powerOffAccounts)}>
                        <i className={`fas fa-power-off`}></i>

                        <span>Turn Off</span>
                      </div>
                      <div className={styles.optionsButton} onClick={() => onClickAction(powerOnAccounts)}>
                        <i className={`fas fa-play-circle`}></i>

                        <span>Turn On</span>
                      </div>
                      <div className={styles.optionsButton} onClick={() => onClickAction(restartVms)}>
                        <i className={`fas fa-sync-alt`}></i>

                        <span>Restart VM</span>
                      </div>
                      <div className={styles.optionsButton} onClick={() => onClickAction(turnOffVms)}>
                        <i className={`fas fa-power-off`}></i>

                        <span>Turn Off VM</span>
                      </div>

                      {selectedIds.length > 0 && (
                        <div title={"Edit accounts"} className={styles.optionsButton} onClick={onClickUpdate}>
                          <i className={`fas fa-user-edit`}></i>

                          <span>Edit</span>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {showNameSkinSearch && (
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0.7em" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "1em" }}>Name Searcher</span>

                  <input onChange={onChangeSummonerNameSearch} value={summonerNameSearch} autoFocus={true} style={{ height: "30px", color: "white", textIndent: "0.5em", backgroundColor: "#484c71", border: "none", borderRadius: "5px" }}></input>
                  <div onClick={() => setChange(!change)} style={{ height: "23px", width: "70px", marginLeft: "1em", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", borderRight: "2px solid #302f4e" }} className="button aqua-green">
                    Search
                  </div>
                  <div onClick={eraseSummonerNameSearch} style={{ height: "23px", width: "30px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} className={"button aqua-green"}>
                    <i className="fas fa-eraser"></i>
                  </div>
                </div>
                <div>
                  <label style={{ marginRight: ".5em" }}>Labels</label>
                  <select className="credentials__input" style={{ width: "60px" }} onChange={onChangeLabel} defaultValue={label}>
                    <option value="-">-</option>

                    {labels.map((label) => (
                      <option key={label} value={label}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "1em" }}>Skin Searcher</span>

                  <input onChange={onChangeSkinSearch} value={skinSearch} autoFocus={true} style={{ height: "30px", color: "white", textIndent: "0.5em", backgroundColor: "#484c71", border: "none", borderRadius: "5px" }}></input>
                  <div onClick={() => setChange(!change)} style={{ height: "23px", width: "70px", marginLeft: "1em", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", borderRight: "2px solid #302f4e" }} className="button aqua-green">
                    Search
                  </div>
                  <div onClick={eraseSkinSearch} style={{ height: "23px", width: "30px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} className={"button aqua-green"}>
                    <i className="fas fa-eraser"></i>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.filterRegions}>
              <div className={styles.filterRegionsButtons}>
                <div onClick={() => onClickRegionFilter("na")} className={`${styles.tooltip} ${!filterByRegion["na"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  NA {statsPerRegion["na"]["total"] > 0 && `(${statsPerRegion["na"]["total"]})`}
                  {filterByRegion["na"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["na"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["na"]["finished"]})</span>
                    </div>
                  )}
                </div>
                <div onClick={() => onClickRegionFilter("euw")} className={`${styles.tooltip} ${!filterByRegion["euw"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  EUW {statsPerRegion["euw"]["total"] > 0 && `(${statsPerRegion["euw"]["total"]})`}
                  {filterByRegion["euw"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["euw"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["euw"]["finished"]})</span>
                    </div>
                  )}
                </div>
                <div onClick={() => onClickRegionFilter("eune")} className={`${styles.tooltip} ${!filterByRegion["eune"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  EUNE {statsPerRegion["eune"]["total"] > 0 && `(${statsPerRegion["eune"]["total"]})`}
                  {filterByRegion["eune"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["eune"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["eune"]["finished"]})</span>
                    </div>
                  )}
                </div>
                <div onClick={() => onClickRegionFilter("las")} className={`${styles.tooltip} ${!filterByRegion["las"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  LAS {statsPerRegion["las"]["total"] > 0 && `(${statsPerRegion["las"]["total"]})`}
                  {filterByRegion["las"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["las"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["las"]["finished"]})</span>
                    </div>
                  )}
                </div>
                <div onClick={() => onClickRegionFilter("lan")} className={`${styles.tooltip} ${!filterByRegion["lan"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  LAN {statsPerRegion["lan"]["total"] > 0 && `(${statsPerRegion["lan"]["total"]})`}
                  {filterByRegion["lan"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["lan"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["lan"]["finished"]})</span>
                    </div>
                  )}
                </div>
                <div onClick={() => onClickRegionFilter("jp")} className={`${styles.tooltip} ${!filterByRegion["jp"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  JP {statsPerRegion["jp"]["total"] > 0 && `(${statsPerRegion["jp"]["total"]})`}
                  {filterByRegion["jp"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["jp"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["jp"]["finished"]})</span>
                    </div>
                  )}
                </div>
                <div onClick={() => onClickRegionFilter("ru")} className={`${styles.tooltip} ${!filterByRegion["ru"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  RU {statsPerRegion["ru"]["total"] > 0 && `(${statsPerRegion["ru"]["total"]})`}
                  {filterByRegion["ru"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["ru"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["ru"]["finished"]})</span>
                    </div>
                  )}
                </div>
                <div onClick={() => onClickRegionFilter("br")} className={`${styles.tooltip} ${!filterByRegion["br"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  BR {statsPerRegion["br"]["total"] > 0 && `(${statsPerRegion["br"]["total"]})`}
                  {filterByRegion["br"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["br"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["br"]["finished"]})</span>
                    </div>
                  )}
                </div>
                <div onClick={() => onClickRegionFilter("tr")} className={`${styles.tooltip} ${!filterByRegion["tr"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  TR {statsPerRegion["tr"]["total"] > 0 && `(${statsPerRegion["tr"]["total"]})`}
                  {filterByRegion["tr"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["tr"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["tr"]["finished"]})</span>
                    </div>
                  )}
                </div>
                <div onClick={() => onClickRegionFilter("kr")} className={`${styles.tooltip} ${!filterByRegion["kr"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  KR {statsPerRegion["kr"]["total"] > 0 && `(${statsPerRegion["kr"]["total"]})`}
                  {filterByRegion["kr"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["kr"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["kr"]["finished"]})</span>
                    </div>
                  )}
                </div>
                <div onClick={() => onClickRegionFilter("oce")} className={`${styles.tooltip} ${!filterByRegion["oce"] ? styles.nonActiveRegions : styles.activeRegions}`}>
                  OCE {statsPerRegion["oce"]["total"] > 0 && `(${statsPerRegion["oce"]["total"]})`}
                  {filterByRegion["oce"] && (
                    <div className={`${styles.tooltiptext}`}>
                      <span>ACTIVE ({statsPerRegion["oce"]["active"]})</span>
                      <br />
                      <span>FINISHED ({statsPerRegion["oce"]["finished"]})</span>
                    </div>
                  )}
                </div>
              </div>

              {!isLoading && (
                <>
                  <div className={styles.buttonGroup} style={{ color: "#c5c5c5", fontSize: ".75rem" }}>
                    <div style={{ marginLeft: ".5em" }} title={"Toggle"} className={`${styles.optionsButton} ${filterByStatus["ingame"] ? "green" : "red"}`} onClick={() => onClickStatusFilter("ingame")}>
                      <span>Ingame</span>
                      <i className={`fas fa-${filterByStatus["ingame"] ? "check" : "times"}-circle`} style={{ color: `${filterByStatus["ingame"] ? "green" : "#970000"}` }}></i>
                    </div>
                    <div style={{ marginLeft: ".5em" }} title={"Toggle"} className={`${styles.optionsButton} ${filterByStatus["in-queue"] ? "green" : "red"}`} onClick={() => onClickStatusFilter("in-queue")}>
                      <span>In Queue</span>
                      <i className={`fas fa-${filterByStatus["in-queue"] ? "check" : "times"}-circle`} style={{ color: `${filterByStatus["in-queue"] ? "green" : "#970000"}` }}></i>
                    </div>
                    <div style={{ marginLeft: ".5em" }} title={"Toggle"} className={`${styles.optionsButton} ${filterByStatus["tutorial"] ? "green" : "red"}`} onClick={() => onClickStatusFilter("tutorial")}>
                      <span>Tutorial</span>
                      <i className={`fas fa-${filterByStatus["tutorial"] ? "check" : "times"}-circle`} style={{ color: `${filterByStatus["tutorial"] ? "green" : "#970000"}` }}></i>
                    </div>
                    <div style={{ marginLeft: ".5em" }} title={"Toggle"} className={`${styles.optionsButton} ${filterByStatus["online"] ? "green" : "red"}`} onClick={() => onClickStatusFilter("online")}>
                      <span>Online</span>
                      <i className={`fas fa-${filterByStatus["online"] ? "check" : "times"}-circle`} style={{ color: `${filterByStatus["online"] ? "green" : "#970000"}` }}></i>
                    </div>
                    <div style={{ marginLeft: ".5em" }} title={"Toggle"} className={`${styles.optionsButton} ${filterByStatus["offline"] ? "green" : "red"}`} onClick={() => onClickStatusFilter("offline")}>
                      <span>Offline</span>
                      <i className={`fas fa-${filterByStatus["offline"] ? "check" : "times"}-circle`} style={{ color: `${filterByStatus["offline"] ? "green" : "#970000"}` }}></i>
                    </div>
                    <div style={{ marginLeft: ".5em" }} title={"Toggle"} className={`${styles.optionsButton} ${filterByStatus["quota-finished"] ? "green" : "red"}`} onClick={() => onClickStatusFilter("quota-finished")}>
                      <span>Quota Finished</span>
                      <i className={`fas fa-${filterByStatus["quota-finished"] ? "check" : "times"}-circle`} style={{ color: `${filterByStatus["quota-finished"] ? "green" : "#970000"}` }}></i>
                    </div>
                  </div>
                  <div className={styles.filterRegionsButtons}>
                    <div className={`${!filterByRegion["BE"] ? styles.nonActiveRegions : styles.activeRegions}`} style={{ padding: "0px", display: "flex", alignItems: "center" }} onClick={() => onClickRegionFilter("BE")}>
                      <img className={styles.img} src={"./BE.png"}></img>
                      <span>{statsPerRegion["BE"]}</span>
                    </div>
                    <div className={`${!filterByRegion["nonBE"] ? styles.nonActiveRegions : styles.activeRegions}`} style={{ padding: "0px", display: "flex", alignItems: "center" }} onClick={() => onClickRegionFilter("nonBE")}>
                      <img style={{ width: "25px", height: "25px" }} className={styles.img} src={"./CAPSULE.png"}></img>
                      <span>{statsPerRegion["nonBE"]}</span>
                    </div>
                  </div>
                  <div className={styles.optionsPanel}></div>
                  <div title={"Reload Accounts"} className={`${styles.optionsButton}`} onClick={onClickRefreshAccounts}>
                    <i className={`fas fa-sync-alt`}></i>
                  </div>
                </>
              )}
            </div>
            {sortedAccs && sortedAccs.length > 0 ? (
              <div className="accounts__content">
                {!isLoading ? (
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="2">
                          <div>
                            <i style={{ cursor: "pointer" }} onClick={onClickSelectAll} className={`fa${selectedIds.length === accounts.length ? "s" : "r"} fa-check-square`}></i>
                          </div>
                        </th>
                        {dashboardColumns.map((col) => {
                          return col.enabled ? showColumnsThs(col.id) : null;
                        })}
                        {/* {showMode && <th>Mode</th>}
                        <th data-name="summonerName" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                          Summoner Name
                          {sorts["summonerName"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["summonerName"])}`}></i>}
                        </th>

                        <th data-name="currentLevel" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                          Current Level
                          {sorts["currentLevel"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["currentLevel"])}`}></i>}
                        </th>
                        <th>Desired Level</th>
                        {showDesiredPercentage && <th>Desired Percentage</th>}

                        {showDesiredBE && (
                          <th data-name="desiredBE" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                            Desired BE
                            {sorts["desiredBE"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["desiredBE"])}`}></i>}
                          </th>
                        )}
                        <th data-name="blueEssence" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                          BE
                          {sorts["blueEssence"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["blueEssence"])}`}></i>}
                        </th>
                      

                        {showRp && (
                          <th data-name="rp" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                            RP
                            {sorts["rp"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["rp"])}`}></i>}
                          </th>
                        )}

                        {showOrangeEssence && (
                          <th data-name="orangeEssence" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                            Orange Essence
                            {sorts["orangeEssence"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["orangeEssence"])}`}></i>}
                          </th>
                        )}
                        {showCooldown && <th>Cooldown over in</th>}

                        <th data-name="nOfGames" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                          Games Played
                          {sorts["nOfGames"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["nOfGames"])}`}></i>}
                        </th>
                        <th>Session Games</th>
                        
                        <th data-name="skins" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                          Skins
                          {sorts["skins"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["skins"])}`}></i>}
                        </th>
                        <th data-name="playtime" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                          Playtime
                          {sorts["playtime"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["playtime"])}`}></i>}
                        </th>
                        {showStartToFinishTime && (
                         
                          <th>Start to Finish Time (days)</th>
                        )}
                        <th data-name="region" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                          Region
                          {sorts["region"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["region"])}`}></i>}
                        </th>
                        <th></th>
                        <th data-name="isActive" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                          Active
                          {sorts["isActive"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["isActive"])}`}></i>}
                        </th>
                        <th data-name="status" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                          Status
                          {sorts["status"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["status"])}`}></i>}
                        </th>
                        <th>State</th>
                        <th></th>
                        <th>Account Actions</th>
                        <th></th>
                        <th>VM Actions</th>
                        {showPriority && (
                          <th data-name="priority" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                            Priority
                            {sorts["priority"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["priority"])}`}></i>}
                          </th>
                        )}
                        {showLimitRes && (
                          <th data-name="limitResources" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                            Limit Res
                            {sorts["limitResources"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["limitResources"])}`}></i>}
                          </th>
                        )}
                        {showFlashOn && (
                          <th data-name="flashOn" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                            Flash On
                            {sorts["flashOn"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["flashOn"])}`}></i>}
                          </th>
                        )}
                        {showMissions && <th data-name="showMissions">Missions</th>}
                        {showDisenchant && (
                          <th data-name="showDisenchant" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                            Disenchant
                            {sorts["showDisenchant"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["showDisenchant"])}`}></i>}
                          </th>
                        )}
                        {showIps && (
                          <th data-name="showIps" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                            Ips
                            {sorts["showIps"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["showIps"])}`}></i>}
                          </th>
                        )}

                        {showMac && <th>Server MAC</th>}

                        {showFinishStartDates && <th data-name="showFinishStartDates">Finish and Start dates</th>}
                        {showCheckerStatus && <th data-name="showCheckerStatus">Checker Status</th>}
                        {showFreeNameChange && <th data-name="showFreeNameChange">Free Name Change</th>}
                        {showUserTaken && <th>Name Taken</th>}
                        {showHasActiveBoost && (
                          <th data-name="hasBoostActive" style={{ cursor: "pointer", minWidth: "70px" }} onClick={(e) => onClickChangeSorting(e)}>
                            Has Active Boost
                            {sorts["hasBoostActive"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["hasBoostActive"])}`}></i>}
                          </th>
                        )}

                        {showVmCloneNumber && (
                          <th data-name="vmNumber" style={{ cursor: "pointer" }} onClick={(e) => onClickChangeSorting(e)}>
                            VM Number
                            {sorts["vmNumber"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["vmNumber"])}`}></i>}
                          </th>
                        )}
                        {showLimitFps && <th>Limit FPS</th>}
                        {showServerId && <th>Server Id</th>}
                        {showBotVersion && <th>Bot Version</th>}

                        {showCountry && <th>Country</th>}
                        {showLastGamePlayed && (
                          <th data-name="lastTimePlayed" style={{ cursor: "pointer", minWidth: "70px" }} onClick={(e) => onClickChangeSorting(e)}>
                            Last Game Played
                            {sorts["lastTimePlayed"] != 0 && <i style={{ marginLeft: "3px" }} className={`fas fa-sort-amount-${getIconString(sorts["lastTimePlayed"])}`}></i>}
                          </th>
                        )} */}
                      </tr>
                    </thead>

                    <tbody>
                      {sortedAccs.map((acc) => (
                        <AccountItem key={acc._id} selectionOn={selectionOn} account={acc} userRole={userRole} selectedIds={selectedIds} setSelectedIds={setSelectedIds} isPopupShowing={isPopupShowing} sendPopupShowing={(v) => setIsPopupShowing(v)} isPopupShowing2={isPopupShowing2} sendPopupShowing2={(v) => setIsPopupShowing2(v)} />
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Spinner />
                )}
                <div className={styles.optionsPanel} style={{ paddingTop: "0.4em" }}>
                  <div className={styles.buttonGroup}>
                    <div title={"Toggle Selection"} className={`${styles.optionsButton} ${selectionOn ? "green" : "red"}`} onClick={onClickToggleSelection}>
                      <span>Selection</span>
                      <i className={`fas fa-${selectionOn ? "check" : "times"}-circle`} style={{ color: `${selectionOn ? "green" : "#970000"}` }}></i>
                    </div>
                  </div>
                  <div>
                    <label style={{ marginRight: ".5em" }}>Accs/Page</label>
                    <select className="credentials__input" style={{ width: "60px" }} onChange={onChangeAccountsPerPage} defaultValue={accountsPerPage}>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                      <option value={250}>250</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                      <option value={10000}>10000</option>
                      <option value={9999999}>All</option>
                    </select>
                  </div>
                  <div>
                    <label style={{ marginRight: ".5em" }}>Page</label>
                    <select className="credentials__input" style={{ width: "60px" }} onChange={onChangePage} defaultValue={page}>
                      {pageArray.map((page) => (
                        <option key={page} value={page}>
                          {page}
                        </option>
                      ))}
                    </select>
                  </div>

                  {selectedIds.length > 1 && (
                    <>
                      {!isLoading && (
                        <div className={styles.buttonGroupActions}>
                          <div className={styles.selectedAccounts}>
                            <span>Selected Accounts: {selectedIds.length}</span>
                          </div>
                          <div title={"Remove from dashboard"} className={styles.optionsButton} onClick={() => onClickAction(removeAccountsFromDashboard)}>
                            <i className={`fas fa-times-circle`}></i>

                            <span>Remove</span>
                          </div>
                          <div className={styles.optionsButton} onClick={() => onClickAction(pauseAccounts)}>
                            <i className={`fas fa-pause`}></i>
                            <span>Pause</span>
                          </div>
                          <div className={styles.optionsButton} onClick={() => onClickAction(unpauseAccounts)}>
                            <i className={`fas fa-play`}></i>
                            <span>Unpause</span>
                          </div>
                          <div className={styles.optionsButton} onClick={() => onClickAction(stopAccounts)}>
                            <i className={`fas fa-exchange-alt`}></i>

                            <span>Change Accs</span>
                          </div>
                          <div className={styles.optionsButton} onClick={() => onClickAction(restartAccounts)}>
                            <i className={`fas fa-sync-alt`}></i>

                            <span>Restart</span>
                          </div>

                          <div className={styles.optionsButton} onClick={() => onClickAction(powerOffAccounts)}>
                            <i className={`fas fa-power-off`}></i>

                            <span>Turn Off</span>
                          </div>
                          <div className={styles.optionsButton} onClick={() => onClickAction(powerOnAccounts)}>
                            <i className={`fas fa-play-circle`}></i>

                            <span>Turn On</span>
                          </div>
                          <div className={styles.optionsButton} onClick={() => onClickAction(restartVms)}>
                            <i className={`fas fa-sync-alt`}></i>

                            <span>Restart VM</span>
                          </div>
                          <div className={styles.optionsButton} onClick={() => onClickAction(turnOffVms)}>
                            <i className={`fas fa-power-off`}></i>

                            <span>Turn Off VM</span>
                          </div>

                          {selectedIds.length > 0 && (
                            <div title={"Edit accounts"} className={styles.optionsButton} onClick={onClickUpdate}>
                              <i className={`fas fa-user-edit`}></i>

                              <span>Edit</span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <h1>No accounts on dashboard</h1>
            )}
          </div>
          {error && <h1>Error...</h1>}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  accounts: state.accounts.accounts,
  skinsData: state.accounts.skinsData,
});
export default connect(mapStateToProps, {
  getMyAccounts,
  pauseAccounts,
  stopAccounts,
  restartAccounts,
  powerOffAccounts,
  powerOnAccounts,
  unpauseAccounts,
  restartVms,
  turnOffVms,
  setPrioritizeHigherLvl,
  removeAccountsFromDashboard,
})(Accounts);
