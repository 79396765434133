import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addAccounts } from "../../actions/accountActions";
import Spinner from "../Spinner";
import getChampionName from "../../utils/getChampionName";
const regions = ["oc", "eun", "las", "lan", "euw", "eune", "na", "oce", "br", "jp", "ru", "tr", "kr", "la2", "la1", "euw1", "eun1", "na1", "oce1", "br1", "jp1", "ru1", "tr1", "kr", "ph", "sg", "th", "tw", "vn"];

const AddAccounts = ({ history, addAccounts }) => {
  const ONE_HOUR_MILISECONDS = 3600000;

  const [error, setError] = useState(false);
  const [format, setFormat] = useState(true);

  const [newAccountTemplate, setNewAccountTemplate] = useState(
    JSON.parse(localStorage.getItem("addaccounts-settings"))
      ? JSON.parse(localStorage.getItem("addaccounts-settings"))
      : {
          accountsCredentials: "",
          desiredLevel: 30,
          desiredPercentage: 0,
          maxGames: 500,
          disenchantLoot: true,
          disenchantFragChamps: true,
          disenchantFullChamps: true,
          randomLanes: false,
          skipIfNameTaken: false,
          completeMissionUntil: 7,
          limitResources: 0.35,
          icon: 0,
          priority: 0,
          killExplorer: true,
          useCustomName: false,
          disenchantEternal: false,
          restartIngameAfter: 300,
          waitBeforePickChamp: 0,
          desiredTutos: 0,

          disenchantGem: false,
          disenchantGemOption: "skin",
          disenchantEmblems: false,
          disenchantEmblemsOption: "be",

          completeAllMissions: false,
          freeNameChange: false,
          skipTutorial: false,
          usePrivateParty: false,
          crazyNumber: 0,
          cooldownAfterSessionInHours: 3,
          cooldownAfterSession: 10800000,
          amountOfGamesToFinish: {
            amountOfGames: 0,
          },
          blackWhiteListChamp: {
            enabled: false,
            option: "whitelist",
            ids: [],
          },
          // spaceGrooveOption: 'TheGroove_series',
          desiredBE: 0,
          flashOn: "d",
          mode: "normal",
          leagueClientMode: 2,
          missions: {
            1: "brand",
            2: "ezreal",
            3: "riven",
            4: "ziggs",
            5: "thresh",
            6: "ekko",
          },
          label: "",
        }
  );
  const onChangeCooldownAfterSession = (event) => {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      setNewAccountTemplate({
        ...newAccountTemplate,
        cooldownAfterSession: ONE_HOUR_MILISECONDS * event.target.value.trim(),
        cooldownAfterSessionInHours: event.target.value.trim(),
      });
    }
  };
  // console.log(newAccountTemplate);
  const onChangeMissions = (event) => {
    const value = event.target.value;
    const key = event.target.name;

    setNewAccountTemplate({
      ...newAccountTemplate,
      missions: { ...missions, [Number(key)]: value },
    });
    localStorage.setItem("addaccounts-settings", JSON.stringify({ ...newAccountTemplate, missions: { ...missions, [Number(key)]: value } }));
  };
  const onChangeLevel = (event) => {
    const re = /^[0-9\b]+$/;
    if ((re.test(event.target.value) || event.target.value === "") && event.target.value.length < 4) {
      setNewAccountTemplate({
        ...newAccountTemplate,
        [event.target.name]: event.target.value.trim(),
      });
      localStorage.setItem(
        "addaccounts-settings",
        JSON.stringify({
          ...newAccountTemplate,
          [event.target.name]: event.target.value.trim(),
        })
      );
    }
  };

  const onChangeLimitRes = (event) => {
    const re = /^[0-9.\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      setNewAccountTemplate({
        ...newAccountTemplate,
        [event.target.name]: event.target.value.trim(),
      });
      localStorage.setItem(
        "addaccounts-settings",
        JSON.stringify({
          ...newAccountTemplate,
          [event.target.name]: event.target.value.trim(),
        })
      );
    }
  };
  const onChangeNumber = (event) => {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      setNewAccountTemplate({
        ...newAccountTemplate,
        [event.target.name]: event.target.value.trim(),
      });
      localStorage.setItem(
        "addaccounts-settings",
        JSON.stringify({
          ...newAccountTemplate,
          [event.target.name]: event.target.value.trim(),
        })
      );
    }
  };

  const onChangeCheckBox = (event) => {
    setNewAccountTemplate({
      ...newAccountTemplate,
      [event.target.name]: event.target.checked,
    });
    localStorage.setItem(
      "addaccounts-settings",
      JSON.stringify({
        ...newAccountTemplate,
        [event.target.name]: event.target.checked,
      })
    );
  };

  const onChangeBlackWhiteListCheckbox = (event) => {
    setNewAccountTemplate({
      ...newAccountTemplate,
      blackWhiteListChamp: { ...newAccountTemplate.blackWhiteListChamp, enabled: event.target.checked },
    });
    localStorage.setItem(
      "addaccounts-settings",
      JSON.stringify({
        ...newAccountTemplate,
        blackWhiteListChamp: { ...newAccountTemplate.blackWhiteListChamp, enabled: event.target.checked },
      })
    );
  };

  const onChangeBlackWhiteListOption = (event) => {
    setNewAccountTemplate({
      ...newAccountTemplate,
      blackWhiteListChamp: { ...newAccountTemplate.blackWhiteListChamp, option: event.target.value },
    });
    localStorage.setItem(
      "addaccounts-settings",
      JSON.stringify({
        ...newAccountTemplate,
        blackWhiteListChamp: { ...newAccountTemplate.blackWhiteListChamp, option: event.target.value },
      })
    );
  };
  const onClickRemoveChampId = (id_) => {
    setNewAccountTemplate({
      ...newAccountTemplate,
      blackWhiteListChamp: { ...newAccountTemplate.blackWhiteListChamp, ids: newAccountTemplate.blackWhiteListChamp.ids.filter((id) => Number(id) != Number(id_)) },
    });
    localStorage.setItem(
      "addaccounts-settings",
      JSON.stringify({
        ...newAccountTemplate,
        blackWhiteListChamp: { ...newAccountTemplate.blackWhiteListChamp, ids: newAccountTemplate.blackWhiteListChamp.ids.filter((id) => Number(id) != Number(id_)) },
      })
    );
  };
  const onChangeBlackWhiteListIds = (event) => {
    if (!newAccountTemplate.blackWhiteListChamp.ids.includes(Number(event.target.value))) {
      setNewAccountTemplate({
        ...newAccountTemplate,
        blackWhiteListChamp: { ...newAccountTemplate.blackWhiteListChamp, ids: [...newAccountTemplate.blackWhiteListChamp.ids, Number(event.target.value)] },
      });
      localStorage.setItem(
        "addaccounts-settings",
        JSON.stringify({
          ...newAccountTemplate,
          blackWhiteListChamp: { ...newAccountTemplate.blackWhiteListChamp, ids: [...newAccountTemplate.blackWhiteListChamp.ids, Number(event.target.value)] },
        })
      );
    }
  };

  const onChangeTextarea = (event) => {
    setNewAccountTemplate({
      ...newAccountTemplate,
      accountsCredentials: event.target.value,
    });
    localStorage.setItem(
      "addaccounts-settings",
      JSON.stringify({
        ...newAccountTemplate,
        accountsCredentials: event.target.value,
      })
    );
  };

  const onChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      setNewAccountTemplate({
        ...newAccountTemplate,
        [event.target.name]: event.target.value,
      });
      localStorage.setItem(
        "addaccounts-settings",
        JSON.stringify({
          ...newAccountTemplate,
          [event.target.name]: event.target.value,
        })
      );
    }
  };
  const onChangeLabel = (event) => {
    setNewAccountTemplate({
      ...newAccountTemplate,
      [event.target.name]: event.target.value,
    });
    localStorage.setItem(
      "addaccounts-settings",
      JSON.stringify({
        ...newAccountTemplate,
        [event.target.name]: event.target.value,
      })
    );
  };
  const onChangeFlash = (event) => {
    setNewAccountTemplate({
      ...newAccountTemplate,
      [event.target.name]: event.target.value,
    });
    localStorage.setItem(
      "addaccounts-settings",
      JSON.stringify({
        ...newAccountTemplate,
        [event.target.name]: event.target.value,
      })
    );
  };
  const onChangeGamesToFinish = (event) => {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      setNewAccountTemplate({
        ...newAccountTemplate,

        amountOfGamesToFinish: { ...newAccountTemplate.amountOfGamesToFinishObject, amountOfGames: event.target.value.trim() },
      });
      localStorage.setItem(
        "addaccounts-settings",
        JSON.stringify({
          ...newAccountTemplate,

          amountOfGamesToFinish: { ...newAccountTemplate.amountOfGamesToFinishObject, amountOfGames: event.target.value.trim() },
        })
      );
    }
  };
  const onChangeFormat = () => {
    setFormat(!format);
  };
  const formatAccounts = async (credentials) => {
    let accounts = credentials.split("\n");
    accounts = accounts.filter((acc) => acc != "");
    // console.log('accounts: ', accounts);
    const formattedAccounts = accounts.map((acc) => {
      if (!format) {
        const [summonerName, password, region, email, dateOfBirth, creationDate, countryCode] = acc.split(sp);
        return { summonerName, region, password, email, dateOfBirth, creationDate, countryCode };
      } else {
        const [region, summonerName, password, email, dateOfBirth, creationDate, countryCode] = acc.split(sp);
        return { summonerName, region, password, email, dateOfBirth, creationDate, countryCode };
      }
    });

    let error = false;
    let accounts2 = [];
    formattedAccounts.forEach((acc, i) => {
      accounts2.forEach((acc2) => {
        if (acc.summonerName.toLowerCase() === acc2.summonerName.toLowerCase() && acc.region.toLowerCase() === acc2.region.toLowerCase()) {
          error = true;
          setError(`Duplicated account at line ${i + 1}.`);
          return;
        }
      });
      if (!acc.summonerName || !acc.password || !acc.region) {
        error = true;
        setError("Invalid format");
        return;
      }
      if (acc.summonerName === "" || acc.summonerName.length <= 3) {
        setError(`Invalid summoner name at line ${i + 1}.`);
        error = true;
        return;
      }
      if (acc.password.length <= 3) {
        setError(`Invalid password at line ${i + 1}.`);
        error = true;
        return;
      }
      if (!regions.includes(acc.region.toLowerCase())) {
        setError(`Invalid region at line ${i + 1}`);
        error = true;
        return;
      }
      if (newAccountTemplate.blackWhiteListChamp.enabled && newAccountTemplate.blackWhiteListChamp.ids.length == 0) {
        setError(`Champion list can't be empty if Black/White list champ enabled.`);
        error = true;
        return;
      }
      accounts2.push({ summonerName: acc.summonerName, region: acc.region });
    });
    if (formattedAccounts.length === 0) {
      setError("Please add accounts.");
      return;
    }
    if (desiredPercentage === "" || desiredLevel === "" || desiredBE === "" || icon === "" || priority === "" || limitResources === "" || (completeAllMissions && completeMissionUntil === "")) {
      setError(`A field is empty, please fill it.`);
      error = true;
      return;
    }
    if (!error) {
      setError("");
      setIsLoading(true);
      let settingsToSend = { ...newAccountTemplate };
      delete settingsToSend.accountsCredentials;
      await addAccounts({ formattedAccounts, settings: settingsToSend });
      setIsLoading(false);
      history.push("/dashboard");
    }
  };
  const [sp, setSeparator] = useState(":");
  const onChangeSeparator = (e) => {
    setSeparator(e.target.value);
  };
  // console.log(newAccountTemplate)
  const [isLoading, setIsLoading] = useState(false);
  const { label, desiredPercentage, useCustomName, crazyNumber, usePrivateParty, spaceGrooveOption, priority, completeMissionUntil, freeNameChange, limitResources, blackWhiteListChamp, completeAllMissions, disenchantGemOption, disenchantGem, disenchantEternal, disenchantEmblems, disenchantEmblemsOption, mode, desiredBE, flashOn, skipTutorial, skipIfNameTaken, icon, killExplorer, accountsCredentials, desiredLevel, maxGames, cooldownAfterSession, cooldownAfterSessionInHours, disenchantLoot, disenchantFragChamps, disenchantFullChamps, missions, randomLanes } = newAccountTemplate;
  // console.log(newAccountTemplate);
  // console.log(blackWhiteListChamp);
  return (
    <div className="credentials__addaccounts">
      {!isLoading ? (
        <>
          <div className="credentials__textarea__container">
            <textarea onChange={onChangeTextarea} value={accountsCredentials} placeholder={!format ? `summoner123${sp}password123${sp}NA\nsummoner123${sp}password123${sp}NA` : `NA${sp}summoner123${sp}password123\nNA${sp}summoner123${sp}password123`}></textarea>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: ".5em", height: "50px", alignItems: "center" }}>
              <button onClick={() => onChangeFormat()} className="credentials__button anim aqua-green">
                Change Format
              </button>
              <div className="credentials__info-field" style={{ width: "50px", marginBottom: "3px" }}>
                <label style={{ fontSize: "0.8rem" }}>Separator</label>
                <input style={{ marginTop: "3px" }} onChange={onChangeSeparator} className="credentials__input" value={sp}></input>
              </div>
              <div>
                <span style={{ alignSelf: "center" }}>{!format ? `summoner123${sp}password123${sp}NA` : `NA${sp}summoner123${sp}password123`}</span>
                <div className={`tooltip`} style={{ border: "none", marginLeft: "5px" }}>
                  <span className="tooltiptext" style={{ fontSize: "15px" }}>
                    To add more information, follow this order: <span style={{ fontWeight: "bold" }}>{format ? `region${sp}summonerName${sp}password${sp}email${sp}dateOfBirth${sp}creationDate${sp}countryCode` : `summonerName${sp}password${sp}region${sp}email${sp}dateOfBirth${sp}creationDate${sp}countryCode`}</span>
                  </span>
                  <i className="fas fa-question-circle"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="credentials__info__container" style={{ fontSize: "0.9rem" }}>
            <div className="credentials__info-row">
              <div className="credentials-field">
                <div className="tooltip" style={{ border: "none" }}>
                  <label>Desired Level</label>
                  <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                    0 for disabled.
                  </span>
                </div>
                <input onChange={onChangeLevel} name="desiredLevel" className="credentials__input" value={desiredLevel}></input>
              </div>
              <div className="credentials-field">
                <div>
                  <label>Desired Percentage</label>
                </div>
                <input onChange={onChangeLevel} name="desiredPercentage" className="credentials__input" value={desiredPercentage}></input>
              </div>
              <div className="credentials-field">
                <div className="tooltip" style={{ border: "none" }}>
                  <label>Desired BE</label>
                  <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                    0 for disabled.
                  </span>
                </div>
                <input onChange={onChange} name="desiredBE" className="credentials__input" value={desiredBE}></input>
              </div>

              <div className="credentials-field">
                <div className="tooltip" style={{ border: "none" }}>
                  <label>Priority</label>
                  <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                    Bot will choose the higher priority when looking for accounts to play.
                  </span>
                </div>
                <input onChange={onChange} name="priority" className="credentials__input" value={priority}></input>
              </div>
            </div>
            <div className="credentials__info-row" style={{ fontSize: "0.9rem" }}>
              <div className="credentials__info-field">
                <label>Disenchant Loot</label>
                <input onChange={onChangeCheckBox} name="disenchantLoot" className="checkbox" type="checkbox" value={disenchantLoot} defaultChecked={disenchantLoot}></input>
              </div>
              <div className="credentials__info-field">
                <label>Fragment Champions</label>
                <input onChange={onChangeCheckBox} name="disenchantFragChamps" className="checkbox" type="checkbox" value={disenchantFragChamps} defaultChecked={disenchantFragChamps}></input>
              </div>
              <div className="credentials__info-field">
                <label>Full Champions</label>
                <input onChange={onChangeCheckBox} name="disenchantFullChamps" className="checkbox" type="checkbox" value={disenchantFullChamps} defaultChecked={disenchantFullChamps}></input>
              </div>
              <div className="credentials__info-field">
                <label>Disenchant Eternal</label>
                <input onChange={onChangeCheckBox} name="disenchantEternal" className="checkbox" type="checkbox" value={disenchantEternal} defaultChecked={disenchantEternal}></input>
              </div>
            </div>

            <div className="credentials__info-row">
              <div className="credentials__info-field">
                <label>Mision 1</label>
                <select className="credentials__input" name="1" onChange={onChangeMissions} defaultValue={missions["1"]}>
                  <option value="lux">Lux</option>
                  <option value="masteryi">Master Yi</option>
                  <option value="brand">Brand</option>
                  <option value="darius">Darius</option>
                  <option value="missfortune">Miss Fortune</option>
                </select>
              </div>
              <div className="credentials__info-field">
                <label>Mision 2</label>
                <select className="credentials__input" name="2" onChange={onChangeMissions} defaultValue={missions["2"]}>
                  <option value="tristana">Tristana</option>
                  <option value="ezreal">Ezreal</option>
                  <option value="caitlyn">Caitlyn</option>
                </select>
              </div>
              <div className="credentials__info-field">
                <label>Mision 3</label>
                <select className="credentials__input" name="3" onChange={onChangeMissions} defaultValue={missions["3"]}>
                  <option value="illaoi">Illaoi</option>
                  <option value="riven">Riven</option>
                  <option value="garen">Garen</option>
                </select>
              </div>
            </div>

            <div className="credentials__info-row">
              <div className="credentials__info-field">
                <label>Mision 4</label>
                <select className="credentials__input" name="4" onChange={onChangeMissions} defaultValue={missions["4"]}>
                  <option value="ziggs">Ziggs</option>
                  <option value="morgana">Morgana</option>
                  <option value="seraphine">Seraphine</option>
                </select>
              </div>
              <div className="credentials__info-field">
                <label>Mision 5</label>
                <select className="credentials__input" name="5" onChange={onChangeMissions} defaultValue={missions["5"]}>
                  <option value="thresh">Thresh</option>
                  <option value="sona">Sona</option>
                  <option value="nami">Nami</option>
                </select>
              </div>
              <div className="credentials__info-field">
                <label>Mision 6</label>
                <select className="credentials__input" name="6" onChange={onChangeMissions} defaultValue={missions["6"]}>
                  <option value="ekko">Ekko</option>
                  <option value="fizz">Fizz</option>
                  <option value="talon">Talon</option>
                </select>
              </div>
            </div>

            <div className="credentials__info-row" style={{ fontSize: "0.95rem" }}>
              {/* <div className='credentials__info-field'>
								<label>Random Lane</label>
								<input onChange={onChangeCheckBox} name='randomLanes' className='checkbox' type='checkbox' value={randomLanes} defaultChecked={randomLanes}></input>
							</div> */}

              {/* <div className='credentials__info-field'>
								<div className='tooltip' style={{ border: 'none' }}>
									<label>Skip if name taken</label>
									<span style={{ fontSize: '0.8rem' }} className='tooltiptext'>
										Bot will change accounts if summoner name is taken and flagged account as such.
									</span>
								</div>
								<input onChange={onChangeCheckBox} name='skipIfNameTaken' className='checkbox' type='checkbox' value={skipIfNameTaken} defaultChecked={skipIfNameTaken}></input>
							</div> */}
              {/* <div className='credentials__info-field'>
								<label>Kill Explorer</label>
								<input onChange={onChangeCheckBox} name='killExplorer' className='checkbox' type='checkbox' value={killExplorer} defaultChecked={killExplorer}></input>
							</div> */}
              <div className="credentials__info-field">
                <label>Use Custom Name</label>
                <input onChange={onChangeCheckBox} name="useCustomName" className="checkbox" type="checkbox" value={useCustomName} defaultChecked={useCustomName}></input>
              </div>
              <div className="credentials-field">
                <div className="tooltip" style={{ border: "none" }}>
                  <label>Icon Id</label>
                  <span style={{ fontSize: "0.8rem" }} className="tooltiptext">
                    Icon id to set after account reaches desired level.
                  </span>
                </div>
                <input onChange={onChange} name="icon" className="credentials__input" value={icon}></input>
              </div>

              <div className="credentials__info-field">
                <label>Disenchant Emblems</label>
                <input onChange={onChangeCheckBox} name="disenchantEmblems" className="checkbox" type="checkbox" value={disenchantEmblems} defaultChecked={disenchantEmblems}></input>
              </div>
              {disenchantEmblems && (
                <div className="credentials__info-field">
                  <label>Emblems Option</label>
                  <select className="credentials__input" name="disenchantEmblemsOption" onChange={onChangeFlash} defaultValue={disenchantEmblemsOption}>
                    <option value="be">BE</option>
                    <option value="orb">Orb</option>
                  </select>
                </div>
              )}

              {/* <div className='credentials__info-field'>
								<label>Space Groove Team</label>
								<select className='credentials__input' name='spaceGrooveOption' onChange={onChangeFlash} defaultValue={spaceGrooveOption}>
								<option value='TheGroove_series'>The Groove</option>
								<option value='HarshVibes_series'>Harsh Vibes</option>
								</select>
							</div> */}
            </div>

            <div className="credentials__info-row">
              <div className="credentials__info-field">
                <label>Skip Tutorial</label>
                <input onChange={onChangeCheckBox} name="skipTutorial" className="checkbox" type="checkbox" value={skipTutorial} defaultChecked={skipTutorial}></input>
              </div>
              <div className="credentials__info-field">
                <label>Flash on</label>
                <select className="credentials__input" name="flashOn" onChange={onChangeFlash} defaultValue={flashOn}>
                  <option value="d">D</option>
                  <option value="f">F</option>
                  <option value="random">Random</option>
                </select>
              </div>
              <div className="credentials__info-field">
                <label>Disenchant Gem</label>
                <input onChange={onChangeCheckBox} name="disenchantGem" className="checkbox" type="checkbox" value={disenchantGem} defaultChecked={disenchantGem}></input>
              </div>
              {disenchantGem && (
                <div className="credentials__info-field">
                  <label>Gem Option</label>
                  <select className="credentials__input" name="disenchantGemOption" onChange={onChangeFlash} defaultValue={disenchantGemOption}>
                    <option value="be">BE</option>
                    <option value="skin">Skin</option>
                    <option value="oe">Orange Essence</option>
                  </select>
                </div>
              )}
              {/* <div className='credentials__info-field'>
								<label>Free Name Change</label>
								<input onChange={onChangeCheckBox} name='freeNameChange' className='checkbox' type='checkbox' value={freeNameChange} defaultChecked={freeNameChange}></input>
							</div> */}
            </div>
            <div className="credentials__info-row">
              <div className="credentials__info-field">
                <label>Max Games per session</label>
                <input onChange={onChangeNumber} name="maxGames" className="credentials__input" value={maxGames}></input>
              </div>
              <div className="credentials__info-field">
                <label>Cooldown (hours)</label>
                <input onChange={onChangeCooldownAfterSession} name="cooldownAfterSession" className="credentials__input" value={cooldownAfterSessionInHours}></input>
              </div>

              <div className="credentials__info-field">
                <label>Games To Finish</label>
                <input onChange={onChangeGamesToFinish} name="amountOfGamesToFinish" className="credentials__input" value={newAccountTemplate.amountOfGamesToFinish.amountOfGames}></input>
              </div>
            </div>
            <div className="credentials__info-row">
              <div className="credentials__info-field" style={{ fontSize: "0.9rem" }}>
                <label>Restart Ingame After (sec)</label>
                <input onChange={onChangeNumber} name="restartIngameAfter" className="credentials__input" value={newAccountTemplate.restartIngameAfter}></input>
              </div>
              <div className="credentials__info-field" style={{ fontSize: "0.9rem" }}>
                <label>Wait Before Pick Champ (sec)</label>
                <input onChange={onChangeNumber} name="waitBeforePickChamp" className="credentials__input" value={newAccountTemplate.waitBeforePickChamp}></input>
              </div>
              <div className="credentials__info-field">
                <label>Desired Tutos</label>
                <input onChange={onChangeNumber} name="desiredTutos" className="credentials__input" value={newAccountTemplate.desiredTutos}></input>
              </div>
            </div>
            <div className="credentials__info-row">
              <div className="credentials__info-field">
                <label>Mode:</label>
                <select className="credentials__input" name="mode" onChange={onChangeFlash} defaultValue={mode}>
                  <option value="normal">Normal</option>
                  <option value="disenchant">Disenchant</option>
                </select>
              </div>
              <div className="credentials__info-field">
                <label>League Client Mode:</label>
                <select className="credentials__input" name="leagueClientMode" onChange={onChangeNumber} defaultValue={newAccountTemplate.leagueClientMode}>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                </select>
              </div>
              <div className="credentials__info-field">
                <div className="tooltip" style={{ border: "none" }}>
                  <label>Label</label>
                  <span style={{ fontSize: "1rem", left: "-300px" }} className="tooltiptext">
                    Set a label name to identify your accounts easily by filtering them enabling <br></br>
                    <span style={{ fontWeight: "bold", color: "#4caf50" }}>Show Search Skin, Summoner Name and Labels</span> option in User Settings.
                  </span>
                </div>
                <input onChange={onChangeLabel} name="label" className="credentials__input" value={label} placeholder="account label"></input>
              </div>
              {/* <div className="credentials__info-field">
                <label>Black/White list Champs</label>
                <input onChange={onChangeBlackWhiteListCheckbox} name="blackWhiteListChamp" className="checkbox" type="checkbox" value={blackWhiteListChamp.enabled} defaultChecked={blackWhiteListChamp.enabled}></input>
              </div>
              {blackWhiteListChamp.enabled && (
                <>
                  <div className="credentials__info-field">
                    <label>Option</label>
                    <select className="credentials__input" name="blackWhiteListChamp" onChange={onChangeBlackWhiteListOption} defaultValue={blackWhiteListChamp.option}>
                      <option value="whitelist">Whitelist</option>
                      <option value="blacklist">Blacklist</option>
                    </select>
                  </div>
                  <div className="credentials__info-field">
                    <label>Champions</label>
                    <select className="credentials__input" name="blackWhiteListChamp" onChange={onChangeBlackWhiteListIds} value={blackWhiteListChamp.ids}>
                      <option value={1}>Annie</option>
                      <option value={42}>Corki</option>
                      <option value={29}>Twitch</option>
                      <option value={222}>Jinx</option>
                      <option value={110}>Varus</option>
                      <option value={40}>Janna</option>
                      <option value={126}>Jayce</option>
                      <option value={202}>Jhin</option>
                      <option value={134}>Syndra</option>
                      <option value={45}>Veigar</option>
                      <option value={115}>Ziggs</option>
                      <option value={13}>Ryze</option>
                      <option value={235}>Senna</option>
                      <option value={133}>Quinn</option>
                      <option value={122}>Darius</option>
                      <option value={11}>Master Yi</option>
                      <option value={7}>Le Blanc</option>
                      <option value={4}>Twisted Fate</option>
                      <option value={34}>Anivia</option>
                      <option value={145}>Kai'Sa</option>
                      <option value={61}>Orianna</option>
                      <option value={203}>Kindred</option>
                      <option value={127}>Lissandra</option>
                      <option value={112}>Viktor</option>
                      <option value={163}>Taliyah</option>
                      <option value={429}>Kalista</option>
                      <option value={161}>Velkoz</option>
                      <option value={43}>Karma</option>
                      <option value={412}>Thresh</option>
                      <option value={101}>Xerath</option>
                      <option value={432}>Bard</option>
                      <option value={17}>Teemo</option>
                      <option value={90}>Malzahar</option>
                      <option value={9}>Fiddlesticks</option>
                      <option value={30}>Karthus</option>
                      <option value={25}>Morgana</option>
                      <option value={267}>Nami</option>
                      <option value={8}>Vladimir</option>
                      <option value={104}>Graves</option>
                      <option value={51}>Caitlyn</option>
                      <option value={427}>Ivern</option>
                      <option value={69}>Cassiopeia</option>
                      <option value={498}>Xayah</option>
                      <option value={18}>Tristana</option>
                      <option value={81}>Ezreal</option>
                      <option value={21}>Miss Fortune</option>
                      <option value={85}>Kennen</option>
                      <option value={15}>Sivir</option>
                      <option value={67}>Vayne</option>
                      <option value={119}>Draven</option>
                      <option value={22}>Ashe</option>
                      <option value={103}>Ahri</option>
                      <option value={37}>Sona</option>
                      <option value={16}>Soraka</option>
                      <option value={76}>Nidalee</option>
                      <option value={86}>Garen</option>
                      <option value={150}>Gnar</option>
                      <option value={63}>Brand</option>
                      <option value={92}>Riven</option>
                      <option value={523}>Aphelios</option>
                      <option value={136}>Aurelion Sol</option>
                      <option value={268}>Azir</option>
                      <option value={35}>Shaco</option>

                      <option value={143}>Zyra</option>
                      <option value={142}>Zoe</option>
                      <option value={26}>Zilean</option>
                      <option value={420}>Illaoi</option>
                      <option value={99}>Lux</option>
                      <option value={117}>Lulu</option>
                      <option value={236}>Lucian</option>
                      <option value={96}>KogMaw</option>
                      <option value={518}>Neeko</option>
                    </select>
                  </div>
                </>
              )} */}
            </div>
            {/* {blackWhiteListChamp.enabled && (
              <div className="credentials__info-row">
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", gridGap: "20px", width: "100%" }}>
                  {blackWhiteListChamp.ids.map((id) => (
                    <div key={id} style={{ fontSize: ".9rem" }}>
                      {getChampionName(id)}
                      <i className="fas fa-times" style={{ cursor: "pointer", fontSize: ".8rem", marginLeft: ".25em", color: "rgb(151, 0, 0)" }} onClick={() => onClickRemoveChampId(id)}></i>
                    </div>
                  ))}
                </div>
              </div>
            )} */}

            <div className="credentials__info-row">
              <div className="credentials__info-field">
                <label>Limit Resources</label>
                <input onChange={onChangeLimitRes} name="limitResources" className="credentials__input" value={limitResources}></input>
              </div>

              <div className="credentials__info-field">
                <label>Complete All Missions</label>
                <input onChange={onChangeCheckBox} name="completeAllMissions" className="checkbox" type="checkbox" value={completeAllMissions} defaultChecked={completeAllMissions}></input>
              </div>
              {completeAllMissions && (
                <div className="credentials__info-field">
                  <label>Missions to Complete</label>
                  <input onChange={onChangeNumber} name="completeMissionUntil" className="credentials__input" value={completeMissionUntil}></input>
                </div>
              )}
            </div>
            <div className="credentials__info-row">
              <div className="credentials__info-field">
                <label>Use Private Party</label>
                <input onChange={onChangeCheckBox} name="usePrivateParty" className="checkbox" type="checkbox" value={usePrivateParty} defaultChecked={usePrivateParty}></input>
              </div>
              <div className="credentials__info-field">
                <label>Temp Number</label>
                <input onChange={onChangeNumber} name="crazyNumber" className="credentials__input" value={crazyNumber}></input>
              </div>
            </div>
            <div style={{ marginTop: "15px" }} className="credentials__info-row">
              <div className="buttonGroup">
                <button onClick={() => formatAccounts(accountsCredentials)} className="credentials__button aqua-green anim">
                  <span>Add Accounts</span>
                </button>
              </div>
            </div>
            {error && (
              <div className="credentials__info-row">
                <div className="credentials__info-button">
                  <h2 style={{ color: "red" }}>{error}</h2>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="main">
          <Spinner />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  history: props.history,
});

export default withRouter(connect(mapStateToProps, { addAccounts })(AddAccounts));

// {accounts.map(acc=> (

//   ))}
