import React, { Fragment, useEffect } from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "./routing/PrivateRoute";
import Login from "./Login";
import Register from "./Register";
import TermsOfUsePublic from "./TermsOfUsePublic";

import DashboardHandler from "./Dashboard/DashboardHandler";
import CredentialsOverview from "./Credentials/CredentialsOverview";
import UsersHandler from "./Users/UsersHandler";
import Home from "./Home";
import SettingsOverview from "./SettingsOverview";
import WhitelistIps from "./WhitelistIps/WhitelistIps";
import BuyLicenses from "./BuyLicenses/BuyLicenses";

import Servers from "./Servers/Servers";
import History from "./History/History";
import CustomNames from "./CustomNames/CustomNames";
import Tasks from "./Tasks/Tasks";

import { connect } from "react-redux";
import { loadUser } from "../actions/authActions";
const AppWrapper = ({ loadUser }) => {
  useEffect(() => {
    loadUser();

    //eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/credentials" component={CredentialsOverview} />
      <PrivateRoute exact path="/settings" component={SettingsOverview} />
      <PrivateRoute exact path="/servers" component={Servers} />
      <PrivateRoute exact path="/history" component={History} />
      <PrivateRoute exact path="/whitelist-ips" component={WhitelistIps} />

      <PrivateRoute exact path="/dashboard" component={DashboardHandler} />
      <PrivateRoute exact path="/users" component={UsersHandler} />
      <PrivateRoute exact path="/names" component={CustomNames} />
      <PrivateRoute exact path="/buy" component={BuyLicenses} />

      <PrivateRoute exact path="/tasks" component={Tasks} />

      <Route exact path="/terms-of-use" component={TermsOfUsePublic} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
    </Fragment>
  );
};

export default connect(null, { loadUser })(AppWrapper);
