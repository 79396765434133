import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { updateAccount, deleteAccounts } from "../../actions/accountActions";
import Spinner from "../Spinner";
import formatTime from "../../utils/formatTime";
// import setSkinName from "../../utils/setSkinName";
// import skinsData from "../../utils/skinsData";

import styles from "./ExportAccounts.module.scss";
const ExportAccounts = ({ user, accounts, setShowingExportAccs, deleteAccounts, skinsData, labels }) => {
  const inputSkinSearch = useRef(null);
  const [summonerNameSearch, setSummonerNameSearch] = useState("");
  const [skinNameSearch, setSkinNameSearch] = useState("");

  const onChangeSummonerNameSearch = (e) => {
    setSummonerNameSearch(e.target.value);
  };

  const onChangeSkinSearch = (e) => {
    setSkinNameSearch(e.target.value);
  };
  const textArea = useRef("");
  const [options, setOptions] = useState(
    JSON.parse(localStorage.getItem("export-settings"))
      ? JSON.parse(localStorage.getItem("export-settings"))
      : {
          summonerName: true,
          displayName: false,
          priority: false,
          password: true,
          region: true,
          currentLevel: false,
          blueEssence: false,
          orangeEssence: false,
          matchmakingStatus: false,
          skins: false,
          skinsIds: false,
          rp: false,
          filterByRegion: false,
          filterByQuota: false,
          flashOn: false,
          filterByDisenchanted: false,
          filterByMissionWait: false,
          filterByDisenchantType: false,
          filterByPriority: false,
          filterByFreeNameChange: false,
          filterByMissionsCompletedAmount: false,
          filterByActive: false,
          missionsCompleted: false,
          amountMissions: 0,
          separator: ":",
          skinSeparator: ",",
          priority: 0,
          priorityValue: 0,
          dateOfBirth: false,
          email: false,
          creationDate: false,
          countryCode: false,
          sort: false,
          // deleteAfter: false,
          filterByBERange: false,
          startBEAt: 0,
          stopBEAt: 70000,
          filterByLevelRange: false,
          startLevelAt: 0,
          stopLevelAt: 30,
          filterByRpRange: false,
          startRPAt: 0,
          stopRPAt: 1000,
          filterBySkinAmount: false,
          startSkinAmountAt: 0,
          stopSkinAmountAt: 20,
          sortType: "asc",
          label: "-",
          sortByName: "region",
          sortBy: {
            currentLevel: false,
            blueEssence: false,
            orangeEssence: false,
            lastGamePlayed: false,
            skins: false,
            region: true,
            be: false,
            flashOn: false,
          },
        }
  );
  const [deleteAfter, setDeleteAfter] = useState(localStorage.getItem("deleteAfter") ? localStorage.getItem("deleteAfter") : "yes");
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [idsToDeleteAfterClosing, setIdsToDeleteAfterClosing] = useState([]);
  const [regionFilter, setRegionFilter] = useState(JSON.parse(localStorage.getItem("region-settings")) ? JSON.parse(localStorage.getItem("region-settings")) : "na");
  const [disenchantTypeFilter, setDisenchantTypeFilter] = useState(JSON.parse(localStorage.getItem("disenchant-settings")) ? JSON.parse(localStorage.getItem("disenchant-settings")) : "BE");
  const [activeFilter, setActiveFilter] = useState(JSON.parse(localStorage.getItem("active-settings")) ? JSON.parse(localStorage.getItem("active-settings")) : "notactive");
  const [filterFreeNameChange, setFilterFreeNameChange] = useState("yes");
  const [maxAmountToExport, setMaxAmountToExport] = useState(accounts.length);

  const [mirrorMode, setMirrorMode] = useState(false);
  const [templateTextArea, setTemplateTextArea] = useState(JSON.parse(localStorage.getItem("templateTextArea")) ? JSON.parse(localStorage.getItem("templateTextArea")) : "${summonerName}:${password}:${region}");

  const [mirrorTextarea, setMirrorTextarea] = useState("");
  const [label, setLabel] = useState("-");
  const [showingTemplate, setShowingTemplate] = useState(JSON.parse(localStorage.getItem("showingTemplate")) ? JSON.parse(localStorage.getItem("showingTemplate")) : false);
  const onChangeTextarea = (event) => {
    setTemplateTextArea(event.target.value);
    localStorage.setItem("templateTextArea", JSON.stringify(event.target.value));
  };
  const onChangeTextareaMirror = (event) => {
    setMirrorTextarea(event.target.value);
  };
  const [isLoading, setIsLoading] = useState(false);

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([formattedAccounts.map((acc) => acc.formattedAcc).join("")], { type: "text/plain" });
    element.href = URL.createObjectURL(file);

    const region = options.filterByRegion ? regionFilter + " -" : false;
    const date = formatTime(Date.now());

    let regionsAndAmount = "";
    for (let [key, value] of Object.entries(statsPerRegion)) {
      if (key != "BE" && key != "nonBE") {
        if (statsPerRegion[key]) {
          regionsAndAmount += `${value} ${key.toUpperCase()}, `;
        }
      }
    }
    regionsAndAmount = regionsAndAmount.substring(0, regionsAndAmount.length - 2);
    element.download = `${regionsAndAmount} ~ ${date}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const copyToClipboard = () => {
    textArea.current.select();
    document.execCommand("copy");
  };
  const onClickExport = async () => {
    if (formattedAccounts.length > 0) {
      // setIsLoading(true);
      // copyToClipboard();
      if (deleteAfter === "yes") {
        setIdsToDeleteAfterClosing([...idsToDeleteAfterClosing, ...idsToDelete]);
        await deleteAccounts(idsToDelete, false);

        // await deleteAccounts(idsToDelete);
      }
      // setShowingExportAccs(false);
      downloadTxtFile();
    }
  };
  const onChangeLabel = (event) => {
    setOptions({ ...options, label: event.target.value });
    localStorage.setItem("export-settings", JSON.stringify({ ...options, label: event.target.value }));
  };
  const onClickCancel = async () => {
    setShowingExportAccs(false);
    if (idsToDeleteAfterClosing.length > 0) {
      // console.log('deleting...');
      // console.log(idsToDeleteAfterClosing);
      await deleteAccounts(idsToDeleteAfterClosing, true);
    }
  };
  const onChange = (event) => {
    setOptions({ ...options, [event.target.name]: event.target.checked });
    localStorage.setItem("export-settings", JSON.stringify({ ...options, [event.target.name]: event.target.checked }));
  };
  const onChangeSeparator = (event) => {
    setOptions({ ...options, [event.target.name]: event.target.value });
    localStorage.setItem("export-settings", JSON.stringify({ ...options, [event.target.name]: event.target.value }));
  };

  const onChangeLabelInput = (event) => {
    setOptions({ ...options, [event.target.name]: event.target.value });
    localStorage.setItem("export-settings", JSON.stringify({ ...options, [event.target.name]: event.target.value }));
  };

  const onChangeMaxAmount = (event) => {
    if (!isNaN(event.target.value) || event.target.value === "") {
      setMaxAmountToExport(event.target.value);
    }
  };
  const onChangePriority = (event) => {
    if (!isNaN(event.target.value) || event.target.value === "") {
      setOptions({ ...options, [event.target.name]: event.target.value });
      localStorage.setItem("export-settings", JSON.stringify({ ...options, [event.target.name]: event.target.value }));
    }
  };
  const onChangeRegionFilter = (event) => {
    setRegionFilter(event.target.value);
    localStorage.setItem("region-settings", JSON.stringify(event.target.value));
  };
  const onChangeShowingTemplate = () => {
    setShowingTemplate(!showingTemplate);
    localStorage.setItem("showingTemplate", JSON.stringify(!showingTemplate));
  };
  const onChangeDisenchantFilter = (event) => {
    setDisenchantTypeFilter(event.target.value);
    localStorage.setItem("disenchant-settings", JSON.stringify(event.target.value));
  };
  const onChangeActiveFilter = (event) => {
    setActiveFilter(event.target.value);
    localStorage.setItem("active-settings", JSON.stringify(event.target.value));
  };
  const onChangeFreeNameChange = (event) => {
    setFilterFreeNameChange(event.target.value);
  };

  const [change, setChange] = useState(true);
  const [regionFormat, setRegionFormat] = useState(JSON.parse(localStorage.getItem("regionformat-settings")) ? JSON.parse(localStorage.getItem("regionformat-settings")) : "yes");

  const onChangeRegionFormat = () => {
    localStorage.setItem("regionformat-settings", JSON.stringify(regionFormat === "yes" ? "no" : "yes"));
    setRegionFormat(regionFormat === "yes" ? "no" : "yes");
  };
  const regionsFormatted = {
    lan: "lan",
    kr: "kr",
    tr: "tr",
    ru: "ru",
    br: "br",
    las: "las",
    euw: "euw",
    jp: "jp",
    na: "na",
    eune: "eun",
    oce: "oc",
    ph: "ph",
    sg: "sg",
    th: "th",
    tw: "tw",
    vn: "vn",
  };
  // console.log(activeFilter);
  const eraseSkinSearch = () => {
    setSkinNameSearch("");
    setChange(!change);
  };
  const eraseSummonerNameSearch = () => {
    setSummonerNameSearch("");
    setChange(!change);
  };
  // const onChangeDisenchantType = (event) => {
  //   setOptions({ ...options, [event.target.name]: event.target.checked });

  //   disenchantType
  //   localStorage.setItem('region-settings', JSON.stringify(event.target.value));
  // };
  const rarities = {
    DEFAULT: 1,
    RARE: 2,
    EPIC: 3,
    LEGENDARY: 4,
    MYTHIC: 5,
    ULTIMATE: 6,
  };
  const setSkinName = (skin) => {
    if (typeof skin === "string") {
      return skin;
    } else {
      if (skinsData[skin.id]) {
        if (user.skinLanguage != "-") {
          return skinsData[skin.id][user.skinLanguage];
        } else {
          return skin.name;
        }
      } else {
        return String(skin.id);
      }
    }
  };
  let formatSkins = (skins) => {
    skins.sort((a, b) => rarities[b.rarity] - rarities[a.rarity]);
    if (typeof skins[0] === "string") {
      return skins.join(options["skinSeparator"]);
    } else {
      return skins
        .map((skin) => {
          let skinRarity = user.showSkinRarityExport ? ` (${skin.rarity})` : "";
          return `${setSkinName(skin)}${skinRarity}`;
        })
        .join(options["skinSeparator"]);
      // return skins.map((skin) => `${skin.name} (${skin.rarity})`).join("-");
    }
  };
  let formatSkinsIds = (skins) => {
    skins.sort((a, b) => rarities[b.rarity] - rarities[a.rarity]);

    if (typeof skins[0] === "string") {
      return skins.join(options["skinSeparator"]);
    } else {
      return skins.map((skin) => `${skin.id}`).join(options["skinSeparator"]);
    }
  };
  function get(path, obj, fb = `$\{${path}}`) {
    return path.split(".").reduce((res, key) => res[key] || "", obj);
  }

  function parseTpl(template, map, fallback) {
    return template.replace(/\$\{.+?}/g, (match) => {
      const path = match.substr(2, match.length - 3).trim();
      return get(path, map, fallback);
    });
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setChange(!change);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [mirrorTextarea]);

  const formatAccounts = (accounts_) => {
    // console.log("Init FormatAccounts");
    let accounts = accounts_;
    if (options.sort) {
      Object.keys(options.sortBy).forEach((key) => {
        if (options.sortBy[key]) {
          switch (key) {
            case "lastGamePlayed":
              if (options.sortType === "asc") {
                accounts_.sort((a, b) => (a.lastTimePlayed > b.lastTimePlayed ? 1 : -1));
              } else if (options.sortType === "dsc") {
                accounts_.sort((a, b) => (a.lastTimePlayed < b.lastTimePlayed ? 1 : -1));
              }
              break;

            case "flashOn":
              if (options.sortType === "asc") {
                accounts_.sort((a, b) => a.flashOn.localeCompare(b.flashOn));
              } else if (options.sortType === "dsc") {
                accounts_.sort((a, b) => -1 * a.flashOn.localeCompare(b.flashOn));
              }
              break;

            case "be":
              if (options.sortType == "asc") {
                accounts_.sort((a, b) => a.disenchantLoot.toString().localeCompare(b.disenchantLoot));
              } else if (options.sortType == "dsc") {
                accounts_.sort((a, b) => -1 * a.disenchantLoot.toString().localeCompare(b.disenchantLoot));
              }
              break;
            case "currentLevel":
              if (options.sortType == "asc") {
                accounts_.sort((a, b) => (a.currentLevel > b.currentLevel ? 1 : a.currentLevel === b.currentLevel ? (a.nextLevelPercentage < b.nextLevelPercentage ? 1 : -1) : -1));
              } else if (options.sortType == "dsc") {
                accounts_.sort((a, b) => (a.currentLevel < b.currentLevel ? 1 : a.currentLevel === b.currentLevel ? (a.nextLevelPercentage < b.nextLevelPercentage ? 1 : -1) : -1));
              }

              break;
            case "blueEssence":
              if (options.sortType == "asc") {
                accounts_.sort((a, b) => (a.blueEssence > b.blueEssence ? 1 : -1));
              } else if (options.sortType == "dsc") {
                accounts_.sort((a, b) => (a.blueEssence < b.blueEssence ? 1 : -1));
              }
              break;
            case "orangeEssence":
              if (options.sortType == "asc") {
                accounts_.sort((a, b) => (a.orangeEssence > b.orangeEssence ? 1 : -1));
              } else if (options.sortType == "dsc") {
                accounts_.sort((a, b) => (a.orangeEssence < b.orangeEssence ? 1 : -1));
              }
              break;
            case "skins":
              if (options.sortType == "asc") {
                accounts_.sort((a, b) => (a.skins.length > b.skins.length ? 1 : a.skins.length === b.skins.length ? (a.blueEssence < b.blueEssence ? 1 : -1) : -1));
              } else if (options.sortType == "dsc") {
                accounts_.sort((a, b) => (a.skins.length < b.skins.length ? 1 : a.skins.length === b.skins.length ? (a.blueEssence < b.blueEssence ? 1 : -1) : -1));
              }
              break;
            case "region":
              if (options.sortType == "asc") {
                accounts_.sort((a, b) => a.region.localeCompare(b.region));
              } else if (options.sortType == "dsc") {
                accounts_.sort((a, b) => -1 * a.region.localeCompare(b.region));
              }
              break;
          }
        }
      });
    }
    let accs = [];
    let mirrorAccs = [];
    mirrorAccs = mirrorTextarea ? mirrorTextarea.toLowerCase().split("\n") : [];
    // console.log(mirrorAccs, mirrorTextarea);

    for (let i = 0; i < accounts.length; i++) {
      let acc = accounts[i];

      // Mirror Mode
      if (mirrorAccs.length > 0) {
        if (!mirrorAccs.includes(`${acc.summonerName.toLowerCase()}:${acc.region.toLowerCase()}`)) {
          continue;
        }
      }
      // Skin Search
      if (skinNameSearch != "") {
        // console.log("here1");
        let foundSkin = false;
        // console.log('ACA')
        for (let a = 0; a < acc.skins.length; a++) {
          let skin = acc.skins[a];
          // let skinName = skin.name.toLowerCase();
          // let skinName = setSkinName(skin).toLowerCase();
          //
          let skinName = "";

          if (typeof skin === "string") {
            skinName = skin;
          } else {
            if (skinsData[skin.id]) {
              if (user.skinLanguage != "-") {
                skinName = skinsData[skin.id][user.skinLanguage];
              } else {
                skinName = skin.name;
              }
            } else {
              skinName = String(skin.id);
            }
          }

          if (skinName != undefined) {
            skinName = skinName.toLowerCase();
            //
            for (let skinSearch_ of skinNameSearch.toLowerCase().split(",")) {
              // console.log(skinName);
              let rarity = skin.rarity.toLowerCase();
              skinName = skinName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              if (skinName.includes(skinSearch_.toLowerCase()) || rarity.includes(skinSearch_.toLowerCase())) {
                foundSkin = true;
                break;
              }
            }
            // let rarity = skin.rarity.toLowerCase();
            // skinName = skinName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            // if (skinName.includes(skinNameSearch.toLowerCase()) || rarity.includes(skinNameSearch.toLowerCase())) {
            //   foundSkin = true;
            //   break;
            // }
          }
        }

        if (!foundSkin) {
          continue;
        }
      }
      // Name Search
      if (summonerNameSearch != "") {
        // console.log("here2");
        if (!acc.summonerName.toLowerCase().includes(summonerNameSearch.toLowerCase())) {
          continue;
        }
      }
      // Label
      // console.log(options["label"], acc.label);
      if (options["label"] != "-" && options["label"] != acc.label) {
        continue;
      }
      // Filter by Active status

      let condition3 = options["filterByActive"] && activeFilter === "active" && !acc["isActive"];
      let condition4 = options["filterByActive"] && activeFilter === "notactive" && acc["isActive"];
      if (condition3 || condition4) {
        continue;
      }

      // Filter by Disenchant Type
      let condition1 = options["filterByDisenchantType"] && disenchantTypeFilter === "BE" && !acc["disenchantLoot"];
      let condition2 = options["filterByDisenchantType"] && disenchantTypeFilter === "capsules" && acc["disenchantLoot"];
      if (condition1 || condition2) {
        continue;
      }

      let allowedStatuses = [];
      options["filterByQuota"] && allowedStatuses.push("quota-finished");
      options["filterByDisenchanted"] && allowedStatuses.push("disenchanted");
      options["filterByMissionWait"] && allowedStatuses.push("mission-wait");

      if (options["filterByQuota"] || options["filterByDisenchanted"] || options["filterByMissionWait"]) {
        if (!allowedStatuses.includes(acc["status"])) {
          continue;
        }
      }
      // Filter by FreeNameChange

      if (options["filterByFreeNameChange"]) {
        if (filterFreeNameChange === "yes") {
          if (acc["displayName"] != "") {
            // no free name change
            continue;
          }
        } else {
          if (acc["displayName"] == "") {
            continue;
          }
        }
      }
      // Filter by region
      if (options["filterByRegion"] && acc.region.toLowerCase() != regionFilter.toLowerCase()) {
        continue;
      }
      // Filter By Priority
      if (options["filterByPriority"] && acc.priority != options["priorityValue"]) {
        continue;
      }
      // Filter By Missions
      if (options["filterByMissionsCompletedAmount"] && (!acc.missionTracker || acc.missionTracker["completed"] != options["amountMissions"])) {
        continue;
      }
      // if (!acc.missionTracker ||  && acc.missionTracker["completed"] != options["amountMissions"])) {
      //   continue;
      // }
      // Filter by Level range
      if (options["filterByLevelRange"] && (acc.currentLevel < options["startLevelAt"] || acc.currentLevel > options["stopLevelAt"])) {
        continue;
      }
      // Filter by BE range
      if (options["filterByBERange"] && (acc.blueEssence < options["startBEAt"] || acc.blueEssence > options["stopBEAt"])) {
        continue;
      }
      // Filter by RP range
      if (options["filterByRpRange"] && (acc.rp < options["startRPAt"] || acc.rp > options["stopRPAt"])) {
        continue;
      }
      // Filter by Skin amount range
      if (options["filterBySkinAmount"] && (acc.skins.length < options["startSkinAmountAt"] || acc.skins.length > options["stopSkinAmountAt"])) {
        continue;
      }
      let accountInfo = {};
      let sp = options["separator"];
      let line = "";
      if (showingTemplate) {
        try {
          const summonerName = acc.summonerName;
          const priority = acc.priority;
          const displayName = acc.displayName;
          const password = acc.password;
          const region = regionFormat === "no" ? regionsFormatted[acc.region].toUpperCase() : acc.region.toUpperCase();
          const currentLevel = acc.currentLevel;
          const blueEssence = acc.blueEssence;
          const orangeEssence = acc.orangeEssence;

          const matchmakingStatus = acc.matchmakingStatus;
          const skins = formatSkins(acc.skins);
          const skinsIds = formatSkinsIds(acc.skins);

          const flashOn = acc.flashOn.toUpperCase();
          const email = acc.email;
          const dateOfBirth = acc.dateOfBirth;
          const creationDate = acc.creationDate;
          const countryCode = acc.countryCode;
          const lastGamePlayed = formatTime(acc.lastTimePlayed);

          line = `${parseTpl(templateTextArea, { summonerName, priority, displayName, password, region, currentLevel, orangeEssence, blueEssence, matchmakingStatus, lastGamePlayed, skins, skinsIds, flashOn, email, dateOfBirth, creationDate, countryCode })}\n`;
          // console.log(line);
          // line = eval('`' + templateTextArea + '`' + '\n');
          // console.log(templateTextArea);
        } catch (error) {
          console.log(error);
        }
      } else {
        accountInfo.summonerName = options["summonerName"] ? acc.summonerName : "";
        accountInfo.priority = options["priority"] ? acc.priority : "";
        accountInfo.displayName = options["displayName"] ? acc.displayName : "";
        accountInfo.password = options["password"] ? acc.password : "";
        accountInfo.region = options["region"] ? (regionFormat === "no" ? regionsFormatted[acc.region].toUpperCase() : acc.region.toUpperCase()) : "";
        accountInfo.currentLevel = options["currentLevel"] ? acc.currentLevel : "";
        accountInfo.blueEssence = options["blueEssence"] ? acc.blueEssence : "";
        accountInfo.orangeEssence = options["orangeEssence"] ? acc.orangeEssence : "";

        accountInfo.matchmakingStatus = options["matchmakingStatus"] ? acc.matchmakingStatus : "";
        accountInfo.skins = options["skins"] ? formatSkins(acc.skins) : "";
        accountInfo.skinsIds = options["skinsIds"] ? formatSkinsIds(acc.skins) : "";

        accountInfo.flashOn = options["flashOn"] ? acc.flashOn.toUpperCase() : "";
        accountInfo.email = options["email"] ? acc.email : "";
        accountInfo.dateOfBirth = options["dateOfBirth"] ? acc.dateOfBirth : "";
        accountInfo.creationDate = options["creationDate"] ? acc.creationDate : "";
        accountInfo.countryCode = options["countryCode"] ? acc.countryCode : "";
        accountInfo.missionsCompleted = options["missionsCompleted"] ? (acc.missionTracker ? acc.missionTracker["completed"] : "-") : "";
        accountInfo.lastGamePlayed = options["lastGamePlayed"] ? (acc.lastTimePlayed > 0 ? formatTime(acc.lastTimePlayed) : "No Last Game") : "";
        accountInfo.rp = options["rp"] ? acc.rp : "";

        line = `${user.exportFormat.map((conf, a) => (accountInfo[conf.id] === "" || a == 0 || (!options[user.exportFormat[0].id] && a == 1) ? "" : sp) + accountInfo[conf.id]).join("")}\n`;
      }
      acc.formattedAcc = line;
      accs.push(acc);
    }
    // console.log("Length of accs: ", accs.length)
    // setBeOrCapsule(beOrCapsule_);
    let splittedAccs = accs.slice(0, maxAmountToExport);
    if (splittedAccs.length > 0) {
      splittedAccs[splittedAccs.length - 1].formattedAcc = splittedAccs[splittedAccs.length - 1].formattedAcc.substring(0, splittedAccs[splittedAccs.length - 1].formattedAcc.length - 1);
    }
    // console.log(splittedAccs);
    return splittedAccs;
  };
  // console.log(filterFreeNameChange, options['filterByFreeNameChange'])
  const [formattedAccounts, setFormattedAccounts] = useState(formatAccounts([...accounts]));
  const onClickChangeSorting = (e) => {
    let newSorts = options.sortBy;
    Object.keys(newSorts).forEach((key) => {
      if (key === e.target.value) {
        if (newSorts[key]) {
          newSorts[key] = false;
        } else {
          newSorts[key] = true;
        }
      } else {
        newSorts[key] = false;
      }
    });

    setOptions({ ...options, sortBy: newSorts, sortByName: e.target.value });
    localStorage.setItem("export-settings", JSON.stringify({ ...options, sortBy: newSorts, sortByName: e.target.value }));
  };
  const onClickChangeSortingType = (e) => {
    let newSortingType = e.target.value;
    setOptions({ ...options, sortType: newSortingType });
    localStorage.setItem("export-settings", JSON.stringify({ ...options, sortType: newSortingType }));
  };
  useEffect(() => {
    // console.log("First UseEffect")
    setFormattedAccounts(formatAccounts([...accounts]));
  }, [change, options, regionFilter, disenchantTypeFilter, activeFilter, filterFreeNameChange, maxAmountToExport, mirrorMode]);
  // console.log(disenchantTypeFilter);
  const [statsPerRegion, setStatsPerRegion] = useState({
    na: 0,
    las: 0,
    euw: 0,
    lan: 0,
    br: 0,
    kr: 0,
    oce: 0,
    jp: 0,
    eune: 0,
    ru: 0,
    tr: 0,
    ph: 0,
    sg: 0,
    th: 0,
    tw: 0,
    vn: 0,
    BE: 0,
    nonBE: 0,
  });

  useEffect(() => {
    setIdsToDelete(formattedAccounts.map((acc) => acc._id));
    let statsPerRegion_ = { ph: 0, sg: 0, th: 0, tw: 0, vn: 0, na: 0, las: 0, euw: 0, lan: 0, br: 0, kr: 0, oce: 0, jp: 0, eune: 0, ru: 0, tr: 0, BE: 0, nonBE: 0 };
    formattedAccounts.forEach((acc) => {
      statsPerRegion_[acc.region] += 1;
      if (acc.disenchantLoot) {
        statsPerRegion_["BE"] += 1;
      } else {
        statsPerRegion_["nonBE"] += 1;
      }
    });
    setStatsPerRegion(statsPerRegion_);
  }, [formattedAccounts]);

  const onClickRemoveAccount = (id) => {
    setFormattedAccounts(formattedAccounts.filter((acc) => acc._id !== id));
  };

  const onClickDeleteAfter = (deleteAfter) => {
    localStorage.setItem("deleteAfter", deleteAfter);
    setDeleteAfter(deleteAfter);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && skinNameSearch != "") {
      setChange(!change);
    }
  };
  const userFormat = [...user.exportFormat];
  const userFormat2 = [...user.exportFormat];

  var half_length = Math.ceil(userFormat.length / 2);

  var leftSide = userFormat.splice(0, half_length);

  var rightSide = userFormat2.splice(half_length, userFormat2.length);

  return (
    <div className={styles.popup} onKeyDown={handleKeyDown}>
      <div className={styles.popupContent}>
        {!isLoading ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <h1>Export accounts {formattedAccounts.length > 0 ? `(${formattedAccounts.length})` : ""}</h1>
              </div>

              <div className={styles.filterRegionsButtons}>
                {statsPerRegion["na"] > 0 && <div className={styles.nonActiveRegions}>NA {statsPerRegion["na"] > 0 && `(${statsPerRegion["na"]})`}</div>}
                {statsPerRegion["las"] > 0 && <div className={styles.nonActiveRegions}>LAS {statsPerRegion["las"] > 0 && `(${statsPerRegion["las"]})`}</div>}
                {statsPerRegion["euw"] > 0 && <div className={styles.nonActiveRegions}>EUW {statsPerRegion["euw"] > 0 && `(${statsPerRegion["euw"]})`}</div>}
                {statsPerRegion["lan"] > 0 && <div className={styles.nonActiveRegions}>LAN {statsPerRegion["lan"] > 0 && `(${statsPerRegion["lan"]})`}</div>}
                {statsPerRegion["br"] > 0 && <div className={styles.nonActiveRegions}>BR {statsPerRegion["br"] > 0 && `(${statsPerRegion["br"]})`}</div>}
                {statsPerRegion["kr"] > 0 && <div className={styles.nonActiveRegions}>KR {statsPerRegion["kr"] > 0 && `(${statsPerRegion["kr"]})`}</div>}
                {statsPerRegion["oce"] > 0 && <div className={styles.nonActiveRegions}>OCE {statsPerRegion["oce"] > 0 && `(${statsPerRegion["oce"]})`}</div>}
                {statsPerRegion["jp"] > 0 && <div className={styles.nonActiveRegions}>JP {statsPerRegion["jp"] > 0 && `(${statsPerRegion["jp"]})`}</div>}
                {statsPerRegion["eune"] > 0 && <div className={styles.nonActiveRegions}>EUNE {statsPerRegion["eune"] > 0 && `(${statsPerRegion["eune"]})`}</div>}
                {statsPerRegion["ru"] > 0 && <div className={styles.nonActiveRegions}>RU {statsPerRegion["ru"] > 0 && `(${statsPerRegion["ru"]})`}</div>}
                {statsPerRegion["tr"] > 0 && <div className={styles.nonActiveRegions}>TR {statsPerRegion["tr"] > 0 && `(${statsPerRegion["tr"]})`}</div>}

                {/* <div className={styles.nonActiveRegions}>LAS {statsPerRegion["las"] > 0 && `(${statsPerRegion["las"]})`}</div>
                <div className={styles.nonActiveRegions}>EUW {statsPerRegion["euw"] > 0 && `(${statsPerRegion["euw"]})`}</div>
                <div className={styles.nonActiveRegions}>LAN {statsPerRegion["lan"] > 0 && `(${statsPerRegion["lan"]})`}</div>
                <div className={styles.nonActiveRegions}>BR {statsPerRegion["br"] > 0 && `(${statsPerRegion["br"]})`}</div>
                <div className={styles.nonActiveRegions}>KR {statsPerRegion["kr"] > 0 && `(${statsPerRegion["kr"]})`}</div>
                <div className={styles.nonActiveRegions}>OCE {statsPerRegion["oce"] > 0 && `(${statsPerRegion["oce"]})`}</div>
                <div className={styles.nonActiveRegions}>JP {statsPerRegion["jp"] > 0 && `(${statsPerRegion["jp"]})`}</div>
                <div className={styles.nonActiveRegions}>EUNE {statsPerRegion["eune"] > 0 && `(${statsPerRegion["eune"]})`}</div>
                <div className={styles.nonActiveRegions}>RU {statsPerRegion["ru"] > 0 && `(${statsPerRegion["ru"]})`}</div>
                <div className={styles.nonActiveRegions}>TR {statsPerRegion["tr"] > 0 && `(${statsPerRegion["tr"]})`}</div> */}
              </div>

              <div className={styles.filterRegionsButtons}>
                <div className={styles.nonActiveRegions} style={{ padding: "0px", display: "flex", alignItems: "center" }}>
                  <img className={styles.img} src={"./BE.png"}></img>
                  <span>{statsPerRegion["BE"]}</span>
                </div>
                <div className={styles.nonActiveRegions} style={{ padding: "0px", display: "flex", alignItems: "center" }}>
                  <img style={{ width: "25px", height: "25px" }} className={styles.img} src={"./CAPSULE.png"}></img>
                  <span>{statsPerRegion["nonBE"]}</span>
                </div>
              </div>
              <div className="credentials__info-field" style={{ fontSize: "0.9rem" }}>
                <label>Separator</label>
                <input onChange={onChangeSeparator} name="separator" className="credentials__input" style={{ width: "70px" }} value={options["separator"]}></input>
              </div>
              <div className="credentials__info-field" style={{ fontSize: "0.9rem" }}>
                <label>Skin Separator</label>
                <input onChange={onChangeSeparator} name="skinSeparator" className="credentials__input" style={{ width: "70px" }} value={options["skinSeparator"]}></input>
              </div>
            </div>
            <div className="credentials__addaccounts">
              <div className={styles.textarea}>
                {formattedAccounts.map((acc, i) => (
                  <div key={acc._id} style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap", backgroundColor: i % 2 === 0 ? "#3b3e59" : "#484c71" }}>
                    <i className="fas fa-check-square" onClick={() => onClickRemoveAccount(acc._id)} title={"Remove from export list"} style={{ cursor: "pointer" }}></i>
                    <div>{acc.disenchantLoot ? <img style={{ width: "25px", height: "25px" }} className={styles.img} src={"./BE.png"}></img> : <img style={{ width: "23px", height: "23px" }} className={styles.img} src={"./CAPSULE.png"}></img>}</div>

                    <span style={{ whiteSpace: "pre-wrap" }}>{acc.formattedAcc}</span>
                  </div>
                ))}
              </div>
              <div style={{ marginLeft: "3em", width: "50%" }} className="credentials__info__container">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex" }}>
                    <h2 style={{ marginTop: "0px" }}>Export Format</h2>
                    <div className={`tooltip`} style={{ border: "none", margin: ".35em 0 0 .5em", height: "100%" }}>
                      <span className="tooltiptext">Change the order of fields on your User Settings</span>
                      <i className="fas fa-question-circle"></i>
                    </div>
                    {regionFormat === "yes" ? (
                      <div onClick={onChangeRegionFormat} className={`tooltip`} style={{ border: "none", margin: ".35em 0 0 .5em", height: "100%" }}>
                        <span style={{ cursor: "pointer" }} className="tooltiptext">
                          Change OCE to OC and EUNE to EUN
                        </span>
                        <i className="fas fa-globe-americas"></i>
                      </div>
                    ) : (
                      <div onClick={onChangeRegionFormat} className={`tooltip`} style={{ border: "none", margin: ".35em 0 0 .5em", height: "100%" }}>
                        <span className="tooltiptext">Change OC to OCE and EUN to EUNE</span>

                        <i style={{ color: "#bbbbbb" }} className="fas fa-globe-americas"></i>
                      </div>
                    )}
                    <div onClick={onChangeShowingTemplate} className={`tooltip`} style={{ border: "none", margin: ".35em 0 0 .5em", height: "100%" }}>
                      <span style={{ cursor: "pointer" }} className="tooltiptext">
                        {!showingTemplate ? "Enable Template Mode" : "Disable Template Mode"}
                      </span>
                      <i className="fas fa-pen-square"></i>
                    </div>
                    <div onClick={() => setMirrorMode(!mirrorMode)} className={`tooltip`} style={{ border: "none", margin: ".35em 0 0 .5em", height: "100%" }}>
                      <span style={{ cursor: "pointer" }} className="tooltiptext">
                        {mirrorMode ? "Hide" : "Show"} Mirror Mode
                      </span>
                      <i style={{ color: mirrorMode ? "#bbbbbb" : "white" }} className="fas fa-clipboard-list"></i>
                    </div>
                  </div>
                  <div>
                    <label style={{ marginRight: ".5em" }}>Max Amount to export: </label>
                    <input onChange={onChangeMaxAmount} className="credentials__input" style={{ width: "40px" }} value={maxAmountToExport}></input>
                  </div>
                </div>

                {showingTemplate ? (
                  <>
                    <span>Variables are:</span>

                    <p>{user.exportFormat.map((conf, i) => (i === user.exportFormat.length - 1 ? `${conf.id}.` : `${conf.id}, `))}</p>
                    <textarea style={{ marginTop: "1em", fontSize: "0.9rem", width: "100%", minHeight: "5em" }} onChange={onChangeTextarea} value={templateTextArea} placeholder={""}></textarea>
                  </>
                ) : (
                  <>
                    <div className="credentials__info-row" style={{ fontSize: "11px" }}>
                      {leftSide.map((conf) => (
                        <div key={conf.id} className="credentials__info-field">
                          <label>{conf.name}</label>
                          <input disabled={conf.id === "summonerName" ? true : false} onChange={onChange} name={conf.id} className="checkbox" type="checkbox" value={options[conf.id]} defaultChecked={options[conf.id]}></input>
                        </div>
                      ))}
                    </div>
                    <div className="credentials__info-row" style={{ fontSize: "11px" }}>
                      {rightSide.map((conf) => (
                        <div key={conf.id} className="credentials__info-field">
                          <label>{conf.name}</label>
                          <input disabled={conf.id === "summonerName" ? true : false} onChange={onChange} name={conf.id} className="checkbox" type="checkbox" value={options[conf.id]} defaultChecked={options[conf.id]}></input>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {/* <div className='credentials__info-field'>
                    <label>Password</label>
                    <input onChange={onChange} name='password' className='checkbox' type='checkbox' value={options['password']} defaultChecked={options['password']}></input>
                  </div>
                  <div className='credentials__info-field'>
                    <label>Region</label>
                    <input onChange={onChange} name='region' className='checkbox' type='checkbox' value={options['region']} defaultChecked={options['region']}></input>
                  </div>
                  <div className='credentials__info-field'>
                    <label>Current Level</label>
                    <input onChange={onChange} name='currentLevel' className='checkbox' type='checkbox' value={options['currentLevel']} defaultChecked={options['currentLevel']}></input>
                  </div>
                  <div className='credentials__info-field'>
                    <label>Display Name</label>
                    <input onChange={onChange} name='displayName' className='checkbox' type='checkbox' value={options['displayName']} defaultChecked={options['displayName']}></input>
                  </div>
                  <div className='credentials__info-field'>
                    <label>Flash On</label>
                    <input onChange={onChange} name='flashOn' className='checkbox' type='checkbox' value={options['flashOn']} defaultChecked={options['flashOn']}></input>
                  </div> */}
                {/* <div className='credentials__info-row'>
                  <div className='credentials__info-field'>
                    <label>Blue Essence</label>
                    <input onChange={onChange} name='blueEssence' className='checkbox' type='checkbox' value={options['blueEssence']} defaultChecked={options['blueEssence']}></input>
                  </div>
                  <div className='credentials__info-field'>
                    <label>LPQ Status</label>
                    <input onChange={onChange} name='matchmakingStatus' className='checkbox' type='checkbox' value={options['matchmakingStatus']} defaultChecked={options['matchmakingStatus']}></input>
                  </div>
                  <div className='credentials__info-field'>
                    <label>Skins</label>
                    <input onChange={onChange} name='skins' className='checkbox' type='checkbox' value={options['skins']} defaultChecked={options['skins']}></input>
                  </div>
                  <div className='credentials__info-field'>
                    <label>Priority</label>
                    <input onChange={onChange} name='priority' className='checkbox' type='checkbox' value={options['priority']} defaultChecked={options['priority']}></input>
                  </div>
                </div> */}
                {!mirrorMode ? (
                  <>
                    <h2>Account Status</h2>

                    <div className="credentials__info-row">
                      <div className="credentials__info-field">
                        <label style={{ fontSize: ".94rem" }}>Quota Finished</label>
                        <input onChange={onChange} name="filterByQuota" className="checkbox" type="checkbox" value={options["filterByQuota"]} defaultChecked={options["filterByQuota"]}></input>
                      </div>
                      <div className="credentials__info-field">
                        <label style={{ fontSize: ".94rem" }}>Disenchanted</label>
                        <input onChange={onChange} name="filterByDisenchanted" className="checkbox" type="checkbox" value={options["filterByDisenchanted"]} defaultChecked={options["filterByDisenchanted"]}></input>
                      </div>
                      <div className="credentials__info-field">
                        <label style={{ fontSize: ".94rem" }}>Mission Wait</label>
                        <input onChange={onChange} name="filterByMissionWait" className="checkbox" type="checkbox" value={options["filterByMissionWait"]} defaultChecked={options["filterByMissionWait"]}></input>
                      </div>
                      <div className="credentials__info-field">
                        <label style={{ fontSize: ".94rem" }}>By Active</label>
                        <input onChange={onChange} name="filterByActive" className="checkbox" type="checkbox" value={options["filterByActive"]} defaultChecked={options["filterByActive"]}></input>
                      </div>

                      <div className="credentials__info-field" style={{ visibility: `${options["filterByActive"] ? "visible" : "hidden"}` }}>
                        <label style={{ fontSize: ".94rem" }}>Active or not</label>

                        <select name="sortBy" className={styles.select} onChange={onChangeActiveFilter} defaultValue={activeFilter}>
                          <option value="active">Active</option>
                          <option value="notactive">Not Active</option>
                        </select>
                      </div>
                    </div>
                    <h2>Sorting</h2>
                    <div className="credentials__info-row">
                      <div className="credentials__info-field">
                        <label>Enable Sorting</label>
                        <input onChange={onChange} name="sort" className="checkbox" type="checkbox" value={options["sort"]} defaultChecked={options["sort"]}></input>
                      </div>
                      {options["sort"] && (
                        <div className="credentials__info-field">
                          <label>Sort By</label>

                          <select name="sortByName" className={styles.select} onChange={onClickChangeSorting} defaultValue={options.sortByName}>
                            <option value="region">Region</option>
                            <option value="currentLevel">Current Level</option>
                            <option value="blueEssence">Blue Essence</option>
                            <option value="orangeEssence">Orange Essence</option>

                            <option value="skins">Skins</option>
                            <option value="be">Disenchant Type</option>
                            <option value="lastGamePlayed">Last Game</option>

                            <option value="flashOn">Flash On</option>
                          </select>
                        </div>
                      )}
                      {options["sort"] && (
                        <div className="credentials__info-field">
                          <label>Sorting Type</label>

                          <select name="sortType" className={styles.select} onChange={onClickChangeSortingType} defaultValue={options.sortType}>
                            <option value="asc">Ascending</option>
                            <option value="dsc">Descending</option>
                          </select>
                        </div>
                      )}
                    </div>
                    <h2>Account Filters</h2>

                    <div className="credentials__info-row">
                      <div style={{ display: "flex", justifyContent: "space-between", width: "45%", alignItems: "center" }}>
                        <div className="credentials__info-field">
                          <label>By Region</label>
                          <input onChange={onChange} name="filterByRegion" className="checkbox" type="checkbox" value={options["filterByRegion"]} defaultChecked={options["filterByRegion"]}></input>
                        </div>

                        <div className="credentials__info-field" style={{ visibility: `${options["filterByRegion"] ? "visible" : "hidden"}` }}>
                          <label>Regions</label>

                          <select name="region" className={styles.select} onChange={onChangeRegionFilter} defaultValue={regionFilter}>
                            <option value="na">NA</option>
                            <option value="euw">EUW</option>
                            <option value="las">LAS</option>
                            <option value="lan">LAN</option>
                            <option value="tr">TR</option>
                            <option value="kr">KR</option>
                            <option value="br">BR</option>
                            <option value="ru">RU</option>
                            <option value="eune">EUNE</option>
                            <option value="jp">JP</option>
                            <option value="oce">OCE</option>
                            <option value="ph">PH</option>
                            <option value="sg">SG</option>
                            <option value="th">TH</option>
                            <option value="tw">TW</option>
                            <option value="vn">VN</option>
                          </select>
                        </div>
                      </div>

                      <div style={{ display: "flex", justifyContent: "space-between", width: "45%", alignItems: "center" }}>
                        <div className="credentials__info-field">
                          <label style={{ fontSize: ".94rem" }}>By Disenchant Type</label>
                          <input onChange={onChange} name="filterByDisenchantType" className="checkbox" type="checkbox" value={options["filterByDisenchantType"]} defaultChecked={options["filterByDisenchantType"]}></input>
                        </div>

                        <div className="credentials__info-field" style={{ visibility: `${options["filterByDisenchantType"] ? "visible" : "hidden"}` }}>
                          <label style={{ fontSize: ".94rem" }}>Disenchant Type</label>

                          <select name="sortBy" className={styles.select} onChange={onChangeDisenchantFilter} defaultValue={disenchantTypeFilter}>
                            <option value="BE">BE</option>
                            <option value="capsules">Capsules</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="credentials__info-row">
                      <div style={{ display: "flex", justifyContent: "space-between", width: "45%", alignItems: "center" }}>
                        <div className="credentials__info-field">
                          <label>By Priority</label>
                          <input onChange={onChange} name="filterByPriority" className="checkbox" type="checkbox" value={options["filterByPriority"]} defaultChecked={options["filterByPriority"]}></input>
                        </div>

                        <div className="credentials__info-field" style={{ visibility: `${options["filterByPriority"] ? "visible" : "hidden"}` }}>
                          <label>Priority Value</label>

                          <input onChange={onChangePriority} name="priorityValue" className="credentials__input" style={{ width: "70px" }} value={Number(options["priorityValue"])}></input>
                        </div>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", width: "45%", alignItems: "center" }}>
                        <div className="credentials__info-field">
                          <label>By Missions</label>
                          <input onChange={onChange} name="filterByMissionsCompletedAmount" className="checkbox" type="checkbox" value={options["filterByMissionsCompletedAmount"]} defaultChecked={options["filterByMissionsCompletedAmount"]}></input>
                        </div>

                        <div className="credentials__info-field" style={{ visibility: `${options["filterByMissionsCompletedAmount"] ? "visible" : "hidden"}` }}>
                          <label>Missions Amount</label>

                          <input onChange={onChangePriority} name="amountMissions" className="credentials__input" style={{ width: "70px" }} value={Number(options["amountMissions"])}></input>
                        </div>
                      </div>
                      {/* <div style={{ display: 'flex', justifyContent: 'space-between', width: '45%', alignItems: 'center' }}>
										<div className='credentials__info-field'>
											<label>By Free Name Change</label>
											<input onChange={onChange} name='filterByFreeNameChange' className='checkbox' type='checkbox' value={options['filterByFreeNameChange']} defaultChecked={options['filterByFreeNameChange']}></input>
										</div>

										<div className='credentials__info-field' style={{ visibility: `${options['filterByFreeNameChange'] ? 'visible' : 'hidden'}` }}>
											<label>Option</label>

											<select className={styles.select} onChange={onChangeFreeNameChange} defaultValue={filterFreeNameChange}>
												<option value='yes'>Yes</option>
												<option value='no'>No</option>
											</select>
										</div>
									</div> */}
                    </div>

                    <div className="credentials__info-row">
                      <div className="credentials__info-field">
                        <label>By BE Range</label>
                        <input onChange={onChange} name="filterByBERange" className="checkbox" type="checkbox" value={options["filterByBERange"]} defaultChecked={options["filterByBERange"]}></input>
                      </div>

                      <div className="credentials__info-field" style={{ visibility: `${options["filterByBERange"] ? "visible" : "hidden"}` }}>
                        <label style={{ fontSize: "0.8rem" }}>Start BE</label>

                        <input onChange={onChangePriority} name="startBEAt" className="credentials__input" style={{ width: "50px" }} value={options["startBEAt"]}></input>
                      </div>

                      <div className="credentials__info-field" style={{ visibility: `${options["filterByBERange"] ? "visible" : "hidden"}` }}>
                        <label style={{ fontSize: "0.8rem" }}>End BE</label>

                        <input onChange={onChangePriority} name="stopBEAt" className="credentials__input" style={{ width: "50px" }} value={options["stopBEAt"]}></input>
                      </div>

                      <div className="credentials__info-field">
                        <label>Filter by Level Range</label>
                        <input onChange={onChange} name="filterByLevelRange" className="checkbox" type="checkbox" value={options["filterByLevelRange"]} defaultChecked={options["filterByLevelRange"]}></input>
                      </div>

                      <div className="credentials__info-field" style={{ visibility: `${options["filterByLevelRange"] ? "visible" : "hidden"}` }}>
                        <label style={{ fontSize: "0.8rem" }}>Start Level</label>

                        <input onChange={onChangePriority} name="startLevelAt" className="credentials__input" style={{ width: "50px" }} value={options["startLevelAt"]}></input>
                      </div>

                      <div className="credentials__info-field" style={{ visibility: `${options["filterByLevelRange"] ? "visible" : "hidden"}` }}>
                        <label style={{ fontSize: "0.8rem" }}>End Level</label>

                        <input onChange={onChangePriority} name="stopLevelAt" className="credentials__input" style={{ width: "50px" }} value={options["stopLevelAt"]}></input>
                      </div>
                    </div>

                    <div className="credentials__info-row">
                      <div className="credentials__info-field">
                        <label>By RP Range</label>
                        <input onChange={onChange} name="filterByRpRange" className="checkbox" type="checkbox" value={options["filterByRpRange"]} defaultChecked={options["filterByRpRange"]}></input>
                      </div>

                      <div className="credentials__info-field" style={{ visibility: `${options["filterByRpRange"] ? "visible" : "hidden"}` }}>
                        <label style={{ fontSize: "0.8rem" }}>Start RP</label>

                        <input onChange={onChangePriority} name="startRPAt" className="credentials__input" style={{ width: "50px" }} value={options["startRPAt"]}></input>
                      </div>

                      <div className="credentials__info-field" style={{ visibility: `${options["filterByRpRange"] ? "visible" : "hidden"}` }}>
                        <label style={{ fontSize: "0.8rem" }}>End RP</label>

                        <input onChange={onChangePriority} name="stopRPAt" className="credentials__input" style={{ width: "50px" }} value={options["stopRPAt"]}></input>
                      </div>

                      <div className="credentials__info-field">
                        <label>By Skin Amount</label>
                        <input onChange={onChange} name="filterBySkinAmount" className="checkbox" type="checkbox" value={options["filterBySkinAmount"]} defaultChecked={options["filterBySkinAmount"]}></input>
                      </div>

                      <div className="credentials__info-field" style={{ visibility: `${options["filterBySkinAmount"] ? "visible" : "hidden"}` }}>
                        <label style={{ fontSize: "0.8rem" }}>Start Amount</label>

                        <input onChange={onChangePriority} name="startSkinAmountAt" className="credentials__input" style={{ width: "50px" }} value={options["startSkinAmountAt"]}></input>
                      </div>

                      <div className="credentials__info-field" style={{ visibility: `${options["filterBySkinAmount"] ? "visible" : "hidden"}` }}>
                        <label style={{ fontSize: "0.8rem" }}>End Amount</label>

                        <input onChange={onChangePriority} name="stopSkinAmountAt" className="credentials__input" style={{ width: "50px" }} value={options["stopSkinAmountAt"]}></input>
                      </div>
                    </div>

                    <div className="credentials__info-row">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "1em" }}>Skin Searcher</span>

                        <input onChange={onChangeSkinSearch} value={skinNameSearch} style={{ height: "30px", color: "white", textIndent: "0.5em", backgroundColor: "#484c71", border: "none", borderRadius: "5px" }}></input>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div onClick={() => setChange(!change)} style={{ alignSelf: "center", height: "22.5px", width: "70px", marginLeft: "1em", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", borderRight: "2px solid #302f4e" }} className="button aqua-green">
                          Search
                        </div>
                        <div onClick={eraseSkinSearch} style={{ alignSelf: "center", height: "22.5px", width: "30px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} className={"button aqua-green"}>
                          <i className="fas fa-eraser"></i>
                        </div>
                      </div>
                    </div>

                    <div className="credentials__info-row" style={{ alignItems: "center" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "1em" }}>Name Searcher</span>

                        <input onChange={onChangeSummonerNameSearch} value={summonerNameSearch} style={{ height: "30px", color: "white", textIndent: "0.5em", backgroundColor: "#484c71", border: "none", borderRadius: "5px" }}></input>
                      </div>
                      <div>
                        <label style={{ marginRight: ".5em" }}>Labels</label>
                        <select className="credentials__input" style={{ width: "60px", marginTop: "0px" }} onChange={onChangeLabel} defaultValue={options["label"]}>
                          <option value="-">-</option>

                          {labels.map((label) => (
                            <option key={label} value={label}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div onClick={() => setChange(!change)} style={{ alignSelf: "center", height: "22.5px", width: "70px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", borderRight: "2px solid #302f4e" }} className="button aqua-green">
                          Search
                        </div>
                        <div onClick={eraseSummonerNameSearch} style={{ alignSelf: "center", height: "22.5px", width: "30px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} className={"button aqua-green"}>
                          <i className="fas fa-eraser"></i>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h2>Summoner and Region</h2>
                    <textarea style={{ marginBottom: "1em", fontSize: "0.9rem", width: "100%", minHeight: "32.2em" }} onChange={onChangeTextareaMirror} value={mirrorTextarea} placeholder={"summonername:region\nsummonername:region\nsummonername:region\nsummonername:region\n"}></textarea>
                  </>
                )}
                <div className="credentials__info-row">
                  <div style={{ display: "flex" }}>
                    <div style={{ border: "none" }} className={`tooltip button ${deleteAfter === "yes" ? "red_" : "aqua-green"} ${formattedAccounts.length === 0 && "disabled"}`} onClick={onClickExport}>
                      <span className="tooltiptext"> {deleteAfter === "yes" ? `Download txt file with accounts and PERMANENTLY remove ${idsToDelete.length} selected accounts.` : `Download txt file with selected accounts.`}</span>
                      <span>Export</span>
                    </div>
                    <div style={{ marginLeft: "1em" }} className="button aqua-green" onClick={onClickCancel}>
                      <span>Close</span>
                    </div>
                  </div>
                  <div className="credentials__info-field tooltip" style={{ border: "none" }}>
                    <span className="tooltiptext" style={{ left: "0%", marginLeft: "-250px" }}>{`Delete ${idsToDelete.length} selected accounts after exporting. Only accounts that are NOT active will be deleted.`}</span>

                    <label>Delete after exporting</label>
                    <input onChange={() => onClickDeleteAfter(deleteAfter === "yes" ? "no" : "yes")} className="checkbox" type="checkbox" value={deleteAfter === "yes" ? true : false} defaultChecked={deleteAfter === "yes" ? true : false}></input>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  skinsData: state.accounts.skinsData,
});
export default connect(mapStateToProps, { updateAccount, deleteAccounts })(ExportAccounts);
