import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import formatPlaytime from "../../utils/formatPlaytimeDashboard";
import formatTime from "../../utils/formatTime";
import formatPlaytime2 from "../../utils/formatPlaytime";
import setSkinName from "../../utils/setSkinName";

import { pauseAccounts, stopAccounts, restartAccounts, powerOffAccounts, restartVms, turnOffVms, powerOnAccounts, unpauseAccounts, removeAccountsFromDashboard, setOfflineTesting, revertActions } from "../../actions/accountActions";
import Spinner from "./Spinner";
import longAgo from "../../utils/longAgo";

const AccountItem = ({
  user: { dashboardColumns, skinLanguage, showHasActiveBoost, showMac, showLastGamePlayed, showCooldown, showServerId, showRp, showDesiredPercentage, showOrangeEssence, showCountry, showLimitFps, showBotVersion, showVmCloneNumber, showCheckerStatus, showStartToFinishTime, showFreeNameChange, showMode, showIps, externalWebsite, showDesiredBE, showPriority, showLimitRes, showFlashOn, showMissions, showDisenchant, showUserTaken, showFinishStartDates },
  selectionOn,
  sendPopupShowing,
  isPopupShowing,
  sendPopupShowing2,
  isPopupShowing2,
  selectedIds,
  setSelectedIds,
  userRole,
  account,
  pauseAccounts,
  stopAccounts,
  restartAccounts,
  powerOffAccounts,
  restartVms,
  turnOffVms,
  powerOnAccounts,
  unpauseAccounts,
  removeAccountsFromDashboard,
  revertActions,
  skinsData,
}) => {
  const {
    gameVersion,
    leagueClientMode,
    label,
    hasBoostActive,
    vmNumber,
    rp,
    macAddress,
    bestSkinType,
    orangeEssence,
    country,
    botVersion,
    limitFps,
    freeNameChange,
    checker,
    priority,
    mode,
    flashOn,
    ip,
    desiredBE,
    limitResources,
    summonerNameTaken,
    missionTracker,
    currentLevel,
    completeMissionUntil,
    displayName,
    summonerName,
    state,
    status,
    playtime,
    nextLevelPercentage,
    desiredLevel,
    blueEssence,
    cooldownAfterSession,
    lastTimePlayed,
    maxGames,
    maxGamesReached,
    nOfGames,
    matchmakingStatus,
    skins,
    region,
    isActive,
    gamesInSession,
    _id,
    disenchantLoot,
    finishAndStart,
    desiredPercentage,
    serverId,
  } = account;
  const [isLoading, setisLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const handleAction = async (action, id) => {
    setisLoading(true);
    await action([id]);
    setisLoading(false);
  };
  const handleActionDelete = async (action, id) => {
    setisLoading(true);
    await action([id]);
  };

  const handlePopup = () => {
    // console.log(account);
    sendPopupShowing({
      showing: !isPopupShowing.showing,
      accounts: [account],
    });
  };

  const handlePopupSkins = () => {
    // console.log(account);
    sendPopupShowing2({
      showing: !isPopupShowing2.showing,
      skins: account.skins,
    });
  };

  const onMouseOver = (event) => {
    if (selectionOn) {
      //   setIsSelected(!isSelected);
      if (!isSelected) {
        setSelectedIds([...selectedIds, _id]);
      } else {
        setSelectedIds([...selectedIds].filter((id) => id !== _id));
      }
    }
  };

  useEffect(() => {
    // console.log("Updated");
    if (selectedIds.some((id) => id === _id)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedIds]);

  const getExternalWebsite = (website) => {
    switch (website) {
      case "leaguegraphs":
        // return `https://www.leagueofgraphs.com/summoner/${region}/${displayName !== " " ? displayName : summonerName}`;
        if (displayName) {
          return `https://www.leagueofgraphs.com/summoner/${region}/${displayName.split("#")[0]}-${displayName.split("#")[1]}`;
        }
      case "opgg":
        // return `https://${region}.op.gg/summoner/userName=${displayName !== " " ? displayName : summonerName}`;
        if (displayName) {
          return `https://op.gg/summoners/${region}/${displayName.split("#")[0]}-${displayName.split("#")[1]}`;
        }
    }
  };

  const setSkinColor = (rarity) => {
    switch (rarity) {
      case "DEFAULT":
        return "white";
      case "RARE":
        return "white";
      case "LEGENDARY":
        return "#DC3636";
      case "EPIC":
        return "#39DADF";
      case "MYTHIC":
        return "#7943ED";
      case "ULTIMATE":
        return "#ECB827";
      default:
        return "white";
    }
  };
  const setSkinName = (skin) => {
    if (typeof skin === "string") {
      return skin;
    } else {
      if (skinsData[skin.id]) {
        if (skinLanguage != "-") {
          return skinsData[skin.id][skinLanguage];
        } else {
          return skin.name;
        }
      } else {
        return String(skin.id);
      }
    }
  };
  const showColumnsTds = (id) => {
    switch (id) {
      case "mode":
        return <td style={{ fontSize: ".5rem" }}>{mode.toUpperCase()}</td>;
      case "summonerName":
        return (
          <td>
            <div className="tooltip">
              <a rel="noopener noreferrer" href={getExternalWebsite(externalWebsite)} target="_blank">
                {summonerName.length > 16 ? `${summonerName.substring(0, 9)}...` : summonerName}
              </a>
              {displayName && <span className="tooltiptext">{displayName !== " " ? displayName : summonerName}</span>}
            </div>
          </td>
        );
      case "currentLevel":
        return (
          <td style={{ width: "250px" }}>
            {currentLevel} ({nextLevelPercentage}%)
          </td>
        );
      case "desiredLevel":
        return <td>{desiredLevel}</td>;

      case "desiredPercentage":
        return <td>{desiredPercentage}%</td>;
      case "desiredBE":
        return <td>{desiredBE}</td>;
      case "blueEssence":
        return <td>{blueEssence}</td>;
      case "rp":
        return <td>{rp}</td>;
      case "orangeEssence":
        return <td>{orangeEssence}</td>;
      case "cooldownAfterSession":
        return (
          <td>
            {maxGamesReached ? (
              <div className="tooltip">
                <span>{lastTimePlayed + cooldownAfterSession > Date.now() ? `${formatPlaytime(lastTimePlayed + cooldownAfterSession - Date.now())}` : "Ready"}</span>

                {lastTimePlayed + cooldownAfterSession > Date.now() && <span className="tooltiptext">{longAgo(lastTimePlayed + cooldownAfterSession)}</span>}
              </div>
            ) : (
              <span>-</span>
            )}
          </td>
        );
      case "nOfGames":
        return <td>{nOfGames}</td>;
      case "gamesInSession":
        return <td>{gamesInSession}</td>;
      case "skins":
        return (
          <td>
            {skins.length > 0 ? (
              <div className="tooltip">
                <span onClick={handlePopupSkins} style={{ cursor: "pointer", color: setSkinColor(bestSkinType), fontWeight: "bold" }}>
                  {skins.length}
                </span>
                <span className="tooltiptext" key={Math.random().toString(36).substr(2, 9)}>
                  {skins.map((skin) => (
                    <p key={Math.random().toString(36).substr(2, 9)} style={{ lineHeight: ".5px", color: setSkinColor(skin.rarity) }}>{`${setSkinName(skin)}\n`}</p>
                  ))}
                </span>
              </div>
            ) : (
              "-"
            )}
          </td>
        );
      case "playtime":
        return <td style={{ width: "350px" }}> {formatPlaytime(playtime)} </td>;
      case "finishAndStart":
        return <td style={{ textAlign: "center" }}>{finishAndStart && finishAndStart.started.date != 0 ? (((finishAndStart.finished.date != 0 ? finishAndStart.finished.date : Date.now()) - finishAndStart.started.date) / 8.64e7).toFixed(1) : "-"}</td>;
      case "region":
        return <td>{region.toUpperCase()}</td>;
      case "isActive_status_state":
        return (
          <>
            <td></td>
            <td>{!isActive ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>
            <td className="accounts__status">{status}</td>
            <td>{state.toUpperCase()}</td>
            <td></td>
          </>
        );
      case "accountActions":
        return (
          <td className="accounts__buttons">
            {!isLoading ? (
              <>
                <div title={isActive ? `Pause Account` : `Can't perform action on inactive account`}>{status !== "paused" && status !== "pausing" && <i className={`accounts__button fas fa-pause ${!isActive && "disabled"}`} onClick={() => handleAction(pauseAccounts, _id)}></i>}</div>
                <div title={isActive ? `Unpause Account` : `Can't perform action on inactive account`}>{(status === "paused" || status === "pausing") && <i className={`accounts__button fas fa-play ${(status !== "paused" || !isActive) && "disabled"}`} onClick={() => handleAction(unpauseAccounts, _id)}></i>}</div>

                <div title={isActive ? `Change Account` : `Can't perform action on inactive account`}>
                  <i className={`accounts__button fas fa-exchange-alt ${!isActive && "disabled"}`} onClick={() => handleAction(stopAccounts, _id)}></i>
                </div>

                <div title={isActive ? `Restart Account` : `Can't perform action on inactive account`}>
                  <i className={`accounts__button fas fa-sync-alt ${!isActive && "disabled"}`} onClick={() => handleAction(restartAccounts, _id)}></i>
                </div>

                <div title={isActive ? `Revert Action` : `Can't perform action on inactive account`}>
                  <i className={`accounts__button fas fa-undo-alt ${!isActive && "disabled"}`} onClick={() => handleAction(revertActions, _id)}></i>
                </div>

                <div>{state === "off" ? <i className="accounts__button fas fa-play-circle" title="Turn On Account" onClick={() => handleAction(powerOnAccounts, _id)}></i> : <i className="accounts__button fas fa-power-off" title="Turn Off Account" onClick={() => handleAction(powerOffAccounts, _id)}></i>}</div>
                <div title={!isActive ? "Remove from dashboard" : `Can't remove from dashboard while account is active`}>
                  <i style={{ color: "#970000" }} className={`accounts__button fas fa-times-circle ${isActive && "disabled"}`} onClick={() => handleActionDelete(removeAccountsFromDashboard, _id)}></i>
                </div>
                <div title="Edit account">
                  <i className="accounts__button fas fa-user-edit" onClick={handlePopup}></i>
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </td>
        );
      case "vmActions":
        return (
          <>
            <td></td>
            <td className="accounts__buttons">
              {!isLoading ? (
                <>
                  <div title={isActive ? "Restart VM" : `Can't perform action on inactive account`}>
                    <i className={`accounts__button fas fa-sync-alt ${!isActive && "disabled"}`} title="Restart VM" onClick={() => handleAction(restartVms, _id)}></i>
                  </div>

                  <div title={isActive ? "Turn Off VM" : `Can't perform action on inactive account`}>
                    <i className={`accounts__button fas fa-power-off ${!isActive && "disabled"}`} title="Turn Off VM" onClick={() => handleAction(turnOffVms, _id)}></i>
                  </div>
                </>
              ) : (
                <Spinner />
              )}
            </td>
          </>
        );
      case "priority":
        return <td>{priority}</td>;
      case "limitResources":
        return <td>{limitResources}</td>;
      case "flashOn":
        return <td>{flashOn.toUpperCase()}</td>;
      case "missionTracker":
        return (
          <td>
            {missionTracker.completed}/{completeMissionUntil}
          </td>
        );

      case "disenchantLoot":
        return <td>{!disenchantLoot ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>;
      case "ip":
        return <td>{ip}</td>;
      case "macAddress":
        return <td>{macAddress}</td>;
      case "finishAndStartDates":
        return (
          <td>
            <div className="tooltip" style={{ border: "none" }}>
              <span className="tooltiptext">
                {finishAndStart.started.date === 0 ? (
                  <p style={{ margin: "0" }}>
                    <span style={{ color: "#bdbdbd" }}>Started: </span> N/A
                  </p>
                ) : (
                  <p style={{ margin: "0" }}>
                    {" "}
                    <span style={{ color: "#bdbdbd" }}>Started: </span>
                    {formatTime(finishAndStart.started.date)}
                  </p>
                )}
                {finishAndStart.finished.date === 0 ? (
                  <p style={{ margin: "0" }}>
                    <span style={{ color: "#bdbdbd" }}>Finished: </span> N/A
                  </p>
                ) : (
                  <p style={{ margin: "0" }}>
                    {" "}
                    <span style={{ color: "#bdbdbd" }}>Finished: </span>
                    {formatTime(finishAndStart.finished.date)}
                  </p>
                )}
              </span>
              <i className="fas fa-info-circle"></i>
            </div>
          </td>
        );
      case "checker":
        return (
          <td>
            <p style={{ margin: "0", color: "black" }}>{checker.status}</p>
          </td>
        );
      case "hasBoostActive":
        return (
          <td>
            {Date.now() < hasBoostActive ? (
              <div className="tooltip">
                <span className="tooltiptext">Boost Ends: {formatTime(hasBoostActive)}</span>
                <i style={{ color: "green" }} className="fas fa-check"></i>
              </div>
            ) : (
              <i style={{ color: "rgb(151, 0, 0)" }} className="fas fa-times"></i>
            )}
          </td>
        );
      case "vmNumber":
        return <td>{vmNumber}</td>;
      case "limitFps":
        return <td>{limitFps}</td>;
      case "serverId":
        return <td>{serverId}</td>;
      case "botVersion":
        return <td>{botVersion}</td>;
      case "country":
        return <td>{country}</td>;
      case "lastTimePlayed":
        return <td style={{ fontSize: "0.8rem" }}>{formatTime(lastTimePlayed)}</td>;
      case "label":
        return <td>{label}</td>;
      case "leagueClientMode":
        return <td>{leagueClientMode}</td>;
      case "gameVersion":
        return <td>{gameVersion}</td>;
    }
  };
  return (
    <tr className={isSelected ? "selected" : ""}>
      <td colSpan="2" onMouseEnter={onMouseOver}>
        {isSelected && <i style={{ pointerEvents: "none", color: "#1c4141" }} className="fas fa-check-square"></i>}
      </td>

      {dashboardColumns.map((col) => {
        return col.enabled ? showColumnsTds(col.id) : null;
      })}
      {/* 
      {showMode && mode && <td style={{ fontSize: ".5rem" }}>{mode.toUpperCase()}</td>}
      <td>
        <div className="tooltip">
          <a rel="noopener noreferrer" href={getExternalWebsite(externalWebsite)} target="_blank">
            {summonerName.length > 16 ? `${summonerName.substring(0, 9)}...` : summonerName}
          </a>
          {displayName && <span className="tooltiptext">{displayName !== " " ? displayName : summonerName}</span>}
        </div>
      </td>

      <td style={{ width: "250px" }}>
        {currentLevel} ({nextLevelPercentage}%)
      </td>
      <td>{desiredLevel}</td>
      {showDesiredPercentage && <td>{desiredPercentage}%</td>}

      {showDesiredBE && <td>{desiredBE}</td>}

      <td>{blueEssence}</td>
      {showRp && <td>{rp}</td>}

      {showOrangeEssence && <td>{orangeEssence}</td>}
      {showCooldown && (
        <td>
          {maxGamesReached ? (
            <div className="tooltip">
              <span>{lastTimePlayed + cooldownAfterSession > Date.now() ? `${formatPlaytime(lastTimePlayed + cooldownAfterSession - Date.now())}` : "Ready"}</span>

              {lastTimePlayed + cooldownAfterSession > Date.now() && <span className="tooltiptext">{longAgo(lastTimePlayed + cooldownAfterSession)}</span>}
            </div>
          ) : (
            <span>-</span>
          )}

        </td>
      )}


      <td>{nOfGames}</td>
      <td>{gamesInSession}</td>

      <td>
        {skins.length > 0 ? (
          <div className="tooltip">
            <span onClick={handlePopupSkins} style={{ cursor: "pointer", color: setSkinColor(bestSkinType), fontWeight: "bold" }}>
              {skins.length}
            </span>
            <span className="tooltiptext" key={Math.random().toString(36).substr(2, 9)}>
              {skins.map((skin) => (
                <p key={Math.random().toString(36).substr(2, 9)} style={{ lineHeight: ".5px", color: setSkinColor(skin.rarity) }}>{`${setSkinName(skin)}\n`}</p>
              ))}
            </span>
          </div>
        ) : (
          "-"
        )}
      </td>
      <td style={{ width: "350px" }}> {formatPlaytime(playtime)} </td>
      {showStartToFinishTime && <td style={{ textAlign: "center" }}>{finishAndStart && finishAndStart.started.date != 0 ? (((finishAndStart.finished.date != 0 ? finishAndStart.finished.date : Date.now()) - finishAndStart.started.date) / 8.64e7).toFixed(1) : "-"}</td>}
      <td>{region.toUpperCase()}</td>
      <td></td>
      <td>{!isActive ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>
      <td className="accounts__status">{status}</td>
      <td>{state.toUpperCase()}</td>
      <td></td>

      <td className="accounts__buttons">
        {!isLoading ? (
          <>
            <div title={isActive ? `Pause Account` : `Can't perform action on inactive account`}>{status !== "paused" && status !== "pausing" && <i className={`accounts__button fas fa-pause ${!isActive && "disabled"}`} onClick={() => handleAction(pauseAccounts, _id)}></i>}</div>
            <div title={isActive ? `Unpause Account` : `Can't perform action on inactive account`}>{(status === "paused" || status === "pausing") && <i className={`accounts__button fas fa-play ${(status !== "paused" || !isActive) && "disabled"}`} onClick={() => handleAction(unpauseAccounts, _id)}></i>}</div>

            <div title={isActive ? `Change Account` : `Can't perform action on inactive account`}>
              <i className={`accounts__button fas fa-exchange-alt ${!isActive && "disabled"}`} onClick={() => handleAction(stopAccounts, _id)}></i>
            </div>

            <div title={isActive ? `Restart Account` : `Can't perform action on inactive account`}>
              <i className={`accounts__button fas fa-sync-alt ${!isActive && "disabled"}`} onClick={() => handleAction(restartAccounts, _id)}></i>
            </div>

            <div title={isActive ? `Revert Action` : `Can't perform action on inactive account`}>
              <i className={`accounts__button fas fa-undo-alt ${!isActive && "disabled"}`} onClick={() => handleAction(revertActions, _id)}></i>
            </div>

            <div>{state === "off" ? <i className="accounts__button fas fa-play-circle" title="Turn On Account" onClick={() => handleAction(powerOnAccounts, _id)}></i> : <i className="accounts__button fas fa-power-off" title="Turn Off Account" onClick={() => handleAction(powerOffAccounts, _id)}></i>}</div>
            <div title={!isActive ? "Remove from dashboard" : `Can't remove from dashboard while account is active`}>
              <i style={{ color: "#970000" }} className={`accounts__button fas fa-times-circle ${isActive && "disabled"}`} onClick={() => handleActionDelete(removeAccountsFromDashboard, _id)}></i>
            </div>
            <div title="Edit account">
              <i className="accounts__button fas fa-user-edit" onClick={handlePopup}></i>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </td>

      <td></td>

      <td className="accounts__buttons">
        {!isLoading ? (
          <>
            <div title={isActive ? "Restart VM" : `Can't perform action on inactive account`}>
              <i className={`accounts__button fas fa-sync-alt ${!isActive && "disabled"}`} title="Restart VM" onClick={() => handleAction(restartVms, _id)}></i>
            </div>

            <div title={isActive ? "Turn Off VM" : `Can't perform action on inactive account`}>
              <i className={`accounts__button fas fa-power-off ${!isActive && "disabled"}`} title="Turn Off VM" onClick={() => handleAction(turnOffVms, _id)}></i>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </td>
      {showPriority && <td>{priority}</td>}
      {showLimitRes && <td>{limitResources}</td>}

      {showFlashOn && flashOn && <td>{flashOn.toUpperCase()}</td>}
      {showMissions && missionTracker && completeMissionUntil && (
        <td>
          {missionTracker.completed}/{completeMissionUntil}
        </td>
      )}
      {showDisenchant && <td>{!disenchantLoot ? <i style={{ color: "#970000" }} className="fas fa-times"></i> : <i style={{ color: "green" }} className={`fas fa-check`}></i>}</td>}
      {showIps && <td>{ip}</td>}
      {showMac && <td>{macAddress}</td>}

      {showFinishStartDates && finishAndStart && (
        <td>
          <div className="tooltip" style={{ border: "none" }}>
            <span className="tooltiptext">
              {finishAndStart.started.date === 0 ? (
                <p style={{ margin: "0" }}>
                  <span style={{ color: "#bdbdbd" }}>Started: </span> N/A
                </p>
              ) : (
                <p style={{ margin: "0" }}>
                  {" "}
                  <span style={{ color: "#bdbdbd" }}>Started: </span>
                  {formatTime(finishAndStart.started.date)}
                </p>
              )}
              {finishAndStart.finished.date === 0 ? (
                <p style={{ margin: "0" }}>
                  <span style={{ color: "#bdbdbd" }}>Finished: </span> N/A
                </p>
              ) : (
                <p style={{ margin: "0" }}>
                  {" "}
                  <span style={{ color: "#bdbdbd" }}>Finished: </span>
                  {formatTime(finishAndStart.finished.date)}
                </p>
              )}
            </span>
            <i className="fas fa-info-circle"></i>
          </div>
        </td>
      )}

      {showCheckerStatus && (
        <td>
          <p style={{ margin: "0", color: "black" }}>{checker.status}</p>
        </td>
      )}
      {showFreeNameChange && <td>{freeNameChange ? <i style={{ color: "green" }} className="fas fa-check"></i> : <i style={{ color: "rgb(151, 0, 0)" }} className="fas fa-times"></i>}</td>}
      {showUserTaken && <td>{summonerNameTaken ? <i style={{ color: "green" }} className="fas fa-check"></i> : <i style={{ color: "rgb(151, 0, 0)" }} className="fas fa-times"></i>}</td>}
      {showHasActiveBoost && (
        <td>
          {Date.now() < hasBoostActive ? (
            <div className="tooltip">
              <span className="tooltiptext">Boost Ends: {formatTime(hasBoostActive)}</span>
              <i style={{ color: "green" }} className="fas fa-check"></i>
            </div>
          ) : (
            <i style={{ color: "rgb(151, 0, 0)" }} className="fas fa-times"></i>
          )}
        </td>
      )}

      {showVmCloneNumber && <td>{vmNumber}</td>}
      {showLimitFps && <td>{limitFps}</td>}
      {showServerId && <td>{serverId}</td>}
      {showBotVersion && <td>{botVersion}</td>}
      {showCountry && <td>{country}</td>}

      {showLastGamePlayed && <td style={{ fontSize: "0.8rem" }}>{formatTime(lastTimePlayed)}</td>} */}
    </tr>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  skinsData: state.accounts.skinsData,
});
export default connect(mapStateToProps, {
  pauseAccounts,
  stopAccounts,
  restartAccounts,
  powerOffAccounts,
  powerOnAccounts,
  unpauseAccounts,
  removeAccountsFromDashboard,
  setOfflineTesting,
  revertActions,
  restartVms,
  turnOffVms,
})(AccountItem);
