import React, { useState } from 'react';
import { connect } from 'react-redux';

import { createUser } from '../../actions/authActions';

const AddAccounts = ({ createUser }) => {
  const [userTemplate, setUserTemplate] = useState({
    username: '',
    email: '@example.com',
    role: 'user',
    password: '',
    maxAccounts: 999,
    prioritizeHigherLvls: true,
    timeOfLicense: 2592000000,
    months: 1,
  });

  const onChangeSelect = (event) => {
    setUserTemplate({
      ...userTemplate,
      role: event.target.value,
    });
  };
  const onChangeCheckBox = (event) => {
    setUserTemplate({
      ...userTemplate,
      [event.target.name]: event.target.checked,
    });
  };
  const onChange = (event) => {
    setUserTemplate({
      ...userTemplate,
      [event.target.name]: event.target.value,
    });
  };

  const { months, username, email, role, password, maxAccounts, prioritizeHigherLvls } = userTemplate;
  const onClick = () => {
    if (username != '' && email != '' && password) {
      console.log('Creating user...');
      console.log(userTemplate.username, userTemplate.email);
      let newUser = { ...userTemplate };
      newUser = { ...newUser, email: newUser.username + newUser.email, timeOfLicense: newUser.timeOfLicense * months };
      createUser(newUser);
    }
  };

  return (
    <div>
      <div>
        <label>Username</label>
        <input onChange={onChange} value={username} name='username' type='text'></input>
      </div>
      <div>
        <label>Password</label>
        <input onChange={onChange} value={password} name='password'></input>
      </div>
      <div>
        <label>Email</label>
        <input readOnly value={username + email}></input>
      </div>
      <div>
        <label>Role</label>
        <select name='role' onChange={onChangeSelect} defaultValue={role}>
          <option value='user'>user</option>
          <option value='admin'>admin</option>
        </select>
      </div>
      <div>
        <label>Licenses</label>
        <input onChange={onChange} name='maxAccounts' value={maxAccounts}></input>
      </div>
      <div>
        <label>Months</label>
        <input onChange={onChange} name='months' value={months}></input>
      </div>
      <label>Prioritize Higher LVLS</label>
      <input onChange={onChangeCheckBox} name='prioritizeHigherLvls' type='checkbox' value={prioritizeHigherLvls} defaultChecked={prioritizeHigherLvls}></input>
      <div className='button aqua-green' onClick={onClick}>
        Create Account
      </div>
    </div>
  );
};

export default connect(null, { createUser })(AddAccounts);

// {accounts.map(acc=> (

//   ))}
