import { GET_TASKS, DELETE_TASK, ADD_TASK, TOGGLE_ACTIVE, EDIT_TASK, TASKS_FAIL } from "../actions/types";

const initialState = {
  tasks: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TASKS:
      return {
        ...state,
        tasks: payload,
      };
    case ADD_TASK:
      return {
        ...state,
        tasks: [...state.tasks, payload],
      };
    case TOGGLE_ACTIVE:
      return {
        ...state,
        tasks: state.tasks.map((task) => (task._id == payload._id ? payload : task)),
      };
    case EDIT_TASK:
      // console.log(payload);
      return {
        ...state,
        tasks: state.tasks.map((task) => (task._id == payload._id ? payload : task)),
      };
    case DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter((task) => task._id !== payload),
      };

    default:
      return state;
  }
}
