import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { login } from "../actions/authActions";
import Spinner from "./Spinner";
import { useHistory } from "react-router-dom";
import reCAPTCHA from "react-google-recaptcha";

const Login = ({ props, auth: { isAuthenticated, loading, error }, login }) => {
  let history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push("/dashboard");
    }

    // eslint-disable-next-line
  }, [error, isAuthenticated, props]);

  const [loginUser, setLoginUser] = useState({
    username: "",
    password: "",
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { username, password } = loginUser;
  const onChange = (e) => setLoginUser({ ...loginUser, [e.target.name]: e.target.value.trim() });

  const LogIn = async (e) => {
    setErrorMessage("");
    if (username === "" || password === "") {
      setErrorMessage("Please fill in all fields");
    } else {
      setIsLoggingIn(true);
      const loginResponse = await login({ username, password });
      if (loginResponse == "BAD_CREDS") {
        setErrorMessage("Invalid credentials");
        setLoginUser({ ...loginUser, password: "" });
      }
      setIsLoggingIn(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      LogIn();
    }
  };
  return (
    <div className="main" style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "90%" }}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="login" onKeyDown={handleKeyDown}>
            <div className="login__container">
              <div className="login__title">Sign In</div>
              <form className="login__form">
                <div className="login__form__fields">
                  <div className="login__form__field">
                    <input type="username" name="username" value={username} onChange={onChange} placeholder="Username" autoFocus={true} />
                  </div>
                  <div className="login__form__field">
                    <input type="password" name="password" id="password" value={password} onChange={onChange} placeholder="Password" />
                  </div>
                </div>
                <div className="button aqua-green" onClick={LogIn} style={{ width: "auto", marginTop: "1em" }}>
                  {isLoggingIn ? (
                    <div className="loadingio-spinner-eclipse-gr3e527dds">
                      <div className="ldio-vbb5fe2lwi">
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <>
                        <span>Sign In</span>
                        <i className="fa-solid fa-arrow-right-to-bracket"></i>
                      </>
                    </>
                  )}
                </div>

                {errorMessage && (
                  <div style={{ alignItems: "center", marginTop: "1em", padding: "15px", border: "1px solid #302f4e", borderRadius: "10px", backgroundColor: "#3b3e59" }}>
                    <i style={{ fontSize: "1.1rem", color: "#e70000" }} className="far fa-times-circle"></i>
                    <span style={{ marginLeft: "1em", color: "#dedede" }}>{errorMessage}</span>
                  </div>
                )}
                <div style={{ justifyContent: "space-around" }}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <p style={{ margin: "20px 0px 0px 0px" }}>
                      Don't have an account?{" "}
                      <span onClick={() => history.push("/register")} style={{ cursor: "pointer", fontWeight: "bold" }}>
                        Register
                      </span>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <h4>
              More amazing software at{" "}
              <a target="__blank" style={{ color: "#9897bb" }} href="https://www.elitepvpers.com">
                https://www.elitepvpers.com
              </a>
              , visit them!
            </h4>
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  props,
  auth: state.auth,
});
export default connect(mapStateToProps, { login })(Login);
