import React from 'react';

const OverviewItem = ({ iconClassName, title, value, percentageValue }) => {
	return (
		<div className='overview__item'>
			<i className={iconClassName}></i>

			<div className='overview__item__desc'>
				<h2>{title}</h2>
				<span>{value}</span>
				{typeof percentageValue == 'number' && <span className='overview__item__percentage'>({percentageValue}%)</span>}
			</div>
		</div>
	);
};

export default OverviewItem;
