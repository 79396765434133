import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CustomNamesItem from "./CustomNamesItem";
import Spinner from "../Spinner";
import { getNames, addNames } from "../../actions/customNamesActions";
const CustomNames = ({ summonerNames, getNames, addNames }) => {
  useEffect(() => {
    getNames();
  }, []);
  const [summonerNamesTextArea, setSummonerNamesTextArea] = useState("");

  const [namesPerPage, setNamesPerPage] = useState(100);
  const [page, setPage] = useState(1);

  const [formattedSummonerNames, setFormattedSummonerPages] = useState(summonerNames);
  useEffect(() => {
    if (summonerNames) {
      let newPage = (page - 1) * namesPerPage > summonerNames.length ? 1 : page;
      let summonerNamesCopy = [...summonerNames].slice((newPage - 1) * namesPerPage, (newPage - 1) * namesPerPage + namesPerPage);
      setFormattedSummonerPages(summonerNamesCopy);
    }
  }, [summonerNames, namesPerPage, page]);

  const onChangeTextarea = (event) => {
    setSummonerNamesTextArea(event.target.value);
  };
  const [error, setError] = useState("");
  const onClickAddNames = async () => {
    if (summonerNamesTextArea.length > 0) {
      const names = summonerNamesTextArea.split("\n");
      let foundError = false;
      let invalidLine = 0;
      names.forEach((name, i) => {
        if (name.length > 16 || name.length <= 2) {
          foundError = true;
          invalidLine = i + 1;
        }
      });
      if (!foundError) {
        addNames({ names });
        setSummonerNamesTextArea("");
        setError("");
      } else {
        setError(`Name on line ${invalidLine} is invalid.`);
      }
    }
  };
  const onChangeNamesPerPage = (event) => {
    setNamesPerPage(Number(event.target.value));
  };
  const onChangePage = (event) => {
    setPage(Number(event.target.value));
  };

  return (
    <>
      <div className="main" style={{ paddingBottom: "2.5em" }}>
        <h1>Add Summoner Names</h1>
        <div className="credentials__textarea__container">
          <textarea className="textarea-summoner-names" onChange={onChangeTextarea} value={summonerNamesTextArea} placeholder={"summoner123\nsummoner123\nsummoner123\nsummoner123"}></textarea>
        </div>
        {error && (
          <div style={{ color: "red" }}>
            <h2>{error}</h2>
          </div>
        )}

        {formattedSummonerNames ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "160px", margin: "1em 0em 1em 0em" }} className="button aqua-green" onClick={onClickAddNames}>
                  <span>Add Summoner Names</span>
                </div>
                <div style={{ marginLeft: "1em" }}>Total names: {summonerNames.length}</div>
              </div>
              <div>
                <label style={{ marginRight: ".5em" }}>Names per page</label>
                <select className="credentials__input" style={{ width: "60px" }} onChange={onChangeNamesPerPage} defaultValue={namesPerPage}>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <div>
                <label style={{ marginRight: ".5em" }}>Page</label>
                <select className="credentials__input" style={{ width: "60px" }} onChange={onChangePage} defaultValue={page}>
                  {Array.from({ length: Math.ceil(summonerNames.length / namesPerPage) }, (_, i) => i + 1).map((page) => (
                    <option key={page} value={page}>
                      {page}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {formattedSummonerNames.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Name</th>

                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {formattedSummonerNames.map((name) => (
                    <CustomNamesItem key={name._id} name={name} />
                  ))}
                </tbody>
              </table>
            ) : (
              <h1>No names added yet.</h1>
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  summonerNames: state.summonerNames.summonerNames,
});
export default connect(mapStateToProps, { getNames, addNames })(CustomNames);
