import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { addTask } from "../../actions/taskActions";
import Spinner from "../Spinner";
import getChampionName from "../../utils/getChampionName";
import formatPlaytime from "../../utils/formatPlaytimeDashboard";
import longAgo from "../../utils/longAgo";
import styles from "./Tasks.module.scss";
const UpdateItem = ({ update, sendUpdateUpdate, allowedUpdateFieldNames }) => {
  const [newUpdate, setUpdate] = useState(update);

  const onChange = (event) => {
    setUpdate({ ...newUpdate, [event.target.name]: event.target.value });
    // sendUpdateFilter({ ...filter, [event.target.name]: event.target.value });
    sendUpdateUpdate({ ...newUpdate, [event.target.name]: event.target.value });
  };
  const onClickRemoveUpdate = () => {
    sendUpdateUpdate({ _id: update._id, type: "remove" });
  };
  // console.log('Filter in UpdateItem', filter);
  // console.log('Update in UpdateItem', update);
  const formatPlaceholders = (fieldName) => {
    const a = allowedUpdateFieldNames[fieldName]["enum"];
    if (a.length > 0) {
      return a.map((e, i) => (i === a.length ? `${e}, ` : `${e}`));
    } else {
      return allowedUpdateFieldNames[fieldName]["type"];
    }
  };
  return (
    <>
      <div>
        <div key={update._id} className={styles.taskFilterItem}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
            <span>Update Type: </span>
            <select style={{ marginLeft: "5px" }} className="credentials__input" name="updateType" onChange={onChange} defaultValue={newUpdate.updateType}>
              <option value="fields">Fields</option>
              <option value="manual_finish">Finish Account</option>
            </select>
          </div>
          {update.updateType != "manual_finish" && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
                <span>Field Name: </span>
                <select style={{ marginLeft: "5px" }} className="credentials__input" name="fieldName" onChange={onChange} defaultValue={newUpdate.fieldName}>
                  <option value="disenchantFragChamps">DisenchantFragChamps</option>
                  <option value="disenchantFullChamps">DisenchantFullChamps</option>
                  <option value="disenchantLoot">Disenchant Loot</option>
                  <option value="disenchantEternal">Disenchant Eternals</option>
                  <option value="useCustomName">Use Custom Name</option>
                  <option value="showOnDashboard">Show On Dashboard</option>
                  <option value="disenchantGem">Disenchant Gem</option>
                  <option value="disenchantGemOption">Disenchant Gem Option</option>
                  <option value="mode">Mode</option>
                  <option value="region">Region</option>
                  <option value="cooldownAfterSession">CooldownAfterSession</option>
                  <option value="maxGamesReached">Max Games Reached</option>
                  <option value="disenchantEmblems">Disenchant Emblems</option>
                  <option value="disenchantEmblemsOption">Disenchant Emblems Option</option>
                  <option value="icon">Icon</option>
                  <option value="state">State</option>
                  <option value="skipIfNameTaken">SkipIfNameTaken</option>
                  <option value="priority">Priority</option>
                  <option value="desiredBE">DesiredBE</option>
                  <option value="desiredLevel">Desired Level</option>
                  <option value="desiredPercentage">Desired Percentage</option>
                  <option value="maxGames">Max Games</option>
                  <option value="amountOfGamesToFinish.amountOfGames">Games To Finish</option>
                  <option value="completeAllMissions">Complete All Missions</option> {/* HERE CHANGE */}
                  <option value="completeMissionUntil">Complete Missions Until</option> {/* HERE CHANGE */}
                  <option value="skipTutorial">Skip Tutorial</option> {/* HERE CHANGE */}
                  <option value="limitResources">Limit Resources</option> {/* HERE CHANGE */}
                  <option value="label">Label</option> {/* HERE CHANGE */}
                  <option value="crazyNumber">Temp Number</option> {/* HERE CHANGE */}
                </select>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "10px" }}>
                <span>Field Value: </span>
                <input placeholder={formatPlaceholders(update.fieldName)} style={{ marginLeft: "5px" }} onChange={onChange} name="fieldValue" className="credentials__input" value={newUpdate.fieldValue}></input>
              </div>
            </>
          )}
        </div>
        <div style={{ justifyContent: "center", display: "flex", marginTop: ".5em" }} title="Remove Update">
          <div style={{ height: "12px", width: "10px", backgroundColor: "#b93030" }} className="button aqua-green" onClick={onClickRemoveUpdate}>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>-</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateItem;
